// @flow

import { status } from 'modules/utils/enum';
import { SPECIALTIES } from '../actions/specialties';

const initialState = {
  mainSpecialties: new Map(),
  subSpecialties: new Map(),
  symptoms: { data: new Map(), MaximumEndorsementsPerDr: null },
  subSpecialtiesState: undefined,
  mainSpecialtiesObj: undefined,
  symptomsState: undefined,
  mainSpecialtiesState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SPECIALTIES.MAIN_SPECIALTIES.LOADING: {
      return {
        ...state,
        mainSpecialtiesState: SPECIALTIES.MAIN_SPECIALTIES.LOADING,
      };
    }

    case SPECIALTIES.MAIN_SPECIALTIES.SUCCEEDED: {
      const mainSpecialtiesObj = {};
      if (payload.mainSpecialties) {
        payload.mainSpecialties.forEach(mainSpecialty => {
          mainSpecialtiesObj[mainSpecialty.SpecialityKey] = mainSpecialty;
        });
      }

      const mainSpecialties = new Map();

      if (payload.mainSpecialties) {
        payload.mainSpecialties.forEach(mainSpecialty => {
          mainSpecialties.set(mainSpecialty.SpecialityKey, mainSpecialty);
        });
      }

      return {
        ...state,
        mainSpecialties,
        mainSpecialtiesObj,
        mainSpecialtiesState: SPECIALTIES.MAIN_SPECIALTIES.SUCCEEDED,
      };
    }

    case SPECIALTIES.MAIN_SPECIALTIES.FAILED: {
      return {
        ...state,
        mainSpecialties: new Map(),
        mainSpecialtiesState: SPECIALTIES.MAIN_SPECIALTIES.FAILED,
      };
    }

    case SPECIALTIES.SUB_SPECIALTIES.LOADING: {
      return {
        ...state,
        subSpecialtiesState: SPECIALTIES.SUB_SPECIALTIES.LOADING,
      };
    }

    case SPECIALTIES.SUB_SPECIALTIES.SUCCEEDED: {
      const subSpecialties = new Map();

      if (payload.subSpecialties) {
        payload.subSpecialties.forEach(subSpecialty => {
          subSpecialties.set(subSpecialty.SpecialityKey, subSpecialty);
        });
      }

      return {
        ...state,
        subSpecialties,
        subSpecialtiesState: SPECIALTIES.SUB_SPECIALTIES.SUCCEEDED,
      };
    }

    case SPECIALTIES.SUB_SPECIALTIES.FAILED: {
      return {
        ...state,
        subSpecialties: new Map(),
        subSpecialtiesState: SPECIALTIES.SUB_SPECIALTIES.FAILED,
      };
    }

    case SPECIALTIES.SYMPTOMS.LOADING: {
      return {
        ...state,
        symptomsState: SPECIALTIES.SYMPTOMS.LOADING,
      };
    }

    case SPECIALTIES.SYMPTOMS.SUCCEEDED: {
      const symptoms = new Map();
      if (payload.symptoms) {
        payload.symptoms.Symptoms.sort(() => Math.random() - 0.5).forEach(symptom => {
          symptoms.set(symptom.SymptomKey, symptom);
        });
      }

      return {
        ...state,
        symptoms: {
          data: symptoms,
          MaximumEndorsementsPerDr: payload.symptoms.MaximumEndorsementsPerDr,
        },
        symptomsState: SPECIALTIES.SYMPTOMS.SUCCEEDED,
      };
    }

    case SPECIALTIES.SYMPTOMS.FAILED: {
      return {
        ...state,
        symptoms: new Map(),
        symptomsState: SPECIALTIES.SYMPTOMS.FAILED,
      };
    }

    default: {
      return state;
    }
  }
};
