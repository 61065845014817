export const PATIENTS = {
  MEDICAL_CARD: {
    FETCH: 'FETCH_MEDICAL_CARD',
    SUCCESS: 'FETCH_FETCH_MEDICAL_CARD_SUCCESS',
    FAIL: 'FETCH_FETCH_MEDICAL_CARD_FAIL',
  },
  GET_PATIENT_DETAILS: {
    LOAD: 'PATIENTS_GET_PATIENT_DETAILS_LOAD',
    SUCCESS: 'PATIENTS_GET_PATIENT_DETAILS_SUCCESS',
    FAIL: 'PATIENTS_GET_PATIENT_DETAILS_FAIL',
  },
  GET_PATIENT_INFO: {
    LOAD: 'GET_PATIENT_INFO_LOAD',
    SUCCESS: 'GET_PATIENT_INFO_SUCCESS',
    FAIL: 'GET_PATIENT_INFO_FAIL',
  },
  FETCH: {
    LOAD: 'PATIENTS_FETCH_LOAD',
    SUCCESS: 'PATIENTS_FETCH_SUCCESS',
    FAIL: 'PATIENTS_FETCH_FAIL',
  },
  SEARCH: {
    LOAD: 'PATIENTS_SEARCH_LOAD',
    SUCCESS: 'PATIENTS_SEARCH_SUCCESS',
    FAIL: 'PATIENTS_SEARCH_FAIL',
  },
  DELETE: {
    LOAD: 'PATIENTS_DELETE_LOAD',
    SUCCESS: 'PATIENTS_DELETE_SUCCESS',
    FAIL: 'PATIENTS_DELETE_FAIL',
  },
  ADD: {
    LOAD: 'PATIENTS_ADD_LOAD',
    SUCCESS: 'PATIENTS_ADD_SUCCESS',
    FAIL: 'PATIENTS_ADD_FAIL',
  },
  EDIT: {
    LOAD: 'PATIENTS_EDIT_LOAD',
    SUCCESS: 'PATIENT_EDIT_SUCCESS',
    FAIL: 'PATIENT_EDIT_FAIL',
  },
  EDIT_PATIENT_DETAILS: {
    LOAD: 'EDIT_PATIENT_DETAILS_LOAD',
    SUCCESS: 'EDIT_PATIENT_DETAILS_SUCCESS',
    FAIL: 'EDIT_PATIENT_DETAILS_FAIL',
  },
  MODAL: {
    DETAILS_TOGGLE: 'PATIENTS_MODAL_DETAILS_TOGGLE',
    DELETE_TOGGLE: 'PATIENTS_MODAL_DELETE_TOGGLE',
    NOTES_TOGGLE: 'PATIENTS_MODAL_NOTES_TOGGLE',
    ADD_PATIENT_TOGGLE: 'ADD_PATIENT_TOGGLE',
  },
  SNACK_BAR: {
    HIDE: 'APPOINTMENTS_SNACK_BAR_HIDE',
  },
  SET_PATIENT_DETAILS: 'SET_PATIENT_DETAILS',
  PROFILE: {
    SET_PATIENT_PROFILE_INFO: 'SET_PATIENT_PROFILE_INFO',
  },
  SET_DELETE_PATIENT_DETAILS: 'SET_DELETE_PATIENT_DETAILS',
  SET_PATIENT_NOTES: 'SET_PATIENT_NOTES',
  PATIENT_LIST: {
    FETCH: 'FETCH_PATIENT_LIST',
    SUCCESS: 'FETCH_PATIENT_LIST_SUCCESS',
    FAIL: 'FETCH_PATIENT_LIST_FAIL',
  },
};

export const toggleAddPatientModal = isOpened => ({
  type: PATIENTS.MODAL.ADD_PATIENT_TOGGLE,
  isOpened,
});

export const fetchPatients = entityKey => ({
  type: PATIENTS.FETCH.LOAD,
  entityKey,
});

export const fetchPatientsSuccess = patients => ({
  type: PATIENTS.FETCH.SUCCESS,
  patients,
});

export const fetchPatientsFail = error => ({
  type: PATIENTS.FETCH.FAIL,
  error,
});

export const getPatientDetails = patientKey => ({
  type: PATIENTS.GET_PATIENT_DETAILS.LOAD,
  patientKey,
});

export const getPatientDetailsSuccess = patient => ({
  type: PATIENTS.GET_PATIENT_DETAILS.SUCCESS,
  patient,
});

export const getPatientDetailsFail = error => ({
  type: PATIENTS.GET_PATIENT_DETAILS.FAIL,
  error,
});

export const searchPatients = (query, entitiesKeys, source = 'searchBar') => ({
  type: PATIENTS.SEARCH.LOAD,
  query,
  entitiesKeys,
  source,
});

export const searchPatientsSuccess = patients => ({
  type: PATIENTS.SEARCH.SUCCESS,
  patients,
});

export const searchPatientsFail = error => ({
  type: PATIENTS.SEARCH.FAIL,
  error,
});

export const deletePatient = patientKey => ({
  type: PATIENTS.DELETE.LOAD,
  patientKey,
});

export const deletePatientSuccess = patientKey => ({
  type: PATIENTS.DELETE.SUCCESS,
  patientKey,
});

export const deletePatientFail = error => ({
  type: PATIENTS.DELETE.FAIL,
  error,
});

export const addPatient = (patient, entityKey) => ({
  type: PATIENTS.ADD.LOAD,
  patient,
  entityKey,
});

export const addPatientSuccess = (patient, callback, callbackParams) => ({
  type: PATIENTS.ADD.SUCCESS,
  patient,
  callback,
  callbackParams,
});

export const addPatientFail = error => ({
  type: PATIENTS.ADD.FAIL,
  error,
});

export const editPatientDetails = patient => ({
  type: PATIENTS.EDIT_PATIENT_DETAILS.LOAD,
  patient,
});

export const editPatientDetailsSuccess = (patient, callback, callbackParams) => ({
  type: PATIENTS.EDIT_PATIENT_DETAILS.SUCCESS,
  patient,
  callback,
  callbackParams,
});

export const editPatientDetailsFail = error => ({
  type: PATIENTS.EDIT_PATIENT_DETAILS.FAIL,
  error,
});

export const editPatient = patient => ({
  type: PATIENTS.EDIT.LOAD,
  patient,
});

export const editPatientSuccess = patient => ({
  type: PATIENTS.EDIT.SUCCESS,
  patient,
});

export const editPatientFail = error => ({
  type: PATIENTS.EDIT.FAIL,
  error,
});

export const togglePatientDetailsModal = isOpened => ({
  type: PATIENTS.MODAL.DETAILS_TOGGLE,
  isOpened,
});

export const setPatientDetails = patientDetails => ({
  type: PATIENTS.SET_PATIENT_DETAILS,
  patientDetails,
});

export const setPatientProfileInfo = patientDetails => ({
  type: PATIENTS.PROFILE.SET_PATIENT_PROFILE_INFO,
  patientDetails,
});

export const toggleDeletePatientModal = isOpened => ({
  type: PATIENTS.MODAL.DELETE_TOGGLE,
  isOpened,
});
export const setDeletePatientDetails = patientDetails => ({
  type: PATIENTS.SET_DELETE_PATIENT_DETAILS,
  patientDetails,
});

export const togglePatientNotesModal = isOpened => ({
  type: PATIENTS.MODAL.NOTES_TOGGLE,
  isOpened,
});

export const setPatientNotes = notes => ({
  type: PATIENTS.SET_PATIENT_NOTES,
  notes,
});
export const setPatientsSnackBarAsHidden = () => ({
  type: PATIENTS.SNACK_BAR.HIDE,
});

export const fetchPatientList = payload => ({
  type: PATIENTS.PATIENT_LIST.FETCH,
  payload,
});

export const fetchPatientListSuccess = payload => ({
  type: PATIENTS.PATIENT_LIST.SUCCESS,
  payload,
});

export const fetchPatientListFail = payload => ({
  type: PATIENTS.PATIENT_LIST.FAIL,
  payload,
});

export const fetchPatientInfo = payload => ({
  type: PATIENTS.GET_PATIENT_INFO.LOAD,
  payload,
});

export const fetchPatientInfoSuccess = payload => ({
  type: PATIENTS.GET_PATIENT_INFO.SUCCESS,
  payload,
});

export const fetchPatientInfoFail = payload => ({
  type: PATIENTS.GET_PATIENT_INFO.FAIL,
  payload,
});

export const getMedicalCardByKey = payload => ({
  type: PATIENTS.MEDICAL_CARD.FETCH,
  payload,
});

export const getMedicalCardByKeySuccess = payload => ({
  type: PATIENTS.MEDICAL_CARD.SUCCESS,
  payload,
});

export const getMedicalCardByKeyFail = payload => ({
  type: PATIENTS.MEDICAL_CARD.FAIL,
  payload,
});
