export const USER = {
  GET_USER: 'USER_SET',
  SET_USER_DONE: 'USER_SET_DONE',
  SET_USER_FAIL: 'USER_SET_FAIL',
  SET_USER_LANGUAGE: 'SET_USER_LANGUAGE',
  SET_USER_LANGUAGE_DONE: 'SET_USER_LANGUAGE_DONE',
  SET_USER_LANGUAGE_FAIL: 'SET_USER_LANGUAGE_FAIL',
  USER_LANGUAGE_MODAL_TOGGLE: 'SET_USER_LANGUAGE_MODAL_TOGGLE',
};

export const getUser = language => ({
  type: USER.GET_USER,
  language,
});

export const setUserDone = user => ({
  type: USER.SET_USER_DONE,
  user,
});

export const setUserFail = err => ({
  type: USER.SET_USER_FAIL,
  err,
});

export const toggleUserDefaultLangaugeModal = show => ({
  type: USER.USER_LANGUAGE_MODAL_TOGGLE,
  show,
});

export const setUserDefaultLangauge = (languageId, reload) => ({
  type: USER.SET_USER_LANGUAGE,
  languageId,
  reload,
});

export const setUserDefaultLangaugeDone = language => ({
  type: USER.SET_USER_LANGUAGE_DONE,
  language,
});

export const setUserDefaultLangaugeFail = () => ({
  type: USER.SET_USER_LANGUAGE_FAIL,
});
