import { renderer } from '@vezeeta/web-components';
import { getFormattedDate, getDateAfterGivenDays, toIndiaDigits } from 'modules/utils/Helpers';

import { VACATIONS } from '../actions/vacations';
import VACATIONS_STATES from '../types/vacations';
import { language } from '../types/language';

const todayDate = new Date();
const dateAfterSixDays = getDateAfterGivenDays(6);
const defaultState = {
  vacations: renderer.loading(),
  vacationsList: [],
  selected: {
    branches: [],
    rooms: [],
    doctors: [],
    startDate: getFormattedDate(todayDate),
    endDate: getFormattedDate(dateAfterSixDays),
  },
  addVacation: {
    data: '',
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  deleteVacation: {
    data: '',
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  reservationsInRange: {
    reservationsInRange: null,
    data: language === 'en' ? 'Choose vacation range' : 'اختر تاريخ بدء وانتهاء العطلة',
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  isCalendarEmpty: false,
  isFilterEmpty: false,
  snackBar: { show: false, message: '' },
  addVacationModal: { show: false },
  deleteVacationModal: {
    show: false,
    vacationDetails: {},
  },
};

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case VACATIONS.LOAD:
      return Object.assign({}, state, {
        vacations: renderer.loading(),
      });

    case VACATIONS.LOAD_DONE: {
      if (action.vacations.length > 0) {
        return Object.assign({}, state, {
          vacations: renderer.success(action.vacations),
          vacationsList: action.vacations,
        });
      }
      return Object.assign({}, state, {
        vacations: renderer.empty(VACATIONS_STATES.NO_VACATIONS),
        vacationsList: action.vacations,
      });
    }

    case VACATIONS.LOAD_FAIL: {
      return Object.assign({}, state, {
        vacations: renderer.failure(
          language === 'en' ? 'An Error has happened' : 'لقد حدث خطاء ما',
        ),
      });
    }

    case VACATIONS.FILTERED.SET_SELECTED_BRANCH: {
      return Object.assign({}, state, {
        selected: Object.assign({}, state.selected, { branches: action.branches }),
      });
    }

    case VACATIONS.FILTERED.SET_SELECTED_ROOMS: {
      if (action.rooms.length > 0) {
        return Object.assign({}, state, {
          isFilterEmpty: false,
          selected: Object.assign({}, state.selected, { rooms: action.rooms }),
        });
      }
      return Object.assign({}, state, {
        isFilterEmpty: true,
        vacations: renderer.empty(VACATIONS_STATES.EMPTY_FILTER),
        selected: Object.assign({}, state.selected, { rooms: action.rooms }),
      });
    }

    case VACATIONS.FILTERED.SET_SELECTED_DOCTORS: {
      const selectedDoctors = action.doctors;
      if (selectedDoctors.length > 0) {
        return Object.assign({}, state, {
          isFilterEmpty: false,
          selected: Object.assign({}, state.selected, { doctors: selectedDoctors }),
        });
      }

      return Object.assign({}, state, {
        isFilterEmpty: true,
        vacations: renderer.empty(
          action.hasDoctors ? VACATIONS_STATES.EMPTY_FILTER : VACATIONS_STATES.NO_USERS,
        ),
        selected: Object.assign({}, state.selected, { doctors: selectedDoctors }),
      });
    }

    case VACATIONS.FILTERED.SET_DATES: {
      if (!action.data.startDate || !action.data.endDate) {
        return Object.assign({}, state, {
          isCalendarEmpty: true,
          vacations: renderer.empty(VACATIONS_STATES.EMPTY_CALENDAR),
          selected: Object.assign({}, state.selected, {
            startDate: action.data.startDate,
            endDate: action.data.endDate,
          }),
        });
      }

      const {
        selected: { doctors },
      } = state;

      let { vacations } = state;
      if (doctors.length === 0) {
        vacations = renderer.empty(VACATIONS_STATES.NO_USERS);
      }

      return Object.assign({}, state, {
        isCalendarEmpty: false,
        vacations,
        selected: Object.assign({}, state.selected, {
          startDate: action.data.startDate,
          endDate: action.data.endDate,
        }),
      });
    }

    case VACATIONS.ADD: {
      const addVacation = action.vacation ? action.vacation : [];
      return Object.assign({}, state, {
        addVacation: {
          data: addVacation,
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
          },
        },
      });
    }

    case VACATIONS.ADD_DONE: {
      if (state.vacations && action.vacation) {
        const newState = { ...state };
        const justAddedArray = state.vacationsList;
        action.vacation.forEach(vacation => {
          const vacationObject = vacation;
          vacationObject.Model[0].justAdded = true;
          vacationObject.DoctorName = action.doctors.find(
            doctor => vacationObject.AccountKey === doctor.AccountKey,
          ).DoctorName;
          vacationObject.RoomName = action.rooms.find(
            room => vacationObject.RoomKey === room.RoomKey,
          ).RoomName;
          justAddedArray.unshift(vacationObject);
        });

        return Object.assign({}, newState, {
          vacations: renderer.success(justAddedArray),
          vacationsList: justAddedArray,
          addVacation: {
            isFetching: false,
            isSuccess: true,
            isFail: {
              isError: false,
            },
          },
          reservationsInRange: {
            reservationsInRange: null,
            data: language === 'en' ? 'Choose vacation range' : 'اختر تاريخ بدء وانتهاء العطلة',
            isFetching: false,
            isSuccess: false,
            isFail: { isError: false, message: '' },
          },
          addVacationModal: { show: false },
          snackBar: {
            show: true,
            message: language === 'en' ? 'Vacation Added successfully' : 'تم إضافة العطلة بنجاح',
          },
        });
      }
      return Object.assign({}, state, {
        addVacation: {
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
          },
        },
        reservationsInRange: {
          reservationsInRange: null,
          data: language === 'en' ? 'Choose vacation range' : 'اختر تاريخ بدء وانتهاء العطلة',
          isFetching: false,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
        addVacationModal: { show: false },
        snackBar: {
          show: !state.snackBar.show,
          message: language === 'en' ? 'Vacation Added successfully' : 'تم إضافة العطلة بنجاح',
        },
      });
    }
    case VACATIONS.ADD_FAIL: {
      return Object.assign({}, state, {
        addVacation: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: action.err ? action.err : action.res,
          },
        },
        addVacationModal: {
          show: false,
        },
        reservationsInRange: {
          reservationsInRange: null,
          data: language === 'en' ? 'Choose vacation range' : 'اختر تاريخ بدء وانتهاء العطلة',
          isFetching: false,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
        snackBar: {
          show: !state.snackBar.show,
          message: language === 'en' ? 'An Error has happened' : 'لقد حدث خطاء ما',
        },
      });
    }
    case VACATIONS.DELETE: {
      return Object.assign({}, state, {
        deleteVacation: {
          data: '',
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      });
    }
    case VACATIONS.DELETE_DONE: {
      if (state.vacations && action.vacation) {
        const newState = { ...state };
        newState.vacationsList = state.vacationsList;
        action.vacation.doctors.forEach(doctor => {
          newState.vacationsList.forEach((vacation, index) => {
            const newStateVacation = vacation;
            const checkAccount = newStateVacation.AccountKey === doctor.AccountKey;
            const checkRoom = newStateVacation.RoomKey === doctor.RoomKey;
            const checkDates =
              newStateVacation.Model &&
              newStateVacation.Model[0].From === action.vacation.startDate &&
              newStateVacation.Model[0].To === action.vacation.endDate;
            if (checkAccount && checkRoom && checkDates) {
              newStateVacation.Model[0].isDeleted = true;
              newState.vacationsList[index] = newStateVacation;
            }
          });
        });
        return Object.assign({}, newState, {
          vacations: renderer.success(newState.vacationsList),
          vacationsList: newState.vacationsList,
          deleteVacation: {
            data: '',
            isFetching: false,
            isSuccess: true,
            isFail: { isError: false, message: '' },
          },
          snackBar: {
            show: true,
            message: language === 'en' ? 'Vacation deleted successfully' : 'تم حذف العطلة بنجاح',
          },
          deleteVacationModal: { show: false, vacationDetails: {} },
        });
      }
      return Object.assign({}, state, {
        deleteVacation: {
          data: '',
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        snackBar: {
          show: true,
          message: language === 'en' ? 'Vacation deleted successfully' : 'تم حذف العطلة بنجاح',
        },
        deleteVacationModal: { show: false, vacationDetails: {} },
      });
    }
    case VACATIONS.DELETE_FAIL: {
      return Object.assign({}, state, {
        deleteVacation: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: action.err ? action.err : action.res,
          },
        },
      });
    }
    case VACATIONS.MODAL.TOGGLE_DELETE: {
      return Object.assign({}, state, {
        deleteVacationModal: { show: action.showModal, vacationDetails: {} },
      });
    }
    case VACATIONS.MODAL.TOGGLE_ADD: {
      return Object.assign({}, state, {
        addVacationModal: { show: action.showModal },
      });
    }
    case VACATIONS.SET_DELETE_MESSAGE: {
      return Object.assign({}, state, {
        deleteVacationModal: {
          show: state.deleteVacationModal.show,
          vacationDetails: action.message,
        },
      });
    }
    case VACATIONS.RESERVATIONS_IN_RANGE.LOAD: {
      return Object.assign({}, state, {
        reservationsInRange: {
          reservationsInRange: null,
          data: 0,
          isFetching: true,
          isSuccess: false,
          isFail: {
            isError: false,
          },
        },
      });
    }
    case VACATIONS.RESERVATIONS_IN_RANGE.LOAD_DONE: {
      let message;
      if (action.reservationsInRange === 0) {
        message =
          language === 'en'
            ? 'There are no appointments in this range'
            : 'لا يوجد حجوزات في الفترة المحددة';
      } else {
        const enMessage = `${action.reservationsInRange} appointment${
          action.reservationsInRange > 1 ? 's' : ''
        } will be canceled`;
        let cancelWord;
        if (action.reservationsInRange > 2 && action.reservationsInRange < 11) {
          cancelWord = 'حجوزات';
        } else if (action.reservationsInRange === 2) {
          cancelWord = 'حجزين';
        } else {
          cancelWord = 'حجز';
        }
        const reservNumber =
          language === 'en'
            ? action.reservationsInRange
            : toIndiaDigits(action.reservationsInRange.toString());

        const arMessage = `سيتم إلغاء ${
          action.reservationsInRange !== 2 ? reservNumber : ''
        } ${cancelWord}`;

        message = language === 'en' ? enMessage : arMessage;
      }

      return Object.assign({}, state, {
        reservationsInRange: {
          reservationsInRange: action.reservationsInRange,
          data: message,
          isFetching: false,
          isSuccess: true,
          isFail: {
            isError: false,
          },
        },
      });
    }
    case VACATIONS.RESERVATIONS_IN_RANGE.LOAD_FAIL: {
      return Object.assign({}, state, {
        reservationsInRange: {
          isFetching: false,
          isSuccess: false,
          isFail: {
            isError: true,
            message: action.err ? action.err : action.res,
          },
        },
      });
    }
    case VACATIONS.RESERVATIONS_IN_RANGE.RESET: {
      return {
        ...state,
        reservationsInRange: {
          data: language === 'en' ? 'Choose vacation range' : 'اختر تاريخ بدء وانتهاء العطلة',
          isSuccess: false,
          isFail: {
            isError: false,
            message: '',
          },
        },
      };
    }
    case VACATIONS.SET_SNACK_BAR_AS_HIDDEN: {
      return Object.assign({}, state, {
        snackBar: { show: false },
      });
    }
    default:
      return state;
  }
};
