import { status } from '../enum';

import { PLANS } from '../actions/plans';

const initialState = {
  plansLoadState: status.SHOULD_CALL_API,
  plans: undefined,
  subscribePlansLoadState: status.SHOULD_CALL_API,
  subscribePlans: undefined,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case PLANS.GET_PLANS.LOAD:
      return { ...state, plansLoadState: status.FETCHING, plans: undefined };
    case PLANS.GET_PLANS.SUCCESS: {
      return {
        ...state,
        plansLoadState: status.SUCCESS,
        plans: payload.payload,
      };
    }
    case PLANS.GET_PLANS.FAIL:
      return { ...state, plansLoadState: status.FAIL, plans: payload.payload };

    case PLANS.SUBSCRIBE.LOAD:
      return {
        ...state,
        subscribePlansLoadState: status.SUBMITING,
        subscribePlans: undefined,
        plans: undefined,
      };
    case PLANS.SUBSCRIBE.SUCCESS: {
      return {
        ...state,
        subscribePlansLoadState: status.SUCCESS,
        subscribePlans: payload.payload,
      };
    }
    case PLANS.SUBSCRIBE.FAIL:
      return { ...state, subscribePlansLoadState: status.FAIL, subscribePlans: payload.payload };

    default:
      return state;
  }
};
