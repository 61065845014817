export const WIDGETS = {
  SHOW_SNACK_BAR: 'SHOW_SNACK_BAR',
  HIDE_SNACK_BAR: 'HIDE_SNACK_BAR',
};

export const showSnackBar = (message, color, backgroundColor, icon) => ({
  type: WIDGETS.SHOW_SNACK_BAR,
  message,
  color,
  backgroundColor,
  icon,
});

export const hideSnackBar = () => ({
  type: WIDGETS.HIDE_SNACK_BAR,
});
