import { CITIES_AREAS } from '../actions/citiesAndAreas';

const initialState = {
  cities: {
    list: [],
    loaded: false,
  },
  areas: {
    list: [],
    loaded: false,
  },
  branchesAreas: {
    data: undefined,
    loaded: false,
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case CITIES_AREAS.CITIES.LOAD:
      return {
        ...state,
        cities: {
          list: [],
          loaded: false,
        },
      };

    case CITIES_AREAS.CITIES.LOAD_DONE:
      return {
        ...state,
        cities: {
          list: payload.cities,
          loaded: true,
        },
      };

    case CITIES_AREAS.CITIES.LOAD_FAIL:
      return {
        ...state,
        cities: {
          list: [],
          loaded: false,
        },
      };

    case CITIES_AREAS.AREAS.LOAD:
      return {
        ...state,
        areas: {
          list: [],
          loaded: false,
        },
      };

    case CITIES_AREAS.AREAS.LOAD_DONE:
      return {
        ...state,
        areas: {
          list: payload.areas,
          loaded: true,
        },
      };

    case CITIES_AREAS.AREAS.LOAD_FAIL:
      return {
        ...state,
        areas: {
          list: [],
          loaded: false,
        },
      };

    case CITIES_AREAS.BRANCHES_AREAS.LOAD:
      return {
        ...state,
        branchesAreas: {
          data: undefined,
          loaded: false,
        },
      };

    case CITIES_AREAS.BRANCHES_AREAS.LOAD_DONE:
      return {
        ...state,
        branchesAreas: {
          data: payload.areas,
          loaded: true,
        },
      };

    case CITIES_AREAS.BRANCHES_AREAS.LOAD_FAIL:
      return {
        ...state,
        branchesAreas: {
          data: undefined,
          loaded: false,
        },
      };

    default:
      return state;
  }
};
