export const VEZEETA_SHAMEL_KEY = 'ins48a96c1020EG';

export const FILTER_NAMES = {
  BRANCHES: 'BRANCHES',
  ROOMS: 'ROOMS',
  DOCTORS: 'DOCTORS',
  DOCTORS_STATUS: 'DOCTORS_STATUS',
};

export const ALL_BRANCHES_VALUE = 'ALL_BRANCHES';

export const CONFIRMATION_SELECT_TYPES = {
  THIS_WEEK: 'THIS_WEEK',
  NEXT_WEEK: 'NEXT_WEEK',
  THIRD_WEEK: 'THIRD_WEEK',
  FOURTH_WEEK: 'FOURTH_WEEK',
};

export const PAGE_SIZE = 10;

export const CALENDAR_EMPTY_ERROR = 'Select start/end dates from the Calendar';

export const FILTER_EMPTY_ERROR = 'Select a doctor from the filter';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later';

export const doctorsStatusList = [
  {
    name: {
      en: 'All Status',
      ar: 'جميع الحالات',
    },
    value: 6,
    enabled: true,
  },
  {
    name: {
      en: 'Published',
      ar: 'منشور',
    },
    value: 5,
    enabled: true,
  },
  {
    name: {
      en: 'Ready for Publish',
      ar: 'جاهز للنشر',
    },
    value: 2,
    enabled: true,
  },
  {
    name: {
      en: 'Pending',
      ar: '',
    },
    value: 1,
    enabled: false,
  },
  {
    name: {
      en: 'In Active',
      ar: '',
    },
    value: 3,
    enabled: false,
  },
  {
    name: {
      en: 'Deleted',
      ar: '',
    },
    value: 4,
    enabled: false,
  },
];
