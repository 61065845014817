import { status } from 'modules/utils/enum';
import { BIDS, BID_INFO, PLACE_BID, SNAKE_BAR, TOGGLE_TUTORIAL_MODAL } from '../actions/bid';

const initialState = {
  bids: [],
  bidsLoadState: status.SHOULD_CALL_API,
  placeBidState: status.SHOULD_CALL_API,
  bidInfo: undefined,
  snackBar: {
    show: false,
    message: undefined,
  },
  isTutorialModalShown: false,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case BIDS.FETCH:
      return { ...state, bidsLoadState: status.FETCHING, bids: [] };
    case BIDS.SUCCESS: {
      return { ...state, bidsLoadState: status.SUCCESS, bids: payload.payload };
    }
    case BIDS.FAIL:
      return { ...state, bidsLoadState: status.FAIL };

    case PLACE_BID.SAVE:
      return { ...state, placeBidState: status.SUBMITING };
    case PLACE_BID.SUCCESS: {
      return {
        ...state,
        placeBidState: status.SUCCESS,
      };
    }
    case PLACE_BID.FAIL:
      return { ...state, placeBidState: status.FAIL };

    case BID_INFO.SET:
      return { ...state, bidInfo: payload.payload };

    case TOGGLE_TUTORIAL_MODAL.TOGGLE:
      return { ...state, isTutorialModalShown: payload.payload };

    case SNAKE_BAR.HIDE_SNACK_BAR: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
