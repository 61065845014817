export const ACTIVITIES = {
  ACTIVITIES_RESERVATION: {
    LOAD: 'ACTIVITIES_RESERVATION_LOAD',
    SUCCESS: 'ACTIVITIES_RESERVATION_LOAD_SUCCESS',
    FAIL: 'ACTIVITIES_RESERVATION_LOAD_FAIL',
    RESET: 'ACTIVITIES_RESERVATION_RESET',
  },
};

export const getActivitiesReservations = payload => ({
  type: ACTIVITIES.ACTIVITIES_RESERVATION.LOAD,
  payload,
});

export const getActivitiesReservationsSuccess = payload => ({
  type: ACTIVITIES.ACTIVITIES_RESERVATION.SUCCESS,
  payload,
});

export const getActivitiesReservationsFail = payload => ({
  type: ACTIVITIES.ACTIVITIES_RESERVATION.FAIL,
  payload,
});

export const resetActivitiesList = () => ({
  type: ACTIVITIES.ACTIVITIES_RESERVATION.RESET,
});
