import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';
import { PREFIX_TITLES } from '../actions/prefixTitles';

function* getPrefixTitles(action) {
  const prefixTitlesApi = new API();
  const prefixTitlesApiResponse = yield prefixTitlesApi.get(
    `${Urls.getPrefixTitles}CountryId=${action.countryId}&LanguageId=${action.languageId}&IsActive=true&entityTypes=1&entityTypes=2&entityTypes=3&genderId=${action.genderId}`,
  );

  if (STATUS.isSuccess(prefixTitlesApiResponse.status)) {
    yield put({
      type: PREFIX_TITLES.SUCCEEDED,
      prefixTitles: prefixTitlesApiResponse.data,
    });
  } else {
    yield put({
      type: PREFIX_TITLES.FAILED,
      error: prefixTitlesApiResponse.data,
    });
  }
}

function* prefixTitlesSaga() {
  yield takeEvery(PREFIX_TITLES.LOADING, getPrefixTitles);
}

export default prefixTitlesSaga;
