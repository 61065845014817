// @flow

import { generateMapsFromArrayModels } from 'modules/utils/ArrayModels';

import { PREFIX_TITLES } from '../actions/prefixTitles';

const initialState = {
  prefixTitles: new Map(),
  prefixTitlesNames: new Map(),
  prefixTitlesState: undefined,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case PREFIX_TITLES.LOADING: {
      return {
        ...state,
        prefixTitlesState: PREFIX_TITLES.LOADING,
      };
    }

    case PREFIX_TITLES.SUCCEEDED: {
      const prefixTitles = new Map();
      const prefixTitlesNames = new Map();

      if (payload.prefixTitles) {
        payload.prefixTitles.forEach(prefixTitle => {
          prefixTitles.set(prefixTitle.PrefixTitleId, prefixTitle);
          const { Name } = generateMapsFromArrayModels(
            prefixTitle.PrefixTitleLanguageItemModels,
            'LanguageId',
            ['Name'],
          );
          prefixTitlesNames.set(prefixTitle.PrefixTitleId, Name);
        });
      }

      return {
        ...state,
        prefixTitles,
        prefixTitlesNames,
        prefixTitlesState: PREFIX_TITLES.SUCCEEDED,
      };
    }

    case PREFIX_TITLES.FAILED: {
      return {
        ...state,
        prefixTitles: new Map(),
        prefixTitlesState: PREFIX_TITLES.FAILED,
      };
    }

    default: {
      return state;
    }
  }
};
