import { combineReducers } from 'redux';
import insurances from './insurances';
import relativeTypes from './relativeTypes';
import clinics from './clinics';
import appointments from './appointments';
import confirmations from './confirmations';
import workingHours from './workingHours';
import roles from './roles';
import user from './user';
import vacations from './vacations';
import patients from './patients';
import room from './room';
import country from './country';
import schedules from './schedules';
import payment from './payment';
import specialties from './specialties';
import prefixTitles from './prefixTitles';
import professionalTitles from './professionalTitles';
import entities from './entities';
import services from './services';
import aman from './aman';
import assistant from './assistant';
import citiesAndAreas from './citiesAndAreas';
import accounts from './accounts';
import pharma from './Pharma';
import widgets from './widgets';
import bids from './bid';
import filters from './filters';
import activities from './activities';
import invoices from './invoices';
import servicesAndOperations from './servicesAndOperations';
import doctor from './doctor';
import plans from './plans';
import common from './common';

export default combineReducers({
  common,
  plans,
  doctor,
  servicesAndOperations,
  invoices,
  activities,
  filters,
  bids,
  insurances,
  relativeTypes,
  workingHours,
  clinics,
  room,
  appointments,
  confirmations,
  roles,
  user,
  vacations,
  patients,
  country,
  schedules,
  payment,
  specialties,
  prefixTitles,
  professionalTitles,
  entities,
  services,
  aman,
  assistant,
  citiesAndAreas,
  accounts,
  pharma,
  widgets,
});
