import { renderer } from '@vezeeta/web-components';

import { ASSISTANT } from '../actions/assistant';

const initialState = {
  getAssistants: {
    assistants: renderer.loading(),
    assistantsList: new Map(),
  },
  addAssistanthModal: {
    show: false,
  },
  assistantState: {
    isSaving: false,
  },
  addAssistant: {
    isSaving: false,
    addAssistantState: undefined,
    errorMessage: undefined,
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case ASSISTANT.FETCH_ASSISTANTS.LOAD: {
      return {
        ...state,
        getAssistants: {
          assistants: renderer.loading(),
          assistantsList: new Map(),
        },
      };
    }
    case ASSISTANT.FETCH_ASSISTANTS.SUCCESS: {
      return {
        ...state,
        getAssistants: {
          assistants: renderer.success(payload.assistants),
          assistantsList: payload.assistants,
        },
      };
    }
    case ASSISTANT.FETCH_ASSISTANTS.FAIL: {
      return {
        ...state,
        getAssistants: {
          assistants: renderer.failure(),
        },
      };
    }
    case ASSISTANT.ADD_ASSISTANT.LOAD: {
      return {
        ...state,
        addAssistant: {
          isSaving: true,
          addAssistantState: ASSISTANT.ADD_ASSISTANT.LOAD,
        },
      };
    }
    case ASSISTANT.ADD_ASSISTANT.SUCCESS: {
      return {
        ...state,
        addAssistanthModal: {
          show: false,
        },
        addAssistant: {
          isSaving: false,
          addAssistantState: ASSISTANT.ADD_ASSISTANT.SUCCESS,
        },
      };
    }
    case ASSISTANT.ADD_ASSISTANT.FAIL: {
      return {
        ...state,
        addAssistant: {
          isSaving: false,
          errorMessage: payload.errorMessage,
          addAssistantState: ASSISTANT.ADD_ASSISTANT.FAIL,
        },
      };
    }
    case ASSISTANT.ASSISTANT_STATE.LOAD: {
      return {
        ...state,
        assistantState: {
          isSaving: true,
        },
      };
    }
    case ASSISTANT.ASSISTANT_STATE.SUCCESS: {
      return {
        ...state,
        assistantState: {
          isSaving: false,
        },
      };
    }
    case ASSISTANT.ASSISTANT_STATE.FAIL: {
      return {
        ...state,
        assistantState: {
          isSaving: false,
        },
      };
    }
    case ASSISTANT.MODAL.TOGGLE_ADD: {
      return {
        ...state,
        addAssistanthModal: {
          show: payload.showModel,
        },
      };
    }
    default:
      return state;
  }
};
