const flattenClinics = clinics => {
  const entities = [];
  const branches = [];
  const rooms = [];
  const hasMultipleClinics = clinics.length > 1;
  const entitiesNames = new Map();
  const branchesNames = new Map();
  const roomsNames = new Map();
  const doctorsNames = new Map();
  const branchesMap = new Map();
  let doctors = [];
  let doctorsHaveSpecialityAttr = true;

  clinics.forEach(clinic => {
    entitiesNames.set(clinic.EntityName, clinic.EntityKey);
    entities.push({
      entityName: clinic.EntityName,
      entityKey: clinic.EntityKey,
    });

    clinic.Branches.forEach(branch => {
      branches.push({ ...branch, EntityName: clinic.EntityName });
      branchesNames.set(branch.BranchKey, branch.BranchName);
      branchesMap.set(branch.BranchKey, { ...branch, EntityName: clinic.EntityName });

      branch.Rooms.forEach(room => {
        rooms.push(room);
        roomsNames.set(room.RoomKey, room.RoomName);

        room.Doctors.forEach(doctor => {
          if (!Object.prototype.hasOwnProperty.call(doctor, 'MainSpecialty'))
            doctorsHaveSpecialityAttr = false;
          doctors.push({ ...doctor, RoomKey: room.RoomKey });
          doctorsNames.set(doctor.AccountKey, doctor.DoctorName);
        });
      });
    });
  });

  if (doctorsHaveSpecialityAttr) {
    const sortBySpeciality = (a, b) => {
      const x = a.MainSpecialty.SpecialityNameEnglish.toLowerCase();
      const y = b.MainSpecialty.SpecialityNameEnglish.toLowerCase();
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    };
    doctors = doctors.sort(sortBySpeciality);
  }

  return {
    entities,
    branches,
    rooms,
    doctors,
    hasMultipleClinics,
    entitiesNames,
    branchesNames,
    roomsNames,
    doctorsNames,
    branchesMap,
  };
};

const getRoomsInBranches = (branches, branchesKeys) =>
  branches
    .filter(branch => branchesKeys.includes(branch.BranchKey))
    .map(branch => branch.Rooms)
    .reduce((prevRooms, nextRooms) => prevRooms.concat(nextRooms), []);

const getDoctorsInRooms = (rooms, roomsKeys, doctorsStatus) => {
  const doctors = [];
  const doctorsKeys = [];

  rooms.forEach(room => {
    if (roomsKeys.includes(room.RoomKey)) {
      room.Doctors.forEach(doctor => {
        if (doctorsStatus === 6) {
          // Select All doctors
          if (!doctorsKeys.includes(doctor.AccountKey)) {
            doctorsKeys.push(doctor.AccountKey);
            doctors.push(doctor);
          }
        } else if (doctor.StatusId === doctorsStatus) {
          if (!doctorsKeys.includes(doctor.AccountKey)) {
            doctorsKeys.push(doctor.AccountKey);
            doctors.push(doctor);
          }
        }
      });
    }
  });

  return doctors;
};

const getDoctorsInfo = rooms => {
  const res = [];

  rooms.forEach(room => {
    room.Doctors.forEach(doctor => {
      res.push({
        RoomKey: room.RoomKey,
        AccountKey: doctor.AccountKey,
        DoctorName: doctor.DoctorName,
      });
    });
  });

  return res;
};

const getDoctorsNames = doctors => {
  const res = {};
  doctors.forEach(doctor => {
    res[doctor.AccountKey] = doctor.DoctorName;
  });
  return res;
};

const getRoomNames = rooms => {
  const res = {};
  rooms.forEach(room => {
    res[room.RoomKey] = room.RoomName;
  });
  return res;
};

const getBranchesNames = branches => {
  const res = {};
  branches.forEach(branch => {
    branch.Rooms.forEach(room => {
      res[room.RoomKey] = branch.BranchName;
    });
  });
  return res;
};

export default {
  flattenClinics,
  getRoomsInBranches,
  getDoctorsInRooms,
  getDoctorsInfo,
  getDoctorsNames,
  getRoomNames,
  getBranchesNames,
};
