import React, { Suspense } from 'react';

const AccountsPage = React.lazy(() => import('./Accounts'));
const SchedulePage = React.lazy(() => import('./Schedule'));
const EntityPage = React.lazy(() => import('./Entity'));
const OffersPage = React.lazy(() => import('./Offers'));
const AdsPage = React.lazy(() => import('./Ads'));
const ServicesPage = React.lazy(() => import('./Services'));
const PatientsPage = React.lazy(() => import('./Patients'));
const FinancePage = React.lazy(() => import('./Finance'));

const Pages = () => {
  const { host } = window.location;
  const subDomainName = String(host.split('.')[0]).toLowerCase();

  if (subDomainName.includes('sponsored') || subDomainName.includes('ads')) {
    document.title = 'Sponsored Ads | Vezeeta';
    return (
      <Suspense fallback={<div />}>
        <AdsPage />
      </Suspense>
    );
  }
  if (subDomainName.includes('service') || subDomainName.includes('serviceslist')) {
    document.title = 'Services | Vezeeta';
    return (
      <Suspense fallback={<div />}>
        <ServicesPage />
      </Suspense>
    );
  }
  if (subDomainName.includes('patients')) {
    document.title = 'Patients | Vezeeta';
    return (
      <Suspense fallback={<div />}>
        <PatientsPage />
      </Suspense>
    );
  }
  if (subDomainName.includes('finance') || subDomainName.includes('billing')) {
    document.title = 'Finance | Vezeeta';
    return (
      <Suspense fallback={<div />}>
        <FinancePage />
      </Suspense>
    );
  }
  if (subDomainName.includes('entity')) {
    document.title = 'Entity | Vezeeta';
    return (
      <Suspense fallback={<div />}>
        <EntityPage />
      </Suspense>
    );
  }
  if (subDomainName.includes('schedule')) {
    document.title = 'Schedule | Vezeeta';
    return (
      <Suspense fallback={<div />}>
        <SchedulePage />
      </Suspense>
    );
  }
  if (subDomainName.includes('accounts') || subDomainName.includes('localhost')) {
    document.title = 'Welcome | Vezeeta';
    return (
      <Suspense fallback={<div />}>
        <AccountsPage />
      </Suspense>
    );
  }
  if (subDomainName.includes('offers')) {
    document.title = 'offers | Vezeeta';
    return (
      <Suspense fallback={<div />}>
        <OffersPage />
      </Suspense>
    );
  }
};

export default Pages;
