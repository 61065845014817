export const WORKING_HOURS = {
  LOAD: 'WORKING_HOURS_LOAD',
  LOAD_SUCCESS: 'WORKING_HOURS_LOAD_SUCCESS',
  LOAD_DONE: 'WORKING_HOURS_LOAD_DONE',
  LOAD_FAIL: 'WORKING_HOURS_LOAD_FAIL',
  SAVE: 'WORKING_HOURS_SAVE',
  SAVE_SUCCESS: 'WORKING_HOURS_SAVE_SUCCESS',
  SAVE_DONE: 'WORKING_HOURS_SAVE_DONE',
  SAVE_FAIL: 'WORKING_HOURS_SAVE_FAIL',
  GET_BRANCH_WORKING_HOURS: {
    FETCH: 'GET_BRANCH_WORKING_HOURS_FETCH',
    SUCCESS: 'GET_BRANCH_WORKING_HOURS_SUCCESS',
    FAIL: 'GET_BRANCH_WORKING_HOURS_FAIL',
  },
  SET_BRANCH_WORKING_HOURS: {
    SUBMIT: 'SET_BRANCH_WORKING_HOURS_SUBMIT',
    SUCCESS: 'SET_BRANCH_WORKING_HOURS_SUCCESS',
    FAIL: 'SET_BRANCH_WORKING_HOURS_FAIL',
  },
  WORKING_HOURS: {
    SUBMIT: 'WORKING_HOURS_SUBMIT',
    SUCCESS: 'WORKING_HOURS_SUBMIT_SUCCESS',
    FAIL: 'WORKING_HOURS_SUBMIT_FAIL',
  },
};

export const loadWorkingHours = (doctors, rooms, additionalParams) => ({
  type: WORKING_HOURS.LOAD,
  doctors,
  rooms,
  additionalParams,
});

export const loadWorkingHoursDone = rooms => ({
  type: WORKING_HOURS.LOAD_DONE,
  rooms,
});

export const loadWorkingHoursFail = err => ({
  type: WORKING_HOURS.LOAD_FAIL,
  err,
});

export const saveWorkingHours = (keys, reservationOptions, scheduleTypes, scheduleKey) => ({
  type: WORKING_HOURS.WORKING_HOURS.SUBMIT,
  keys,
  reservationOptions,
  scheduleTypes,
  scheduleKey,
});

export const saveWorkingHoursDone = rooms => ({
  type: WORKING_HOURS.WORKING_HOURS.SUCCESS,
  rooms,
});

export const saveWorkingHoursFail = err => ({
  type: WORKING_HOURS.WORKING_HOURS.FAIL,
  err,
});

export const setBranchWorkingHours = payload => ({
  type: WORKING_HOURS.SET_BRANCH_WORKING_HOURS.SUBMIT,
  payload,
});

export const setBranchWorkingHoursSuccess = payload => ({
  type: WORKING_HOURS.SET_BRANCH_WORKING_HOURS.SUCCESS,
  payload,
});

export const setBranchWorkingHoursFail = payload => ({
  type: WORKING_HOURS.SET_BRANCH_WORKING_HOURS.FAIL,
  payload,
});

export const getBranchWorkingHours = payload => ({
  type: WORKING_HOURS.GET_BRANCH_WORKING_HOURS.FETCH,
  payload,
});

export const getBranchWorkingHoursSuccess = payload => ({
  type: WORKING_HOURS.GET_BRANCH_WORKING_HOURS.SUCCESS,
  payload,
});

export const getBranchWorkingHoursFail = payload => ({
  type: WORKING_HOURS.GET_BRANCH_WORKING_HOURS.FAIL,
  payload,
});
