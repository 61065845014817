import { STATUS, API, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';
import { put, takeEvery } from 'redux-saga/effects';
import { PAGE_SIZE } from 'modules/utils/Constants';
import { AuthApi } from 'modules/sso';

import {
  PATIENTS,
  fetchPatientListSuccess,
  fetchPatientListFail,
  editPatientDetailsSuccess,
  editPatientDetailsFail,
  fetchPatientInfoFail,
  fetchPatientInfoSuccess,
  getMedicalCardByKeyFail,
  getMedicalCardByKeySuccess,
} from '../actions/patients';

function* fetchPatientMedicalCardSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];
  const response = yield api.get(
    `${Urls.patientMedicalCards}/${action.payload.insuranceCardKey}?userkey=${action.payload.userKey}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getMedicalCardByKeySuccess({
        ...response.data,
      }),
    );
  } else {
    yield put(getMedicalCardByKeyFail(response));
  }
}

function* sagaFetchPatients(payload) {
  const fetchPatientsAPI = new AuthApi();
  const bodyReq = [
    {
      EntityKey: payload.entityKey,
      KeyWord: '',
      Page: 0,
      PageSize: PAGE_SIZE,
    },
  ];
  const fetchPatientsResponse = yield fetchPatientsAPI.post(Urls.searchPatientsByKeyword, bodyReq);
  if (STATUS.isSuccess(fetchPatientsResponse.status)) {
    yield put({
      type: PATIENTS.FETCH.SUCCESS,
      patients: fetchPatientsResponse.data[0].Model,
    });
  } else {
    yield put({
      type: PATIENTS.FETCH.FAIL,
      error: fetchPatientsResponse,
    });
  }
}

function* sagaGetPatientDetails(payload) {
  const getPatientDetailsAPI = new AuthApi();
  const body = [payload.patientKey];
  const getPatientDetailsResponse = yield getPatientDetailsAPI.post(Urls.getPatientByKeyUrl, body);

  if (STATUS.isSuccess(getPatientDetailsResponse.status)) {
    yield put({
      type: PATIENTS.GET_PATIENT_DETAILS.SUCCESS,
      patientDetails: getPatientDetailsResponse.data[0],
    });
  } else {
    yield put({
      type: PATIENTS.GET_PATIENT_DETAILS.FAIL,
      error: getPatientDetailsResponse,
    });
  }
}

function* sagaSearchPatients(payload) {
  if (payload.entitiesKeys) {
    const searchPatientsAPI = new AuthApi();
    const body = payload.entitiesKeys.map(entityKey => ({
      EntityKey: entityKey,
      KeyWord: payload.query,
      Page: 0,
      PageSize: PAGE_SIZE,
    }));

    const searchPatientsResponse = yield searchPatientsAPI.post(Urls.searchPatientsByKeyword, body);

    yield put({
      type: PATIENTS.SEARCH.SUCCESS,
      patients: payload.query === '' ? [] : searchPatientsResponse.data,
      source: payload.source,
    });
  }
}

function getBodyAddPatient(patient, entityKey) {
  const addPatientBody = [];
  addPatientBody.push({
    entityKey,
    PatientModel: {
      ...patient,
    },
  });
  return addPatientBody;
}

function* sagaAddPatient(payload) {
  const { entityKey } = payload;
  const bodyReq = getBodyAddPatient(payload.patient, entityKey);
  const addPatientAPI = new AuthApi();
  const addPatientResponse = yield addPatientAPI.post(Urls.createPatient, bodyReq);
  if (STATUS.isSuccess(addPatientResponse.status)) {
    const patient = {
      ...payload.patient,
      PatientKey: addPatientResponse.data[0],
      // insuranceName: payload.insurancesNames.get(payload.patient.insurance),
    };

    /* if (payload.callback) {
      payload.callback(...payload.callbackParams, patient);
    } */

    yield put({
      type: PATIENTS.ADD.SUCCESS,
      patient,
    });
  } else {
    yield put({
      type: PATIENTS.ADD.FAIL,
      error: addPatientResponse.response,
    });
  }
}

function* sagaEditPatientDetails(payload) {
  const editPatientAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const editPatientAPIResponse = yield editPatientAPI.post(
    Urls.editPatientDetails,
    { ...payload.patient },
    header,
  );

  if (STATUS.isSuccess(editPatientAPIResponse.status)) {
    const patient = {
      ...payload.patient,
    };

    yield put(
      editPatientDetailsSuccess({
        type: PATIENTS.EDIT_PATIENT_DETAILS.SUCCESS,
        patient,
      }),
    );
  } else {
    yield put(
      editPatientDetailsFail({
        type: PATIENTS.EDIT_PATIENT_DETAILS.FAIL,
        error: editPatientAPIResponse.response,
      }),
    );
  }
}

function* sagaEditPatient(payload) {
  const bodyReq = { ...payload.patient };
  const editPatientAPI = new AuthApi();
  const editPatientResponse = yield editPatientAPI.post(Urls.editPatient, bodyReq);
  if (STATUS.isSuccess(editPatientResponse.status)) {
    yield put({
      type: PATIENTS.EDIT.SUCCESS,
      patient: payload.patient,
    });
  } else {
    yield put({
      type: PATIENTS.EDIT.FAIL,
      error: editPatientResponse,
    });
  }
}

function* sagaDeletePatient(payload) {
  const bodyReq = [...payload.patientKey];
  const deletePatientAPI = new AuthApi();
  const deletePatientResponse = yield deletePatientAPI.post(Urls.deletePatientByKeyUrl, bodyReq);
  if (STATUS.isSuccess(deletePatientResponse.status)) {
    yield put({
      type: PATIENTS.DELETE.SUCCESS,
      patientKey: payload.patientKey,
    });
  } else {
    yield put({
      type: PATIENTS.DELETE.FAIL,
      error: deletePatientResponse,
    });
  }
}

function* sagafetchPatientListPaginated(action) {
  const patientsListAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield patientsListAPI.post(Urls.getPatientList, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchPatientListSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      fetchPatientListFail({
        ...action.payload,
      }),
    );
  }
}

function* sagafetchPatientInfo(action) {
  const patientInfoAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield patientInfoAPI.get(
    `${Urls.getPatientInfo}/${action.payload && action.payload.patientKey}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchPatientInfoSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      fetchPatientInfoFail({
        ...action.payload,
      }),
    );
  }
}

export default function* patientsSaga() {
  yield takeEvery(PATIENTS.SEARCH.LOAD, sagaSearchPatients);
  yield takeEvery(PATIENTS.FETCH.LOAD, sagaFetchPatients);
  yield takeEvery(PATIENTS.GET_PATIENT_DETAILS.LOAD, sagaGetPatientDetails);
  yield takeEvery(PATIENTS.ADD.LOAD, sagaAddPatient);
  yield takeEvery(PATIENTS.EDIT_PATIENT_DETAILS.LOAD, sagaEditPatientDetails);
  yield takeEvery(PATIENTS.EDIT.LOAD, sagaEditPatient);
  yield takeEvery(PATIENTS.DELETE.LOAD, sagaDeletePatient);
  yield takeEvery(PATIENTS.PATIENT_LIST.FETCH, sagafetchPatientListPaginated);
  yield takeEvery(PATIENTS.GET_PATIENT_INFO.LOAD, sagafetchPatientInfo);
  yield takeEvery(PATIENTS.MEDICAL_CARD.FETCH, fetchPatientMedicalCardSaga);
}
