import { USER } from '../actions/user';

const initialState = {
  user: undefined,
  loaded: false,
  userLangiageModal: {
    isOpen: undefined,
  },
  userLanguage: {
    state: undefined,
    loading: false,
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case USER.GET_USER: {
      return {
        ...state,
        loaded: false,
      };
    }
    case USER.SET_USER_DONE: {
      return {
        ...state,
        loaded: true,
        user: payload.user,
      };
    }
    case USER.USER_LANGUAGE_MODAL_TOGGLE:
      return {
        ...state,
        userLangiageModal: {
          isOpen: payload.show,
        },
      };
    case USER.SET_USER_LANGUAGE: {
      return {
        ...state,
        userLanguage: {
          state: USER.SET_USER_LANGUAGE,
          loading: true,
        },
      };
    }
    case USER.SET_USER_LANGUAGE_DONE: {
      return {
        ...state,
        loaded: true,
        userLangiageModal: {
          isOpen: false,
        },
        userLanguage: {
          isOpen: false,
          state: USER.SET_USER_LANGUAGE_DONE,
          loading: false,
        },
      };
    }
    case USER.SET_USER_LANGUAGE_FAIL: {
      return {
        ...state,
        loaded: true,
        userLangiageModal: {
          isOpen: false,
        },
        userLanguage: {
          isOpen: false,
          state: USER.SET_USER_LANGUAGE_FAIL,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
