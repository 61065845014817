import { status } from 'modules/utils/enum';
import { PAYMENT, TRANSACTION_INFO, VALID_TRANSACTION } from '../actions/payment';

const initialState = {
  paymentDetailsState: undefined,
  paymentDetails: {},
  cardsState: undefined,
  cards: [],
  error: undefined,
  transactionDetails: undefined,
  paymentMethods: undefined,
  paymentMethodsState: status.SHOULD_CALL_API,
  validTransactionDetails: undefined,
  validTransactionDetailsState: status.SHOULD_CALL_API,
  addPaymentTransactionDetails: undefined,
  addPaymentTransactionDetailsState: status.SHOULD_CALL_API,
  updatePaymentMethod: undefined,
  updatePaymentMethodState: status.SHOULD_CALL_API,
  setPaymentMethod: undefined,
  setPaymentMethodState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case PAYMENT.SET_PAYMENT_METHOD.SUBMIT: {
      return {
        ...state,
        setPaymentMethodState: status.SUBMITING,
      };
    }

    case PAYMENT.SET_PAYMENT_METHOD.SUCCEEDED: {
      return {
        ...state,
        setPaymentMethod: payload.payload,
        setPaymentMethodState: status.SUCCESS,
      };
    }

    case PAYMENT.SET_PAYMENT_METHOD.FAILED: {
      return {
        ...state,
        setPaymentMethod: payload.payload,
        setPaymentMethodState: status.FAIL,
      };
    }

    case PAYMENT.UPDATE_PAYMENT_METHOD.SUBMIT: {
      return {
        ...state,
        updatePaymentMethodState: status.SUBMITING,
      };
    }

    case PAYMENT.UPDATE_PAYMENT_METHOD.SUCCEEDED: {
      return {
        ...state,
        updatePaymentMethod: payload.payload,
        updatePaymentMethodState: status.SUCCESS,
      };
    }

    case PAYMENT.UPDATE_PAYMENT_METHOD.FAILED: {
      return {
        ...state,
        updatePaymentMethod: payload.payload,
        updatePaymentMethodState: status.FAIL,
      };
    }

    case PAYMENT.ADD_PAYMENT_TRANSACTION.SUBMIT: {
      return {
        ...state,
        addPaymentTransactionDetailsState: status.SUBMITING,
      };
    }

    case PAYMENT.ADD_PAYMENT_TRANSACTION.SUCCEEDED: {
      return {
        ...state,
        addPaymentTransactionDetails: payload.payload,
        addPaymentTransactionDetailsState: status.SUCCESS,
      };
    }

    case PAYMENT.ADD_PAYMENT_TRANSACTION.FAILED: {
      return {
        ...state,
        addPaymentTransactionDetails: payload.payload,
        addPaymentTransactionDetailsState: status.FAIL,
      };
    }

    case PAYMENT.PAYMENT_METHODS.LOADING: {
      return {
        ...state,
        paymentMethodsState: status.FETCHING,
      };
    }

    case PAYMENT.PAYMENT_METHODS.SUCCEEDED: {
      return {
        ...state,
        paymentMethods: payload.paymentMethods,
        paymentMethodsState: status.SUCCESS,
      };
    }

    case PAYMENT.PAYMENT_METHODS.FAILED: {
      return {
        ...state,
        paymentMethodsState: status.FAIL,
      };
    }

    case VALID_TRANSACTION.LOADING: {
      return {
        ...state,
        validTransactionDetails: undefined,
        validTransactionDetailsState: status.FETCHING,
      };
    }

    case VALID_TRANSACTION.SUCCEEDED: {
      return {
        ...state,
        validTransactionDetails: payload.transactionDetails,
        validTransactionDetailsState: status.SUCCESS,
      };
    }

    case VALID_TRANSACTION.FAILED: {
      return {
        ...state,
        validTransactionDetailsState: status.FAIL,
      };
    }

    case TRANSACTION_INFO.SET:
      return { ...state, transactionDetails: payload.payload };

    case PAYMENT.GET_PAYMENT_DETAILS.LOAD: {
      return {
        ...state,
        paymentDetailsState: PAYMENT.GET_PAYMENT_DETAILS.LOAD,
      };
    }

    case PAYMENT.GET_PAYMENT_DETAILS.SUCCEEDED: {
      return {
        ...state,
        paymentDetailsState: PAYMENT.GET_PAYMENT_DETAILS.SUCCEEDED,
        paymentDetails: payload.paymentDetails,
      };
    }

    case PAYMENT.GET_PAYMENT_DETAILS.FAILED: {
      return {
        ...state,
        paymentDetailsState: PAYMENT.GET_PAYMENT_DETAILS.FAILED,
        error: payload.error,
      };
    }

    case PAYMENT.GET_CREDIT_CARDS.LOAD: {
      return {
        ...state,
        cardsState: PAYMENT.GET_CREDIT_CARDS.LOAD,
      };
    }

    case PAYMENT.GET_CREDIT_CARDS.SUCCEEDED: {
      return {
        ...state,
        cardsState: PAYMENT.GET_CREDIT_CARDS.SUCCEEDED,
        cards: payload.cards,
      };
    }

    case PAYMENT.GET_CREDIT_CARDS.FAILED: {
      return {
        ...state,
        cardsState: PAYMENT.GET_CREDIT_CARDS.FAILED,
        error: payload.error,
      };
    }

    default: {
      return state;
    }
  }
};
