import { takeEvery, put } from 'redux-saga/effects';
import { STATUS, API, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { AuthApi } from 'modules/sso';

import FilterUtils from 'modules/utils/Filter';
import ClinicsUtils from 'modules/utils/Clinics';
import {
  ROOM,
  getRoomDetailsSuccess,
  getRoomDetailsFail,
  addRoomFail,
  addRoomSucces,
  deleteRoomFail,
  deleteRoomSuccess,
  editRoomFail,
  editRoomSucces,
  getRoomsFail,
  getRoomsSuccess,
  getDoctorWorkingHoursShiftFail,
  getDoctorWorkingHoursShiftSuccess,
  assignDoctorShiftToRoomFail,
  assignDoctorShiftToRoomSuccess,
  getRoomsCalendarViewFail,
  getRoomsCalendarViewSuccess,
} from '../actions/room';

function* filterWorkingHours(action) {
  const allShifts = yield FilterUtils.filterClinics(action.days, action.filterData);
  yield put({ type: ROOM.FILTER_SUCCESS, allShifts });
}

function* getRoomDetails(action) {
  const apiScheduleTypes = new AuthApi();
  const apiReservationWindow = new AuthApi();
  const clinicsData = ClinicsUtils.flattenClinics(action.clinics);

  let accountKey;
  let apiBody = [];

  if (action.loadAllDoctors) {
    // Should get working Hours for all doctors and rooms
    clinicsData.rooms.forEach(room => {
      room.Doctors.forEach(doctor =>
        apiBody.push({
          AccountKey: doctor.AccountKey,
          RoomKey: room.RoomKey,
        }),
      );
    });
  } else {
    // To maintain backward compatibility with old working hours
    accountKey = clinicsData.doctors[0].AccountKey;
    apiBody = clinicsData.rooms.map(room => ({
      AccountKey: accountKey,
      RoomKey: room.RoomKey,
    }));
  }

  const apiCalls = [
    { call: apiScheduleTypes, url: Urls.getScheduleTypes },
    { call: apiReservationWindow, url: Urls.getAllowReservationWindow },
  ];
  const apiResponses = yield apiCalls.map(apiCall => apiCall.call.post(apiCall.url, apiBody));

  if (STATUS.isSuccess(apiResponses[0].status) && STATUS.isSuccess(apiResponses[1].status)) {
    const scheduleTypes = [];
    apiBody.forEach((doctorAndRoom, index) => {
      const scheduleType = apiResponses[0].data[index];
      scheduleType.RoomKey = doctorAndRoom.RoomKey;
      scheduleTypes.push(scheduleType);
    });

    yield put(getRoomDetailsSuccess(scheduleTypes, apiResponses[1]));
  } else {
    yield put(getRoomDetailsFail('Failed to load room details'));
  }
}

function* getRoomsSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.get(
    `${Urls.getRooms}/${action.payload.branchKey}/physicalrooms`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getRoomsSuccess({
        ...response,
      }),
    );
  } else {
    yield put(getRoomsFail());
  }
}

function* addRoomSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.post(
    `${Urls.addRoom}/${action.payload.branchKey}/physicalroom`,
    action.payload.room,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      addRoomSucces({
        ...response.data,
      }),
    );
  } else {
    yield put(
      addRoomFail({
        ...response,
      }),
    );
  }
}

function* deleteRoomSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.delete(`${Urls.deleteRoom}/${action.payload.roomKey}`, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      deleteRoomSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      deleteRoomFail({
        ...response,
      }),
    );
  }
}

function* updateRoomSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.put(
    `${Urls.editRoom}/${action.payload.roomKey}`,
    action.payload.room,
    header,
  );
  if (STATUS.isSuccess(response.status)) {
    yield put(
      editRoomSucces({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      editRoomFail({
        ...response,
      }),
    );
  }
}

function* getDoctorShiftsSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);

  let culture = Cookie.get(Cookie.CULTURE);
  culture = `en-${culture.split('-')[1]}`;

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'actionsource',
      value: 'ScheduleWeb',
    },
    {
      key: 'language',
      value: `${culture}`,
    },
  ];

  const response = yield api.post(
    `${Urls.getDoctorWorkingHoursShiftsAndRooms}`,
    action.payload,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getDoctorWorkingHoursShiftSuccess({
        ...response.data,
      }),
    );
  } else {
    yield put(
      getDoctorWorkingHoursShiftFail({
        ...response,
      }),
    );
  }
}

function* assignDcotorShiftsToRoomSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.put(`${Urls.assignShiftToRoom}`, action.payload, header);
  if (STATUS.isSuccess(response.status)) {
    yield put(
      assignDoctorShiftToRoomSuccess({
        ...response.response,
      }),
    );
  } else {
    yield put(
      assignDoctorShiftToRoomFail({
        ...response,
      }),
    );
  }
}

function* getRoomsCalendarViewSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);

  let culture = Cookie.get(Cookie.CULTURE);
  culture = `en-${culture.split('-')[1]}`;

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'language',
      value: `${culture}`,
    },
  ];

  // daysOfWeekString Not used any more
  /* let daysOfWeekString = '';
  if (action.payload.daysOfWeek && action.payload.daysOfWeek.length) {
    daysOfWeekString = `DayOfWeek=${action.payload.daysOfWeek.join(',')}`;
  } */

  let roomKeysString = '';
  if (action.payload.roomKeys && action.payload.roomKeys.length) {
    action.payload.roomKeys.forEach(roomkey => {
      roomKeysString = `${roomKeysString}&phroomkeys=${roomkey}`;
    });
  }

  let dateString = '';
  if (action.payload.startDate && action.payload.endDate) {
    dateString = `StartDate=${action.payload.startDate}&EndDate=${action.payload.endDate}`;
  }

  const response = yield api.get(
    `${Urls.getCalendarView}/${action.payload.branchKey}/calendar?${dateString}${roomKeysString}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getRoomsCalendarViewSuccess({
        ...response,
      }),
    );
  } else {
    yield put(
      getRoomsCalendarViewFail({
        ...response,
      }),
    );
  }
}

function* roomSaga() {
  yield takeEvery(ROOM.GET_ROOMS_CALENDAR_VIEW.FETCH, getRoomsCalendarViewSaga);
  yield takeEvery(ROOM.FILTER, filterWorkingHours);
  yield takeEvery(ROOM.GET_ROOM_DETAILS, getRoomDetails);
  yield takeEvery(ROOM.GET_ROOMS.FETCH, getRoomsSaga);
  yield takeEvery(ROOM.ADD_ROOM.SUBMIT, addRoomSaga);
  yield takeEvery(ROOM.DELETE_ROOM.SUBMIT, deleteRoomSaga);
  yield takeEvery(ROOM.UPDATE_ROOM.SUBMIT, updateRoomSaga);
  yield takeEvery(ROOM.GET_DOCTOR_SHIFTS_ROOMS.FETCH, getDoctorShiftsSaga);
  yield takeEvery(ROOM.ASSIGN_DOCTOR_SHIFTS_TO_ROOMS.SUBMIT, assignDcotorShiftsToRoomSaga);
}

export default roomSaga;
