import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  ACTIVITIES,
  getActivitiesReservationsSuccess,
  getActivitiesReservationsFail,
} from '../actions/activities';

function* fetchActivitiesReservations(action) {
  const activitiesReservationsAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const culture = Cookie.get(Cookie.CULTURE);

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  const queryString = `toDate=${action.payload.to}&fromDate=${action.payload.from}&PageSize=${action.payload.PageSize}&PageNumber=${action.payload.PageNumber}&PatientKey=${action.payload.PatientKey}`;

  const response = yield activitiesReservationsAPI.get(
    `${Urls.reservationSearch}?${queryString}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getActivitiesReservationsSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      getActivitiesReservationsFail({
        ...action.payload,
      }),
    );
  }
}

function* activitiesSaga() {
  yield takeLatest(ACTIVITIES.ACTIVITIES_RESERVATION.LOAD, fetchActivitiesReservations);
}

export default activitiesSaga;
