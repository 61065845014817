import { STATUS, API, Cookie, decodeJwtToken } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';
import { put, takeEvery } from 'redux-saga/effects';

import {
  PLANS,
  fetchPlansFail,
  fetchPlansSuccess,
  subscribePlansFail,
  subscribePlansSuccess,
} from '../actions/plans';

function* sagaSubscribePlan(payload) {
  /**
   * {
   *  planKey,
   *  branchKey,
   *  entityKey,
   *  roomKey,
   * }
   */
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;

  const header = [
    {
      key: 'Authorization',
      value: `bearer ${authToken}`,
    },
    {
      key: 'AccountKey',
      value: `${accountKey}`,
    },
  ];

  let body = {
    PlanKey: `${payload.planKey}`,
    BranchKey: `${payload.branchKey}`,
    EntityKey: `${payload.entityKey}`,
  };

  if (payload && payload.roomKey) {
    body = { ...body, RoomKey: `${payload.roomKey}` };
  }

  if (payload && payload.providerType) {
    body = { ...body, ProviderType: `${payload.providerType}` };
  }

  const api = new API();
  const response = yield api.post(Urls.plans.subscribePlan, body, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      subscribePlansSuccess({
        ...response.data,
        ...payload.payload,
      }),
    );
  } else {
    yield put(
      subscribePlansFail({
        ...payload.payload,
      }),
    );
  }
}

function* sagafetchPlans(payload) {
  /**
   * {
   *  languageId,
   *  paymentType,
   *  countryId,
   *  areaKey,
   *  specilityKeys,
   * }
   */

  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'LanguageId',
      value: `${payload.languageId}`,
    },
    {
      key: 'PaymentType',
      value: `${payload.paymentType}`,
    },
    {
      key: 'CountryId',
      value: `${payload.countryId}`,
    },
    {
      key: 'AccountKey',
      value: `${accountKey}`,
    },
    {
      key: 'branchKey',
      value: `${payload.branchKey}`,
    },
  ];

  let specKeys = '';
  if (payload.isSingle) {
    if (payload && payload.specilityKeys && payload.specilityKeys.length) {
      payload.specilityKeys.forEach(key => {
        if (!specKeys) {
          specKeys = `&specilityKey=${key}`;
        } else {
          specKeys = `${specKeys}&specilityKey=${key}`;
        }
      });
    }
  } else if (payload && payload.specilityKeys && payload.specilityKeys.length) {
    payload.specilityKeys.forEach(key => {
      if (!specKeys) {
        specKeys = `&specilityKeys=${key}`;
      } else {
        specKeys = `${specKeys}&specilityKeys=${key}`;
      }
    });
  }

  let response;
  if (payload.isSingle) {
    response = yield api.get(
      `${Urls.plans.getPlansSingleDr}?areaKey=${payload.areaKey}&paymentType=${payload.paymentType}${specKeys}`,
      header,
    );
  } else {
    response = yield api.get(
      `${Urls.plans.getPlans}?areaKey=${payload.areaKey}&paymentType=${payload.paymentType}${specKeys}`,
      header,
    );
  }

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchPlansSuccess({
        ...response.data,
        ...payload.payload,
      }),
    );
  } else {
    yield put(
      fetchPlansFail({
        ...payload.payload,
      }),
    );
  }
}

export default function* plansSaga() {
  yield takeEvery(PLANS.SUBSCRIBE.LOAD, sagaSubscribePlan);
  yield takeEvery(PLANS.GET_PLANS.LOAD, sagafetchPlans);
}
