export const INVOICES = {
  FETCH: 'FETCH_INVOICES',
  SUCCESS: 'FETCH_SUCCESS_INVOICES',
  FAIL: 'FETCH_FAIL_INVOICES',
};

export const fetchInvoices = (payload, userTimeZone) => ({
  type: INVOICES.FETCH,
  payload,
  userTimeZone,
});

export const fetchInvoicesSuccess = payload => ({
  type: INVOICES.SUCCESS,
  payload,
});

export const fetchInvoicesFail = payload => ({
  type: INVOICES.FAIL,
  payload,
});
