import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, Cookie, decodeJwtToken } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  PAYMENT,
  VALID_TRANSACTION,
  addPaymentTransactionFailed,
  addPaymentTransactionSucceeded,
  setPaymentMethodFailed,
  setPaymentMethodSucceeded,
  updatePaymentMethodFailed,
  updatePaymentMethodSucceeded,
} from '../actions/payment';

const paymentHeaders = [
  {
    key: 'x-vzt-authorization',
    value:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBpZCI6InNlcnZpY2VzIiwiZXhwIjoxNTI2NDAxMzc2LjAsIm5uYyI6IjZiZjk3NTk3ZDliNTczYmUifQ.qlUP48qVZc-0ocJl8ZM1un2b6-c34VhUs2Pa89EKkME',
  },
  {
    key: 'x-vzt-component',
    value: 'AMWeb',
  },
];

function* getAccountPaymentDetails(action) {
  const paymentDetailsApi = new API();
  const paymentDetailsApiResponse = yield paymentDetailsApi.get(
    `${Urls.payment.getAccountPaymentDetails}${action.accountKey}`,
    paymentHeaders,
  );

  if (STATUS.isSuccess(paymentDetailsApiResponse.status)) {
    yield put({
      type: PAYMENT.GET_PAYMENT_DETAILS.SUCCEEDED,
      paymentDetails: paymentDetailsApiResponse.data,
    });
  } else {
    yield put({
      type: PAYMENT.GET_PAYMENT_DETAILS.FAILED,
      paymentDetails: paymentDetailsApiResponse.data,
    });
  }
}

function* getAccountCreditCards(action) {
  const { accountKey, isActive, status } = action;
  const creditCardsApi = new API();
  const creditCardApiResponse = yield creditCardsApi.get(
    `${Urls.payment.checkIfAccountHasCards}?accountKey=${accountKey}&isactive=${isActive}&status=${status}`,
    paymentHeaders,
  );

  if (STATUS.isSuccess(creditCardApiResponse.status)) {
    yield put({
      type: PAYMENT.GET_CREDIT_CARDS.SUCCEEDED,
      cards: creditCardApiResponse.data,
    });
  } else {
    yield put({
      type: PAYMENT.GET_CREDIT_CARDS.FAILED,
      error: creditCardApiResponse.data,
    });
  }
}

function* getPaymentMethods(action) {
  const paymentMethodsApi = new API();
  const paymentMethodsApiResponse = yield paymentMethodsApi.get(
    `${Urls.payment.getPaymentTypes}countryid=${action.payload.countryId}&languageId=${action.payload.languageId}`,
    paymentHeaders,
  );

  if (STATUS.isSuccess(paymentMethodsApiResponse.status)) {
    yield put({
      type: PAYMENT.PAYMENT_METHODS.SUCCEEDED,
      paymentMethods: paymentMethodsApiResponse.data,
    });
  } else {
    yield put({
      type: PAYMENT.PAYMENT_METHODS.FAILED,
      error: paymentMethodsApiResponse.data,
    });
  }
}

function* getVaidTransactionByTransactionKey(action) {
  const header = [
    {
      key: 'x-vzt-authorization',
      value:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBpZCI6InNlcnZpY2VzIiwiZXhwIjoxNTI0NDE0MDkxLjAsIm5uYyI6IjU4MDllYmIyYWQ2YjdmMmMifQ.lcVf-afeEm8KIpqFaMT3Y7hpyrPWg6fNic8Ew9YyU6k',
    },
    {
      key: 'x-vzt-component',
      value: 'paymentportal',
    },
  ];
  const vaidTransactionApi = new API();
  const vaidTransactionApiResponse = yield vaidTransactionApi.get(
    `${Urls.payment.getTransactionByTransactionKey}${action.transactionKey}`,
    header,
  );

  if (STATUS.isSuccess(vaidTransactionApiResponse.status)) {
    yield put({
      type: VALID_TRANSACTION.SUCCEEDED,
      transactionDetails: vaidTransactionApiResponse.data,
    });
  } else {
    yield put({
      type: VALID_TRANSACTION.FAILED,
      error: vaidTransactionApiResponse.data,
    });
  }
}

function* addPaymentTransactionSaga(action) {
  const api = new API();
  const response = yield api.post(Urls.payment.addTransaction, action.payload, paymentHeaders);
  if (STATUS.isSuccess(response.status)) {
    yield put(
      addPaymentTransactionSucceeded({
        ...response.data,
      }),
    );
  } else {
    yield put(
      addPaymentTransactionFailed({
        ...response.data,
      }),
    );
  }
}

function* updatePaymentMethodSaga(action) {
  const api = new API();
  const body = {
    PaymentMethodKey: 'pm4bcc2653a34f5454',
    ...action.payload,
  };
  const salesForcePaymentMethodHeader = [
    {
      key: 'CallSource',
      value: 'AccountManagement',
    },
  ];
  const response = yield api.post(Urls.updatePaymentMethod, body, salesForcePaymentMethodHeader);
  if (STATUS.isSuccess(response.status)) {
    yield put(
      updatePaymentMethodSucceeded({
        ...response.data,
      }),
    );
  } else {
    yield put(
      updatePaymentMethodFailed({
        ...response.data,
      }),
    );
  }
}

function* setPaymentMethodSaga(action) {
  const api = new API();
  const body = {
    PaymentMethodKey: 'pm4bcc2653a34f5454',
    PaymentTypeKey: 'pt10673dcd39212r8',
    ...action.payload,
  };
  const culture = Cookie.get(Cookie.CULTURE);
  const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;
  const header = [
    {
      key: 'Culture',
      value: `${culture}`,
    },
    {
      key: 'AccountKey',
      value: `${accountKey}`,
    },
    {
      key: 'x-vzt-authentication',
      value: `${Cookie.get(Cookie.AUTH_TOKEN)}`,
    },
  ];
  const response = yield api.post(Urls.payment.setPaymentMethod, body, header);
  if (STATUS.isSuccess(response.status)) {
    yield put(
      setPaymentMethodSucceeded({
        ...response.data,
      }),
    );
  } else {
    yield put(
      setPaymentMethodFailed({
        ...response.data,
      }),
    );
  }
}

function* schedulesSaga() {
  yield takeEvery(PAYMENT.GET_PAYMENT_DETAILS.LOAD, getAccountPaymentDetails);
  yield takeEvery(PAYMENT.GET_CREDIT_CARDS.LOAD, getAccountCreditCards);
  yield takeEvery(PAYMENT.PAYMENT_METHODS.LOADING, getPaymentMethods);
  yield takeEvery(VALID_TRANSACTION.LOADING, getVaidTransactionByTransactionKey);
  yield takeEvery(PAYMENT.ADD_PAYMENT_TRANSACTION.SUBMIT, addPaymentTransactionSaga);
  yield takeEvery(PAYMENT.SET_PAYMENT_METHOD.SUBMIT, setPaymentMethodSaga);
  yield takeEvery(PAYMENT.UPDATE_PAYMENT_METHOD.SUBMIT, updatePaymentMethodSaga);
}

export default schedulesSaga;
