import { renderer } from '@vezeeta/web-components';
import { language } from '../types/language';
import { status } from '../enum';

import { PATIENTS } from '../actions/patients';

const initialState = {
  patients: renderer.loading(),
  patientsList: [],
  patientDetailsModal: { patientDetails: {}, isOpen: false },
  deletePatientModal: { patientDetails: {}, isOpen: false },
  patientNotesModal: { notes: '', isOpen: false },
  addPatientModal: {
    searchedPatients: [],
    isFetching: false,
    isSuccess: false,
  },
  searchPatients: {
    query: '',
    searchedPatients: [],
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getPatientDetails: {
    patientDetails: {},
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  addPatient: {
    patientDetails: {},
    addPatientState: status.SHOULD_CALL_API,
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  editPatient: {
    editPatientDetails: {},
    editPatientDetailsLoadState: status.SHOULD_CALL_API,
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  deletePatient: {
    patientKey: false,
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  snackBar: { show: false, message: '' },
  deletedPatientsKeys: [],
  isAddPatientModalOpened: false,
  patientProfileInfo: {
    patientInfo: undefined,
  },
  patientListPage: undefined,
  patientListPageLoadState: status.SHOULD_CALL_API,
  patientInfoPage: undefined,
  patientInfoLoadState: status.SHOULD_CALL_API,
  medicalCard: undefined,
  medicalCardLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case PATIENTS.GET_PATIENT_INFO.LOAD:
      return { ...state, patientInfoLoadState: status.FETCHING, patientInfoPage: undefined };
    case PATIENTS.GET_PATIENT_INFO.SUCCESS: {
      return {
        ...state,
        patientInfoLoadState: status.SUCCESS,
        patientInfoPage: payload.payload,
      };
    }
    case PATIENTS.GET_PATIENT_INFO.FAIL:
      return { ...state, patientInfoLoadState: status.FAIL, patientInfoPage: payload.payload };

    case PATIENTS.PATIENT_LIST.FETCH:
      return { ...state, patientListPageLoadState: status.FETCHING, patientListPage: undefined };
    case PATIENTS.PATIENT_LIST.SUCCESS: {
      return {
        ...state,
        patientListPageLoadState: status.SUCCESS,
        patientListPage: payload.payload,
      };
    }
    case PATIENTS.PATIENT_LIST.FAIL:
      return { ...state, patientListPageLoadState: status.FAIL, patientListPage: payload.payload };

    case PATIENTS.MODAL.ADD_PATIENT_TOGGLE: {
      return { ...state, isAddPatientModalOpened: payload.isOpened };
    }
    case PATIENTS.FETCH.LOAD:
      return { ...state, patients: renderer.loading() };
    case PATIENTS.FETCH.SUCCESS: {
      let patients;
      let patientsList = [];
      if (payload.patients.length > 0) {
        patients = renderer.success(payload.patients);
        patientsList = payload.patients;
      } else {
        patients = renderer.empty({ icon: 'search', message: 'No Patients available' });
      }
      return {
        ...state,
        patients,
        patientsList,
      };
    }

    case PATIENTS.FETCH.FAIL:
      return {
        ...state,
        patients: renderer.failure(payload.error),
      };

    case PATIENTS.GET_PATIENT_DETAILS.LOAD:
      return {
        ...state,
        getPatientDetails: {
          patientDetails: {},
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };

    case PATIENTS.GET_PATIENT_DETAILS.SUCCESS:
      return {
        ...state,
        getPatientDetails: {
          patientDetails: payload.patientDetails,
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };

    case PATIENTS.GET_PATIENT_DETAILS.FAIL:
      return {
        ...state,
        getPatientDetails: {
          patientDetails: {},
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: payload.error },
        },
      };

    case PATIENTS.SEARCH.LOAD: {
      if (payload.source === 'searchBar') {
        return {
          ...state,
          searchPatients: {
            query: payload.query,
            searchedPatients: [],
            isFetching: true,
            isSuccess: false,
            isFail: { isError: false, message: '' },
          },
        };
      }
      return {
        ...state,
        addPatientModal: {
          searchedPatients: [],
          isFetching: true,
          isSuccess: false,
        },
      };
    }

    case PATIENTS.SEARCH.SUCCESS: {
      const searchedPatients = [];
      payload.patients.forEach(entity => {
        entity.Model.forEach(patient => {
          searchedPatients.push({ ...patient, EntityKey: entity.EntityKey });
        });
      });

      if (payload.source === 'searchBar') {
        return {
          ...state,
          searchPatients: {
            ...state.searchPatients,
            searchedPatients,
            isFetching: false,
            isSuccess: true,
            isFail: { isError: false, message: '' },
          },
        };
      }

      return {
        ...state,
        addPatientModal: {
          searchedPatients,
          isFetching: false,
          isSuccess: true,
        },
      };
    }

    case PATIENTS.SEARCH.FAIL:
      return {
        ...state,
        searchPatients: {
          ...state.searchPatients,
          searchedPatients: [],
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: payload.error },
        },
      };

    case PATIENTS.ADD.LOAD:
      return {
        ...state,
        addPatient: {
          patientDetails: {},
          addPatientState: status.SUBMITING,
        },
      };

    case PATIENTS.ADD.SUCCESS: {
      // const newPatientsList = state.patientsList;
      // newPatientsList.push(payload.patient);

      return {
        ...state,
        // patients: renderer.success(newPatientsList),
        // patientsList: newPatientsList,
        addPatient: {
          patientDetails: payload.patient,
          addPatientState: status.SUCCESS,
        },
        /* snackBar: {
            show: true,
            message: language === 'en' ? 'Patient added successfully' : 'تم اضافة المريض بنجاح',
          }, */
      };
    }

    case PATIENTS.ADD.FAIL:
      return {
        ...state,
        addPatient: {
          patientDetails: payload,
          addPatientState: status.FAIL,
        },
      };

    case PATIENTS.EDIT_PATIENT_DETAILS.LOAD:
      return {
        ...state,
        editPatient: {
          editPatientDetails: payload,
          editPatientDetailsLoadState: status.SUBMITING,
        },
      };

    case PATIENTS.EDIT_PATIENT_DETAILS.SUCCESS: {
      return {
        ...state,
        editPatient: {
          editPatientDetails: payload,
          editPatientDetailsLoadState: status.SUCCESS,
        },
      };
    }

    case PATIENTS.EDIT_PATIENT_DETAILS.FAIL:
      return {
        ...state,
        editPatient: {
          editPatientDetails: payload,
          editPatientDetailsLoadState: status.FAIL,
        },
      };

    case PATIENTS.EDIT.LOAD:
      return Object.assign({}, state, { editPatientSaved: false }, { editPatientSaving: true });

    case PATIENTS.EDIT.SUCCESS: {
      const patientKey = payload.patient.PatientKey;
      const { patients } = state;
      const newState = { ...state };
      const patientsLen = patients.length;
      let check = true;
      let i = 0;
      while (i < patientsLen && check) {
        if (patients[i].PatientKey === patientKey) {
          patients[i] = { ...payload.patient };
          check = false;
        }
        i += 1;
      }
      return Object.assign({}, newState, {
        editPatientSaved: true,
        editPatientSaving: false,
      });
    }

    case PATIENTS.EDIT.FAIL:
      return Object.assign({}, state, {
        editPatientSaved: false,
        editPatientSaving: false,
      });

    case PATIENTS.DELETE.LOAD:
      return {
        ...state,
        deletePatient: {
          patientKey: payload.patientKey,
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };

    case PATIENTS.DELETE.SUCCESS: {
      const oldDeletedPatientsKeys = state.deletedPatientsKeys;
      oldDeletedPatientsKeys.push(payload.patientKey);

      return {
        ...state,
        deletePatient: {
          ...state.deletePatient,
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        deletePatientModal: {
          ...state.deletePatientModal,
          isOpen: false,
        },
        deletedPatientsKeys: oldDeletedPatientsKeys,
        snackBar: {
          show: true,
          message: language === 'en' ? 'Patient deleted successfully' : 'تم حذف المريض بنجاح',
        },
      };
    }

    case PATIENTS.DELETE.FAIL:
      return {
        ...state,
        deletePatient: {
          patientKey: '',
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: payload.error },
        },
      };

    case PATIENTS.MODAL.DETAILS_TOGGLE:
      return {
        ...state,
        patientDetailsModal: { isOpen: payload.isOpened },
      };

    case PATIENTS.SET_PATIENT_DETAILS:
      return {
        ...state,
        patientDetailsModal: {
          ...state.patientDetailsModal,
          patientDetails: payload.patientDetails,
        },
      };

    case PATIENTS.PROFILE.SET_PATIENT_PROFILE_INFO:
      return {
        ...state,
        patientProfileInfo: {
          patientInfo: payload.patientDetails,
        },
      };

    case PATIENTS.MODAL.DELETE_TOGGLE: {
      return {
        ...state,
        deletePatientModal: { ...state.deletePatientModal, isOpen: payload.isOpened },
        patientDetailsModal: {
          ...state.patientDetailsModal,
          isOpen: false,
        },
      };
    }

    case PATIENTS.SET_DELETE_PATIENT_DETAILS: {
      return {
        ...state,
        deletePatientModal: {
          ...state.patientDetailsModal,
          patientDetails: payload.patientDetails,
        },
      };
    }

    case PATIENTS.MODAL.NOTES_TOGGLE:
      return {
        ...state,
        patientNotesModal: { ...state.patientNotesModal, isOpen: payload.isOpened },
      };

    case PATIENTS.SET_PATIENT_NOTES:
      return {
        ...state,
        patientNotesModal: { ...state.patientNotesModal, notes: payload.notes },
      };
    case PATIENTS.SNACK_BAR.HIDE:
      return {
        ...state,
        snackBar: {
          show: false,
          message: '',
        },
      };

    case PATIENTS.MEDICAL_CARD.FETCH:
      return { ...state, medicalCardLoadState: status.FETCHING, medicalCard: undefined };
    case PATIENTS.MEDICAL_CARD.SUCCESS: {
      return { ...state, medicalCardLoadState: status.SUCCESS, medicalCard: payload.payload };
    }
    case PATIENTS.MEDICAL_CARD.FAIL:
      return { ...state, medicalCardLoadState: status.FAIL, medicalCard: payload.payload };

    default:
      return state;
  }
};
