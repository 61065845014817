import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { CITIES_AREAS } from '../actions/citiesAndAreas';

function* getCities() {
  const apiObj = new API();
  const res = yield apiObj.get(Urls.areasAndCities.getCities, undefined, true);
  if (STATUS.isSuccess(res.status)) {
    yield put({ type: CITIES_AREAS.CITIES.LOAD_DONE, cities: res.data });
  } else {
    yield put({ type: CITIES_AREAS.CITIES.LOAD_FAIL });
  }
}

function* getAreas() {
  const apiObj = new API();
  const res = yield apiObj.get(Urls.areasAndCities.getAreas, undefined, true);
  if (STATUS.isSuccess(res.status)) {
    yield put({ type: CITIES_AREAS.AREAS.LOAD_DONE, areas: res.data });
  } else {
    yield put({ type: CITIES_AREAS.AREAS.LOAD_FAIL });
  }
}

function* getBranchesAreas(action) {
  const apiObj = new API();
  const res = yield apiObj.get(
    `${Urls.entity.getBranchesAreas}?branchKeys=${action.branchesKeys}`,
    undefined,
    true,
  );
  if (STATUS.isSuccess(res.status)) {
    yield put({ type: CITIES_AREAS.BRANCHES_AREAS.LOAD_DONE, areas: res.data[0] });
  } else {
    yield put({ type: CITIES_AREAS.BRANCHES_AREAS.LOAD_FAIL });
  }
}

export default function* citiesAndAreas() {
  yield takeEvery(CITIES_AREAS.CITIES.LOAD, getCities);
  yield takeEvery(CITIES_AREAS.AREAS.LOAD, getAreas);
  yield takeEvery(CITIES_AREAS.BRANCHES_AREAS.LOAD, getBranchesAreas);
}
