import { status, prescriptionItemTypesEnum } from 'modules/utils/enum';
import { PHARMA } from '../actions/Pharma';

const initialState = {
  pharmaProducts: undefined,
  pharmaProductsLoadState: status.SHOULD_CALL_API,
  labTestsProducts: undefined,
  labTestsProductsLoadState: status.SHOULD_CALL_API,
  prevAddedMedications: undefined,
  prevAddedMedicationsLoadState: status.SHOULD_CALL_API,
  prescriptionSettings: undefined,
  prescriptionSettingsLoadState: status.SHOULD_CALL_API,
  dosageSelectionModal: false,
  displayEPrescModalIsOpened: false,
  displayPrescModalIsOpened: false,
  selectedMedicationItemDetails: undefined,
  presciptionMedicationsList: [],
  presciptionLabTestsList: [],
  prescriptionScansList: [],
  submitEprescriptionLoadState: status.SHOULD_CALL_API,
  ePrescDetails: undefined,
  ePrescDetailsLoadState: status.SHOULD_CALL_API,
  prescImages: undefined,
  prescImagesLoadState: status.SHOULD_CALL_API,
  scansCatalog: undefined,
  scansCatalogLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case PHARMA.GET_PRESC.LOAD: {
      return {
        ...state,
        prescImagesLoadState: status.FETCHING,
      };
    }

    case PHARMA.GET_PRESC.SUCCESS: {
      return {
        ...state,
        prescImages: payload.payload,
        prescImagesLoadState: status.SUCCESS,
      };
    }

    case PHARMA.GET_PRESC.FAIL: {
      return {
        ...state,
        prescImagesLoadState: status.FAIL,
      };
    }

    case PHARMA.GET_EPRESC.LOAD: {
      return {
        ...state,
        ePrescDetailsLoadState: status.FETCHING,
        ePrescDetails: undefined,
      };
    }

    case PHARMA.GET_EPRESC.RESET: {
      return {
        ...state,
        ePrescDetails: undefined,
        ePrescDetailsLoadState: status.SHOULD_CALL_API,
      };
    }

    case PHARMA.GET_EPRESC.SUCCESS: {
      const labTestsList = [];
      const scansList = [];
      if (
        payload.payload.prescriptionLabTestItems &&
        payload.payload.prescriptionLabTestItems.length
      ) {
        payload.payload.prescriptionLabTestItems.map(item => {
          labTestsList.push({
            productKey: item.itemKey,
            productName: item.name,
            description: '',
            PrescriptionItemTypeId: prescriptionItemTypesEnum.labTest,
          });
        });
      }
      if (payload.payload.prescriptionScanItems && payload.payload.prescriptionScanItems.length) {
        payload.payload.prescriptionScanItems.map(item => {
          scansList.push({
            productKey: item.itemKey,
            productName: item.name,
            description: '',
            PrescriptionItemTypeId: prescriptionItemTypesEnum.scan,
          });
        });
      }
      return {
        ...state,
        ePrescDetails: payload.payload,
        presciptionLabTestsList: labTestsList,
        prescriptionScansList: scansList,
        ePrescDetailsLoadState: status.SUCCESS,
      };
    }

    case PHARMA.GET_EPRESC.FAIL: {
      return {
        ...state,
        ePrescDetailsLoadState: status.FAIL,
        ePrescDetails: undefined,
      };
    }
    case PHARMA.DISPLAY_EPRESC_MODAL.TOGGLE_DISPLAY_EPRESC_MODAL: {
      return {
        ...state,
        displayEPrescModalIsOpened: payload.isOpened,
      };
    }
    case PHARMA.DISPLAY_PRESC_MODAL.TOGGLE_DISPLAY_PRESC_MODAL: {
      return {
        ...state,
        displayPrescModalIsOpened: payload.isOpened,
      };
    }
    case PHARMA.RESET_MEDICATION_TO_PRESCRIPTION_LIST: {
      return {
        ...state,
        presciptionMedicationsList: [],
        presciptionLabTestsList: [],
        prescriptionScansList: [],
        prescriptionSettings: undefined,
        prescriptionSettingsLoadState: status.SHOULD_CALL_API,
      };
    }
    case PHARMA.SUBMIT_EPRESCRIPTION.SUBMIT: {
      return {
        ...state,
        submitEprescriptionLoadState: status.SUBMITING,
      };
    }
    case PHARMA.SUBMIT_EPRESCRIPTION.SUCCESS: {
      return {
        ...state,
        submitEprescriptionLoadState: status.SUCCESS,
      };
    }
    case PHARMA.SUBMIT_EPRESCRIPTION.FAIL: {
      return {
        ...state,
        submitEprescriptionLoadState: status.FAIL,
      };
    }
    case PHARMA.ADD_MEDICATION_TO_PRESCRIPTION_LIST: {
      const { presciptionMedicationsList } = state;
      const indexOfPorduct = presciptionMedicationsList.findIndex(
        item => item.productKey === payload.payload.productKey,
      );
      if (indexOfPorduct === -1) {
        const updatedList = [...presciptionMedicationsList, payload.payload];
        return {
          ...state,
          presciptionMedicationsList: updatedList,
        };
      }
      const updatedList = [...presciptionMedicationsList];
      updatedList[indexOfPorduct] = payload.payload;
      return {
        ...state,
        presciptionMedicationsList: updatedList,
      };
    }
    case PHARMA.REMOVE_MEDICATION_FROM_PRESCRIPTION_LIST: {
      const listAfterRemovingItem = state.presciptionMedicationsList.filter(
        item => item.productKey !== payload.productKey,
      );
      return {
        ...state,
        presciptionMedicationsList: listAfterRemovingItem,
      };
    }
    case PHARMA.EDIT_MEDICATION: {
      const getMedicationToBeEdited = state.presciptionMedicationsList.filter(
        item => item.productKey === payload.productKey,
      );
      return {
        ...state,
        selectedMedicationItemDetails: getMedicationToBeEdited && getMedicationToBeEdited[0],
      };
    }
    case PHARMA.ADD_LAB_TESTS_TO_PRESCRIPTION_LIST: {
      const { presciptionLabTestsList } = state;
      const indexOfPorduct = presciptionLabTestsList.findIndex(
        item => item.productKey === payload.payload.productKey,
      );
      if (indexOfPorduct === -1) {
        const updatedList = [...presciptionLabTestsList, payload.payload];
        return {
          ...state,
          presciptionLabTestsList: updatedList,
        };
      }
      const updatedList = [...presciptionLabTestsList];
      updatedList[indexOfPorduct] = payload.payload;
      return {
        ...state,
        presciptionLabTestsList: updatedList,
      };
    }
    case PHARMA.REMOVE_LAB_TESTS_FROM_PRESCRIPTION_LIST: {
      const listAfterRemovingItem = state.presciptionLabTestsList.filter(
        item => item.productKey !== payload.productKey,
      );
      return {
        ...state,
        presciptionLabTestsList: listAfterRemovingItem,
      };
    }
    case PHARMA.SET_SELECTED_MEDICATION_ITEM: {
      return {
        ...state,
        selectedMedicationItemDetails: payload.payload,
      };
    }
    case PHARMA.DOSAGE_SELECTION_MODAL.TOGGLE_DOSAGE_SELECTION_MODAL: {
      return {
        ...state,
        dosageSelectionModal: payload.isOpened,
      };
    }
    case PHARMA.PHARMA_PRODUCTS.LOAD: {
      return {
        ...state,
        pharmaProductsLoadState: status.FETCHING,
      };
    }

    case PHARMA.PHARMA_PRODUCTS.SUCCESS: {
      return {
        ...state,
        pharmaProducts: payload.payload,
        pharmaProductsLoadState: status.SUCCESS,
      };
    }

    case PHARMA.PHARMA_PRODUCTS.FAIL: {
      return {
        ...state,
        pharmaProductsLoadState: status.FAIL,
      };
    }
    case PHARMA.LAB_TESTS_PRODUCTS.LOAD: {
      return {
        ...state,
        labTestsProductsLoadState: status.FETCHING,
      };
    }

    case PHARMA.LAB_TESTS_PRODUCTS.SUCCESS: {
      return {
        ...state,
        labTestsProducts: payload.payload,
        labTestsProductsLoadState: status.SUCCESS,
      };
    }

    case PHARMA.LAB_TESTS_PRODUCTS.FAIL: {
      return {
        ...state,
        labTestsProductsLoadState: status.FAIL,
      };
    }
    case PHARMA.GET_PREVIOUSLY_ADDED_MEDICATIONS.LOAD: {
      return {
        ...state,
        prevAddedMedicationsLoadState: status.FETCHING,
      };
    }

    case PHARMA.GET_PREVIOUSLY_ADDED_MEDICATIONS.SUCCESS: {
      return {
        ...state,
        prevAddedMedications: payload.payload,
        prevAddedMedicationsLoadState: status.SUCCESS,
      };
    }

    case PHARMA.GET_PREVIOUSLY_ADDED_MEDICATIONS.FAIL: {
      return {
        ...state,
        prevAddedMedicationsLoadState: status.FAIL,
      };
    }
    case PHARMA.GET_PRESCRIPTION_SETTINGS.LOAD: {
      return {
        ...state,
        prescriptionSettingsLoadState: status.FETCHING,
      };
    }

    case PHARMA.GET_PRESCRIPTION_SETTINGS.SUCCESS: {
      return {
        ...state,
        prescriptionSettings: payload.payload,
        prescriptionSettingsLoadState: status.SUCCESS,
      };
    }

    case PHARMA.GET_PRESCRIPTION_SETTINGS.FAIL: {
      return {
        ...state,
        prescriptionSettingsLoadState: status.FAIL,
      };
    }

    case PHARMA.SCANS_CATALOG.LOAD: {
      return {
        ...state,
        scansCatalogLoadState: status.FETCHING,
      };
    }

    case PHARMA.SCANS_CATALOG.SUCCESS: {
      return {
        ...state,
        scansCatalog: payload.payload,
        scansCatalogLoadState: status.SUCCESS,
      };
    }

    case PHARMA.SCANS_CATALOG.FAIL: {
      return {
        ...state,
        scansCatalogLoadState: status.FAIL,
      };
    }

    case PHARMA.ADD_SCANS_TO_PRESCRIPTION_LIST: {
      const { prescriptionScansList } = state;
      const indexOfPorduct = prescriptionScansList.findIndex(
        item => item.productKey === payload.payload.productKey,
      );
      if (indexOfPorduct === -1) {
        const updatedList = [...prescriptionScansList, payload.payload];
        return {
          ...state,
          prescriptionScansList: updatedList,
        };
      }
      const updatedList = [...prescriptionScansList];
      updatedList[indexOfPorduct] = payload.payload;
      return {
        ...state,
        prescriptionScansList: updatedList,
      };
    }
    case PHARMA.REMOVE_SCANS_FROM_PRESCRIPTION_LIST: {
      const listAfterRemovingItem = state.prescriptionScansList.filter(
        item => item.productKey !== payload.productKey,
      );
      return {
        ...state,
        prescriptionScansList: listAfterRemovingItem,
      };
    }

    default:
      return state;
  }
};
