import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { AMAN } from '../actions/aman';

function* getAmanSuscribedBranches(payload) {
  const { branchesKeys } = payload;
  const parameters = [];
  branchesKeys.map(branchKey => {
    parameters.push(encodeURI(`branchKeys=${branchKey}`));
    return true;
  });

  const amanBranches = new API();
  const getbranches = yield amanBranches.get(`${Urls.aman.providers}?${parameters.join('&')}`);
  if (STATUS.isSuccess(getbranches.status)) {
    yield put({
      type: AMAN.FETCH.SUCCEEDED,
      amanBranches: getbranches.data.Branches,
    });
  } else {
    yield put({
      type: AMAN.FETCH.FAILED,
    });
  }
}

function* suscribeBranchesToAman(payload) {
  const { branchesKeys } = payload;
  const branches = branchesKeys.map(branchKey => ({
    branchKey,
  }));
  const amanSignUp = new API();
  const signUp = yield amanSignUp.post(Urls.aman.signUp, {
    branches,
  });
  if (STATUS.isSuccess(signUp.status)) {
    yield put({
      type: AMAN.SUBSCIBE.SUCCEEDED,
    });
  } else {
    yield put({
      type: AMAN.SUBSCIBE.FAILED,
      err: signUp.data,
    });
  }
}

function* editAmanBranchSubscribtion(payload) {
  const { branchDetails } = payload;
  const apiBody = {
    branches: [
      {
        branchKey: branchDetails,
      },
    ],
  };
  const amanSignUp = new API();
  const signUp = yield amanSignUp.post(Urls.aman.signUp, apiBody);
  if (STATUS.isSuccess(signUp.status)) {
    yield put({
      type: AMAN.EDIT.SUCCEEDED,
    });
  } else {
    yield put({
      type: AMAN.EDIT.FAILED,
    });
  }
}

export default function* entities() {
  yield takeEvery(AMAN.SUBSCIBE.LOADING, suscribeBranchesToAman);
  yield takeEvery(AMAN.FETCH.LOADING, getAmanSuscribedBranches);
  yield takeEvery(AMAN.EDIT.LOADING, editAmanBranchSubscribtion);
}
