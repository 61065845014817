import { API, STATUS, Cookie } from '@vezeeta/web-utils';
import authenticate from './Authenticate';

class AuthApi extends API {
  getAuthHeader = () => Cookie.get(Cookie.AUTH_TOKEN);
  getCulture = () => Cookie.get(Cookie.CULTURE);

  makeRequest = (
    type,
    body,
    url,
    reqHeaders,
    shouldCache,
  ) => {
    const reqHeadersFinal = reqHeaders || [];

    reqHeadersFinal.push(
      {
        key: 'Language',
        value: this.getCulture(),
      },
      {
        key: 'ActionSource',
        value: process.env.REACT_APP_ACTION_SOURCE,
      },
      {
        key: 'x-vzt-authentication',
        value: this.getAuthHeader(),
      },
    );

    return new Promise((resolve, reject) =>
      super
        .makeRequest(type, body, url, reqHeadersFinal, shouldCache)
        .then(response => {
          if (STATUS.isNotAuthorized(response.status) || STATUS.isForbidden(response.status)) {
            authenticate(resolve(response), response.status);
          } else {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        }),
    );
  };
}

export default AuthApi;
