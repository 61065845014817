import { takeEvery, takeLatest, select, put } from 'redux-saga/effects';
import { API, STATUS, decodeJwtToken, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { ASSISTANT } from '../actions/assistant';

const assistants = state => state.assistant;

function* getAssistants(action) {
  const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;
  const assistantsHeaders = [
    {
      key: 'AccountKey',
      value: accountKey,
    },
  ];
  const getAssistantsAPI = new API();
  const assistantsData = yield getAssistantsAPI.get(
    `${Urls.assistant.getAssistants}${action.entityKey}`,
    assistantsHeaders,
  );

  if (STATUS.isSuccess(assistantsData.status)) {
    const assistantsList = new Map();
    assistantsData.data.map(assistantData => {
      assistantsList.set(assistantData.AccountKey, {
        ...assistantData,
      });
      return true;
    });
    yield put({
      type: ASSISTANT.FETCH_ASSISTANTS.SUCCESS,
      assistants: assistantsList,
    });
  } else {
    yield put({
      type: ASSISTANT.FETCH_ASSISTANTS.FAIL,
    });
  }
}

function* addAssistant(action) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const assistantsHeaders = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ];
  const addAssistantBody = {
    EntityKey: action.entityKey,
    Assistant: action.assistantDetails,
  };

  const addAssistantAPI = new API();
  const result = yield addAssistantAPI.post(
    Urls.assistant.addAssistant,
    addAssistantBody,
    assistantsHeaders,
    false,
  );

  if (STATUS.isSuccess(result.status)) {
    yield [
      put({
        type: ASSISTANT.ADD_ASSISTANT.SUCCESS,
      }),
      put({
        type: ASSISTANT.FETCH_ASSISTANTS.LOAD,
        entityKey: action.entityKey,
      }),
    ];
  } else {
    yield put({
      type: ASSISTANT.ADD_ASSISTANT.FAIL,
      errorMessage: result.data,
    });
  }
}

function* toggleAssistantState(action) {
  const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;
  const assistantsHeaders = [
    {
      key: 'AccountKey',
      value: accountKey,
    },
  ];
  const toggleAssistantStateAPI = new API();
  const result = yield toggleAssistantStateAPI.post(
    Urls.assistant.toggleAssistantState,
    action.state,
    assistantsHeaders,
    false,
  );

  if (STATUS.isSuccess(result.status)) {
    const assistantsState = yield select(assistants);
    const { assistantsList } = assistantsState.getAssistants;
    const selectedAssistant = assistantsList.get(action.state.AccountKey);
    selectedAssistant.IsActive = action.state.IsActive;
    assistantsList.set(action.state.AccountKey, selectedAssistant);
    yield [
      put({
        type: ASSISTANT.FETCH_ASSISTANTS.SUCCESS,
        assistants: assistantsList,
      }),
      put({
        type: ASSISTANT.ASSISTANT_STATE.SUCCESS,
      }),
    ];
  } else {
    yield put({
      type: ASSISTANT.ASSISTANT_STATE.FAIL,
    });
  }
}

function* assistant() {
  yield takeEvery(ASSISTANT.FETCH_ASSISTANTS.LOAD, getAssistants);
  yield takeEvery(ASSISTANT.ADD_ASSISTANT.LOAD, addAssistant);
  yield takeLatest(ASSISTANT.ASSISTANT_STATE.LOAD, toggleAssistantState);
}

export default assistant;
