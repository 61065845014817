import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, decodeJwtToken, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { USER } from '../actions/user';

function* setUser(actions) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const getAccountByKey = new API();
  const getAccountByKeyHeader = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ];
  const userData = yield getAccountByKey.get(
    `${Urls.getAccountByKey}?languageId=${actions.language === 'en' ? 1 : 2}`,
    getAccountByKeyHeader,
  );
  if (STATUS.isSuccess(userData.status)) {
    Cookie.set(Cookie.CULTURE, `${actions.language}-${userData.data.CountryIsoCode}`);
    yield put({
      type: USER.SET_USER_DONE,
      user: userData.data,
    });
  } else {
    yield put({
      type: USER.SET_USER_FAIL,
      err: userData.data,
    });
  }
}

function* setUserLangauge(actions) {
  const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);

  const { languageId, reload } = actions;
  const setUserDefaultLangauge = new API();
  const setUserDefaultLangaugeHeaders = [
    {
      key: 'AccountKey',
      value: accountKey,
    },
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ];
  const language = yield setUserDefaultLangauge.post(
    Urls.account.setUserDefaultLangauge,
    {
      DefaultLanguageId: languageId,
    },
    setUserDefaultLangaugeHeaders,
  );
  if (STATUS.isSuccess(language.status)) {
    yield put({
      type: USER.SET_USER_LANGUAGE_DONE,
    });
    if (reload) {
      Cookie.set(Cookie.LANGUAGE, `${languageId === 1 ? 'en' : 'ar'}`);
      window.location.reload();
    }
  } else {
    yield put({
      type: USER.SET_USER_LANGUAGE_FAIL,
      err: language.data,
    });
  }
}

function* userSaga() {
  yield takeEvery(USER.GET_USER, setUser);
  yield takeEvery(USER.SET_USER_LANGUAGE, setUserLangauge);
}

export default userSaga;
