import { renderer } from '@vezeeta/web-components';
import { generateMapsFromArrayModels } from 'modules/utils/ArrayModels';
import { getItemName } from 'modules/utils/ComboBox';
import { ENTITIES } from '../actions/entities';
import { STATUS } from '../types/entities';
import { language } from '../types/language';

const initialState = {
  entities: renderer.loading(),
  entitiesList: new Map(),
  branches: renderer.loading(),
  branchesList: new Map(),
  users: renderer.loading(),
  usersList: new Map(),
  userBranches: new Map(),
  currentEntityKey: undefined,
  editEntityModal: {
    isOpen: false,
    isSaving: false,
  },
  branchWorkingHoursModal: {
    isOpen: false,
  },
  currentBranchKey: undefined,
  editBranchModal: {
    isOpen: false,
    isSaving: false,
  },
  currentUserKey: undefined,
  currentUserBranchKey: undefined,
  userInfoModal: { isOpen: false },
  addUser: {
    state: undefined,
    error: undefined,
    snackBar: {
      show: false,
      message: undefined,
    },
  },
  snackBar: {
    show: false,
    message: undefined,
  },
  branchInsurances: [],
  branchInsurancesNames: new Map(),
  isbranchInsurancesLoading: false,
  isbranchInsurancesSuccess: false,
  isbranchInsurancesFailed: false,
  isSetInsurancesLoading: false,
  isSetInsurancesSuccess: false,
  isSetInsurancesFailed: false,
  editBranchInsurancesModal: {
    isOpen: false,
  },
  homeVisitsDoctorStatsModal: {
    isOpen: false,
    doctorDetails: {},
  },
  homeVisitsDoctorStats: {
    isFetching: false,
    isSuccess: false,
    isFail: false,
    doctorStats: {},
  },
  homeVisitsDrLiveStatus: {
    isFetching: false,
    isSuccess: false,
    isFail: false,
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.LOAD:
      state.branchInsurancesNames.clear();
      return {
        ...state,
        isbranchInsurancesLoading: true,
        isbranchInsurancesSuccess: false,
        isbranchInsurancesFailed: false,
      };

    case ENTITIES.MODAL.BRANCH_INSURANCES_TOGGLE:
      return {
        ...state,
        editBranchInsurancesModal: {
          isOpen: payload.show,
        },
      };

    case ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.SUCCESS: {
      const branchInsurancesNames = new Map();
      payload.branchInsurances.forEach(insuranceProvider => {
        branchInsurancesNames.set(
          insuranceProvider.InsuranceKey,
          getItemName(insuranceProvider, 'LanguageItemModels'),
        );
      });
      return {
        ...state,
        branchInsurances: payload.branchInsurances,
        isbranchInsurancesLoading: false,
        isbranchInsurancesSuccess: true,
        isbranchInsurancesFailed: false,
        branchInsurancesNames,
      };
    }

    case ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.FAIL: {
      return {
        ...state,
        isbranchInsurancesLoading: false,
        isbranchInsurancesSuccess: false,
        isbranchInsurancesFailed: true,
      };
    }

    case ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.RESET_DONE: {
      state.branchInsurancesNames.clear();
      return {
        ...state,
        isbranchInsurancesLoading: false,
        isbranchInsurancesSuccess: false,
        isbranchInsurancesFailed: false,
      };
    }

    case ENTITIES.BRANCHES.SET_BRANCH_INSURANCES.LOAD: {
      return {
        ...state,
        isSetInsurancesLoading: true,
        isSetInsurancesSuccess: false,
        isSetInsurancesFailed: false,
      };
    }

    case ENTITIES.BRANCHES.SET_BRANCH_INSURANCES.SUCCESS: {
      return {
        ...state,
        isSetInsurancesLoading: false,
        isSetInsurancesSuccess: true,
        isSetInsurancesFailed: false,
        editBranchInsurancesModal: {
          isOpen: false,
        },
      };
    }
    case ENTITIES.BRANCHES.SET_BRANCH_INSURANCES.FAIL: {
      return {
        ...state,
        isSetInsurancesLoading: false,
        isSetInsurancesSuccess: false,
        isSetInsurancesFailed: true,
        editBranchInsurancesModal: {
          isOpen: true,
        },
      };
    }

    case ENTITIES.ENTITIES.FETCH.LOADING:
      return {
        ...state,
        entities: renderer.loading(),
      };

    case ENTITIES.ENTITIES.FETCH.SUCCEEDED: {
      const { entities } = payload;
      const { entitiesList } = state;

      // See `entity` type
      entities.forEach(entity => {
        const { entityKey, prefixTitleId, entityPhotos } = entity;
        const branches = new Map();
        const { base64 } = generateMapsFromArrayModels(entityPhotos, 'photoTypeId', ['base64']);
        const { name, about } = generateMapsFromArrayModels(
          entity.entityCountryDetails,
          'languageId',
          ['name', 'about'],
        );

        entity.branches.forEach(branch => {
          const branchName = generateMapsFromArrayModels(branch.branchLanguageDetails, 'languageId')
            .name;
          branches.set(branch.branchKey, branchName);
        });

        // Saving each entity in a Map for a faster access
        entitiesList.set(entityKey, {
          key: entityKey,
          prefixTitleId,
          photos: base64,
          name,
          about,
          branches,
        });
      });

      return {
        ...state,
        entities: renderer.success(entitiesList),
        entitiesList,
      };
    }

    case ENTITIES.ENTITIES.FETCH.FAILED:
      return {
        ...state,
        entities: renderer.failure(payload.error),
      };

    case ENTITIES.ENTITIES.EDIT.LOADING: {
      return {
        ...state,
        editEntityModal: {
          isOpen: true,
          isSaving: true,
        },
      };
    }

    case ENTITIES.ENTITIES.EDIT.SUCCEEDED: {
      const { entitiesList } = state;
      const { entityKey, entity } = payload;
      entitiesList.set(entityKey, entity);

      return {
        ...state,
        entities: renderer.success(entitiesList),
        entitiesList,
        editEntityModal: {
          isOpen: false,
          isSaving: false,
        },
        snackBar: {
          show: true,
          message:
            language === 'en'
              ? 'Entity details updated successfully'
              : 'تم تحديث بيانات المنشأة بنجاح',
        },
      };
    }

    case ENTITIES.ENTITIES.EDIT.FAILED: {
      return {
        ...state,
        editEntityModal: {
          isOpen: true,
          isSaving: false,
        },
        snackBar: {
          show: true,
          message:
            language === 'en'
              ? 'An error has occurred, please retry again'
              : '!حدث خطأ، يُرجى إعادة المحاولة',
        },
      };
    }

    case ENTITIES.BRANCHES.FETCH.LOADING:
      return {
        ...state,
        branches: renderer.loading(),
        users: renderer.loading(),
      };

    case ENTITIES.BRANCHES.FETCH.SUCCEEDED: {
      const { branchesList, usersList } = state;
      const { branches, isFullyLoaded } = payload;
      let branchStatusId;

      // See `branch` type
      branches.map(branch => {
        branchStatusId = branch.statusId;
        const {
          name,
          floor,
          landMark,
          streetName,
          apartmentNumber,
          buildingNumber,
        } = generateMapsFromArrayModels(branch.branchLanguageDetails, 'languageId', [
          'name',
          'floor',
          'landMark',
          'streetName',
          'apartmentNumber',
          'buildingNumber',
        ]);
        // For the time being we only support one phone number per branch
        const { number, countryCode, phoneTypeId } = branch.branchPhones
          ? branch.branchPhones[0]
          : {};

        // Set users keys
        const users = new Map();
        if (branch.rooms) {
          branch.rooms.forEach(room => {
            if (room.userRooms) {
              room.userRooms.forEach(user => {
                const userRooms = users.get(user.user.userKey) || [];
                userRooms.push(room.roomKey);
                users.set(user.user.userKey, userRooms);
              });
            }
          });
        }

        users.forEach((rooms, userKey) => {
          const user = usersList.get(userKey);

          if (user) {
            const userRooms = user.rooms.get(branch.branchKey);

            if (!userRooms) {
              user.rooms.set(branch.branchKey, rooms);
            }
          }
        });

        branchesList.set(branch.branchKey, {
          key: branch.branchKey,
          lat: branch.latitude,
          lng: branch.longitude,
          isoCode: branch.clinicNumberIsoCode,
          name,
          floor,
          landMark,
          streetName,
          apartmentNumber,
          buildingNumber,
          number,
          countryCode,
          phoneTypeId,
          isPending: branchStatusId === STATUS.PENDING,
          users,
          isFullyLoaded,
          adminNumber: branch.branchAdminMobileNumber,
          zipCode: branch.zipCode,
        });
      });

      return {
        ...state,
        branches: isFullyLoaded ? renderer.success(branchesList) : renderer.loading(),
        users: renderer.success(usersList),
        branchesList,
      };
    }

    case ENTITIES.BRANCHES.FETCH.FAILED:
      return {
        ...state,
        branches: renderer.failure(payload.error),
      };

    case ENTITIES.BRANCHES.EDIT.LOADING: {
      return {
        ...state,
        editBranchModal: {
          isOpen: true,
          isSaving: true,
        },
      };
    }

    case ENTITIES.BRANCHES.EDIT.SUCCEEDED: {
      const { branchesList, entitiesList } = state;
      const { branchKey, branch, entityKey } = payload;
      const { number, countryCode, phoneTypeId } = branch.phones ? branch.phones[0] : {};

      // $FlowFixMe
      entitiesList.get(entityKey).branches.set(branchKey, branch.name);

      branchesList.set(branchKey, {
        ...branch,
        isPending: false,
        number,
        countryCode,
        phoneTypeId,
      });

      return {
        ...state,
        entities: renderer.success(entitiesList),
        entitiesList,
        branches: renderer.success(branchesList),
        branchesList,
        editBranchModal: {
          isOpen: false,
          isSaving: false,
        },
        snackBar: {
          message:
            language === 'en' ? 'Branch updated successfully' : 'تم تحديث بيانات الفرع بنجاح',
          show: true,
        },
      };
    }

    case ENTITIES.BRANCHES.EDIT.FAILED: {
      return {
        ...state,
        editBranchModal: {
          isOpen: true,
          isSaving: false,
        },
        snackBar: {
          show: true,
          message:
            language === 'en'
              ? 'An error has occurred, please retry again'
              : '!حدث خطأ، يُرجى إعادة المحاولة',
        },
      };
    }

    case ENTITIES.USERS.FETCH.LOADING:
      return {
        ...state,
        users: renderer.loading(),
      };

    case ENTITIES.USERS.FETCH.SUCCEEDED: {
      const { profiles, accounts, userRooms, branchKey } = payload;
      const { usersList } = state;

      // Load users data from profile
      profiles.forEach(user => {
        const {
          userCountryDetails,
          userKey,
          userSpecialties,
          userPhotos,
          prefixTitleId,
          titleId,
          genderId,
          statusId,
          nationalitiesIds,
        } = user;

        const {
          firstName,
          lastName,
          professionalTitle,
          about,
        } = generateMapsFromArrayModels(userCountryDetails, 'languageId', [
          'firstName',
          'lastName',
          'professionalTitle',
          'about',
        ]);

        const { url } = generateMapsFromArrayModels(userPhotos, 'photoTypeId', ['url']);

        const subSpecialties = [];
        let mainSpecialty;

        // Loop through all specialties
        userSpecialties.forEach(speciality => {
          const { isMainSpecialty, specialtyKey } = speciality;
          if (isMainSpecialty) {
            mainSpecialty = specialtyKey;
          } else {
            subSpecialties.push(specialtyKey);
          }
        });

        usersList.set(userKey, {
          key: userKey,
          firstName,
          lastName,
          subSpecialties,
          mainSpecialty,
          prefixTitleId,
          titleId,
          genderId,
          statusId,
          photos: url,
          professionalTitle,
          about,
          nationalitiesIds,
          rooms: new Map(),
        });
      });

      // Load data from accounts
      accounts.forEach(user => {
        const { AccountKey, EmailAddress, CountryCode, CountryCodeId, PhoneNumber } = user;
        const userData = usersList.get(AccountKey);

        usersList.set(AccountKey, {
          ...userData,
          email: EmailAddress,
          countryCode: CountryCode,
          number: PhoneNumber,
          countryCodeId: CountryCodeId,
        });
      });

      // Load each user room
      userRooms.forEach((rooms, userKey) => {
        const userData = usersList.get(userKey);
        const branchRooms = new Map();
        branchRooms.set(branchKey, rooms);

        usersList.set(userKey, {
          ...userData,
          rooms: branchRooms,
        });
      });

      return {
        ...state,
        users: renderer.success(usersList),
        usersList,
      };
    }

    case ENTITIES.USERS.FETCH.FAILED:
      return {
        ...state,
        users: renderer.failure(payload.error),
      };

    case ENTITIES.USERS.FETCH_BRANCHES.LOADING: {
      const newUserBranches = new Map();
      return {
        ...state,
        users: renderer.loading(),
        userBranches: newUserBranches,
      };
    }

    case ENTITIES.USERS.FETCH_BRANCHES.SUCCEEDED: {
      const { users } = payload;
      const { userBranches } = state;

      // Load userBranches data from profile
      users.forEach(user => {
        user.entities.forEach(entity => {
          entity.branches.forEach(branch => {
            branch.userBranches.forEach(userBranch => {
              const {
                statusId,
                fees,
                userKey,
                branchKey,
                userBranchesInsuranceProviders,
              } = userBranch;

              userBranches.set(branchKey, {
                branchKey,
                userKey,
                statusId,
                branchFees: fees,
                userBranchesInsuranceProviders,
              });
            });
          });
        });
      });

      return {
        ...state,
        users: renderer.success(userBranches),
        userBranches,
      };
    }

    case ENTITIES.USERS.FETCH_BRANCHES.FAILED:
      return {
        ...state,
        users: renderer.failure(payload.error),
      };

    case ENTITIES.USERS.ADD.LOADING:
      return {
        ...state,
        addUser: {
          ...state.addUser,
          userInfo: payload.userInfo,
          state: ENTITIES.USERS.ADD.LOADING,
        },
      };

    case ENTITIES.USERS.ADD.SUCCEEDED: {
      const { usersList, branchesList } = state;
      const { userKey, user, isUserEdit } = payload;

      // Update both branchesList and usersList
      const rooms = new Map();
      user.branches.map(branch => {
        const { branchKey, branchRooms } = branch;
        const branchDetails = branchesList.get(branchKey);

        if (branchDetails) {
          // Add new user to branches
          branchDetails.users.set(userKey, branchRooms);
          branchesList.set(branchKey, branchDetails);
        }

        // Add rooms to the user
        rooms.set(branchKey, branchRooms);
      });

      usersList.set(userKey, { key: userKey, statusId: STATUS.ACTIVE, rooms, ...user });

      // See if the first branch is loaded or not
      const firstBranch = Array.from(branchesList.values())[0];
      const isFirstBranchFullyLoaded = firstBranch.isFullyLoaded;

      let message;
      if (language === 'en') {
        message = isUserEdit ? 'Changes saved successfully' : 'User added successfully';
      } else {
        message = isUserEdit ? 'تم حفظ التغييرات بنجاح' : 'تم إضافة المستخدم بنجاح';
      }

      return {
        ...state,
        addUser: {
          ...state.addUser,
          state: ENTITIES.USERS.ADD.SUCCEEDED,
          error: undefined,
        },
        snackBar: {
          show: true,
          message,
        },
        users: renderer.success(usersList),
        usersList,
        branches: isFirstBranchFullyLoaded ? renderer.success(branchesList) : renderer.loading(),
        branchesList,
      };
    }

    case ENTITIES.USERS.ADD.FAILED: {
      const { error, isConflict } = payload;

      return {
        ...state,
        addUser: {
          state: ENTITIES.USERS.ADD.FAILED,
          error,
          snackBar: {
            show: !isConflict,
            message:
              language === 'en'
                ? 'An error has occurred, please retry again'
                : 'حدث خطأ ما، برجاء المحاولة مرة أخرى',
          },
        },
      };
    }

    case ENTITIES.MODAL.SET_ENTITY_KEY:
      return {
        ...state,
        currentEntityKey: payload.entityKey,
      };

    case ENTITIES.MODAL.EDIT_ENTITY_TOGGLE:
      return {
        ...state,
        editEntityModal: {
          isOpen: payload.show,
        },
      };

    case ENTITIES.MODAL.TOGGLE_WORKING_HOURS_MODAL:
      return {
        ...state,
        branchWorkingHoursModal: {
          isOpen: payload.show,
        },
      };

    case ENTITIES.MODAL.SET_BRANCH_KEY:
      return {
        ...state,
        currentBranchKey: payload.branchKey,
      };

    case ENTITIES.MODAL.EDIT_BRANCH_TOGGLE:
      return {
        ...state,
        editBranchModal: {
          isOpen: payload.show,
        },
      };

    case ENTITIES.MODAL.SET_USER_KEY:
      return {
        ...state,
        currentUserKey: payload.userKey,
        currentUserBranchKey: payload.branchKey,
      };

    case ENTITIES.MODAL.USER_INFO_TOGGLE:
      return {
        ...state,
        userInfoModal: {
          isOpen: payload.show,
        },
      };

    case ENTITIES.HIDE_SNACK_BAR: {
      return {
        ...state,
        snackBar: {
          show: false,
        },
      };
    }

    case ENTITIES.MODAL.HOME_VISITS_DR_STATS_TOGGLE:
      return {
        ...state,
        homeVisitsDoctorStatsModal: {
          isOpen: payload.isOpen,
        },
      };

    case ENTITIES.MODAL.SET_HOME_VISITS_DR_STATS_DETAILS:
      return {
        ...state,
        homeVisitsDoctorStatsModal: {
          isOpen: state.homeVisitsDoctorStatsModal.isOpen,
          doctorDetails: payload.doctorDetails,
        },
      };

    case ENTITIES.HOME_VISITS_DR_STATS.LOADING:
      return {
        ...state,
        homeVisitsDoctorStats: {
          isFetching: true,
          isSuccess: false,
          isFail: false,
          doctorStats: {},
        },
      };

    case ENTITIES.HOME_VISITS_DR_STATS.SUCCEEDED:
      return {
        ...state,
        homeVisitsDoctorStats: {
          isFetching: false,
          isSuccess: true,
          isFail: false,
          doctorStats: payload.data,
        },
      };

    case ENTITIES.HOME_VISITS_DR_STATS.FAILED:
      return {
        ...state,
        homeVisitsDoctorStats: {
          isFetching: false,
          isSuccess: false,
          isFail: true,
          doctorStats: {},
        },
        snackBar: {
          show: true,
          message: payload.errorMessage,
        },
      };

    case ENTITIES.HOME_VISITS_DR_LIVE_STATUS.LOADING:
      return {
        ...state,
        homeVisitsDrLiveStatus: {
          isFetching: true,
          isSuccess: false,
          isFail: false,
        },
      };

    case ENTITIES.HOME_VISITS_DR_LIVE_STATUS.SUCCEEDED: {
      const { usersList } = state;
      const map = new Map();
      payload.data.map(item => map.set(item.supplierAccountKey, item.hvActivity));
      map.forEach((user, userkey) => {
        const userData = usersList.get(userkey);
        usersList.set(userkey, {
          ...userData,
          HomeVisitsStatus: user,
        });
      });

      // mutate users data
      return {
        ...state,
        users: renderer.success(usersList),
        usersList,
        homeVisitsDrLiveStatus: {
          isFetching: false,
          isSuccess: true,
          isFail: false,
        },
      };
    }

    case ENTITIES.HOME_VISITS_DR_LIVE_STATUS.FAILED:
      return {
        ...state,
        homeVisitsDrLiveStatus: {
          isFetching: false,
          isSuccess: false,
          isFail: true,
        },
        snackBar: {
          show: true,
          message:
            language === 'en'
              ? 'An error has occurred, please retry again'
              : '!حدث خطأ، يُرجى إعادة المحاولة',
        },
      };

    default:
      return state;
  }
};
