// @flow

import { renderer } from '@vezeeta/web-components';
import { status } from 'modules/utils/enum';
import { ACCOUNTS } from '../actions/accounts';

const initialState = {
  userBasicInfo: renderer.loading(),
  userBasicInfoList: new Map(),
  userAccountsList: undefined,
  userAccountsListLoadState: status.SHOULD_CALL_API,
  addUserAccount: undefined,
  addUserAccountLoadState: status.SHOULD_CALL_API,
  editUserAccount: undefined,
  editUserAccountLoadState: status.SHOULD_CALL_API,
  updateLoginStatusLoadState: status.SHOULD_CALL_API,

  userAccountInfo: undefined,
  filterDropDown: { isOpened: false },
  addUserModal: { isOpened: false },
  editUserModal: { isOpened: false },
  aboutUserModal: { isOpened: false },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case ACCOUNTS.BASICINFO.LOADING:
      return {
        ...state,
        userBasicInfo: renderer.loading(),
      };

    case ACCOUNTS.BASICINFO.SUCCEEDED: {
      const { userBasicInfoList } = state;
      const { accountKey, userBasicInfo } = payload;
      userBasicInfoList.set(accountKey, {
        FirstName: userBasicInfo.FirstName,
        LastName: userBasicInfo.LastName,
        CountryCodeId: userBasicInfo.CountryCodeId,
        PhoneNumber: userBasicInfo.PhoneNumber,
        EmailAddress: userBasicInfo.EmailAddress,
        GoogleId: userBasicInfo.GoogleId,
        FacebookId: userBasicInfo.FacebookId,
        CountryIsoCode: userBasicInfo.CountryIsoCode,
        ProfilePhotoPath: userBasicInfo.ProfilePhotoPath,
      });

      return {
        ...state,
        userBasicInfo: renderer.success(userBasicInfoList),
        userBasicInfoList,
      };
    }

    case ACCOUNTS.BASICINFO.FAILED:
      return {
        ...state,
        userBasicInfo: renderer.failure(payload.error),
      };
    case ACCOUNTS.TOGGLE_FILTER_DROPDOWN:
      return { ...state, filterDropDown: { isOpened: payload.payload } };
    case ACCOUNTS.TOGGLE_ADD_USER_MODAL:
      return { ...state, addUserModal: { isOpened: payload.payload } };
    case ACCOUNTS.TOGGLE_EDIT_USER_MODAL:
      return { ...state, editUserModal: { isOpened: payload.payload } };
    case ACCOUNTS.TOGGLE_ABOUT_USER_MODAL:
      return { ...state, aboutUserModal: { isOpened: payload.payload } };

    case ACCOUNTS.SET_USER_ACCOUNT_INFO:
      return { ...state, userAccountInfo: payload.payload };
    case ACCOUNTS.SEARCH_ACCOUNTS.LOADING:
      return { ...state, userAccountsListLoadState: status.FETCHING, userAccountsList: undefined };
    case ACCOUNTS.SEARCH_ACCOUNTS.SUCCEEDED: {
      return {
        ...state,
        userAccountsListLoadState: status.SUCCESS,
        userAccountsList: payload.payload,
      };
    }
    case ACCOUNTS.SEARCH_ACCOUNTS.FAILED:
      return {
        ...state,
        userAccountsListLoadState: status.FAIL,
        userAccountsList: payload.payload,
      };

    case ACCOUNTS.Add_USER_ACCOUNT.LOADING:
      return { ...state, addUserAccountLoadState: status.SUBMITING };
    case ACCOUNTS.Add_USER_ACCOUNT.SUCCEEDED: {
      return {
        ...state,
        addUserAccountLoadState: status.SUCCESS,
        addUserAccount: payload.payload,
      };
    }
    case ACCOUNTS.Add_USER_ACCOUNT.FAILED:
      return {
        ...state,
        addUserAccountLoadState: status.FAIL,
        addUserAccount: payload.payload,
      };

    case ACCOUNTS.EDIT_USER_ACCOUNTS.LOADING:
      return { ...state, editUserAccountLoadState: status.SUBMITING };
    case ACCOUNTS.EDIT_USER_ACCOUNTS.SUCCEEDED: {
      return {
        ...state,
        editUserAccountLoadState: status.SUCCESS,
        editUserAccount: payload.payload,
      };
    }
    case ACCOUNTS.EDIT_USER_ACCOUNTS.FAILED:
      return {
        ...state,
        editUserAccountLoadState: status.FAIL,
        editUserAccount: payload.payload,
      };

    case ACCOUNTS.UPDATE_LOGIN_STATUS.LOADING:
      return { ...state, updateLoginStatusLoadState: status.SUBMITING };
    case ACCOUNTS.UPDATE_LOGIN_STATUS.SUCCEEDED: {
      return {
        ...state,
        updateLoginStatusLoadState: status.SUCCESS,
      };
    }
    case ACCOUNTS.UPDATE_LOGIN_STATUS.FAILED:
      return {
        ...state,
        updateLoginStatusLoadState: status.FAIL,
      };

    default:
      return state;
  }
};
