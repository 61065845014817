import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  ACCOUNTS,
  searchAccountsFail,
  searchAccountsSuccess,
  addUserAccountSuccess,
  addUserAccountFail,
  updateLoginStatusSuccess,
  updateLoginStatusFail,
  editUserAccountSuccess,
  editUserAccountFail,
} from '../actions/accounts';

function* getBasicInfo(payload) {
  const { getAccountHeader } = payload;
  const basicInfo = new API();
  const getUserBasicInfo = yield basicInfo.get(Urls.account.getAccount, getAccountHeader);
  if (STATUS.isSuccess(getUserBasicInfo.status)) {
    yield put({
      type: ACCOUNTS.BASICINFO.SUCCEEDED,
      userBasicInfo: getUserBasicInfo.data,
      accountKey: getAccountHeader[0].value,
    });
  } else {
    yield put({
      type: ACCOUNTS.BASICINFO.FAILED,
    });
  }
}

function* searchAccountsPaginated(action) {
  const searchAccountsAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield searchAccountsAPI.post(Urls.searchAccounts, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      searchAccountsSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      searchAccountsFail({
        ...action.payload,
      }),
    );
  }
}

function* addAccount(action) {
  const addUserAccountAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'ComponentKey',
      value: 'accountmanagement',
    },
  ];

  const response = yield addUserAccountAPI.post(Urls.registerAccount, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      addUserAccountSuccess({
        ...action.payload,
      }),
    );
  } else {
    yield put(
      addUserAccountFail({
        ...action.payload,
        data: response.data,
      }),
    );
  }
}

function* updateLoginStatus(action) {
  const updateLoginStatusAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield updateLoginStatusAPI.post(Urls.updateLoginStatus, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      updateLoginStatusSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      updateLoginStatusFail({
        ...action.payload,
      }),
    );
  }
}

function* editUserAccount(action) {
  const editUserAccountAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield editUserAccountAPI.post(Urls.editAssistant, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      editUserAccountSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      editUserAccountFail({
        ...action.payload,
        ...response,
      }),
    );
  }
}

export default function* accounts() {
  yield takeEvery(ACCOUNTS.BASICINFO.LOADING, getBasicInfo);
  yield takeEvery(ACCOUNTS.SEARCH_ACCOUNTS.LOADING, searchAccountsPaginated);
  yield takeEvery(ACCOUNTS.Add_USER_ACCOUNT.LOADING, addAccount);
  yield takeEvery(ACCOUNTS.UPDATE_LOGIN_STATUS.LOADING, updateLoginStatus);
  yield takeEvery(ACCOUNTS.EDIT_USER_ACCOUNTS.LOADING, editUserAccount);
}
