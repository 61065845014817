export const FILTERS = {
  TOGGLE_CALENDAR: 'TOGGLE_CALENDAR',
  TOGGLE_FILTER_DROPDOWN: 'TOGGLE_FILTER_DROPDOWN',
  TOGGLE_DATE_DROPDOWN: 'TOGGLE_DATE_DROPDOWN',
  CLOSE_ALL_DROPDOWNS: 'CLOSE_ALL_DROPDOWNS',
};

export const toggleCalendar = payload => ({
  type: FILTERS.TOGGLE_CALENDAR,
  payload,
});

export const toggleFilterDropdown = payload => ({
  type: FILTERS.TOGGLE_FILTER_DROPDOWN,
  payload,
});

export const toggleDateDropdown = payload => ({
  type: FILTERS.TOGGLE_DATE_DROPDOWN,
  payload,
});

export const closeDropdowns = () => ({
  type: FILTERS.CLOSE_ALL_DROPDOWNS,
});
