import { status } from 'modules/utils/enum';
import { ACTIVITIES } from '../actions/activities';

const initialState = {
  activitiesReservations: {
    islastPage: false,
  },
  activitiesReservationsState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case ACTIVITIES.ACTIVITIES_RESERVATION.RESET: {
      return {
        ...state,
        activitiesReservations: {
          islastPage: false,
        },
        activitiesReservationsState: status.SHOULD_CALL_API,
      };
    }
    case ACTIVITIES.ACTIVITIES_RESERVATION.LOAD: {
      let activitiesObj;
      if (payload.payload && payload.payload.Page === 1) {
        activitiesObj = payload.payload;
      } else {
        const { activitiesReservations } = state;
        activitiesObj = {
          ...activitiesReservations,
          ...payload.payload,
        };
      }

      return {
        ...state,
        activitiesReservations: activitiesObj,
        activitiesReservationsState: status.FETCHING,
      };
    }

    case ACTIVITIES.ACTIVITIES_RESERVATION.SUCCESS: {
      let activitiesObj;
      if (payload.payload && payload.payload.PageNumber === 1) {
        activitiesObj = {
          ...payload.payload,
          islastPage:
            (payload.payload.Reservations && payload.payload.Reservations.length < 10) ||
            !payload.payload.Reservations.length,
        };
      } else {
        const { activitiesReservations } = state;
        const { Reservations } = activitiesReservations;
        activitiesObj = {
          ...activitiesReservations,
          Reservations: [...Reservations, ...payload.payload.Reservations],
          islastPage:
            (payload.payload.Reservations && payload.payload.Reservations.length < 10) ||
            !payload.payload.Reservations.length,
        };
      }

      return {
        ...state,
        activitiesReservations: activitiesObj,
        activitiesReservationsState: status.SUCCESS,
      };
    }

    case ACTIVITIES.ACTIVITIES_RESERVATION.FAIL: {
      const { activitiesReservations } = state;
      const activitiesObj = {
        ...activitiesReservations,
        islastPage: true,
      };
      return {
        ...state,
        activitiesReservations: activitiesObj,
        activitiesReservationsState: status.FAIL,
      };
    }

    default:
      return state;
  }
};
