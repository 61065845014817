export const ROOM = {
  FILTER: 'ROOM_FILTER',
  FILTER_SUCCESS: 'ROOM_FILTER_SUCCESS',
  GET_ROOM_DETAILS: 'ROOM_GET_ROOM_DETAILS',
  GET_ROOM_DETAILS_SUCCESS: 'ROOM_GET_ROOM_DETAILS_SUCCESS',
  GET_ROOM_DETAILS_FAIL: 'ROOM_GET_ROOM_DETAILS_FAIL',
  SET_ROOM_SCHEDULE_TYPES: 'SET_ROOM_SCHEDULE_TYPES',
  SET_ROOM_RESERVATION_WINDOW: 'SET_ROOM_RESERVATION_WINDOW',
  SET_SELECTED_ROOM_SCHEDULE_TYPE: 'SET_SELECTED_ROOM_SCHEDULE_TYPE',
  ADD_ROOM: {
    SUBMIT: 'ADD_ROOM_SUBMIT',
    SUCCESS: 'ADD_ROOM_SUCCESS',
    FAIL: 'ADD_ROOM_FAIL',
  },
  UPDATE_ROOM: {
    SUBMIT: 'UPDATE_ROOM_SUBMIT',
    SUCCESS: 'UPDATE_ROOM_SUCCESS',
    FAIL: 'UPDATE_ROOM_FAIL',
  },
  DELETE_ROOM: {
    SUBMIT: 'DELETE_ROOM_SUBMIT',
    SUCCESS: 'DELETE_ROOM_SUCCESS',
    FAIL: 'DELETE_ROOM_FAIL',
  },
  GET_ROOMS: {
    FETCH: 'GET_ROOMS_FETCH',
    SUCCESS: 'GET_ROOMS_SUCCESS',
    FAIL: 'GET_ROOMS_FAIL',
  },
  TOGGLE_ADD_EDIT_ROOM_MODAL: 'TOGGLE_ADD_EDIT_ROOM_MODAL',
  GET_DOCTOR_SHIFTS_ROOMS: {
    FETCH: 'GET_DOCTOR_SHIFTS_ROOMS_FETCH',
    SUCCESS: 'GET_DOCTOR_SHIFTS_ROOMS_SUCCESS',
    FAIL: 'GET_DOCTOR_SHIFTS_ROOMS_FAIL',
  },
  ASSIGN_DOCTOR_SHIFTS_TO_ROOMS: {
    SUBMIT: 'ASSIGN_DOCTOR_SHIFTS_TO_ROOMS_SUBMIT',
    SUCCESS: 'ASSIGN_DOCTOR_SHIFTS_TO_ROOMS_SUCCESS',
    FAIL: 'ASSIGN_DOCTOR_SHIFTS_TO_ROOMS_FAIL',
  },
  GET_ROOMS_CALENDAR_VIEW: {
    FETCH: 'GET_ROOMS_CALENDAR_VIEW_FETCH',
    SUCCESS: 'GET_ROOMS_CALENDAR_VIEW_SUCCESS',
    FAIL: 'GET_ROOMS_CALENDAR_VIEW_FAIL',
  },
};

export const getRoomsCalendarView = payload => ({
  type: ROOM.GET_ROOMS_CALENDAR_VIEW.FETCH,
  payload,
});

export const getRoomsCalendarViewSuccess = payload => ({
  type: ROOM.GET_ROOMS_CALENDAR_VIEW.SUCCESS,
  payload,
});

export const getRoomsCalendarViewFail = payload => ({
  type: ROOM.GET_ROOMS_CALENDAR_VIEW.FAIL,
  payload,
});

export const addRoom = payload => ({
  type: ROOM.ADD_ROOM.SUBMIT,
  payload,
});

export const addRoomSucces = payload => ({
  type: ROOM.ADD_ROOM.SUCCESS,
  payload,
});

export const addRoomFail = payload => ({
  type: ROOM.ADD_ROOM.FAIL,
  payload,
});

export const editRoom = payload => ({
  type: ROOM.UPDATE_ROOM.SUBMIT,
  payload,
});

export const editRoomSucces = payload => ({
  type: ROOM.UPDATE_ROOM.SUCCESS,
  payload,
});

export const editRoomFail = payload => ({
  type: ROOM.UPDATE_ROOM.FAIL,
  payload,
});

export const deleteRoom = payload => ({
  type: ROOM.DELETE_ROOM.SUBMIT,
  payload,
});

export const deleteRoomSuccess = payload => ({
  type: ROOM.DELETE_ROOM.SUCCESS,
  payload,
});

export const deleteRoomFail = payload => ({
  type: ROOM.DELETE_ROOM.FAIL,
  payload,
});

export const getRooms = payload => ({
  type: ROOM.GET_ROOMS.FETCH,
  payload,
});

export const getRoomsSuccess = payload => ({
  type: ROOM.GET_ROOMS.SUCCESS,
  payload,
});

export const getRoomsFail = payload => ({
  type: ROOM.GET_ROOMS.FAIL,
  payload,
});

export const toggleAddEditRoomModal = payload => ({
  type: ROOM.TOGGLE_ADD_EDIT_ROOM_MODAL,
  payload,
});

export const filterWorkingHours = (days, filterData) => ({
  type: ROOM.FILTER,
  days,
  filterData,
});

export const getRoomDetails = (clinics, loadAllDoctors = false) => ({
  type: ROOM.GET_ROOM_DETAILS,
  clinics,
  loadAllDoctors,
});

export const getRoomDetailsSuccess = (roomScheduleTypes, roomReservationWindow) => ({
  type: ROOM.GET_ROOM_DETAILS_SUCCESS,
  roomScheduleTypes,
  roomReservationWindow,
});

export const getRoomDetailsFail = error => ({
  type: ROOM.GET_ROOM_DETAILS_FAIL,
  error,
});

export const setRoomScheduleTypes = roomScheduleTypes => ({
  type: ROOM.SET_ROOM_SCHEDULE_TYPES,
  roomScheduleTypes,
});

export const setRoomReservationWindow = roomReservationWindow => ({
  type: ROOM.SET_ROOM_RESERVATION_WINDOW,
  roomReservationWindow,
});

export const setSelectedScheduleType = selectedScheduleType => ({
  type: ROOM.SET_SELECTED_ROOM_SCHEDULE_TYPE,
  selectedScheduleType,
});

export const getDoctorWorkingHoursShift = payload => ({
  type: ROOM.GET_DOCTOR_SHIFTS_ROOMS.FETCH,
  payload,
});

export const getDoctorWorkingHoursShiftSuccess = payload => ({
  type: ROOM.GET_DOCTOR_SHIFTS_ROOMS.SUCCESS,
  payload,
});

export const getDoctorWorkingHoursShiftFail = payload => ({
  type: ROOM.GET_DOCTOR_SHIFTS_ROOMS.FAIL,
  payload,
});

export const assignDoctorShiftToRoom = payload => ({
  type: ROOM.ASSIGN_DOCTOR_SHIFTS_TO_ROOMS.SUBMIT,
  payload,
});

export const assignDoctorShiftToRoomSuccess = payload => ({
  type: ROOM.ASSIGN_DOCTOR_SHIFTS_TO_ROOMS.SUCCESS,
  payload,
});

export const assignDoctorShiftToRoomFail = payload => ({
  type: ROOM.ASSIGN_DOCTOR_SHIFTS_TO_ROOMS.FAIL,
  payload,
});
