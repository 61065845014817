export const ENTITIES = {
  ENTITIES: {
    FETCH: {
      LOADING: 'ENTITIES_FETCH',
      SUCCEEDED: 'ENTITIES_FETCH_SUCCEEDED',
      FAILED: 'ENTITIES_FETCH_FAILED',
    },
    EDIT: {
      LOADING: 'ENTITIES_EDIT',
      SUCCEEDED: 'ENTITIES_EDIT_SUCCEEDED',
      FAILED: 'ENTITIES_EDIT_FAILED',
    },
  },
  BRANCHES: {
    FETCH: {
      LOADING: 'ENTITIES_BRANCHES_FETCH',
      SUCCEEDED: 'ENTITIES_BRANCHES_FETCH_SUCCEEDED',
      FAILED: 'ENTITIES_BRANCHES_FETCH_FAILED',
    },
    EDIT: {
      LOADING: 'ENTITIES_BRANCHES_EDIT',
      SUCCEEDED: 'ENTITIES_BRANCHES_EDIT__SUCCEEDED',
      FAILED: 'ENTITIES_BRANCHES_EDIT_FAILED',
    },
    FETCH_BRANCH_INSURANCES: {
      LOAD: 'INSURANCE_BRANCH_FETCH_LOAD',
      SUCCESS: 'INSURANCE_BRANCH_FETCH_SUCCESS',
      FAIL: 'INSURANCE_BRANCH_FETCH_FAIL',
      RESET: 'INSURANCE_BRANCH_FETCH_RESET',
      RESET_DONE: 'INSURANCE_BRANCH_FETCH_RESET_DONE',
    },
    SET_BRANCH_INSURANCES: {
      LOAD: 'INSURANCE_BRANCH_SET_LOAD',
      SUCCESS: 'INSURANCE_BRANCH_SET_SUCCESS',
      FAIL: 'INSURANCE_BRANCH_SET_FAIL',
    },
  },
  MODAL: {
    EDIT_ENTITY_TOGGLE: 'ENTITIES_MODAL_EDIT_ENTITY_TOGGLE',
    SET_ENTITY_KEY: 'ENTITIES_MODAL_EDIT_SET_KEY',
    EDIT_BRANCH_TOGGLE: 'ENTITIES_MODAL_EDIT_BRANCH_TOGGLE',
    SET_BRANCH_KEY: 'BRANCHES_MODAL_EDIT_SET_KEY',
    USER_INFO_TOGGLE: 'ENTITIES_MODAL_USER_INFO_TOGGLE',
    SET_USER_KEY: 'USER_MODAL_EDIT_SET_KEY',
    BRANCH_INSURANCES_TOGGLE: 'INSURANCES_MODAL_BRANCH_INSURANCES_TOGGLE',
    HOME_VISITS_DR_STATS_TOGGLE: 'HOME_VISITS_DR_STATS_TOGGLE',
    SET_HOME_VISITS_DR_STATS_DETAILS: 'SET_HOME_VISITS_DR_STATS_DETAILS',
    TOGGLE_WORKING_HOURS_MODAL: 'TOGGLE_WORKING_HOURS_MODAL',
  },
  USERS: {
    ADD: {
      LOADING: 'ENTITIES_USERS_ADD',
      SUCCEEDED: 'ENTITIES_USERS_ADD_SUCCEEDED',
      FAILED: 'ENTITIES_USERS_ADD_FAILED',
    },
    FETCH: {
      LOADING: 'ENTITIES_USERS_FETCH',
      SUCCEEDED: 'ENTITIES_USERS_FETCH_SUCCEEDED',
      FAILED: 'ENTITIES_USERS_FETCH_FAILED',
    },
    FETCH_BRANCHES: {
      LOADING: 'ENTITIES_USERS_BRANCHES_FETCH',
      SUCCEEDED: 'ENTITIES_USERS_BRANCHES_FETCH_SUCCEEDED',
      FAILED: 'ENTITIES_USERS_BRANCHES_FETCH_FAILED',
    },
  },
  HIDE_SNACK_BAR: 'ENTITY_HIDE_SNACK_BAR',
  HOME_VISITS_DR_STATS: {
    LOADING: 'HOME_VISITS_DR_STATS_FETCH',
    SUCCEEDED: 'HOME_VISITS_DR_STATS_SUCCEEDED',
    FAILED: 'HOME_VISITS_DR_STATS_FAILED',
  },
  HOME_VISITS_DR_LIVE_STATUS: {
    LOADING: 'HOME_VISITS_DR_LIVE_STATUS_FETCH',
    SUCCEEDED: 'HOME_VISITS_DR_LIVE_STATUS_SUCCEEDED',
    FAILED: 'HOME_VISITS_DR_LIVE_STATUS_FAILED',
  },
};

export const toggleWorkingHoursModal = show => ({
  type: ENTITIES.MODAL.TOGGLE_WORKING_HOURS_MODAL,
  show,
});

export const getEntities = entityKeys => ({
  type: ENTITIES.ENTITIES.FETCH.LOADING,
  entityKeys,
});

export const getBranches = (branchKeys, statusIdOnly = false) => ({
  type: ENTITIES.BRANCHES.FETCH.LOADING,
  branchKeys,
  statusIdOnly,
});

export const getUsers = (users, branchKey) => ({
  type: ENTITIES.USERS.FETCH.LOADING,
  users,
  branchKey,
});

export const getUsersBranches = users => ({
  type: ENTITIES.USERS.FETCH_BRANCHES.LOADING,
  users,
});

export const addUser = (userDetails, entitiesKeys) => ({
  type: ENTITIES.USERS.ADD.LOADING,
  userDetails,
  entitiesKeys,
});

export const setCurrentEntityKey = entityKey => ({
  type: ENTITIES.MODAL.SET_ENTITY_KEY,
  entityKey,
});

export const editEntity = entity => ({
  type: ENTITIES.ENTITIES.EDIT.LOADING,
  entity,
});

export const toggleEditEntityModal = show => ({
  type: ENTITIES.MODAL.EDIT_ENTITY_TOGGLE,
  show,
});

export const setCurrentBranchKey = branchKey => ({
  type: ENTITIES.MODAL.SET_BRANCH_KEY,
  branchKey,
});

export const editBranch = branch => ({
  type: ENTITIES.BRANCHES.EDIT.LOADING,
  branch,
});

export const toggleEditBranchModal = show => ({
  type: ENTITIES.MODAL.EDIT_BRANCH_TOGGLE,
  show,
});

// get default insurance companies assigned on a branch
export const loadBranchInsurances = branchKey => ({
  type: ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.LOAD,
  branchKey,
});
// set updates and changes on insurance companies assigned on a branch
export const updateBranchInsurances = (branchKey, insurances) => ({
  type: ENTITIES.BRANCHES.SET_BRANCH_INSURANCES.LOAD,
  branchKey,
  insurances,
});

export const resetBranchInsurances = () => ({
  type: ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.RESET,
});

export const toggleBranchInsurancesModal = show => ({
  type: ENTITIES.MODAL.BRANCH_INSURANCES_TOGGLE,
  show,
});

export const setCurrentUserKey = (userKey, branchKey) => ({
  type: ENTITIES.MODAL.SET_USER_KEY,
  userKey,
  branchKey,
});

export const toggleUserInfoModalModal = show => ({
  type: ENTITIES.MODAL.USER_INFO_TOGGLE,
  show,
});

export const hideSnackBar = () => ({
  type: ENTITIES.HIDE_SNACK_BAR,
});

export const toggleHomeVisitsDrStatsModal = isOpen => ({
  type: ENTITIES.MODAL.HOME_VISITS_DR_STATS_TOGGLE,
  isOpen,
});

export const setHomeVisitsDrStatsQueryData = doctorDetails => ({
  type: ENTITIES.MODAL.SET_HOME_VISITS_DR_STATS_DETAILS,
  doctorDetails,
});

export const homeVisitsDrStatsLoad = (SupplierKey, CountryId, FilterStatsByDate, BranchKey) => ({
  type: ENTITIES.HOME_VISITS_DR_STATS.LOADING,
  SupplierKey,
  CountryId,
  FilterStatsByDate,
  BranchKey,
});

export const homeVisitsDrStatsSuccess = data => ({
  type: ENTITIES.HOME_VISITS_DR_STATS.SUCCEEDED,
  data,
});

export const homeVisitsDrStatsFail = errorMessage => ({
  type: ENTITIES.HOME_VISITS_DR_STATS.FAILED,
  errorMessage,
});

export const loadHomeVisitsDrLiveStatus = accKeys => ({
  type: ENTITIES.HOME_VISITS_DR_LIVE_STATUS.LOADING,
  accKeys,
});

export const loadHomeVisitsDrLiveStatusSuccess = data => ({
  type: ENTITIES.HOME_VISITS_DR_LIVE_STATUS.SUCCEEDED,
  data,
});

export const loadHomeVisitsDrLiveStatusFail = () => ({
  type: ENTITIES.HOME_VISITS_DR_LIVE_STATUS.FAILED,
});
