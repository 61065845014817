export const VACATIONS = {
  LOAD: 'LOAD',
  LOAD_DONE: 'LOAD_DONE',
  LOAD_FAIL: 'LOAD_FAIL',
  ADD: 'ADD',
  ADD_DONE: 'ADD_DONE',
  ADD_FAIL: 'ADD_FAIL',
  DELETE: 'DELETE',
  DELETE_DONE: 'DELETE_DONE',
  DELETE_FAIL: 'DELETE_FAIL',
  FILTERED: {
    CREATE: 'CREATE',
    SET_SELECTED_BRANCH: 'SET_SELECTED_BRANCH',
    SET_SELECTED_ROOMS: 'SET_SELECTED_ROOMS',
    SET_SELECTED_DOCTORS: 'SET_SELECTED_DOCTORS',
    SET_DATES: 'SET_DATES',
  },
  RESERVATIONS_IN_RANGE: {
    LOAD: 'RESERVATIONS_IN_RANGE_LOAD',
    LOAD_DONE: 'RESERVATIONS_IN_RANGE_LOAD_DONE',
    LOAD_FAIL: 'RESERVATIONS_IN_RANGE_LOAD_FAIL',
    RESET: 'RESERVATIONS_IN_RANGE_RESET',
  },
  MODAL: {
    TOGGLE_DELETE: 'DELETE_MODAL',
    TOGGLE_ADD: 'ADD_MODAL',
  },
  SET_DELETE_MESSAGE: 'SET_DELETE_MESSAGE',
  SET_SNACK_BAR_AS_HIDDEN: 'SET_SNACK_BAR_AS_HIDDEN',
};

export const loadVacations = (roomsNames, doctorsNames, selectedDoctors, startDate, endDate) => ({
  type: VACATIONS.LOAD,
  roomsNames,
  doctorsNames,
  selectedDoctors,
  startDate,
  endDate,
});

export const loadVacationsDone = vacations => ({
  type: VACATIONS.LOAD_DONE,
  vacations,
});

export const loadVacationsFail = err => ({
  type: VACATIONS.LOAD_FAIL,
  err,
});

export const addVacation = (vacation, rooms, doctors, callback, callbackParams) => ({
  type: VACATIONS.ADD,
  vacation,
  rooms,
  doctors,
  callback,
  callbackParams,
});

export const addVacationDone = (vacation, rooms, doctors) => ({
  type: VACATIONS.ADD_DONE,
  vacation,
  rooms,
  doctors,
});

export const addVacationFail = err => ({
  type: VACATIONS.ADD_FAIL,
  err,
});

export const deleteVacation = vacation => ({
  type: VACATIONS.DELETE,
  deleteVacation: vacation,
});

export const deleteVacationDone = vacation => ({
  type: VACATIONS.DELETE_DONE,
  vacation,
});

export const deleteVacationFail = err => ({
  type: VACATIONS.DELETE_FAIL,
  err,
});

export const createVacationFiltered = data => ({
  type: VACATIONS.FILTERED.CREATE,
  clinics: data.clinics || [],
  branches: data.branches || 'All Branches',
  rooms: data.rooms,
  doctors: data.doctors,
  startDate: data.startDate || '',
  endDate: data.endDate || '',
});

export const setVacationFilterSelectedBranch = branches => ({
  type: VACATIONS.FILTERED.SET_SELECTED_BRANCH,
  branches,
});

export const setVacationFilterSelectedRooms = rooms => ({
  type: VACATIONS.FILTERED.SET_SELECTED_ROOMS,
  rooms,
});

export const setVacationFilterSelectedDoctors = (doctors, hasDoctors) => ({
  type: VACATIONS.FILTERED.SET_SELECTED_DOCTORS,
  doctors,
  hasDoctors,
});

export const setVacationFilterDates = data => ({
  type: VACATIONS.FILTERED.SET_DATES,
  data,
});

export const loadCountReservationInRange = data => ({
  type: VACATIONS.RESERVATIONS_IN_RANGE.LOAD,
  data,
});

export const loadCountReservationInRangeDone = data => ({
  type: VACATIONS.RESERVATIONS_IN_RANGE.LOAD_DONE,
  data,
});

export const loadCountReservationInRangeFail = err => ({
  type: VACATIONS.RESERVATIONS_IN_RANGE.LOAD_FAIL,
  err,
});

export const resetReservationsInRange = () => ({
  type: VACATIONS.RESERVATIONS_IN_RANGE.RESET,
});

export const toggleDeleteVacationModal = showModal => ({
  type: VACATIONS.MODAL.TOGGLE_DELETE,
  showModal,
});

export const setDeleteMessage = message => ({
  type: VACATIONS.SET_DELETE_MESSAGE,
  message,
});

export const toggleAddVacationModal = showModal => ({
  type: VACATIONS.MODAL.TOGGLE_ADD,
  showModal,
});

export const setSnackBarAsHidden = () => ({
  type: VACATIONS.SET_SNACK_BAR_AS_HIDDEN,
});
