import { fork } from 'redux-saga/effects';
import insurancesSaga from './insurances';
import relativeTypesSaga from './relativeTypes';
import rolesSaga from './roles';
import userSaga from './user';
import clinicsSaga from './clinics';
import roomSaga from './room';
import appointmentsSaga from './appointments';
import confirmationsSaga from './confirmations';
import workingHoursSaga from './workingHours';
import patientsSaga from './patients';
import vacationSaga from './vacations';
import country from './country';
import schedules from './schedules';
import payment from './payment';
import specialties from './specialties';
import prefixTitles from './prefixTitles';
import professionalTitles from './professionalTitles';
import entities from './entities';
import services from './services';
import aman from './aman';
import assistant from './assistant';
import citiesAndAreas from './citiesAndAreas';
import accounts from './accounts';
import pharma from './Pharma';
import bids from './bid';
import activities from './activities';
import servicesAndOperationsSaga from './servicesAndOperations';
import doctorSaga from './doctor';
import invoices from './invoices';
import plans from './plans';

const sagas = [
  plans,
  invoices,
  doctorSaga,
  servicesAndOperationsSaga,
  activities,
  bids,
  insurancesSaga,
  relativeTypesSaga,
  rolesSaga,
  userSaga,
  workingHoursSaga,
  clinicsSaga,
  roomSaga,
  appointmentsSaga,
  confirmationsSaga,
  patientsSaga,
  vacationSaga,
  country,
  schedules,
  payment,
  specialties,
  prefixTitles,
  professionalTitles,
  entities,
  services,
  aman,
  assistant,
  citiesAndAreas,
  accounts,
  pharma,
];

export default function* rootSaga() {
  yield sagas.map(saga => fork(saga));
}
