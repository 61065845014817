// eslint-disable-next-line import/prefer-default-export
export const status = {
  SHOULD_CALL_API: 'SHOULD_CALL_API',
  FETCHING: 'FETCHING',
  SUBMITING: 'SUBMITING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const branchBookingTypes = {
  PHYSCIAL: 1,
  TELEHEALTH: 2,
  HOME_VISIT: 3,
  PRIMARY_CARE: 4,
};

export const DISCOUNT_TYPES = {
  BY_VALUE: 0,
  BY_PERCENTAGE: 1,
};

export const APPOINTMENT_TYPES = {
  EXAMINATION: 0,
  CONSULTATIONS: 1,
  SERVICES: 3,
};

export const FIELDS_ERRORS = {
  VALID_FIELDS: 0,
  REQUIRED_FIELDS_ERROR: 1,
  NUMERICAL_FIELDS_ERROR: 2,
  ADD_SERVICE: 3,
};

export const appointmentsStatuses = {
  PatientCheckedIn: 'PatientCheckedIn',
  Pending: 'Pending',
  Completed: 'Completed',
  NoShow: 'NoShow',
  Cancelled: 'Cancelled',
  NoAction: 'NoAction',
};

export const prescriptionItemTypesEnum = {
  medicine: 1,
  labTest: 2,
  scan: 3,
};
