import { FILTERS } from '../actions/filters';

const initialState = {
  isCalendarOpened: false,
  isDateDropDownOpened: false,
  isFilterDropDownOpened: false,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case FILTERS.TOGGLE_CALENDAR:
      return { ...state, isCalendarOpened: payload.payload };
    case FILTERS.TOGGLE_DATE_DROPDOWN:
      return { ...state, isDateDropDownOpened: payload.payload };
    case FILTERS.TOGGLE_FILTER_DROPDOWN:
      return { ...state, isFilterDropDownOpened: payload.payload };
    case FILTERS.CLOSE_ALL_DROPDOWNS:
      return {
        ...state,
        isCalendarOpened: false,
        isDateDropDownOpened: false,
        isFilterDropDownOpened: false,
      };

    default:
      return state;
  }
};
