import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

// URLs
import { SERVICES } from '../actions/servicesAndOperations';

function* getServicesList(action) {
  const header = [
    {
      key: 'countryId',
      value: action.payload.countryId,
    },
  ];
  const getServicesApi = new API();
  const getServicesApiResponse = yield getServicesApi.get(
    `${Urls.getServicesAndChildServices}?specialtyCountryKey=${action.payload.serviceCountryKey}`,
    header,
  );

  if (STATUS.isSuccess(getServicesApiResponse.status)) {
    yield put({
      type: SERVICES.GET_SERVICES.SUCCESS,
      list: getServicesApiResponse.data,
    });
  } else {
    yield put({
      type: SERVICES.GET_SERVICES.FAIL,
      error: getServicesApiResponse.data,
    });
  }
}

function* getInsuranceServicesList(action) {
  const header = [
    {
      key: 'countryId',
      value: action.payload.countryId,
    },
  ];
  const getInsuranceServicesApi = new API();
  const getInsuranceServicesApiResponse = yield getInsuranceServicesApi.get(
    `${Urls.getInsuranceServicesAndChildServices}?specialtyCountryKey=${action.payload.serviceCountryKey}&insuranceKey=${action.payload.insuranceKey}`,
    header,
  );

  if (STATUS.isSuccess(getInsuranceServicesApiResponse.status)) {
    yield put({
      type: SERVICES.GET_INSURANCE_SERVICES.SUCCESS,
      list: getInsuranceServicesApiResponse.data,
    });
  } else {
    yield put({
      type: SERVICES.GET_INSURANCE_SERVICES.FAIL,
      error: getInsuranceServicesApiResponse.data,
    });
  }
}

function* servicesAndOperationsSaga() {
  yield takeLatest(SERVICES.GET_SERVICES.LOAD, getServicesList);
  yield takeLatest(SERVICES.GET_INSURANCE_SERVICES.LOAD, getInsuranceServicesList);
}

export default servicesAndOperationsSaga;
