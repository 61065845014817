import { status } from '../enum';
import { DOCTOR } from '../actions/doctor';

const initialState = {
  roomBookingInfo: undefined,
  roomBookingInfoState: status.SHOULD_CALL_API,
  doctorDetails: undefined,
  doctorDetailsLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case DOCTOR.GET_ROOM_BOOKING_INFO.LOAD: {
      return {
        ...state,
        roomBookingInfoState: status.FETCHING,
      };
    }

    case DOCTOR.GET_ROOM_BOOKING_INFO.SUCCESS: {
      return {
        ...state,
        roomBookingInfo: payload,
        roomBookingInfoState: status.SUCCESS,
      };
    }

    case DOCTOR.GET_ROOM_BOOKING_INFO.FAIL: {
      return {
        ...state,
        roomBookingInfoState: status.FAIL,
      };
    }

    case DOCTOR.GET_Doctor_Details.LOAD: {
      return {
        ...state,
        doctorDetails: undefined,
        doctorDetailsLoadState: status.FETCHING,
      };
    }

    case DOCTOR.GET_Doctor_Details.SUCCESS: {
      return {
        ...state,
        doctorDetails: payload,
        doctorDetailsLoadState: status.SUCCESS,
      };
    }

    case DOCTOR.GET_Doctor_Details.FAIL: {
      return {
        ...state,
        doctorDetails: payload,
        doctorDetailsLoadState: status.FAIL,
      };
    }

    default:
      return state;
  }
};
