import { takeLatest, put } from 'redux-saga/effects';
import { STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';
import { AuthApi } from 'modules/sso';

import { CONFIRMATIONS } from '../actions/confirmations';

function* fetchConfirmations(action) {
  const apiWrapper = yield new AuthApi();
  const response = yield apiWrapper.post(Urls.getConfirmationsUrl, action.keys);
  yield put({
    type: CONFIRMATIONS.FETCH_SUCCESS,
    confirmations: response.data,
  });
}

function* saveConfirmations(action) {
  const apiWrapper = yield new AuthApi();
  const changesDoctorsInfoList = [];
  if (action.confirmations && action.confirmations.length) {
    action.confirmations.forEach(item => {
      if (item.isChanged) {
        const { isChanged, ...restOfObj } = item;
        changesDoctorsInfoList.push(restOfObj);
      }
    });
  }
  const response = yield apiWrapper.post(Urls.saveConfirmationsUrl, {
    ActionMaker: 'Doctor',
    AccountRoomActions: changesDoctorsInfoList,
  });
  if (STATUS.isSuccess(response.status)) {
    yield put({
      type: CONFIRMATIONS.SAVE_SUCCESS,
      confirmations: action.confirmations,
    });
  } else {
    yield put({
      type: CONFIRMATIONS.SAVE_FAIL,
    });
  }
}

function* confirmationsSaga() {
  yield takeLatest(CONFIRMATIONS.FETCH, fetchConfirmations);
  yield takeLatest(CONFIRMATIONS.SAVE, saveConfirmations);
}

export default confirmationsSaga;
