export const DOCTOR = {
  GET_ROOM_BOOKING_INFO: {
    LOAD: 'ROOM_BOOKING_INFO_LOAD',
    SUCCESS: 'ROOM_BOOKING_INFO_SUCCESS',
    FAIL: 'ROOM_BOOKING_INFO_FAIL',
  },
  GET_Doctor_Details: {
    LOAD: 'GET_Doctor_Details_LOAD',
    SUCCESS: 'GET_Doctor_Details_SUCCESS',
    FAIL: 'GET_Doctor_Details_FAIL',
  },
};

export const getRoomBookingInfo = payload => ({
  type: DOCTOR.GET_ROOM_BOOKING_INFO.LOAD,
  payload,
});

export const getDoctorDetails = payload => ({
  type: DOCTOR.GET_Doctor_Details.LOAD,
  payload,
});

export const getDoctorDetailsSuccess = payload => ({
  type: DOCTOR.GET_Doctor_Details.SUCCESS,
  payload,
});

export const getDoctorDetailsFailed = payload => ({
  type: DOCTOR.GET_Doctor_Details.FAIL,
  payload,
});
