import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  BIDS,
  PLACE_BID,
  fetchBidsSuccess,
  fetchBidsFail,
  placeBidSuccess,
  placeBidFail,
} from '../actions/bid';

function* fetchBids(action) {
  const bidsAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];
  const response = yield bidsAPI.post(Urls.sponseredAds.getCampaigns, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(fetchBidsSuccess(response.data));
  } else {
    yield put(fetchBidsFail());
  }
}

function* placeBid(action) {
  const placeBidAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];
  const response = yield placeBidAPI.post(Urls.sponseredAds.placeBid, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(placeBidSuccess(response.data));
  } else {
    yield put(placeBidFail());
  }
}

function* bidSaga() {
  yield takeLatest(BIDS.FETCH, fetchBids);
  yield takeLatest(PLACE_BID.SAVE, placeBid);
}

export default bidSaga;
