import { WORKING_HOURS } from '../actions/workingHours';
import { status } from '../enum';

const initialState = {
  list: [],
  getWorkingHoursLoadState: status.SHOULD_CALL_API,
  loadState: WORKING_HOURS.LOAD,
  saveWorkingHoursLoadState: status.SHOULD_CALL_API,
  rooms: [],
  roomScheduleType: [],
  setBranchWorkingHoursResponse: undefined,
  setBranchWorkingHoursLoadState: status.SHOULD_CALL_API,
  branchWorkingHours: undefined,
  getBranchWorkingHoursLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case WORKING_HOURS.LOAD:
      return { ...state, loadState: WORKING_HOURS.LOAD, getWorkingHoursLoadState: status.FETCHING };
    case WORKING_HOURS.LOAD_DONE: {
      let roomsObj = {};
      if (payload.rooms && payload.rooms.data) {
        roomsObj = payload.rooms.data;
      }
      return {
        ...state,
        ...roomsObj,
        loadState: WORKING_HOURS.LOAD_DONE,
        getWorkingHoursLoadState: status.SUCCESS,
      };
    }
    case WORKING_HOURS.LOAD_SUCCESS:
      return { ...state, list: payload.rooms, loadState: WORKING_HOURS.LOAD_SUCCESS };

    case WORKING_HOURS.LOAD_FAIL:
      return { ...state, getWorkingHoursLoadState: status.SUCCESS };

    case WORKING_HOURS.WORKING_HOURS.SUBMIT:
      return { ...state, saveWorkingHoursLoadState: status.SUBMITING };
    case WORKING_HOURS.WORKING_HOURS.SUCCESS: {
      let roomsObj = {};
      if (payload.rooms && payload.rooms.data) {
        roomsObj = payload.rooms.data;
      }
      return {
        ...state,
        ...roomsObj,
        saveWorkingHoursResponse: payload.rooms,
        saveWorkingHoursLoadState: status.SUCCESS,
      };
    }
    case WORKING_HOURS.WORKING_HOURS.FAIL: {
      return {
        ...state,
        saveWorkingHoursLoadState: status.FAIL,
        saveWorkingHoursResponse: payload.err,
      };
    }

    case WORKING_HOURS.SET_BRANCH_WORKING_HOURS.SUBMIT:
      return {
        ...state,
        setBranchWorkingHoursResponse: undefined,
        setBranchWorkingHoursLoadState: status.SUBMITING,
      };
    case WORKING_HOURS.SET_BRANCH_WORKING_HOURS.SUCCESS:
      return {
        ...state,
        setBranchWorkingHoursResponse: payload.payload,
        setBranchWorkingHoursLoadState: status.SUCCESS,
      };
    case WORKING_HOURS.SET_BRANCH_WORKING_HOURS.FAIL:
      return {
        ...state,
        setBranchWorkingHoursResponse: payload.payload,
        setBranchWorkingHoursLoadState: status.FAIL,
      };

    case WORKING_HOURS.GET_BRANCH_WORKING_HOURS.FETCH:
      return {
        ...state,
        branchWorkingHours: undefined,
        getBranchWorkingHoursLoadState: status.FETCHING,
      };
    case WORKING_HOURS.GET_BRANCH_WORKING_HOURS.SUCCESS:
      return {
        ...state,
        branchWorkingHours: payload.payload,
        getBranchWorkingHoursLoadState: status.SUCCESS,
      };
    case WORKING_HOURS.GET_BRANCH_WORKING_HOURS.FAIL:
      return {
        ...state,
        getBranchWorkingHoursLoadState: status.FAIL,
      };

    default:
      return state;
  }
};
