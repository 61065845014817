import { ROOM } from '../actions/room';
import { status } from '../enum';

const initialState = {
  saveObject: {
    ActionMaker: 'Doctor',
    WeekDetails: [
      {
        DayOfWeek: 'Sunday',
        DayShifts: [],
      },
      {
        DayOfWeek: 'Monday',
        DayShifts: [],
      },
      {
        DayOfWeek: 'Tuesday',
        DayShifts: [],
      },
      {
        DayOfWeek: 'Wednesday',
        DayShifts: [],
      },
      {
        DayOfWeek: 'Thursday',
        DayShifts: [],
      },
      {
        DayOfWeek: 'Friday',
        DayShifts: [],
      },
      {
        DayOfWeek: 'Saturday',
        DayShifts: [],
      },
    ],
  },
  roomScheduleTypes: [],
  roomReservationWindow: [],
  roomLoaded: false,
  selectedScheduleType: undefined,

  addRoomResponse: undefined,
  addRoomLoadState: status.SHOULD_CALL_API,
  editRoomResponse: undefined,
  editRoomLoadState: status.SHOULD_CALL_API,
  deleteRoomResponse: undefined,
  deleteRoomLoadState: status.SHOULD_CALL_API,
  getRoomsResponse: undefined,
  getRoomsLoadState: status.SHOULD_CALL_API,
  addEditRoomModal: { roomDetails: {}, isOpen: false, isEdit: false },
  getDoctorShiftsResponse: undefined,
  getDoctorShiftsLoadState: status.SHOULD_CALL_API,
  assignDoctorShiftsToRoomResponse: undefined,
  assignDoctorShiftsToRoomLoadState: status.SHOULD_CALL_API,
  getRoomsCalendarViewResponse: undefined,
  getRoomsCalendarViewLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case ROOM.GET_ROOMS_CALENDAR_VIEW.FETCH:
      return {
        ...state,
        getRoomsCalendarViewResponse: undefined,
        getRoomsCalendarViewLoadState: status.FETCHING,
      };
    case ROOM.GET_ROOMS_CALENDAR_VIEW.SUCCESS:
      return {
        ...state,
        getRoomsCalendarViewResponse: payload.payload,
        getRoomsCalendarViewLoadState: status.SUCCESS,
      };
    case ROOM.GET_ROOMS_CALENDAR_VIEW.FAIL:
      return {
        ...state,
        getRoomsCalendarViewResponse: payload.payload,
        getRoomsCalendarViewLoadState: status.FAIL,
      };
    case ROOM.GET_DOCTOR_SHIFTS_ROOMS.FETCH:
      return {
        ...state,
        getDoctorShiftsResponse: undefined,
        getDoctorShiftsLoadState: status.FETCHING,
      };
    case ROOM.GET_DOCTOR_SHIFTS_ROOMS.SUCCESS:
      return {
        ...state,
        getDoctorShiftsResponse: payload.payload,
        getDoctorShiftsLoadState: status.SUCCESS,
      };
    case ROOM.GET_DOCTOR_SHIFTS_ROOMS.FAIL:
      return {
        ...state,
        getDoctorShiftsResponse: payload.payload,
        getDoctorShiftsLoadState: status.FAIL,
      };

    case ROOM.ASSIGN_DOCTOR_SHIFTS_TO_ROOMS.SUBMIT:
      return {
        ...state,
        assignDoctorShiftsToRoomResponse: undefined,
        assignDoctorShiftsToRoomLoadState: status.SUBMITING,
      };
    case ROOM.ASSIGN_DOCTOR_SHIFTS_TO_ROOMS.SUCCESS:
      return {
        ...state,
        assignDoctorShiftsToRoomResponse: payload.payload,
        assignDoctorShiftsToRoomLoadState: status.SUCCESS,
      };
    case ROOM.ASSIGN_DOCTOR_SHIFTS_TO_ROOMS.FAIL:
      return {
        ...state,
        assignDoctorShiftsToRoomResponse: payload.payload,
        assignDoctorShiftsToRoomLoadState: status.FAIL,
      };
    case ROOM.GET_ROOM_DETAILS_SUCCESS: {
      let roomScheduleTypesObj = {};
      let roomReservationWindowObj = {};
      if (payload.roomScheduleTypes) {
        roomScheduleTypesObj = {
          roomScheduleTypes: payload.roomScheduleTypes,
        };
      }
      if (payload.roomReservationWindow && payload.roomReservationWindow.data) {
        roomReservationWindowObj = {
          roomReservationWindow: payload.roomReservationWindow.data,
        };
      }
      return Object.assign({}, state, roomScheduleTypesObj, roomReservationWindowObj, {
        roomLoaded: true,
      });
    }
    case ROOM.FILTER_SUCCESS:
      return { ...state, saveObject: payload.days };
    case ROOM.SET_ROOM_SCHEDULE_TYPES:
      return { ...state, roomScheduleTypes: payload.roomScheduleTypes };
    case ROOM.SET_ROOM_RESERVATION_WINDOW:
      return { ...state, roomReservationWindow: payload.roomReservationWindow };
    case ROOM.SET_SELECTED_ROOM_SCHEDULE_TYPE:
      return { ...state, selectedScheduleType: payload.selectedScheduleType };

    case ROOM.ADD_ROOM.SUBMIT:
      return {
        ...state,
        addRoomResponse: undefined,
        addRoomLoadState: status.SUBMITING,
      };
    case ROOM.ADD_ROOM.SUCCESS:
      return {
        ...state,
        addRoomResponse: payload.payload,
        addRoomLoadState: status.SUCCESS,
      };
    case ROOM.ADD_ROOM.FAIL:
      return {
        ...state,
        addRoomResponse: payload.payload,
        addRoomLoadState: status.FAIL,
      };

    case ROOM.UPDATE_ROOM.SUBMIT:
      return {
        ...state,
        editRoomResponse: undefined,
        editRoomLoadState: status.SUBMITING,
      };
    case ROOM.UPDATE_ROOM.SUCCESS:
      return {
        ...state,
        editRoomResponse: payload.payload,
        editRoomLoadState: status.SUCCESS,
      };
    case ROOM.UPDATE_ROOM.FAIL:
      return {
        ...state,
        editRoomResponse: payload.payload,
        editRoomLoadState: status.FAIL,
      };

    case ROOM.DELETE_ROOM.SUBMIT:
      return {
        ...state,
        deleteRoomResponse: undefined,
        deleteRoomLoadState: status.SUBMITING,
      };
    case ROOM.DELETE_ROOM.SUCCESS:
      return {
        ...state,
        deleteRoomResponse: payload.payload,
        deleteRoomLoadState: status.SUCCESS,
      };
    case ROOM.DELETE_ROOM.FAIL:
      return {
        ...state,
        deleteRoomResponse: payload.payload,
        deleteRoomLoadState: status.FAIL,
      };

    case ROOM.GET_ROOMS.FETCH:
      return {
        ...state,
        getRoomsResponse: undefined,
        getRoomsLoadState: status.FETCHING,
      };
    case ROOM.GET_ROOMS.SUCCESS:
      return {
        ...state,
        getRoomsResponse: payload.payload,
        getRoomsLoadState: status.SUCCESS,
      };
    case ROOM.GET_ROOMS.FAIL:
      return {
        ...state,
        getRoomsResponse: payload.payload,
        getRoomsLoadState: status.FAIL,
      };

    case ROOM.TOGGLE_ADD_EDIT_ROOM_MODAL:
      return {
        ...state,
        addEditRoomModal: {
          ...state.addEditRoomModal,
          ...payload.payload,
        },
      };
    default:
      return state;
  }
};
