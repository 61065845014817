export const PHARMA = {
  PHARMA_PRODUCTS: {
    LOAD: 'PHARMA_PRODUCTS_LOAD',
    SUCCESS: 'PHARMA_PRODUCTS_SUCCESS',
    FAIL: 'PHARMA_PRODUCTS_FAIL',
  },
  LAB_TESTS_PRODUCTS: {
    LOAD: 'LAB_TESTS_PRODUCTS_LOAD',
    SUCCESS: 'LAB_TESTS_PRODUCTS_SUCCESS',
    FAIL: 'LAB_TESTS_PRODUCTS_FAIL',
  },
  SCANS_CATALOG: {
    LOAD: 'SCANS_CATALOG_LOAD',
    SUCCESS: 'SCANS_CATALOG_SUCCESS',
    FAIL: 'SCANS_CATALOG_FAIL',
  },
  GET_PRESCRIPTION_SETTINGS: {
    LOAD: 'GET_PRESCRIPTION_SETTINGS_LOAD',
    SUCCESS: 'GET_PRESCRIPTION_SETTINGS_SUCCESS',
    FAIL: 'GET_PRESCRIPTION_SETTINGS_FAIL',
  },
  GET_PREVIOUSLY_ADDED_MEDICATIONS: {
    LOAD: 'GET_PREVIOUSLY_ADDED_MEDICATIONS_LOAD',
    SUCCESS: 'GET_PREVIOUSLY_ADDED_MEDICATIONS_SUCCESS',
    FAIL: 'GET_PREVIOUSLY_ADDED_MEDICATIONS_FAIL',
  },
  DOSAGE_SELECTION_MODAL: {
    TOGGLE_DOSAGE_SELECTION_MODAL: 'TOGGLE_DOSAGE_SELECTION_MODAL',
  },
  DISPLAY_EPRESC_MODAL: {
    TOGGLE_DISPLAY_EPRESC_MODAL: 'TOGGLE_DISPLAY_EPRESC_MODAL',
  },
  DISPLAY_PRESC_MODAL: {
    TOGGLE_DISPLAY_PRESC_MODAL: 'TOGGLE_DISPLAY_PRESC_MODAL',
  },
  GET_EPRESC: {
    LOAD: 'GET_EPRESC_LOAD',
    SUCCESS: 'GET_EPRESC_SUCCESS',
    FAIL: 'GET_EPRESC_FAIL',
    RESET: 'GET_EPRESC_RESET',
  },
  SET_SELECTED_MEDICATION_ITEM: 'SET_SELECTED_MEDICATION_ITEM',
  ADD_MEDICATION_TO_PRESCRIPTION_LIST: 'ADD_MEDICATION_TO_PRESCRIPTION_LIST',
  RESET_MEDICATION_TO_PRESCRIPTION_LIST: 'RESET_MEDICATION_TO_PRESCRIPTION_LIST',
  REMOVE_MEDICATION_FROM_PRESCRIPTION_LIST: 'REMOVE_MEDICATION_FROM_PRESCRIPTION_LIST',
  ADD_LAB_TESTS_TO_PRESCRIPTION_LIST: 'ADD_LAB_TESTS_TO_PRESCRIPTION_LIST',
  REMOVE_LAB_TESTS_FROM_PRESCRIPTION_LIST: 'REMOVE_LAB_TESTS_FROM_PRESCRIPTION_LIST',
  ADD_SCANS_TO_PRESCRIPTION_LIST: 'ADD_SCANS_TO_PRESCRIPTION_LIST',
  REMOVE_SCANS_FROM_PRESCRIPTION_LIST: 'REMOVE_SCANS_FROM_PRESCRIPTION_LIST',
  EDIT_MEDICATION: 'EDIT_MEDICATION',
  SUBMIT_EPRESCRIPTION: {
    SUBMIT: 'SUBMIT_EPRESCRIPTION_SUBMIT',
    SUCCESS: 'SUBMIT_EPRESCRIPTION_SUCCESS',
    FAIL: 'SUBMIT_EPRESCRIPTION_FAIL',
  },
  GET_PRESC: {
    LOAD: 'GET_PRESC_LOAD',
    SUCCESS: 'GET_PRESC_SUCCESS',
    FAIL: 'GET_PRESC_FAIL',
  },
};

export const resetEPrescDetails = () => ({
  type: PHARMA.GET_EPRESC.RESET,
});

export const getPrescription = payload => ({
  type: PHARMA.GET_PRESC.LOAD,
  payload,
});

export const getPrescriptionSuccess = payload => ({
  type: PHARMA.GET_PRESC.SUCCESS,
  payload,
});

export const getPrescriptionFail = payload => ({
  type: PHARMA.GET_PRESC.FAIL,
  payload,
});

export const toggleDisplayEPrescModal = isOpened => ({
  type: PHARMA.DISPLAY_EPRESC_MODAL.TOGGLE_DISPLAY_EPRESC_MODAL,
  isOpened,
});

export const toggleDisplayPrescModal = isOpened => ({
  type: PHARMA.DISPLAY_PRESC_MODAL.TOGGLE_DISPLAY_PRESC_MODAL,
  isOpened,
});

export const getEPrescription = payload => ({
  type: PHARMA.GET_EPRESC.LOAD,
  payload,
});

export const getEPrescriptionSuccess = payload => ({
  type: PHARMA.GET_EPRESC.SUCCESS,
  payload,
});

export const getEPrescriptionFail = payload => ({
  type: PHARMA.GET_EPRESC.FAIL,
  payload,
});

export const submitEprescription = payload => ({
  type: PHARMA.SUBMIT_EPRESCRIPTION.SUBMIT,
  payload,
});

export const submitEprescriptionSuccess = payload => ({
  type: PHARMA.SUBMIT_EPRESCRIPTION.SUCCESS,
  payload,
});

export const submitEprescriptionFail = payload => ({
  type: PHARMA.SUBMIT_EPRESCRIPTION.FAIL,
  payload,
});

export const resetMedicationToPrescriptionList = () => ({
  type: PHARMA.RESET_MEDICATION_TO_PRESCRIPTION_LIST,
});

export const addMedicationToPrescriptionList = payload => ({
  type: PHARMA.ADD_MEDICATION_TO_PRESCRIPTION_LIST,
  payload,
});

export const removeMedicationFromPrescriptionList = payload => ({
  type: PHARMA.REMOVE_MEDICATION_FROM_PRESCRIPTION_LIST,
  productKey: payload,
});

export const editMedication = payload => ({
  type: PHARMA.EDIT_MEDICATION,
  productKey: payload,
});

export const addLabTestsToPrescriptionList = payload => ({
  type: PHARMA.ADD_LAB_TESTS_TO_PRESCRIPTION_LIST,
  payload,
});

export const removeLabTestsFromPrescriptionList = payload => ({
  type: PHARMA.REMOVE_LAB_TESTS_FROM_PRESCRIPTION_LIST,
  productKey: payload,
});

export const getPrescriptionSettings = payload => ({
  type: PHARMA.GET_PRESCRIPTION_SETTINGS.LOAD,
  productKey: payload,
});

export const getPrescriptionSettingsSuccess = payload => ({
  type: PHARMA.GET_PRESCRIPTION_SETTINGS.SUCCESS,
  payload,
});

export const getPrescriptionSettingsFail = payload => ({
  type: PHARMA.GET_PRESCRIPTION_SETTINGS.FAIL,
  payload,
});

export const setSelectedMedicationItem = payload => ({
  type: PHARMA.SET_SELECTED_MEDICATION_ITEM,
  payload,
});

export const toggleDosageSelectionModal = isOpened => ({
  type: PHARMA.DOSAGE_SELECTION_MODAL.TOGGLE_DOSAGE_SELECTION_MODAL,
  isOpened,
});

export const getPharmaProducts = payload => ({
  type: PHARMA.PHARMA_PRODUCTS.LOAD,
  keyWord: payload,
});

export const getPharmaProductsSuccess = payload => ({
  type: PHARMA.PHARMA_PRODUCTS.SUCCESS,
  payload,
});

export const getPharmaProductsFail = payload => ({
  type: PHARMA.PHARMA_PRODUCTS.FAIL,
  payload,
});

export const getLabTestsProducts = payload => ({
  type: PHARMA.LAB_TESTS_PRODUCTS.LOAD,
  keyWord: payload,
});

export const getLabTestsProductsSuccess = payload => ({
  type: PHARMA.LAB_TESTS_PRODUCTS.SUCCESS,
  payload,
});

export const getLabTestsProductsFail = payload => ({
  type: PHARMA.LAB_TESTS_PRODUCTS.FAIL,
  payload,
});

export const getPreviouslyAddedMedications = () => ({
  type: PHARMA.GET_PREVIOUSLY_ADDED_MEDICATIONS.LOAD,
});

export const getPreviouslyAddedMedicationsSuccess = payload => ({
  type: PHARMA.GET_PREVIOUSLY_ADDED_MEDICATIONS.SUCCESS,
  payload,
});

export const getPreviouslyAddedMedicationsFail = () => ({
  type: PHARMA.GET_PREVIOUSLY_ADDED_MEDICATIONS.FAIL,
});

export const getScansCatalog = payload => ({
  type: PHARMA.SCANS_CATALOG.LOAD,
  keyWord: payload,
});

export const getScansCatalogSuccess = payload => ({
  type: PHARMA.SCANS_CATALOG.SUCCESS,
  payload,
});

export const getScansCatalogFail = payload => ({
  type: PHARMA.SCANS_CATALOG.FAIL,
  payload,
});

export const addScansToPrescriptionList = payload => ({
  type: PHARMA.ADD_SCANS_TO_PRESCRIPTION_LIST,
  payload,
});

export const removeScansFromPrescriptionList = payload => ({
  type: PHARMA.REMOVE_SCANS_FROM_PRESCRIPTION_LIST,
  productKey: payload,
});
