export const SERVICES = {
  SERVICES: {
    LOAD: 'SERVICES_LOAD',
    LOAD_DONE: 'SERVICES_LOAD_DONE',
    LOAD_FAIL: 'SERVICES_LOAD_FAILED',
  },
  HEALTH_GROUPS: {
    LOAD: 'HEALTH_GROUPS_LOAD',
    LOAD_DONE: 'HEALTH_GROUPS_LOAD_DONE',
    LOAD_FAIL: 'HEALTH_GROUPS_LOAD_FAILED',
  },
  OFFER: {
    LOAD_ALL: 'OFFER_LOAD_ALL',
    LOAD_ALL_DONE: 'OFFER_LOAD_ALL_DONE',
    LOAD_ALL_FAIL: 'OFFER_LOAD_ALL_FAILED',
    ADD: 'OFFER_ADD',
    ADD_DONE: 'OFFER_ADD_DONE',
    ADD_FAIL: 'OFFER_ADD_FAILED',
    EDIT: 'OFFER_EDIT',
    EDIT_DONE: 'OFFER_EDIT_DONE',
    EDIT_FAIL: 'OFFER_EDIT_FAILED',
    SELECT: 'OFFER_SELECT',
    RESET: 'OFFER_RESET',
  },
  SUB_SERVICES: {
    LOAD: 'SUB_SERVICES_LOAD',
    LOAD_DONE: 'SUB_SERVICES_LOAD_DONE',
    LOAD_FAIL: 'SUB_SERVICES_LOAD_FAILED',
    RESET: 'SUB_SERVICES_RESET',
  },
  CODE: {
    REDEEM: 'REDEEM_CODE',
    REDEEM_DONE: 'REDEEM_CODE_DONE',
    REDEEM_FAIL: 'REDEEM_CODE_FAILED',
    RESET_REDEEM: 'RESET_REDEEMED_CODE_DATA',
  },
  UNITS: {
    LOAD: 'UNITS_LOAD',
    LOAD_DONE: 'UNITS_LOAD_DONE',
    LOAD_FAIL: 'UNITS_LOAD_FAIL',
  },
  SNACK_BAR: {
    HIDE: 'SNACK_BAR_HIDE',
  },
  /** Services Module */
  SEARCH: {
    LOAD: 'SERVICES_SEARCH_LOAD',
    SUCCESS: 'SERVICES_SEARCH_SUCCESS',
    FAIL: 'SERVICES_SEARCH_FAIL',
  },
  ADD_SERVICE: {
    LOAD: 'ADD_SERVICE_LOAD',
    SUCCESS: 'ADD_SERVICE_SUCCESS',
    FAIL: 'ADD_SERVICE_FAIL',
  },
  MODAL: {
    SERVICE_DETAILS_TOGGLE: 'SERVICE_DETAILS_TOGGLE',
    BRANCH_DOCTORS_TOGGLE: 'BRANCH_DOCTORS_TOGGLE',
  },
  SET_SERVICE_DETAILS: 'SET_SERVICE_DETAILS',
  SERVICES_LIST: {
    FETCH: 'FETCH_SERVICES_LIST',
    SUCCESS: 'FETCH_SERVICES_LIST_SUCCESS',
    FAIL: 'FETCH_SERVICES_LIST_FAIL',
  },
  SERVICES_LIST_V2: {
    FETCH: 'FETCH_SERVICES_V2_LIST',
    SUCCESS: 'FETCH_SERVICES_LIST_V2_SUCCESS',
    FAIL: 'FETCH_SERVICES_LIST_V2_FAIL',
    RESET: 'FETCH_SERVICES_LIST_V2_RESET',
  },
  SERVICES_CATEGORIES: {
    FETCH: 'FETCH_SERVICES_CATEGORIES',
    SUCCESS: 'FETCH_SERVICES_CATEGORIES_SUCCESS',
    FAIL: 'FETCH_SERVICES_CATEGORIES_FAIL',
  },
  SERVICES_PRICE: {
    FETCH: 'FETCH_SERVICES_PRICE',
    SUCCESS: 'FETCH_SERVICES_PRICE_SUCCESS',
    FAIL: 'FETCH_SERVICES_PRICE_FAIL',
  },
  SUBMIT_SERVICE: {
    SUBMIT: 'SUBMIT_SERVICE_DETAILS',
    SUCCESS: 'SUBMIT_SERVICE_DETAILS_SUCCESS',
    FAIL: 'SUBMIT_SERVICE_DETAILS_FAIL',
  },
  GET_SERVICE_DETAILS: {
    FETCH: 'FETCH_SERVICE_DETAILS',
    SUCCESS: 'FETCH_SERVICE_DETAILS_SUCCESS',
    FAIL: 'FETCH_SERVICE_DETAILS_FAIL',
  },
  EDIT_SERVICE_DETAILS: {
    SUBMIT: 'SUBMIT_EDIT_SERVICE_DETAILS',
    SUCCESS: 'SUBMIT_EDIT_SERVICE_DETAILS_SUCCESS',
    FAIL: 'SUBMIT_EDIT_SERVICE_DETAILS_FAIL',
  },
  GET_ENTITY_SPECIALTY_SERVICE: {
    FETCH: 'GET_ENTITY_SPECIALTY_SERVICE',
    SUCCESS: 'GET_ENTITY_SPECIALTY_SERVICE_SUCCESS',
    FAIL: 'GET_ENTITY_SPECIALTY_SERVICE_FAIL',
  },
  DELETE_SERVICE: {
    SUBMIT: 'DELETE_SERVICE',
    SUCCESS: 'DELETE_SERVICE_SUCCESS',
    FAIL: 'DELETE_SERVICE_FAIL',
  },
  ADD_BULK_SERVICES: {
    SUBMIT: 'BULK_SERVICES',
    SUCCESS: 'BULK_SERVICES_SUCCESS',
    FAIL: 'BULK_SERVICES_FAIL',
  },
  CALCULATE_DISCOUNTS: {
    FETCH: 'CALCULATE_DISCOUNTS',
    SUCCESS: 'CALCULATE_DISCOUNTS_SUCCESS',
    FAIL: 'CALCULATE_DISCOUNTS_FAIL',
  },
};

export const loadUnitsPerCountry = (serviceId, headers) => ({
  type: SERVICES.UNITS.LOAD,
  serviceId,
  headers,
});

export const loadUnitsPerCountryDone = units => ({
  type: SERVICES.UNITS.LOAD_DONE,
  units,
});

export const loadUnitsPerCountryFailed = err => ({
  type: SERVICES.UNITS.LOAD_FAIL,
  err,
});

export const loadHealthGroups = headers => ({
  type: SERVICES.HEALTH_GROUPS.LOAD,
  headers,
});

export const loadHealthGroupsDone = healthGroups => ({
  type: SERVICES.HEALTH_GROUPS.LOAD_DONE,
  healthGroups,
});

export const loadHealthGroupsFailed = err => ({
  type: SERVICES.HEALTH_GROUPS.LOAD_FAIL,
  err,
});

export const loadServices = (serviceId, headers) => ({
  type: SERVICES.SERVICES.LOAD,
  serviceId,
  headers,
});

export const loadServicesDone = services => ({
  type: SERVICES.SERVICES.LOAD_DONE,
  services,
});

export const loadServicesFailed = err => ({
  type: SERVICES.SERVICES.LOAD_FAIL,
  err,
});

export const loadSubServices = (serviceId, headers) => ({
  type: SERVICES.SUB_SERVICES.LOAD,
  serviceId,
  headers,
});

export const loadSubServicesDone = subServices => ({
  type: SERVICES.SUB_SERVICES.LOAD_DONE,
  subServices,
});

export const loadSubServicesFailed = err => ({
  type: SERVICES.SUB_SERVICES.LOAD_FAIL,
  err,
});

export const resetSubServices = () => ({
  type: SERVICES.SUB_SERVICES.RESET,
});

export const loadOffers = (entityKey, accountKey, branckKey, headers) => ({
  type: SERVICES.OFFER.LOAD_ALL,
  entityKey,
  accountKey,
  branckKey,
  headers,
});

export const loadOffersDone = offers => ({
  type: SERVICES.OFFER.LOAD_ALL_DONE,
  offers,
});

export const loadOffersFailed = err => ({
  type: SERVICES.OFFER.LOAD_ALL_FAIL,
  err,
});

export const addOffer = data => ({
  type: SERVICES.OFFER.ADD,
  data,
});

export const addOfferDone = offer => ({
  type: SERVICES.OFFER.ADD_DONE,
  offer,
});

export const addOfferFailed = err => ({
  type: SERVICES.OFFER.ADD_FAIL,
  err,
});

export const editOffer = data => ({
  type: SERVICES.OFFER.EDIT,
  data,
});

export const editOfferDone = offer => ({
  type: SERVICES.OFFER.EDIT_DONE,
  offer,
});

export const editOfferFailed = err => ({
  type: SERVICES.OFFER.EDIT_FAIL,
  err,
});

export const setSelectedOffer = offer => ({
  type: SERVICES.OFFER.SELECT,
  offer,
});

export const resetOffer = () => ({
  type: SERVICES.OFFER.RESET,
});

export const redeemCode = (data, headers) => ({
  type: SERVICES.CODE.REDEEM,
  code: data.code,
  entityKey: data.entityKey,
  headers,
});

export const redeemCodeDone = redeemed => ({
  type: SERVICES.CODE.REDEEM_DONE,
  redeemed,
});

export const redeemCodeFailed = err => ({
  type: SERVICES.CODE.REDEEM_FAIL,
  err,
});

export const resetPatientData = () => ({
  type: SERVICES.CODE.RESET_REDEEM,
});

export const setSnackBarAsHidden = () => ({
  type: SERVICES.SNACK_BAR.HIDE,
});

/*
  Service Module Actions
*/
export const fetchServiceDetails = payload => ({
  type: SERVICES.GET_SERVICE_DETAILS.FETCH,
  payload,
});

export const fetchServiceDetailsSuccess = payload => ({
  type: SERVICES.GET_SERVICE_DETAILS.SUCCESS,
  payload,
});

export const fetchServiceDetailsFail = payload => ({
  type: SERVICES.GET_SERVICE_DETAILS.FAIL,
  payload,
});

export const deleteService = payload => ({
  type: SERVICES.DELETE_SERVICE.SUBMIT,
  payload,
});

export const deleteServiceSuccess = payload => ({
  type: SERVICES.DELETE_SERVICE.SUCCESS,
  payload,
});

export const deleteServiceFail = payload => ({
  type: SERVICES.DELETE_SERVICE.FAIL,
  payload,
});

export const setServicesDetails = serviceDetails => ({
  type: SERVICES.SET_SERVICE_DETAILS,
  serviceDetails,
});

export const toggleServiceDetailsModal = isOpened => ({
  type: SERVICES.MODAL.SERVICE_DETAILS_TOGGLE,
  isOpened,
});

export const toggleBranchDoctorsModal = isOpened => ({
  type: SERVICES.MODAL.BRANCH_DOCTORS_TOGGLE,
  isOpened,
});

export const fetchServicesList = payload => ({
  type: SERVICES.SERVICES_LIST.FETCH,
  payload,
});

export const fetchServicesListSuccess = payload => ({
  type: SERVICES.SERVICES_LIST.SUCCESS,
  payload,
});

export const fetchServicesListFail = payload => ({
  type: SERVICES.SERVICES_LIST.FAIL,
  payload,
});

export const fetchServicesListV2List = payload => ({
  type: SERVICES.SERVICES_LIST_V2.FETCH,
  payload,
});

export const fetchServicesListV2Success = payload => ({
  type: SERVICES.SERVICES_LIST_V2.SUCCESS,
  payload,
});

export const fetchServicesListV2Fail = payload => ({
  type: SERVICES.SERVICES_LIST_V2.FAIL,
  payload,
});

export const fetchServicesListV2Reset = () => ({
  type: SERVICES.SERVICES_LIST_V2.RESET,
});

export const fetchServicesCategories = payload => ({
  type: SERVICES.SERVICES_CATEGORIES.FETCH,
  payload,
});

export const fetchServicesCategoriesSuccess = payload => ({
  type: SERVICES.SERVICES_CATEGORIES.SUCCESS,
  payload,
});

export const fetchServicesCategoriesFail = payload => ({
  type: SERVICES.SERVICES_CATEGORIES.FAIL,
  payload,
});

export const fetchServicesPrice = payload => ({
  type: SERVICES.SERVICES_PRICE.FETCH,
  payload,
});

export const fetchServicesPriceSuccess = payload => ({
  type: SERVICES.SERVICES_PRICE.SUCCESS,
  payload,
});

export const fetchServicesPriceFail = payload => ({
  type: SERVICES.SERVICES_PRICE.FAIL,
  payload,
});

export const submitServicesPrice = payload => ({
  type: SERVICES.SUBMIT_SERVICE.SUBMIT,
  payload,
});

export const submitServicesSuccess = payload => ({
  type: SERVICES.SUBMIT_SERVICE.SUCCESS,
  payload,
});

export const submitServicesFail = payload => ({
  type: SERVICES.SUBMIT_SERVICE.FAIL,
  payload,
});

export const searchService = payload => ({
  type: SERVICES.SEARCH.LOAD,
  payload,
});

export const searchServiceSuccess = payload => ({
  type: SERVICES.SEARCH.SUCCESS,
  payload,
});

export const searchServiceFail = payload => ({
  type: SERVICES.SEARCH.FAIL,
  payload,
});

export const addService = payload => ({
  type: SERVICES.ADD_SERVICE.LOAD,
  payload,
});

export const addServiceSuccess = payload => ({
  type: SERVICES.ADD_SERVICE.SUCCESS,
  payload,
});

export const addServiceFail = payload => ({
  type: SERVICES.ADD_SERVICE.FAIL,
  payload,
});

export const editService = payload => ({
  type: SERVICES.EDIT_SERVICE_DETAILS.SUBMIT,
  payload,
});

export const editServiceSuccess = payload => ({
  type: SERVICES.EDIT_SERVICE_DETAILS.SUCCESS,
  payload,
});

export const editServiceFail = payload => ({
  type: SERVICES.EDIT_SERVICE_DETAILS.FAIL,
  payload,
});

export const addBulkServices = payload => ({
  type: SERVICES.ADD_BULK_SERVICES.SUBMIT,
  payload,
});

export const addBulkServicesSuccess = payload => ({
  type: SERVICES.ADD_BULK_SERVICES.SUCCESS,
  payload,
});

export const addBulkServicesFail = payload => ({
  type: SERVICES.ADD_BULK_SERVICES.FAIL,
  payload,
});

export const getEntitySpecialtyServices = payload => ({
  type: SERVICES.GET_ENTITY_SPECIALTY_SERVICE.FETCH,
  payload,
});

export const getEntitySpecialtyServicesSuccess = payload => ({
  type: SERVICES.GET_ENTITY_SPECIALTY_SERVICE.SUCCESS,
  payload,
});

export const getEntitySpecialtyServicesFail = payload => ({
  type: SERVICES.GET_ENTITY_SPECIALTY_SERVICE.FAIL,
  payload,
});

export const getServiceDiscount = payload => ({
  type: SERVICES.CALCULATE_DISCOUNTS.FETCH,
  payload,
});

export const getServiceDiscountSuccess = payload => ({
  type: SERVICES.CALCULATE_DISCOUNTS.SUCCESS,
  payload,
});

export const getServiceDiscountFail = payload => ({
  type: SERVICES.CALCULATE_DISCOUNTS.FAIL,
  payload,
});
