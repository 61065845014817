import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';
import { STATUS, API, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { PAGE_SIZE } from 'modules/utils/Constants';
import { AuthApi } from 'modules/sso';

import {
  APPOINTMENTS,
  createAppointmentSuccess,
  createAppointmentFail,
  markAsNoShowSuccess,
  markAsNoShowFail,
  checkInSuccess,
  checkInFail,
  cancelFail,
  cancelSuccess,
  fetchAppointmentsSuccess,
  fetchAppointmentsFail,
  fetchAppointmentsUpdatesSuccess,
  fetchAppointmentsUpdatesFail,
  markAsInsuredSuccess,
  markAsInsuredFail,
  addPrescriptionSuccess,
  addPrescriptionFail,
  addAppointmentSuccess,
  addAppointmentFailed,
  fetchNationalIdSuccess,
  fetchNationalIdFail,
  getAppointmentInvoiceSuccess,
  getAppointmentInvoiceFailed,
  fetchPatientInsuranceCardSuccess,
  fetchPatientInsuranceCardFail,
  uploadNationalIdSuccess,
  uploadNationalIdFail,
  submitEditAppointmentSuccess,
  submitEditAppointmentFailed,
  getReservationCountSuccess,
  getReservationCountFailed,
  fetchApprovalLetterSuccess,
  fetchApprovalLetterFail,
  uploadApprovalLetterSuccess,
  uploadApprovalLetterFail,
  completeFail,
  completeSuccess,
  addVitalsFail,
  addVitalsSuccess,
  updateVitalsFail,
  updateVitalsSuccess,
  submitResultsSuccess,
  submitResultsFail,
  getResultsSuccess,
  getResultsFail,
} from '../actions/appointments';

function* getReservationCount(payload) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const culture = Cookie.get(Cookie.CULTURE);

  const Headers = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
    {
      key: 'language',
      value: culture,
    },
  ];

  const getReservationCountApi = new API();
  const body = payload.payload;
  const response = yield getReservationCountApi.post(Urls.countReservationsInRange2, body, Headers);

  if (STATUS.isSuccess(response.status)) {
    yield put(getReservationCountSuccess(response.data));
  } else {
    yield put(getReservationCountFailed(response));
  }
}

function* getAppointmentInvoice(payLoad) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const Headers = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ];
  const { reservationKey } = payLoad.payload;
  const getNationalIdApi = new API();
  const getNationalIdApiResponse = yield getNationalIdApi.get(
    `${Urls.getAppointmentInvoice}${reservationKey}`,
    Headers,
  );
  if (STATUS.isSuccess(getNationalIdApiResponse.status)) {
    yield put(getAppointmentInvoiceSuccess(getNationalIdApiResponse.data));
  } else {
    yield put(getAppointmentInvoiceFailed(getNationalIdApiResponse.response));
  }
}

function* fetchAppointments(payload) {
  // pageNumber
  // pageSize
  // branchKey
  // patientKey
  // accountKeys
  // vezeetaPatientsOnly
  // fromDate
  // toDate
  // includeSummary
  // statuses

  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const culture = Cookie.get(Cookie.CULTURE);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  let accountKeysQueryString = '';
  // m/d/yyyy to yyyy-10-01
  const convertDateAndTimeToIsoDate = date => {
    const dateSplittedList = date.split('/');
    let month = dateSplittedList[0];
    let day = dateSplittedList[1];
    if (month && month.length !== 2) {
      month = `0${month}`;
    }
    if (day && day.length !== 2) {
      day = `0${day}`;
    }
    // YYYY-MM-DD
    const reformedDate = `${dateSplittedList[2]}-${month}-${day}`;
    return `${reformedDate}`;
  };

  if (payload) {
    accountKeysQueryString = `pageNumber=${payload.pageNumber}&pageSize=${
      payload.pageSize ? payload.pageSize : PAGE_SIZE
    }&includeSummary=true&`;

    if (payload.branchKeys && payload.branchKeys.length) {
      payload.branchKeys.forEach(branchKey => {
        accountKeysQueryString = `${accountKeysQueryString}branchKeys=${branchKey}&`;
      });
    }
    if (payload.patientKey) {
      accountKeysQueryString = `${accountKeysQueryString}patientKey=${payload.patientKey}&`;
    }
    if (payload.doctors && payload.doctors.length) {
      payload.doctors.forEach(doctor => {
        accountKeysQueryString = `${accountKeysQueryString}accountKeys=${doctor.AccountKey}&`;
      });
    }
    if (payload.statuses && payload.statuses.length) {
      payload.statuses.forEach(status => {
        accountKeysQueryString = `${accountKeysQueryString}statuses=${status}&`;
      });
    }
    accountKeysQueryString = `${accountKeysQueryString}vezeetaPatientsOnly=false&fromDate=${convertDateAndTimeToIsoDate(
      payload.startDate,
    )} 00:00:00&toDate=${convertDateAndTimeToIsoDate(payload.endDate)} 23:59:59&`;
  }

  const response = yield api.get(`${Urls.fetchAppointmentsUrl2}?${accountKeysQueryString}`, header);

  if (STATUS.isSuccess(response.status)) {
    const appointments = [];

    response.data.Reservations.forEach(appointment => {
      const roomName = payload.roomsNames.get(appointment.Room.RoomKey);
      const doctorName = payload.doctorsNames.get(appointment.Doctor.DoctorKey);

      appointments.push({
        ...appointment,
        roomName,
        doctorName,
      });
    });

    yield put(fetchAppointmentsSuccess(appointments, response.data.Summary, payload.pushToCurrent));
  } else {
    yield put(fetchAppointmentsFail());
  }
}

function* fetchAppointmentsUpdates(payload) {
  const api = new AuthApi();
  const body = {
    AccountRooms: payload.doctors.map(doctor => ({
      AccountKey: doctor.AccountKey,
      RoomKey: doctor.RoomKey,
    })),
    PatientKey: payload.patientKey,
    From: payload.startDate,
    To: payload.endDate,
    Page: !payload.newAppointment ? payload.pageNumber : null,
    PageSize: payload.pageSize,
  };

  const response = yield api.post(Urls.fetchAppointmentsUrl, body);

  if (STATUS.isSuccess(response.status)) {
    const appointments = [];

    response.data.forEach(appointment => {
      const roomName = payload.roomsNames.get(appointment.RoomKey);
      const doctorName = payload.doctorsNames.get(appointment.AccountKey);

      appointments.push({
        ...appointment,
        roomName,
        doctorName,
      });
    });
    yield put(fetchAppointmentsUpdatesSuccess(appointments, payload.pushToCurrent));
  } else {
    yield put(fetchAppointmentsUpdatesFail());
  }
}

function* upsertAppointment(payload) {
  const addAppointmentApi = new AuthApi();
  const addAppointmentBody = payload.payload;
  const addAppointmentResponse = yield addAppointmentApi.post(
    Urls.createAppointmentUrl,
    addAppointmentBody,
  );
  if (STATUS.isSuccess(addAppointmentResponse.status)) {
    yield put(
      addAppointmentSuccess([
        {
          ...(addAppointmentResponse &&
            addAppointmentResponse.response &&
            addAppointmentResponse.response.Data &&
            addAppointmentResponse.response.Data.ReturnObject &&
            addAppointmentResponse.response.Data.ReturnObject[0]),
          ...(payload.payload && payload.payload[0]),
          BranchKey: addAppointmentBody[0].BranchKey,
        },
      ]),
    );
  } else {
    yield put(addAppointmentFailed());
  }
}

function* upsertEditAppointment(payload) {
  const upsertEditAppointmentApi = new AuthApi();
  const editAppointmentBody = payload.payload;
  const editAppointmentResponse = yield upsertEditAppointmentApi.post(
    Urls.editAppointmentUrl,
    editAppointmentBody,
  );

  if (STATUS.isSuccess(editAppointmentResponse.status)) {
    yield put(submitEditAppointmentSuccess(editAppointmentResponse.data));
  } else {
    yield put(submitEditAppointmentFailed());
  }
}

function* addAppointment(payload) {
  let addAppointmentBody;

  if (payload.appointment && payload.appointment.isAppointementReschedule) {
    addAppointmentBody = [
      {
        ReservationSource: 'DoctorsApp',
        ActionMaker: 'Doctor',
        SendReminderMessage: 0,
        PatientKey: payload.appointment.PatientKey,
        AccountKey: payload.appointment.doctor,
        RoomKey: payload.appointment.room,
        ReservationKey: payload.appointment.ReservationKey,
        ReservationDate: `${payload.appointment.date} ${payload.appointment.time}`,
        CountrySpecialityKey: payload.appointment.DoctorSpecialtyKey,
      },
    ];
  } else {
    addAppointmentBody = [
      {
        ReservationSource: 'DoctorsApp',
        ActionMaker: 'Doctor',
        ReservationDate: `${payload.appointment.date} ${payload.appointment.time}`,
        InsuranceKey: '',
        Notes: payload.appointment.notes,
        VisitType: payload.appointment.type,
        SendReminderMessage: 0,
        AccountKey: payload.appointment.doctor,
        RoomKey: payload.appointment.room,
        PatientKey: payload.patient.key,
        // CountrySpecialityKey: payload.appointment.DoctorSpecialtyKey,
      },
    ];
  }

  const addAppointmentApi = new AuthApi();
  const addAppointmentResponse = yield addAppointmentApi.post(
    Urls.createAppointmentUrl,
    addAppointmentBody,
  );

  if (STATUS.isSuccess(addAppointmentResponse.status)) {
    /*     const appointmentDetails = addAppointmentResponse.data[0];

    const newAppointment = {
      roomName: payload.roomsNames.get(payload.appointment.room),
      doctorName: payload.doctorsNames.get(payload.appointment.doctor),
      isJustAdded: true,
      VisitTypeId: payload.appointment.type,
      ReservationKey: appointmentDetails.ReservationKey,
      ReservationDate: appointmentDetails.ReservationDate,
      ShiftStartTime: payload.appointment.isFifo && payload.appointment.shift.shiftStart,
      ShiftEndTime: payload.appointment.isFifo && payload.appointment.shift.shiftEnd,
      Notes: payload.appointment.notes,
      IsFifoDoctor: payload.appointment.isFifo,
      IsVezeetaAppointment: false,
      ReservationPassed: false,

      PatientName: payload.patient.name,
      PatientKey: payload.patient.key,
      PatientPhone: payload.patient.mobileNumber,
      PatientGender: payload.patient.gender,
      PatientBirthDate: payload.patient.birthDate,
      PatientCustomIdentifier: payload.patient.id,
      AccountKey: payload.appointment.doctor,
      RoomKey: payload.appointment.room,
      PatientInsuranceName: payload.patient.insuranceName,
      oldReservationKey: payload.appointment.ReservationKey
        ? payload.appointment.ReservationKey
        : '',
    };

    if (payload.callback) {
      payload.callback(payload.callback, newAppointment);
    }
 */
    yield put(createAppointmentSuccess());
  } else {
    yield put(createAppointmentFail());
  }
}

function* markAsNoShow(payload) {
  const { appointmentKey, accountKey, roomKey, isNoShow, sendSMS } = payload;
  const api = new AuthApi();
  const body = [
    {
      ActionMaker: 'Doctor',
      AccountKey: accountKey,
      RoomKey: roomKey,
      ReservationKey: appointmentKey,
      SendMessage: sendSMS,
    },
  ];
  const url = !isNoShow ? Urls.markAppointmentAsNoShow : Urls.undoAppointmentNoShow;

  const response = yield api.post(url, body);

  if (STATUS.isSuccess(response.status)) {
    yield put(markAsNoShowSuccess(appointmentKey, isNoShow));
  } else {
    yield put(markAsNoShowFail(appointmentKey));
  }
}

function* markAsInsured(payload) {
  const { appointmentKey, accountKey, roomKey, isInsured } = payload;
  const api = new AuthApi();
  const url = Urls.updateReservationsInsurance;
  const body = [
    {
      ActionMaker: 'Doctor',
      AccountKey: accountKey,
      RoomKey: roomKey,
      ReservationKey: appointmentKey,
      IsInsured: isInsured,
    },
  ];

  const response = yield api.post(url, body);

  if (STATUS.isSuccess(response.status)) {
    yield put(markAsInsuredSuccess(appointmentKey, isInsured));
  } else {
    yield put(markAsInsuredFail(appointmentKey));
  }
}

function* checkInAppointment(payload) {
  const { appointmentKey, accountKey, roomKey, isCheckedIn } = payload;
  const api = new AuthApi();
  const body = [
    {
      ActionMaker: 'Doctor',
      AccountKey: accountKey,
      RoomKey: roomKey,
      ReservationKey: appointmentKey,
      UpdateReservationStatus: isCheckedIn ? 'Pending' : 'PatientCheckedIn',
    },
  ];
  const url = Urls.checkInAppointment;

  const response = yield api.post(url, body);

  if (STATUS.isSuccess(response.status)) {
    yield put(checkInSuccess(appointmentKey, isCheckedIn));
  } else {
    yield put(checkInFail(appointmentKey));
  }
}

function* completeAppointment(payload) {
  const { appointmentKey, accountKey, roomKey, isCompelete } = payload;
  const api = new AuthApi();

  const setNewStatus = () => {
    if (!isCompelete) return 'Completed';
    return 'PatientCheckedIn';
  };

  const body = [
    {
      ActionMaker: 'Doctor',
      AccountKey: accountKey,
      RoomKey: roomKey,
      ReservationKey: appointmentKey,
      UpdateReservationStatus: setNewStatus(),
    },
  ];
  const url = Urls.checkInAppointment;

  const response = yield api.post(url, body);

  if (STATUS.isSuccess(response.status)) {
    yield put(completeSuccess(appointmentKey, isCompelete));
  } else {
    yield put(completeFail(appointmentKey));
  }
}

function* cancelAppointment(payload) {
  const { appointmentKey } = payload;
  const api = new AuthApi();
  const culture = Cookie.get(Cookie.CULTURE);
  const Headers = [
    {
      key: 'x-vzt-Component',
      value: 'AccWeb',
    },
    {
      key: 'language',
      value: culture,
    },
  ];
  const url = `${Urls.cancelAppointment}/${appointmentKey}/cancel`;

  const response = yield api.post(url, null, Headers);

  if (STATUS.isSuccess(response.status)) {
    yield put(cancelSuccess(appointmentKey));
  } else {
    yield put(cancelFail(appointmentKey));
  }
}

function* addPrescription(payload) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const Headers = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ];
  const addPrescriptionAPI = new API();
  const body = {
    prescriptionImages: payload.prescriptionImages,
    prescriptionTypeId: payload.prescriptionTypeId,
    operationKey: payload.reservationKey,
    prescriptionOwnerKey: payload.accountKey,
    actionMakerTypeId: 1,
    InsuranceKey: payload.insuranceKey,
    InsuranceCardKey: payload.insuranceCardKey,
  };
  const response = yield addPrescriptionAPI.post(Urls.prescription.addPrescription, body, Headers);
  if (STATUS.isSuccess(response.status)) {
    yield put(
      addPrescriptionSuccess(
        payload.reservationKey,
        payload.prescriptionImages.length,
        payload.successMassage,
      ),
    );
  } else {
    yield put(addPrescriptionFail(payload.errorMassage));
  }
}

function* getPatientNationalId(payload) {
  const Headers = [
    {
      key: 'x-vzt-Component',
      value: 'AccWeb',
    },
    {
      key: 'ServiceKey',
      value: 'nationalids',
    },
  ];

  const getNationalIdApi = new API();
  const getNationalIdApiResponse = yield getNationalIdApi.get(
    `${process.env.REACT_APP_CONTENT_UPLOADER}/api/contentuploader/${payload.reservationKey}`,
    Headers,
  );
  if (STATUS.isSuccess(getNationalIdApiResponse.status)) {
    yield put(fetchNationalIdSuccess(getNationalIdApiResponse.data));
  } else {
    yield put(fetchNationalIdFail());
  }
}

function* getPatientInsuranceCard(payload) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const Headers = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
    {
      key: 'Language',
      value: 'en-EG',
    },
  ];
  const getPatientcardDetails = new API();
  const getPatientcardDetailsApiResponse = yield getPatientcardDetails.get(
    `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/patients-ms-api/api/patientinsurances?id=${payload.cardKey}`,
    Headers,
  );
  if (STATUS.isSuccess(getPatientcardDetailsApiResponse.status)) {
    yield put(fetchPatientInsuranceCardSuccess(getPatientcardDetailsApiResponse.data));
  } else {
    yield put(fetchPatientInsuranceCardFail());
  }
}

function* uploadNationalIdImg(payload) {
  const url = `${process.env.REACT_APP_CONTENT_UPLOADER}/api/ContentUploader`;
  const contentFormData = new FormData();
  contentFormData.append('OperationKey', payload.data.reservationKey);
  contentFormData.append('Files', payload.data.nationalIdImg);
  // eslint-disable-next-line no-unused-vars
  const oldFiles =
    payload.data.nationalId.patientNationalId &&
    payload.data.nationalId.patientNationalId.attachments &&
    payload.data.nationalId.patientNationalId.attachments.forEach(item => {
      contentFormData.append('OldFilesUrl', item.url);
    });

  const response = yield call(fetch, url, {
    method: 'post',
    headers: {
      'x-vzt-Component': 'AccWeb',
      ServiceKey: 'nationalids',
    },
    body: contentFormData,
  });
  if (STATUS.isSuccess(response.status)) {
    yield put(uploadNationalIdSuccess(response.data));
  } else {
    yield put(uploadNationalIdFail());
  }
}

function* getPatientApprovalLetter(payload) {
  const Headers = [
    {
      key: 'x-vzt-Component',
      value: 'AccWeb',
    },
    {
      key: 'ServiceKey',
      value: 'approvalletters',
    },
  ];

  const getApprovalLetterApi = new API();
  const getApprovalLetterApiResponse = yield getApprovalLetterApi.get(
    `${process.env.REACT_APP_CONTENT_UPLOADER}/api/contentuploader/${payload.reservationKey}`,
    Headers,
  );
  if (STATUS.isSuccess(getApprovalLetterApiResponse.status)) {
    yield put(fetchApprovalLetterSuccess(getApprovalLetterApiResponse.data));
  } else {
    yield put(fetchApprovalLetterFail());
  }
}

function* uploadApprovalLetter(payload) {
  const {
    reservationKey,
    approvalLetterFile,
    approvalLetter,
    mixpanel,
    doctorName,
    InsuranceProviderName,
    mainSpecialty,
    areaName,
  } = payload.data;

  const url = `${process.env.REACT_APP_CONTENT_UPLOADER}/api/ContentUploader`;
  const contentFormData = new FormData();
  contentFormData.append('OperationKey', reservationKey);
  contentFormData.append('Files', approvalLetterFile);
  // eslint-disable-next-line no-unused-vars
  const oldFiles =
    approvalLetter.patientApprovalLetter &&
    approvalLetter.patientApprovalLetter.attachments &&
    approvalLetter.patientApprovalLetter.attachments.forEach(item => {
      contentFormData.append('OldFilesUrl', item.url);
    });

  const response = yield call(fetch, url, {
    method: 'post',
    headers: {
      'x-vzt-Component': 'AccWeb',
      ServiceKey: 'approvalletters',
    },
    body: contentFormData,
  });
  if (STATUS.isSuccess(response.status)) {
    yield put(uploadApprovalLetterSuccess(response.data));
  } else {
    yield put(uploadApprovalLetterFail());
  }
  mixpanel.track('Submit approval letter', {
    'Selected insurance': InsuranceProviderName,
    'File added': STATUS.isSuccess(response.status) ? 'Y' : 'N',
    'Type of file uploaded': approvalLetterFile.type === 'application/pdf' ? 'pdf' : 'image',
    'Reservation key': reservationKey,
    'Doctor name': doctorName,
    'Doctor Specialty': mainSpecialty,
    'area value': areaName,
  });
}

function* sagaAddVitals(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.post(
    `${Urls.addVitals}/${action.payload.reservationKey}/vital`,
    action.payload.vitalsObj,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      addVitalsSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      addVitalsFail({
        ...response,
      }),
    );
  }
}

function* sagaUpdateVitals(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.put(
    `${Urls.updateVitals}/${action.payload.vitalKey}`,
    action.payload.vitalsObj,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      updateVitalsSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      updateVitalsFail({
        ...response,
      }),
    );
  }
}

function* submitResultsSaga(payload) {
  const contentFormData = new FormData();
  contentFormData.append('OperationKey', payload.payload.reservationKey);

  if (payload.payload.content && payload.payload.content.length > 0)
    payload.payload.content.forEach(file => {
      contentFormData.append('Files', file.url);
    });

  const uploadContentResponse = yield call(fetch, Urls.uploadContent, {
    method: 'post',
    headers: {
      'x-vzt-Component': 'AccWeb',
      ServiceKey: 'reservationresults',
    },
    body: contentFormData,
  });
  const responseData = yield call([uploadContentResponse, uploadContentResponse.json]);
  let uploadedResultsToBeSent = [];
  if (STATUS.isSuccess(responseData.statusCode)) {
    uploadedResultsToBeSent = responseData.data.map(result => ({
      Url: result.fileUrl,
      Type: 'ReservationResult',
    }));
  }
  const api = new API();
  const url = `${Urls.submitResults}/${payload.payload.reservationKey}/attachments`;
  const resultsToBeUploaded = uploadedResultsToBeSent;
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];
  const response = yield api.put(url, resultsToBeUploaded, header);
  if (STATUS.isSuccess(response.status)) {
    yield put(submitResultsSuccess(payload.payload.successMessage));
  } else {
    yield put(submitResultsFail(payload.payload.errorMessage));
  }
}

function* getResultsSaga(payload) {
  const url = `${Urls.uploadContent}/${payload.payload}`;
  const api = new API();
  const header = [
    {
      key: 'x-vzt-Component',
      value: 'AccWeb',
    },
    {
      key: 'ServiceKey',
      value: 'reservationresults',
    },
  ];
  const response = yield api.get(url, header);
  if (STATUS.isSuccess(response.status)) {
    yield put(getResultsSuccess(response.data));
  } else {
    yield put(getResultsFail());
  }
}

function* appointmentsSaga() {
  yield takeLatest(APPOINTMENTS.FETCH.LOAD, fetchAppointments);
  yield takeLatest(APPOINTMENTS.FETCH_UPDATES.LOAD, fetchAppointmentsUpdates);
  yield takeLatest(APPOINTMENTS.CREATE.LOAD, addAppointment);
  yield takeEvery(APPOINTMENTS.MARK_AS_NO_SHOW.LOAD, markAsNoShow);
  yield takeEvery(APPOINTMENTS.CHECK_IN.LOAD, checkInAppointment);
  yield takeEvery(APPOINTMENTS.COMPLETE.LOAD, completeAppointment);
  yield takeEvery(APPOINTMENTS.CANCEL.LOAD, cancelAppointment);
  yield takeEvery(APPOINTMENTS.MARK_AS_INSURED.LOAD, markAsInsured);
  yield takeLatest(APPOINTMENTS.ADD_PRESCRIPTION.LOAD, addPrescription);
  yield takeLatest(APPOINTMENTS.ADD_APPOINTMENT.SUBMIT, upsertAppointment);
  yield takeLatest(APPOINTMENTS.EDIT_APPOINTMENT.SUBMIT, upsertEditAppointment);
  yield takeLatest(APPOINTMENTS.NATIONAL_ID.LOAD, getPatientNationalId);
  yield takeLatest(APPOINTMENTS.GET_APPOINTMENT_INVOICE.LOAD, getAppointmentInvoice);
  yield takeLatest(APPOINTMENTS.INSURANCE_CARD.LOAD, getPatientInsuranceCard);
  yield takeLatest(APPOINTMENTS.UPLOAD_NATIONAL_ID.LOAD, uploadNationalIdImg);
  yield takeLatest(APPOINTMENTS.FETCH_COUNT_RESERVATION.LOAD, getReservationCount);
  yield takeLatest(APPOINTMENTS.APPROVAL_LETTER.LOAD, getPatientApprovalLetter);
  yield takeLatest(APPOINTMENTS.UPLOAD_APPROVAL_LETTER.LOAD, uploadApprovalLetter);
  yield takeLatest(APPOINTMENTS.ADD_VITALS.SUBMIT, sagaAddVitals);
  yield takeLatest(APPOINTMENTS.UPDATE_VITALS.SUBMIT, sagaUpdateVitals);
  yield takeLatest(APPOINTMENTS.SUBMIT_RESULTS.SUBMIT, submitResultsSaga);
  yield takeLatest(APPOINTMENTS.GET_RESULTS.LOAD, getResultsSaga);
}

export default appointmentsSaga;
