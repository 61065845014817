import React, { Fragment } from 'react';
import { Text } from '@vezeeta/web-components';
import { FILTER_NAMES, ALL_BRANCHES_VALUE } from './Constants';

const createFilterSection = (
  type,
  name,
  filters,
  placeholder,
  selectOptions,
  icon,
  selectedValue,
) => ({
  type,
  name,
  filters,
  placeholder,
  ...selectOptions,
  icon,
  selectedValue,
});

const createDoctorsStatusSection = (
  doctorsStatusList,
  language,
  selectOptions,
  localizedBranches,
  selectedValue,
) => {
  const filters = [];
  doctorsStatusList.forEach(status => {
    if (status.enabled) {
      filters.push({
        data: {
          placeholder: status.name[language],
          value: status.value,
          searchable: [status.name[language]],
        },
        component: (
          <Fragment>
            <Text>{status.name[language]}</Text>
          </Fragment>
        ),
      });
    }
  });

  return createFilterSection(
    'combo', // type
    FILTER_NAMES.DOCTORS_STATUS, // name
    filters, // filters
    localizedBranches || 'Doctors Status',
    selectOptions,
    'clinic', // icon
    selectedValue,
  );
};

const createBranchesSection = (branches, selectOptions, localizedBranches) => {
  const filters = branches.map(branch => ({
    data: {
      placeholder: branch.BranchName,
      value: branch.BranchKey,
      searchable: [branch.BranchName],
    },
    component: (
      <Fragment>
        <Text>{branch.BranchName}</Text>
        {selectOptions.renderClinicName && (
          <Fragment>
            <br />
            <Text className="clinic-name">{branch.EntityName}</Text>
          </Fragment>
        )}
      </Fragment>
    ),
  }));

  return createFilterSection(
    'combo',
    FILTER_NAMES.BRANCHES,
    filters,
    localizedBranches || 'Branches',
    selectOptions,
    'clinic',
  );
};

const createRoomsSection = (rooms, selectOptions) => {
  const filters = rooms.map(room => ({
    id: room.RoomKey,
    label: room.RoomName,
  }));
  filters.unshift({
    label: 'All rooms',
    isAllCheckbox: true,
  });
  return createFilterSection('check', FILTER_NAMES.ROOMS, filters, 'Rooms', selectOptions);
};

const createRadioRoomsSection = (rooms, selectOptions) => {
  const filters = rooms.map(room => ({
    name: 'room',
    value: room.RoomKey,
    component: <Text>{room.RoomName}</Text>,
  }));
  return createFilterSection('radio', FILTER_NAMES.ROOMS, filters, 'Rooms', selectOptions);
};

// Create Doctors Section as checkbox list (Old Schedule view)
const createDoctorsSection = (doctors, selectOptions, localization) => {
  const filters = doctors.map(doctor => ({
    id: doctor.AccountKey,
    label: doctor.DoctorName,
  }));
  filters.unshift({
    label: (localization && localization.allDoctors) || 'All doctors',
    isAllCheckbox: true,
  });
  return createFilterSection(
    'check',
    FILTER_NAMES.DOCTORS,
    filters,
    (localization && localization.doctors) || 'Doctors',
    selectOptions,
  );
};

// Create Doctors Section as bombo list (new Schedular view)
const createDoctorsSectionAsComboList = (doctors, selectOptions, localization) => {
  const filters = doctors.map(doctor => ({
    id: doctor.AccountKey,
    data: {
      placeholder: doctor.DoctorName,
      value: doctor.AccountKey,
      searchable: [doctor.DoctorName],
    },
    component: (
      <Fragment>
        <Text>{doctor.DoctorName}</Text>
      </Fragment>
    ),
  }));

  return createFilterSection(
    'combo',
    FILTER_NAMES.DOCTORS,
    filters,
    (localization && localization.doctors) || 'Doctors',
    selectOptions,
    null,
  );
};

const createDoctorsSearchSection = (doctors, selectOptions, localizedDoctors) => {
  const filters = doctors.map(doctor => ({
    data: {
      placeholder: doctor.fieldValue,
      value: doctor.key,
      groupBy: doctor.groupBy,
      groupIcon: doctor.groupIcon,
      searchable: doctor.searchable,
      disabled: doctor.disabled,
    },
    component: <Text>{doctor.fieldValue}</Text>,
  }));

  return createFilterSection(
    'combo',
    FILTER_NAMES.DOCTORS,
    filters,
    localizedDoctors || 'Doctors',
    selectOptions,
    'user',
  );
};

const filterClinics = (clinics, filterData) =>
  clinics.map(clinic => ({
    ...clinic,
    Branches: clinic.Branches.filter(
      branch =>
        !filterData[FILTER_NAMES.BRANCHES] ||
        filterData[FILTER_NAMES.BRANCHES] === ALL_BRANCHES_VALUE ||
        branch.BranchKey === filterData[FILTER_NAMES.BRANCHES],
    ).map(branch => ({
      ...branch,
      Rooms: branch.Rooms.filter(
        room =>
          !filterData[FILTER_NAMES.ROOMS] || filterData[FILTER_NAMES.ROOMS].includes(room.RoomKey),
      ).map(room => ({
        ...room,
        Doctors: room.Doctors.filter(
          doctor => !filterData.DOCTORS || filterData.DOCTORS.includes(doctor.AccountKey),
        ),
      })),
    })),
  }));

export default {
  createBranchesSection,
  createRoomsSection,
  createDoctorsSection,
  createRadioRoomsSection,
  filterClinics,
  createDoctorsSearchSection,
  createDoctorsSectionAsComboList,
  createDoctorsStatusSection,
};
