// @flow

export const ROLES = {
  LOADING: 'ROLES_LOADING',
  SUCCEEDED: 'ROLES_SUCCEEDED',
  FAILED: 'ROLES_FAILED',
};

export const getRoles = () => ({
  type: ROLES.LOADING,
});
