// @flow

export const PREFIX_TITLES = {
  LOADING: 'PREFIX_TITLE_LOADING',
  SUCCEEDED: 'PREFIX_TITLE_SUCCEEDED',
  FAILED: 'PREFIX_TITLE_FAILED',
};

export const loadPrefixTitles = (countryId: string, languageId: string, genderId: string) => ({
  type: PREFIX_TITLES.LOADING,
  countryId,
  languageId,
  genderId,
});
