export const SCHEDULES = {
  LOADING: 'LOADING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

export const VEZEETA_SCHEDULE = {
  FETCH: 'VEZEETA_SCHEDULE_FETCH',
  SUCCEEDED: 'VEZEETA_SCHEDULE_SUCCEEDED',
  FAILED: 'VEZEETA_SCHEDULE_FAILED',
};

export const loadSchedules = () => ({
  type: SCHEDULES.LOADING,
});

export const loadSchedulesSucceeded = schedules => ({
  type: SCHEDULES.SUCCEEDED,
  schedules,
});

export const loadSchedulesFailed = error => ({
  type: SCHEDULES.FAILED,
  error,
});

export const getVezeetaSchedules = payload => ({
  type: VEZEETA_SCHEDULE.FETCH,
  payload,
});

export const getVezeetaSchedulesSucceeded = payload => ({
  type: VEZEETA_SCHEDULE.SUCCEEDED,
  payload,
});

export const getVezeetaSchedulesFailed = payload => ({
  type: VEZEETA_SCHEDULE.FAILED,
  payload,
});
