import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  INSURANCES,
  ENTITY_INSURANCES,
  loadEnityInsuranceProvidersSuccess,
  loadEnityInsuranceProvidersFail,
} from '../actions/insurances';

function* getInsuranceProviders(payload) {
  const insuranceProviders = new API();
  const insuranceProvidersResponse = yield insuranceProviders.get(
    `${Urls.getInsuranceProvidersByCountryId}${payload.countryId}`,
    undefined,
    true,
  );

  if (STATUS.isSuccess(insuranceProvidersResponse.status)) {
    yield put({ type: INSURANCES.SUCCESS, insuranceProviders: insuranceProvidersResponse.data });
  } else {
    yield put({ type: INSURANCES.FAIL });
  }
}

function* getEntityInsuranceProviders(payload) {
  const api = new API();
  const response = yield api.get(`${Urls.getEntityVDoc}/${payload.entityKey}/insurances`);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      loadEnityInsuranceProvidersSuccess({
        ...response.response,
      }),
    );
  } else {
    yield put(loadEnityInsuranceProvidersFail());
  }
}

export default function* insurancesSaga() {
  yield takeEvery(INSURANCES.LOAD, getInsuranceProviders);
  yield takeEvery(ENTITY_INSURANCES.LOAD, getEntityInsuranceProviders);
}
