export const SERVICES = {
  GET_SERVICES: {
    LOAD: 'SERVICES_CHILD_LOAD',
    SUCCESS: 'SERVICES_CHILD_SUCCESS',
    FAIL: 'SERVICES_CHILD_FAIL',
  },
  GET_INSURANCE_SERVICES: {
    LOAD: 'INSURANCE_SERVICES_LOAD',
    SUCCESS: 'INSURANCE_SERVICES_SUCCESS',
    FAIL: 'INSURANCE_SERVICES_FAIL',
  },
};

export const getServices = payload => ({
  type: SERVICES.GET_SERVICES.LOAD,
  payload,
});

export const getInsuranceServices = payload => ({
  type: SERVICES.GET_INSURANCE_SERVICES.LOAD,
  payload,
});
