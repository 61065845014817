import { status } from 'modules/utils/enum';
import { INVOICES } from '../actions/invoices';

const initialState = {
  invoices: [],
  invoicesLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case INVOICES.FETCH:
      return { ...state, invoicesLoadState: status.FETCHING, invoices: [] };
    case INVOICES.SUCCESS: {
      return { ...state, invoicesLoadState: status.SUCCESS, invoices: payload.payload };
    }
    case INVOICES.FAIL:
      return { ...state, invoicesLoadState: status.FAIL, invoices: payload.payload };

    default:
      return state;
  }
};
