export const PLANS = {
  GET_PLANS: {
    LOAD: 'GET_PLANS_LOAD',
    SUCCESS: 'GET_PLANS_SUCCESS',
    FAIL: 'GET_PLANS_FAIL',
  },
  SUBSCRIBE: {
    LOAD: 'SUBSCRIBE_LOAD',
    SUCCESS: 'SUBSCRIBE_SUCCESS',
    FAIL: 'SUBSCRIBE_FAIL',
  },
};

export const fetchPlans = payload => ({
  type: PLANS.GET_PLANS.LOAD,
  ...payload,
});

export const fetchPlansSuccess = payload => ({
  type: PLANS.GET_PLANS.SUCCESS,
  payload,
});

export const fetchPlansFail = error => ({
  type: PLANS.GET_PLANS.FAIL,
  error,
});

export const subscribePlans = payload => ({
  type: PLANS.SUBSCRIBE.LOAD,
  ...payload,
});

export const subscribePlansSuccess = payload => ({
  type: PLANS.SUBSCRIBE.SUCCESS,
  payload,
});

export const subscribePlansFail = error => ({
  type: PLANS.SUBSCRIBE.FAIL,
  error,
});
