// @flow

import { PROFESSIONAL_TITLES } from '../actions/professionalTitles';

const initialState = {
  professionalTitles: new Map(),
  professionalTitlesState: undefined,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case PROFESSIONAL_TITLES.LOADING: {
      return {
        ...state,
        professionalTitlesState: PROFESSIONAL_TITLES.LOADING,
      };
    }

    case PROFESSIONAL_TITLES.SUCCEEDED: {
      const professionalTitles = new Map();

      if (payload.professionalTitles) {
        payload.professionalTitles.forEach(professionalTitle => {
          professionalTitles.set(professionalTitle.DoctorTitleId, professionalTitle);
        });
      }

      return {
        ...state,
        professionalTitles,
        professionalTitlesState: PROFESSIONAL_TITLES.SUCCEEDED,
      };
    }

    case PROFESSIONAL_TITLES.FAILED: {
      return {
        ...state,
        professionalTitles: new Map(),
        professionalTitlesState: PROFESSIONAL_TITLES.FAILED,
      };
    }

    default: {
      return state;
    }
  }
};
