import { takeLatest, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { AuthApi } from 'modules/sso';

import { DOCTOR, getDoctorDetailsSuccess, getDoctorDetailsFailed } from '../actions/doctor';

function* getRoomBookingInfo(action) {
  const getRoomBookingInfoApi = new AuthApi();
  let doctorsAccountKeys;
  if (action.payload.doctorAccountKey && action.payload.doctorAccountKey.length) {
    doctorsAccountKeys = action.payload.doctorAccountKey;
  } else {
    doctorsAccountKeys = [action.payload.doctorAccountKey];
  }

  const getRoomBookingInfoApiResponse = yield getRoomBookingInfoApi.post(Urls.profile, {
    query: `query usersQuery($userKeys: [String]!)
        {
          usersByKeys(userKeys: $userKeys)
          {
            userKey
            userCountryDetails {
              firstName
              lastName
              languageId
            }
            userSpecialties {
              isMainSpecialty
              specialtyKey
            }
            entities {
              branches {
                userBranches {
                  userKey
                  branchKey
                  fees
                }
              }
            }
          }
        }`,
    variables: {
      userKeys: doctorsAccountKeys,
    },
  });

  if (STATUS.isSuccess(getRoomBookingInfoApiResponse.status)) {
    yield put({
      type: DOCTOR.GET_ROOM_BOOKING_INFO.SUCCESS,
      data: getRoomBookingInfoApiResponse.data,
    });
  } else {
    yield put({
      type: DOCTOR.GET_ROOM_BOOKING_INFO.FAIL,
      error: getRoomBookingInfoApiResponse.data,
    });
  }
}

function* getDoctorDetailsSaga(action) {
  const api = new API();
  const { accountKey, branchKey } = action.payload;
  const response = yield api.get(`${Urls.getDoctorDetails}/${accountKey}?BranchKey=${branchKey}`);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getDoctorDetailsSuccess({
        ...response,
      }),
    );
  } else {
    yield put(
      getDoctorDetailsFailed({
        ...response,
      }),
    );
  }
}

function* doctorSaga() {
  yield takeLatest(DOCTOR.GET_ROOM_BOOKING_INFO.LOAD, getRoomBookingInfo);
  yield takeLatest(DOCTOR.GET_Doctor_Details.LOAD, getDoctorDetailsSaga);
}

export default doctorSaga;
