import ClinicsUtils from 'modules/utils/Clinics';
import { CLINICS, BRANCH_DETAILS, BRANCH_FILTER } from '../actions/clinics';
import { status } from '../enum';

const initialState = {
  Clinics: [],
  loaded: false,
  loading: false,
  rooms: [],
  branches: [],
  doctors: [],
  entities: [],
  entitiesKeys: [],
  entitiesNames: new Map(),
  branchesNames: new Map(),
  roomsNames: new Map(),
  doctorsNames: new Map(),
  branchesMap: new Map(),
  branchDetailsLoadStatus: status.SHOULD_CALL_API,
  branchDetails: undefined,
  selectedBranchKey: undefined,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case BRANCH_FILTER.SET_BRANCH_KEY:
      return {
        ...state,
        selectedBranchKey: payload.branchKey,
      };
    case BRANCH_DETAILS.LOAD:
      return {
        ...state,
        branchDetailsLoadStatus: status.FETCHING,
      };
    case BRANCH_DETAILS.SUCCESS:
      return {
        ...state,
        branchDetails: payload,
        branchDetailsLoadStatus: status.SUCCESS,
      };

    case BRANCH_DETAILS.FAIL:
      return {
        ...state,
        branchDetails: payload,
        branchDetailsLoadStatus: status.FAIL,
      };

    case CLINICS.LOAD:
      return Object.assign({}, state, { loaded: false }, { loading: true });

    case CLINICS.LOAD_DONE: {
      let clinicsObj = {};
      let flattenClinics;
      if (payload.clinics && payload.clinics.data) {
        clinicsObj = payload.clinics.data;
        flattenClinics = ClinicsUtils.flattenClinics(clinicsObj.Clinics);
      }

      return Object.assign({}, state, {
        Clinics: clinicsObj.Clinics,
        loaded: true,
        loading: false,
        rooms: flattenClinics.rooms,
        branches: flattenClinics.branches,
        doctors: flattenClinics.doctors,
        entities: flattenClinics.entities,
        entitiesKeys: flattenClinics.entities.map(entity => entity.entityKey),
        entitiesNames: flattenClinics.entitiesNames,
        branchesNames: flattenClinics.branchesNames,
        roomsNames: flattenClinics.roomsNames,
        doctorsNames: flattenClinics.doctorsNames,
        branchesMap: flattenClinics.branchesMap,
        entityImages:
          clinicsObj.Clinics && clinicsObj.Clinics[0] && clinicsObj.Clinics[0].EntityImages,
      });
    }
    default:
      return state;
  }
};
