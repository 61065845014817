// @flow

export const AMAN = {
  FETCH: {
    LOADING: 'FETCH_AMAN_BRANCHES_LOADING_test',
    SUCCEEDED: 'FETCH_AMAN_BRANCHES_SUCCEEDED',
    FAILED: 'FETCH_AMAN_BRANCHES_FAILED',
  },
  SUBSCIBE: {
    LOADING: 'AMAN_SUBSCRIBING_LOADING',
    SUCCEEDED: 'AMAN_SUBSCRIBING_SUCCEEDED',
    FAILED: 'AMAN_SUBSCRIBING_FAILED',
  },
  EDIT: {
    LOADING: 'EDIT_AMAN_BRANCHE_LOADING',
    SUCCEEDED: 'EDIT_AMAN_BRANCHE_SUCCEEDED',
    FAILED: 'EDIT_AMAN_BRANCHE_FAILED',
  },
  MODAL: {
    SET_BRANCH_KEY: 'MODAL_AMAN_SUBSCRIPTION_EDIT_SET_KEY',
    AMAN_SUBSCRIPTION_TOGGLE: 'MODAL_AMAN_SUBSCRIPTION_TOGGLE',
  },
};

export const getAmanSuscribedBranches = branchesKeys => ({
  type: AMAN.FETCH.LOADING,
  branchesKeys,
});

export const suscribeBranchesToAman = branchesKeys => ({
  type: AMAN.SUBSCIBE.LOADING,
  branchesKeys,
});

export const editAmanBranchSubscribtion = branchDetails => ({
  type: AMAN.EDIT.LOADING,
  branchDetails,
});

export const setCurrentBranchKey = branchKey => ({
  type: AMAN.MODAL.SET_BRANCH_KEY,
  branchKey,
});

export const toggleAmanSubscriptionModal = (show, toggleAmanSubscriptionModalToEdit) => ({
  type: AMAN.MODAL.AMAN_SUBSCRIPTION_TOGGLE,
  show,
  toggleAmanSubscriptionModalToEdit,
});
