import { Cookie } from '@vezeeta/web-utils';

const getLanguage = () => {
  let language;
  if (Cookie.get(Cookie.LANGUAGE)) {
    language = Cookie.get(Cookie.LANGUAGE);
  } else {
    Cookie.set(Cookie.LANGUAGE, 'en');
    language = 'en';
  }
  return language;
};

export const language = getLanguage();
