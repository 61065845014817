import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { COUNTRY, COUNTRIES, loadCountriesSuccess, loadCountriesFailed } from '../actions/country';

function* getCountries() {
  try {
    const apiObj = new API();
    const res = yield apiObj.get(Urls.country.getAllCountries, undefined, true);
    if (STATUS.isSuccess(res.status)) {
      yield put({ type: COUNTRY.LOAD_DONE, countries: res.data });
    } else {
      yield put({ type: COUNTRY.LOAD_FAIL });
    }
  } catch (err) {
    yield put({ type: COUNTRY.LOAD_FAIL });
  }
}
function* getCountryList() {
  try {
    const apiObj = new API();
    const res = yield apiObj.get(Urls.country.getCountryCodes, undefined, true);
    if (STATUS.isSuccess(res.status)) {
      yield put({ type: COUNTRY.LOAD_LIST_DONE, countries: res.data });
    } else {
      yield put({ type: COUNTRY.LOAD_LIST_FAIL });
    }
  } catch (err) {
    yield put({ type: COUNTRY.LOAD_LIST_FAIL });
  }
}

function* fetchCountries() {
  const api = new API();

  const response = yield api.get(Urls.country.getCountries, undefined, true);

  if (STATUS.isSuccess(response.status)) {
    yield put(loadCountriesSuccess(response.data));
  } else {
    yield put(loadCountriesFailed());
  }
}

export default function* country() {
  yield takeEvery(COUNTRY.LOAD, getCountries);
  yield takeEvery(COUNTRY.LOAD_LIST, getCountryList);
  yield takeEvery(COUNTRIES.COUNTRIES.LOADING, fetchCountries);
}
