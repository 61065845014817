export const RELATIVE_TYPES = {
  LOAD: 'RELATIVE_TYPES_LOAD',
  SUCCESS: 'RELATIVE_TYPES_LOAD_DONE',
  FAIL: 'RELATIVE_TYPES_LOAD_FAIL',
};

export const loadRelativeTypes = language => ({
  type: RELATIVE_TYPES.LOAD,
  language,
});

export const loadRelativeTypesSuccess = () => ({
  type: RELATIVE_TYPES.SUCCESS,
});

export const loadRelativeTypesFail = relativeTypes => ({
  type: RELATIVE_TYPES.FAIL,
  relativeTypes,
});
