import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';
import { PROFESSIONAL_TITLES } from '../actions/professionalTitles';

function* getProfessionalTitle(action) {
  const professionalTitlesApi = new API();
  const professionalTitlesApiResponse = yield professionalTitlesApi.get(
    `${Urls.getProfessionalTitles}CountryId=${action.countryId}&LanguageId=${action.languageId}`,
  );

  if (STATUS.isSuccess(professionalTitlesApiResponse.status)) {
    yield put({
      type: PROFESSIONAL_TITLES.SUCCEEDED,
      professionalTitles: professionalTitlesApiResponse.data,
    });
  } else {
    yield put({
      type: PROFESSIONAL_TITLES.FAILED,
      error: professionalTitlesApiResponse.data,
    });
  }
}

function* professionalTitlesSaga() {
  yield takeEvery(PROFESSIONAL_TITLES.LOADING, getProfessionalTitle);
}

export default professionalTitlesSaga;
