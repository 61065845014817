import { renderer } from '@vezeeta/web-components';
import _ from 'lodash';
import { status } from 'modules/utils/enum';
import { CONFIRMATIONS } from '../actions/confirmations';
import CONFIRMATIONS_STATES from '../types/confirmations';
import { language } from '../types/language';

const initialState = {
  confirmations: renderer.loading(),
  confirmationsList: [],
  list: [],
  isLoading: true,
  isConfirmationSaved: false,
  selectedDoctors: [],
  selectedBranch: {},
  snackBar: { show: false, message: '' },
  saveConfirmationsStatus: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case CONFIRMATIONS.FETCH:
      return {
        confirmations: renderer.loading(),
        ...state,
        isLoading: true,
        isConfirmationSaved: false,
      };
    case CONFIRMATIONS.SAVE: {
      return {
        confirmations: renderer.loading(),
        ...state,
        isLoading: true,
        isConfirmationSaved: false,
        saveConfirmationsStatus: status.SUBMITING,
      };
    }
    case CONFIRMATIONS.FETCH_SUCCESS: {
      if (payload.confirmations.length > 0) {
        return {
          ...state,
          confirmations: renderer.success(payload.confirmations),
          confirmationsList: payload.confirmations,
          list: payload.confirmations,
          isLoading: false,
        };
      }

      break;
    }
    case CONFIRMATIONS.FILTER.SET_SELECTED_DOCTORS: {
      if (payload.keys.length > 0) {
        const isSelectionChanged = !_.isEqual(state.selectedDoctors, payload.keys);

        return {
          ...state,
          isLoading: true,
          isFilterEmpty: false,
          selectedDoctors: payload.keys,
          selectedBranch: payload.selectedBranch,
          confirmations: isSelectionChanged
            ? renderer.loading()
            : renderer.success(state.confirmationsList),
        };
      }

      return {
        ...state,
        isFilterEmpty: true,
        selectedDoctors: [],
        confirmations: renderer.empty(
          payload.hasDoctors ? CONFIRMATIONS_STATES.EMPTY_FILTER : CONFIRMATIONS_STATES.NO_USERS,
        ),
      };
    }
    case CONFIRMATIONS.SAVE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isConfirmationSaved: true,
        list: payload.confirmations,
        snackBar: {
          show: true,
          message: language === 'en' ? 'Changes applied successfully' : 'تمت التغييرات بنجاح',
        },
        saveConfirmationsStatus: status.SUCCESS,
      };
    }

    case CONFIRMATIONS.SAVE_FAIL: {
      return {
        ...state,
        isLoading: false,
        isConfirmationSaved: false,
        snackBar: {
          show: true,
          message: language === 'en' ? 'An Error has occurred' : 'لقد حدث خطاء ما',
        },
        saveConfirmationsStatus: status.FAIL,
      };
    }

    case CONFIRMATIONS.SNACK_BAR.HIDE:
      return {
        ...state,
        snackBar: {
          show: false,
          message: '',
        },
      };
    default:
      return state;
  }

  return state;
};
