import { ROLES } from '../actions/roles';

const initialState = {
  roles: new Map(),
  state: undefined,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case ROLES.LOADING: {
      return {
        ...state,
        state: ROLES.LOADING,
      };
    }
    case ROLES.SUCCEEDED: {
      const roles = new Map();
      payload.roles.forEach(role => {
        roles.set(role.RoleId, role);
      });

      return {
        ...state,
        state: ROLES.SUCCEEDED,
        roles,
      };
    }
    case ROLES.FAILED: {
      return {
        ...state,
        state: ROLES.FAILED,
      };
    }
    default:
      return state;
  }
};
