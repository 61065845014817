import { takeEvery, put } from 'redux-saga/effects';
import { STATUS, API, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { AuthApi } from 'modules/sso';

import {
  WORKING_HOURS,
  getBranchWorkingHoursFail,
  getBranchWorkingHoursSuccess,
  setBranchWorkingHoursFail,
  setBranchWorkingHoursSuccess,
  saveWorkingHoursFail,
  saveWorkingHoursDone,
} from '../actions/workingHours';

function* loadWorkingHours(action) {
  const apiObj = new AuthApi();
  const accountRoomModelsObj = [];
  action.doctors.forEach(accountKey => {
    action.rooms.forEach(roomKey => {
      accountRoomModelsObj.push({
        AccountKey: accountKey,
        RoomKey: roomKey,
      });
    });
  });
  const workingHours = yield apiObj.post(Urls.getWorkingHours, {
    AccountRoomModels: accountRoomModelsObj,
    scheduleKey: action.additionalParams.scheduleKey && action.additionalParams.scheduleKey,
    Date: action.additionalParams.date && action.additionalParams.date,
  });
  if (Math.floor(workingHours.status / 100) === 2) {
    yield put({ type: WORKING_HOURS.LOAD_DONE, rooms: workingHours });
    yield put({
      type: WORKING_HOURS.LOAD_SUCCESS,
      rooms: workingHours.data,
    });
  } else {
    yield put({ type: WORKING_HOURS.LOAD_FAIL });
  }
}
function* saveWorkingHours(action) {
  const apiObj = new AuthApi();
  const workingHoursResponse = yield apiObj.post(Urls.saveWorkingHours, {
    scheduleKey: action.scheduleKey,
    ...action.keys,
  });
  const reservationOptions = yield apiObj.post(
    Urls.setAllowReservationWindow,
    action.reservationOptions,
  );
  const setScheduleTypes = yield apiObj.post(Urls.setScheduleTypes, action.scheduleTypes);
  if (
    STATUS.isSuccess(workingHoursResponse.status) &&
    STATUS.isSuccess(reservationOptions.status) &&
    STATUS.isSuccess(setScheduleTypes.status)
  ) {
    yield put(saveWorkingHoursDone({ rooms: workingHoursResponse }));
  } else {
    yield put(saveWorkingHoursFail({ ...workingHoursResponse }));
  }
}

function* updateBranchWorkingHoursSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);

  let culture = Cookie.get(Cookie.CULTURE);
  culture = `en-${culture.split('-')[1]}`;

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  const response = yield api.put(
    `${Urls.setBranchWorkingHours}/${action.payload.branchKey}/workinghours`,
    action.payload.workingHoursShifts,
    header,
  );
  if (STATUS.isSuccess(response.status)) {
    yield put(
      setBranchWorkingHoursSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      setBranchWorkingHoursFail({
        ...response,
      }),
    );
  }
}

function* getBranchWorkingHoursSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  let culture = Cookie.get(Cookie.CULTURE);
  culture = `en-${culture.split('-')[1]}`;

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  const response = yield api.get(
    `${Urls.getBranchWorkingHours}/${action.payload.branchKey}/workinghours`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getBranchWorkingHoursSuccess({
        ...response.data,
      }),
    );
  } else {
    yield put(
      getBranchWorkingHoursFail({
        ...response,
      }),
    );
  }
}

function* workingHoursSaga() {
  yield takeEvery(WORKING_HOURS.LOAD, loadWorkingHours);
  yield takeEvery(WORKING_HOURS.WORKING_HOURS.SUBMIT, saveWorkingHours);
  yield takeEvery(WORKING_HOURS.SET_BRANCH_WORKING_HOURS.SUBMIT, updateBranchWorkingHoursSaga);
  yield takeEvery(WORKING_HOURS.GET_BRANCH_WORKING_HOURS.FETCH, getBranchWorkingHoursSaga);
}

export default workingHoursSaga;
