import { status } from '../enum';
import { SERVICES } from '../actions/servicesAndOperations';

const initialState = {
  servicesList: undefined,
  servicesListState: status.SHOULD_CALL_API,
  servicesInsurance: {
    servicesInsuranceList: undefined,
    status: status.SHOULD_CALL_API,
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SERVICES.GET_SERVICES.LOAD: {
      return {
        ...state,
        servicesListState: status.FETCHING,
      };
    }

    case SERVICES.GET_SERVICES.SUCCESS: {
      return {
        ...state,
        servicesList: payload,
        servicesListState: status.SUCCESS,
      };
    }

    case SERVICES.GET_SERVICES.FAIL: {
      return {
        ...state,
        servicesListState: status.FAIL,
      };
    }

    case SERVICES.GET_INSURANCE_SERVICES.LOAD: {
      return {
        ...state,
        servicesInsurance: {
          servicesInsuranceList: undefined,
          status: status.FETCHING,
        },
      };
    }

    case SERVICES.GET_INSURANCE_SERVICES.SUCCESS: {
      return {
        ...state,
        servicesInsurance: {
          servicesInsuranceList: payload.list,
          status: status.SUCCESS,
        },
      };
    }

    case SERVICES.GET_INSURANCE_SERVICES.FAIL: {
      return {
        ...state,
        servicesInsurance: {
          servicesInsuranceList: undefined,
          status: status.FAIL,
        },
      };
    }

    default:
      return state;
  }
};
