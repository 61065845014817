// @flow

export const PROFESSIONAL_TITLES = {
  LOADING: 'PROFESSIONAL_TITLES_LOADING',
  SUCCEEDED: 'PROFESSIONAL_TITLES_SUCCEEDED',
  FAILED: 'PROFESSIONAL_TITLES_FAILED',
};

export const loadProfessionalTitles = (countryId: string, languageId: string) => ({
  type: PROFESSIONAL_TITLES.LOADING,
  countryId,
  languageId,
});
