import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, jsonToQueryString, Cookie, decodeJwtToken } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  SERVICES,
  loadHealthGroupsDone,
  loadHealthGroupsFailed,
  loadServicesDone,
  loadServicesFailed,
  loadSubServicesDone,
  loadSubServicesFailed,
  loadOffersDone,
  loadOffersFailed,
  addOfferDone,
  addOfferFailed,
  editOfferDone,
  editOfferFailed,
  redeemCodeDone,
  redeemCodeFailed,
  loadUnitsPerCountryDone,
  loadUnitsPerCountryFailed,
  /** Services Module */
  fetchServicesListSuccess,
  fetchServicesListFail,
  searchServiceSuccess,
  searchServiceFail,
  addServiceSuccess,
  addServiceFail,
  fetchServiceDetailsSuccess,
  fetchServiceDetailsFail,
  editServiceFail,
  editServiceSuccess,
  getEntitySpecialtyServicesFail,
  getEntitySpecialtyServicesSuccess,
  deleteServiceFail,
  deleteServiceSuccess,
  fetchServicesListV2Fail,
  fetchServicesListV2Success,
  fetchServicesCategoriesFail,
  fetchServicesCategoriesSuccess,
  fetchServicesPriceFail,
  fetchServicesPriceSuccess,
  submitServicesSuccess,
  submitServicesFail,
  addBulkServicesSuccess,
  addBulkServicesFail,
} from '../actions/services';

function* loadHealthGroups({ headers }) {
  try {
    const apiObj = new API();
    const healthGroupRes = yield apiObj.get(Urls.offers.getHealthGroups, [...headers]);
    if (STATUS.isSuccess(healthGroupRes.status)) {
      yield put(loadHealthGroupsDone(healthGroupRes.data));
    } else {
      yield put(loadHealthGroupsFailed('Failed to load health groups'));
    }
  } catch (err) {
    yield put(loadHealthGroupsFailed('Failed to load health groups'));
  }
}

function* loadUnitsPerCountry({ serviceId, headers }) {
  try {
    const apiObj = new API();
    const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
    const unitsRes = yield apiObj.get(
      `${Urls.offers.getUnitsPerCountry}?${jsonToQueryString({ serviceId })}`,
      [
        ...headers,
        {
          key: 'x-vzt-authentication',
          value: accountToken,
        },
      ],
    );
    if (STATUS.isSuccess(unitsRes.status)) {
      yield put(loadUnitsPerCountryDone(unitsRes.data));
    } else {
      yield put(loadUnitsPerCountryFailed('Failed to load units'));
    }
  } catch (err) {
    yield put(loadUnitsPerCountryFailed('Failed to load units'));
  }
}

function* loadServices({ serviceId, headers }) {
  try {
    const apiObj = new API();
    const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
    const servicesRes = yield apiObj.get(
      `${Urls.offers.getChildrenServices}?${jsonToQueryString({ serviceId })}`,
      [
        ...headers,
        {
          key: 'x-vzt-authentication',
          value: accountToken,
        },
      ],
    );
    if (STATUS.isSuccess(servicesRes.status)) {
      yield put(loadServicesDone(servicesRes.data));
    } else {
      yield put(loadServicesFailed('Failed to load services'));
    }
  } catch (err) {
    yield put(loadServicesFailed('Failed to load services'));
  }
}

function* loadSubServices({ serviceId, headers }) {
  try {
    const apiObj = new API();
    const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
    const subServicesRes = yield apiObj.get(
      `${Urls.offers.getChildrenServices}?${jsonToQueryString({ serviceId })}`,
      [
        ...headers,
        {
          key: 'x-vzt-authentication',
          value: accountToken,
        },
      ],
    );
    if (STATUS.isSuccess(subServicesRes.status)) {
      yield put(loadSubServicesDone(subServicesRes.data));
    } else {
      yield put(loadSubServicesFailed('Failed to load sub-services'));
    }
  } catch (err) {
    yield put(loadSubServicesFailed('Failed to load sub-services'));
  }
}

function* loadOffers({ entityKey, accountKey, branckKey, headers }) {
  try {
    const apiObj = new API();
    const offersRes = yield apiObj.get(
      `${Urls.offers.getOffers}?${jsonToQueryString({ entityKey, branckKey, accountKey })}`,
      [
        {
          key: 'languageId',
          value: 1,
        },
        ...headers,
      ],
    );
    if (STATUS.isSuccess(offersRes.status)) {
      yield put(loadOffersDone(offersRes.data));
    } else {
      yield put(loadOffersFailed('Failed to load offers'));
    }
  } catch (err) {
    yield put(loadOffersFailed('Failed to load offers'));
  }
}

function* addOffer({ data }) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const accountKey = decodeJwtToken(accountToken).payLoad.unique_name;
  try {
    const Headers = [
      {
        key: 'x-vzt-authentication',
        value: accountToken,
      },
      {
        key: 'accountkey',
        value: accountKey,
      },
    ];
    const apiObj = new API();
    const offerRes = yield apiObj.post(Urls.offers.addOffer, { ...data }, Headers);
    if (STATUS.isSuccess(offerRes.status)) {
      yield put(addOfferDone(offerRes.data));
    } else {
      yield put(addOfferFailed('Failed to add offer'));
    }
  } catch (err) {
    yield put(addOfferFailed('Failed to add offer'));
  }
}

function* editOffer({ data }) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const accountKey = decodeJwtToken(accountToken).payLoad.unique_name;
  try {
    const Headers = [
      {
        key: 'x-vzt-authentication',
        value: accountToken,
      },
      {
        key: 'accountkey',
        value: accountKey,
      },
    ];
    const apiObj = new API();
    const offerRes = yield apiObj.post(Urls.offers.editOffer, { ...data }, Headers);
    if (STATUS.isSuccess(offerRes.status)) {
      yield put(editOfferDone(offerRes.data));
    } else {
      yield put(editOfferFailed('Failed to edit offer'));
    }
  } catch (err) {
    yield put(editOfferFailed('Failed to edit offer'));
  }
}

function* redeemCode({ code, entityKey, headers }) {
  const apiObj = new API();
  const redeemedRes = yield apiObj.post(
    `${Urls.offers.redeemCode}?${jsonToQueryString({ code, entityKey })}`,
    null,
    [
      {
        key: 'languageId',
        value: 1,
      },
      ...headers,
    ],
  );
  if (STATUS.isSuccess(redeemedRes.status)) {
    yield put(redeemCodeDone(redeemedRes.data));
  } else {
    yield put(redeemCodeFailed(redeemedRes));
  }
}

/** Services module */
function* sagafetchServicesListPaginated(action) {
  const servicesListAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'countryId',
      value: `${action.payload.countryId}`,
    },
    {
      key: 'languageId',
      value: `${action.payload.languageId}`,
    },
  ];

  const response = yield servicesListAPI.get(
    `${Urls.getEntity}?pageIndex=${action.payload.Page}&pageSize=${
      action.payload.PageSize
    }&keyword=${
      action.payload.KeyWord ? action.payload.KeyWord : ''
    }&branchKeys=${action.payload.selectedBranches.join(
      ',',
    )}&accountKeys=${action.payload.selectedDoctors.join(
      ',',
    )}&insuranceKeys=${action.payload.selectedInsurances.join(',')}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchServicesListSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      fetchServicesListFail({
        ...action.payload,
      }),
    );
  }
}

function* sagafetchServicesListV2Paginated(action) {
  const servicesListAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  let branchKeys = '';
  // let accountkeys = '';
  let serviceKeys = '';
  let categoryKey = '';
  let includePrice = '';

  if (action.payload.Category) {
    categoryKey = `&categorykey=${action.payload.Category}`;
  }
  if (action.payload.includebranchpricebykey) {
    includePrice = `&includebranchpricebykey=${action.payload.includebranchpricebykey}`;
  }

  const includeServicePriceSummary = action.payload.includeServicePriceSummary
    ? '&includeServicePriceSummary=true'
    : '';
  const entityKey = action.payload.EntityKey ? `&entitykey=${action.payload.EntityKey}` : '';

  const assignedToEntity =
    action.payload.assignedToEntity === false
      ? `&assignedToEntity=${action.payload.assignedToEntity}`
      : '';

  if (action.payload.selectedBranches && action.payload.selectedBranches.length) {
    action.payload.selectedBranches.forEach(branchKey => {
      branchKeys = `${branchKeys}&branchkeys=${branchKey}`;
    });
  }

  /* if (action.payload.selectedDoctors && action.payload.selectedDoctors.length) {
    action.payload.selectedDoctors.forEach(accountKey => {
      accountkeys = `${accountkeys}&accountKeys=${accountKey}`;
    });
  } */

  if (action.payload.serviceKeys && action.payload.serviceKeys.length) {
    action.payload.serviceKeys.forEach(serviceKey => {
      serviceKeys = `${serviceKeys}&servicekeys=${serviceKey}`;
    });
  }

  const constantQueryParams =
    '&includes=ServicePreparations&includetotalcount=true&isDeleted=false';

  const response = yield servicesListAPI.get(
    `${Urls.services.getServices}?pagenum=${action.payload.Page}&pagesize=${
      action.payload.PageSize
    }${constantQueryParams}${categoryKey}&searchterm=${
      action.payload.KeyWord ? action.payload.KeyWord : ''
    }${branchKeys}${serviceKeys}${entityKey}${includeServicePriceSummary}${assignedToEntity}${includePrice}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchServicesListV2Success({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      fetchServicesListV2Fail({
        ...action.payload,
      }),
    );
  }
}

function* sagafetchServicesCategories(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.get(`${Urls.services.getCategories}`, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchServicesCategoriesSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      fetchServicesCategoriesFail({
        ...action.payload,
      }),
    );
  }
}

function* sagafetchServicePrice(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.get(
    `${Urls.services.getServicesPrice}?entitykey=${action.payload.entityKey}&servicekeys=${action.payload.servicekey}&isDeleted=false&pagenum=1&pagesize=3000`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchServicesPriceSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      fetchServicesPriceFail({
        ...action.payload,
      }),
    );
  }
}

function* sagafetchServiceDetailsV2(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.get(
    `${Urls.services.getServices}/${action.payload.serviceKey}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchServiceDetailsSuccess({
        ...response,
      }),
    );
  } else {
    yield put(
      fetchServiceDetailsFail({
        ...response,
      }),
    );
  }
}

/* function* sagafetchServiceDetails(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'countryId',
      value: `${action.payload.countryId}`,
    },
    {
      key: 'languageId',
      value: `${action.payload.languageId}`,
    },
  ];

  const response = yield api.get(
    `${Urls.getEntity}/${action.payload.entityServiceCountryKey}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchServiceDetailsSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      fetchServiceDetailsFail({
        ...response,
      }),
    );
  }
} */

function* sagaDeleteServiceV2(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.delete(
    `${Urls.services.getServices}/${action.payload.serviceKey}/serviceprices`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      deleteServiceSuccess({
        ...response.response,
      }),
    );
  } else {
    yield put(
      deleteServiceFail({
        ...response,
      }),
    );
  }
}

/* function* sagaDeleteService(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.delete(
    `${Urls.getEntity}/${action.payload.entityServiceCountryKey}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      deleteServiceSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      deleteServiceFail({
        ...response,
      }),
    );
  }
} */

function* sagaEditServiceDetails(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'countryId',
      value: `${action.payload.countryId}`,
    },
    {
      key: 'languageId',
      value: `${action.payload.languageId}`,
    },
  ];

  const response = yield api.put(
    `${Urls.getEntity}/${action.payload.entityServiceCountryKey}`,
    action.payload.newServiceDetails,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      editServiceSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      editServiceFail({
        ...response,
      }),
    );
  }
}

function* fetchSearchedServices(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);

  const header = [
    {
      key: 'countryid',
      value: `${action.payload.countryId}`,
    },
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.get(
    `${Urls.getServices}?keyword=${action.payload.keyWord}&pageIndex=1&pageSize=10`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      searchServiceSuccess({
        ...response.response,
      }),
    );
  } else {
    yield put(
      searchServiceFail({
        ...action.payload,
      }),
    );
  }
}

function* addServices(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'countryId',
      value: `${action.payload.countryId}`,
    },
    {
      key: 'languageId',
      value: `${action.payload.languageId}`,
    },
  ];

  const response = yield api.post(`${Urls.getEntity}`, action.payload.newServiceDetails, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      addServiceSuccess({
        ...response.response,
      }),
    );
  } else {
    yield put(
      addServiceFail({
        ...response.response,
      }),
    );
  }
}

function* sagaGetEntitySpecialtyServices(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'countryId',
      value: `${action.payload.countryId}`,
    },
  ];
  const response = yield api.get(
    `${Urls.getEntity}/getEntityAndSpecialtyServices?accountKey=${action.payload.accountKey}&branchKey=${action.payload.branckKey}&specialtyCountryKey=${action.payload.specialtyCountryKey}&insuranceCountryKey=${action.payload.insuranceCountryKey}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getEntitySpecialtyServicesSuccess({
        ...response.response,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      getEntitySpecialtyServicesFail({
        ...response,
      }),
    );
  }
}

function* sagaSubmitService(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.put(`${Urls.services.addServicePrice}`, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      submitServicesSuccess({
        ...response,
      }),
    );
  } else {
    yield put(
      submitServicesFail({
        ...response,
      }),
    );
  }
}

function* submitBulkServiceSaga(action) {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
  ];

  const response = yield api.put(Urls.services.addBulkServicesPrice, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      addBulkServicesSuccess({
        ...response,
      }),
    );
  } else {
    yield put(
      addBulkServicesFail({
        ...response,
      }),
    );
  }
}

function* services() {
  yield takeEvery(SERVICES.HEALTH_GROUPS.LOAD, loadHealthGroups);
  yield takeEvery(SERVICES.SERVICES.LOAD, loadServices);
  yield takeEvery(SERVICES.SUB_SERVICES.LOAD, loadSubServices);
  yield takeEvery(SERVICES.OFFER.LOAD_ALL, loadOffers);
  yield takeEvery(SERVICES.OFFER.ADD, addOffer);
  yield takeEvery(SERVICES.OFFER.EDIT, editOffer);
  yield takeEvery(SERVICES.CODE.REDEEM, redeemCode);
  yield takeEvery(SERVICES.UNITS.LOAD, loadUnitsPerCountry);
  yield takeEvery(SERVICES.SERVICES_LIST.FETCH, sagafetchServicesListPaginated);
  yield takeEvery(SERVICES.SERVICES_LIST_V2.FETCH, sagafetchServicesListV2Paginated);
  yield takeEvery(SERVICES.SERVICES_CATEGORIES.FETCH, sagafetchServicesCategories);
  yield takeEvery(SERVICES.SERVICES_PRICE.FETCH, sagafetchServicePrice);
  yield takeEvery(SERVICES.SUBMIT_SERVICE.SUBMIT, sagaSubmitService);
  yield takeEvery(SERVICES.GET_SERVICE_DETAILS.FETCH, sagafetchServiceDetailsV2);
  yield takeEvery(SERVICES.SEARCH.LOAD, fetchSearchedServices);
  yield takeEvery(SERVICES.EDIT_SERVICE_DETAILS.SUBMIT, sagaEditServiceDetails);
  yield takeEvery(SERVICES.ADD_SERVICE.LOAD, addServices);
  yield takeEvery(SERVICES.GET_ENTITY_SPECIALTY_SERVICE.FETCH, sagaGetEntitySpecialtyServices);
  yield takeEvery(SERVICES.DELETE_SERVICE.SUBMIT, sagaDeleteServiceV2);
  yield takeEvery(SERVICES.ADD_BULK_SERVICES.SUBMIT, submitBulkServiceSaga);
}

export default services;
