/* eslint-disable indent */
import { renderer } from '@vezeeta/web-components';
import { getFormattedDate, getDateAfterGivenDays } from 'modules/utils/Helpers';
import { PAGE_SIZE } from 'modules/utils/Constants';
import _ from 'lodash';
import { language } from '../types/language';
import { status /* appointmentsStatuses */ } from '../enum';

import { APPOINTMENTS } from '../actions/appointments';
import APPOINTMENTS_STATES from '../types/appointments';

const initialState = {
  appointments: renderer.loading(),
  appointmentsList: [],
  appointmentsSummary: {
    CancelledCount: 0,
    CompletedCount: 0,
    InCheckInStatusCount: 0,
    NoActionCount: 0,
    NoShowCount: 0,
    PendingCount: 0,
    TotalCount: 0,
  },
  showMore: { isLoading: false, enable: false },
  appointmentsUpdates: null,
  appointmentsListUpdates: [],
  showMoreUpdates: { isLoading: false, enable: false },
  hasNewUpdates: false,

  isCalendarEmpty: false,
  isFilterEmpty: false,
  selected: {
    roomsAndDoctors: [],
    patientKey: '',
    hasDoctors: true,
    startDate: getFormattedDate(new Date()),
    endDate: getFormattedDate(getDateAfterGivenDays(6)),
  },
  createAppointment: {
    appointmentDetails: {},
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
    createAppointmentState: status.SHOULD_CALL_API,
  },
  cancelAppointment: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  markAsNoShowAppointment: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  markAsInsuredAppointment: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  addPrescription: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  addPrescriptionLoadStatus: status.SHOULD_CALL_API,
  snackBar: { show: false, message: '' },
  cancelAppointmentModal: { isOpen: false, appointmentDetails: {} },
  editAppointmentModal: { isOpen: false, appointmentDetails: {} },
  printInvoiceModal: { isOpen: false, printInvoiceDetails: {} },
  markAsNoShowModal: { isOpen: false, appointmentDetails: {} },
  confirmationModal: { isOpen: false },
  markAsInsuredModal: { isOpen: false, appointmentDetails: {} },
  addAppointmentModal: { isOpen: false },
  assignDoctorModal: { isOpen: false, details: {} },
  addPrescriptionModal: { isOpen: false, appointmentDetails: {} },
  rescheduleModal: { isOpen: false, appointmentDetails: {} },
  viewServicesModal: { isOpen: false, services: [], attachments: [], patientDetails: {} },
  addResultsModal: { isOpen: false, reservationKey: '', results: [] },
  attachmentsModal: { isOpen: false, reservationKey: '', attachments: [] },
  physicalServicesModal: { isOpen: false, servicesDetails: {}, patientDetails: {} },
  addAppointmentDetails: {},
  addAppointmentDetailsStatus: status.SHOULD_CALL_API,
  editAppointmentDetailsStatus: status.SHOULD_CALL_API,
  nationalId: {
    patientNationalId: {},
    status: status.SHOULD_CALL_API,
  },
  submitResults: { status: status.SHOULD_CALL_API },
  getResults: {
    uploadedResults: [],
    status: status.SHOULD_CALL_API,
  },
  appointmentInvoiceDetails: {},
  appointmentInvoiceDetailsStatus: status.SHOULD_CALL_API,
  insuranceCard: {
    patientInsuranceCard: {},
    status: status.SHOULD_CALL_API,
  },
  uploadNationalIdStatus: status.SHOULD_CALL_API,
  countReservationStatus: status.SHOULD_CALL_API,
  countReservation: undefined,
  approvalLetter: {
    patientApprovalLetter: {},
    status: status.SHOULD_CALL_API,
  },
  uploadApprovalLetterStatus: status.SHOULD_CALL_API,
  lastUploadedPrescription: undefined,
  addVitalsStatus: status.SHOULD_CALL_API,
  addVitalsResponse: undefined,
  updateVitalsStatus: status.SHOULD_CALL_API,
  updateVitalsResponse: undefined,

  checkInAppointmentStatus: status.SHOULD_CALL_API,
  cancelAppointmentStatus: status.SHOULD_CALL_API,
  completeAppointmentStatus: status.SHOULD_CALL_API,
  noShowAppointmentStatus: status.SHOULD_CALL_API,
  insureAppointmentStatus: status.SHOULD_CALL_API,
  appointmentDetails: {},
  appointmentCommentModal: { isOpen: false },
};

const changeAppointmentState = (appointments, appointmentKey, newState) =>
  appointments.map(appointment =>
    appointment.ReservationKey === appointmentKey ? { ...appointment, ...newState } : appointment,
  );

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case APPOINTMENTS.SET_APPOINTMENT_DETAILS:
      return {
        ...state,
        appointmentDetails: payload.appointmentDetails,
      };
    case APPOINTMENTS.MODAL.APPOINTMENT_COMMENT:
      return {
        ...state,
        appointmentCommentModal: {
          isOpen: payload.isOpened,
        },
      };
    case APPOINTMENTS.ADD_VITALS.SUBMIT:
      return {
        ...state,
        addVitalsStatus: status.SUBMITING,
      };
    case APPOINTMENTS.ADD_VITALS.SUCCESS: {
      const { reservationKey, Data } = payload.payload;
      const appointmentsList = [];
      state.appointmentsList.forEach(appointment => {
        if (appointment.ReservationKey === reservationKey) {
          appointmentsList.push({ ...appointment, Vitals: { ...Data } });
        } else {
          appointmentsList.push(appointment);
        }
      });

      return {
        ...state,
        appointments: renderer.success(appointmentsList),
        addVitalsStatus: status.SUCCESS,
        addVitalsResponse: payload.payload,
      };
    }
    case APPOINTMENTS.ADD_VITALS.FAIL:
      return {
        ...state,
        addVitalsStatus: status.FAIL,
        addVitalsResponse: payload.payload,
      };

    case APPOINTMENTS.UPDATE_VITALS.SUBMIT:
      return {
        ...state,
        updateVitalsStatus: status.SUBMITING,
      };
    case APPOINTMENTS.UPDATE_VITALS.SUCCESS: {
      const { reservationKey, vitalsObj } = payload.payload;
      const appointmentsList = [];
      state.appointmentsList.forEach(appointment => {
        if (appointment.ReservationKey === reservationKey) {
          appointmentsList.push({
            ...appointment,
            Vitals: { ...appointment.Vitals, ...vitalsObj },
          });
        } else {
          appointmentsList.push(appointment);
        }
      });
      return {
        ...state,
        appointments: renderer.success(appointmentsList),
        updateVitalsStatus: status.SUCCESS,
        updateVitalsResponse: payload.payload,
      };
    }
    case APPOINTMENTS.UPDATE_VITALS.FAIL:
      return {
        ...state,
        updateVitalsStatus: status.FAIL,
        updateVitalsResponse: payload.payload,
      };

    case APPOINTMENTS.FETCH_COUNT_RESERVATION.LOAD:
      return {
        ...state,
        countReservationStatus: status.FETCHING,
        countReservation: undefined,
      };
    case APPOINTMENTS.FETCH_COUNT_RESERVATION.SUCCESS:
      return {
        ...state,
        countReservationStatus: status.SUCCESS,
        countReservation: payload.payload,
      };
    case APPOINTMENTS.FETCH_COUNT_RESERVATION.FAIL:
      return {
        ...state,
        countReservationStatus: status.FAIL,
        countReservation: payload,
      };
    case APPOINTMENTS.GET_APPOINTMENT_INVOICE.LOAD:
      return {
        ...state,
        appointmentInvoiceDetails: {},
        appointmentInvoiceDetailsStatus: status.FETCHING,
      };
    case APPOINTMENTS.GET_APPOINTMENT_INVOICE.SUCCESS:
      return {
        ...state,
        appointmentInvoiceDetails: payload,
        appointmentInvoiceDetailsStatus: status.SUCCESS,
      };

    case APPOINTMENTS.GET_APPOINTMENT_INVOICE.FAIL:
      return {
        ...state,
        appointmentInvoiceDetails: payload,
        appointmentInvoiceDetailsStatus: status.FAIL,
      };
    case APPOINTMENTS.ADD_APPOINTMENT.SUBMIT:
      return {
        ...state,
        addAppointmentDetailsStatus: status.SUBMITING,
      };
    case APPOINTMENTS.ADD_APPOINTMENT.SUCCESS: {
      const getAppointmentTypes = {
        0: 'Examination',
        1: 'followUpVisit',
        3: 'services',
      };
      return {
        ...state,
        addAppointmentDetails: payload,
        addAppointmentDetailsStatus: status.SUCCESS,
        printInvoiceModal: {
          isOpen: state.printInvoiceModal.isOpen,
          printInvoiceDetails: {
            reservationKey:
              payload.payload && payload.payload[0] && payload.payload[0].ReservationKey,
            branchKey: payload.payload && payload.payload[0] && payload.payload[0].BranchKey,
            component: 'Add appointment',
            appointmentType:
              getAppointmentTypes[
                payload.payload && payload.payload[0] && payload.payload[0].VisitType
              ],
            ...payload,
          },
        },
      };
    }
    case APPOINTMENTS.ADD_APPOINTMENT.FAILED:
      return {
        ...state,
        addAppointmentDetailsStatus: status.FAIL,
      };
    case APPOINTMENTS.EDIT_APPOINTMENT.SUBMIT:
      return {
        ...state,
        editAppointmentDetailsStatus: status.SUBMITING,
      };
    case APPOINTMENTS.EDIT_APPOINTMENT.SUCCESS:
      return {
        ...state,
        editAppointmentDetailsStatus: status.SUCCESS,
      };
    case APPOINTMENTS.EDIT_APPOINTMENT.FAILED:
      return {
        ...state,
        editAppointmentDetailsStatus: status.FAIL,
      };
    case APPOINTMENTS.FETCH.LOAD:
      if (!payload.pushToCurrent) {
        return {
          ...state,
          appointments: renderer.loading(),
        };
      }
      return {
        ...state,
        showMore: {
          isLoading: true,
          enable: true,
        },
      };
    case APPOINTMENTS.FETCH_UPDATES.LOAD:
      return {
        ...state,
        showMoreUpdates: {
          isLoading: true,
          enable: true,
        },
      };

    case APPOINTMENTS.FETCH.SUCCESS: {
      let appointmentsList = [];
      let appointments;
      let showMore;

      if (payload.pushToCurrent) {
        appointmentsList = [...state.appointmentsList, ...payload.appointments];
      } else {
        appointmentsList = payload.appointments;
      }

      if (payload.appointments.length > 0) {
        appointments = renderer.success(appointmentsList);

        const pageSize = payload.pageSize > 0 ? payload.pageSize : PAGE_SIZE;

        showMore = {
          isLoading: false,
          enable: !(payload.appointments.length < pageSize || !payload.appointments.length),
        };
      } else {
        showMore = { isLoading: false, enable: false };

        if (!payload.pushToCurrent) {
          appointments = renderer.empty(APPOINTMENTS_STATES.NO_APPOINTMENTS);
        } else {
          appointments = renderer.success(appointmentsList);
        }
      }

      return {
        ...state,
        appointments,
        appointmentsSummary: { ...state.appointmentsSummary, ...payload.summary },
        appointmentsList,
        showMore,
      };
    }

    case APPOINTMENTS.FETCH_UPDATES.SUCCESS: {
      let appointmentsListUpdates = [];
      let appointmentsUpdates;
      let showMoreUpdates;
      let hasNewUpdates = false;

      appointmentsListUpdates = payload.appointments;

      if (
        payload.appointments.length > 0 &&
        !_.isEqual(state.appointmentsList, appointmentsListUpdates) &&
        appointmentsListUpdates.length > state.appointmentsList.length
      ) {
        appointmentsUpdates = renderer.success(appointmentsListUpdates);
        showMoreUpdates = {
          isLoading: false,
          enable: payload.appointments.length === PAGE_SIZE,
        };
        hasNewUpdates = true;
      } else {
        showMoreUpdates = { isLoading: false, enable: false };
        appointmentsUpdates = renderer.success(appointmentsListUpdates);
      }

      return {
        ...state,
        appointmentsUpdates,
        appointmentsListUpdates,
        showMoreUpdates,
        hasNewUpdates,
      };
    }

    case APPOINTMENTS.FETCH.FAIL:
      return { ...state, appointments: renderer.failure(payload.error) };

    case APPOINTMENTS.FETCH_UPDATES.FAIL:
      return {
        ...state,
        appointmentsUpdates: renderer.failure(payload.error),
        hasNewUpdates: false,
      };

    case APPOINTMENTS.LOAD_APPOINTMENTS_UPDATES:
      return {
        ...state,
        appointments: state.appointmentsUpdates,
        appointmentsList: state.appointmentsListUpdates,
        showMore: state.showMoreUpdates,
        hasNewUpdates: false,
      };

    case APPOINTMENTS.CLOSE_APPOINTMENTS_NOTIFICATION_BAR:
      return {
        ...state,
        hasNewUpdates: false,
      };

    case APPOINTMENTS.FILTER.SET_SELECTED_DATES: {
      if (!payload.dates.startDate || !payload.dates.endDate) {
        return {
          ...state,
          isCalendarEmpty: true,
          appointments: renderer.empty(APPOINTMENTS_STATES.EMPTY_CALENDAR),
          selected: {
            ...state.selected,
            startDate: payload.dates.startDate,
            endDate: payload.dates.endDate,
          },
        };
      }

      const {
        selected: { hasDoctors },
      } = state;

      let { appointments } = state;
      if (!hasDoctors) {
        appointments = renderer.empty(APPOINTMENTS_STATES.NO_USERS);
      }

      return {
        ...state,
        isCalendarEmpty: false,
        appointments,
        selected: {
          ...state.selected,
          startDate: payload.dates.startDate,
          endDate: payload.dates.endDate,
        },
      };
    }

    case APPOINTMENTS.FILTER.SET_SELECTED_ROOMS_AND_DOCTORS: {
      if (payload.roomsAndDoctors.length > 0) {
        return {
          ...state,
          isFilterEmpty: false,
          selected: {
            ...state.selected,
            roomsAndDoctors: payload.roomsAndDoctors,
            hasDoctors: payload.hasDoctors,
          },
        };
      }

      return {
        ...state,
        isFilterEmpty: true,
        appointments: renderer.empty(
          payload.hasDoctors ? APPOINTMENTS_STATES.EMPTY_FILTER : APPOINTMENTS_STATES.NO_USERS,
        ),
        selected: {
          ...state.selected,
          hasDoctors: payload.hasDoctors,
          roomsAndDoctors: payload.data,
        },
      };
    }

    case APPOINTMENTS.FILTER.SET_PATIENT_NAME:
      return {
        ...state,
        selected: { ...state.selected, patientKey: payload.patientKey },
      };

    case APPOINTMENTS.CREATE.LOAD:
      return {
        ...state,
        createAppointment: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
          createAppointmentState: status.SUBMITING,
        },
      };

    case APPOINTMENTS.CREATE.SUCCESS: {
      // const newAppointmentList = state.appointmentsList;
      /* const { ReservationKey, oldReservationKey } = payload.appointment;

      newAppointmentList.unshift(payload.appointment);

      if (oldReservationKey) {
        const oldRescheduledAppointment = newAppointmentList.filter(
          appointment => appointment.ReservationKey === oldReservationKey,
        );
      } */

      return {
        ...state,
        createAppointment: {
          ...state.createAppointment,
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
          createAppointmentState: status.SUCCESS,
        },
        snackBar: {
          show: true,
          message: language === 'en' ? 'Appointment created successfully' : 'تم إضافة الحجز بنجاح',
        },
        // appointments: renderer.success(newAppointmentList),
        // appointmentsList: newAppointmentList,
        addAppointmentModal: {
          isOpen: false,
        },
        rescheduleModal: {
          isOpen: false,
        },
      };
    }

    case APPOINTMENTS.CREATE.FAIL:
      return {
        ...state,
        createAppointment: {
          appointmentDetails: {},
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: payload.error },
          createAppointmentState: status.FAIL,
        },
      };

    case APPOINTMENTS.CANCEL.LOAD:
      return {
        ...state,
        cancelAppointmentStatus: status.SUBMITING,
        cancelAppointment: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };

    case APPOINTMENTS.CANCEL.SUCCESS: {
      /* const appointmentsList = [];
      let prevStatus;
      let prevSummary = state.appointmentsSummary;
      state.appointmentsList.forEach(appointment => {
        if (appointment.ReservationKey === payload.appointmentKey) {
          prevStatus = appointment.ReservationStatus;
        } else {
          appointmentsList.push(appointment);
        }
      });

      prevSummary = {
        ...prevSummary,
        CancelledCount: prevSummary.CancelledCount + 1,
        InCheckInStatusCount:
          prevStatus === appointmentsStatuses.PatientCheckedIn
            ? prevSummary.InCheckInStatusCount - 1
            : prevSummary.InCheckInStatusCount,
        CompletedCount:
          prevStatus === appointmentsStatuses.Completed
            ? prevSummary.CompletedCount - 1
            : prevSummary.CompletedCount,
        NoActionCount:
          prevStatus === appointmentsStatuses.NoAction
            ? prevSummary.NoActionCount - 1
            : prevSummary.NoActionCount,
        NoShowCount:
          prevStatus === appointmentsStatuses.NoShow
            ? prevSummary.NoShowCount - 1
            : prevSummary.NoShowCount,
        PendingCount:
          prevStatus === appointmentsStatuses.Pending
            ? prevSummary.PendingCount - 1
            : prevSummary.PendingCount,
      }; */

      return {
        ...state,
        cancelAppointmentStatus: status.SUCCESS,
        /* appointmentsSummary: prevSummary,
        appointments: renderer.success(appointmentsList),
        appointmentsList, */
        cancelAppointment: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        cancelAppointmentModal: { isOpen: false, appointmentDetails: {} },
        snackBar: {
          show: true,
          message:
            language === 'en' ? 'Appointment cancelled successfully' : 'تم إلغاء الحجز بنجاح',
        },
      };
    }

    case APPOINTMENTS.CANCEL.FAIL:
      return {
        ...state,
        cancelAppointmentStatus: status.FAIL,
        cancelAppointment: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: '' },
        },
      };

    case APPOINTMENTS.MARK_AS_NO_SHOW.LOAD:
      return {
        ...state,
        noShowAppointmentStatus: status.SUBMITING,
        markAsNoShowAppointment: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };

    case APPOINTMENTS.MARK_AS_NO_SHOW.SUCCESS: {
      /* let prevStatus;
      let prevSummary = state.appointmentsSummary;

      const appointmentsList = [];
      state.appointmentsList.forEach(appointment => {
        if (appointment.ReservationKey === payload.appointmentKey) {
          prevStatus = appointment.ReservationStatus;
        } else {
          appointmentsList.push(appointment);
        }
      });

      if (!payload.isNoShow) {
        prevSummary = {
          ...prevSummary,
          NoShowCount: prevSummary.NoShowCount + 1,
          CancelledCount:
            prevStatus === appointmentsStatuses.Cancelled
              ? prevSummary.CancelledCount - 1
              : prevSummary.CancelledCount,
          CompletedCount:
            prevStatus === appointmentsStatuses.Completed
              ? prevSummary.CompletedCount - 1
              : prevSummary.CompletedCount,
          NoActionCount:
            prevStatus === appointmentsStatuses.NoAction
              ? prevSummary.NoActionCount - 1
              : prevSummary.NoActionCount,
          InCheckInStatusCount:
            prevStatus === appointmentsStatuses.PatientCheckedIn
              ? prevSummary.InCheckInStatusCount - 1
              : prevSummary.InCheckInStatusCount,
          PendingCount:
            prevStatus === appointmentsStatuses.Pending
              ? prevSummary.PendingCount - 1
              : prevSummary.PendingCount,
        };
      } else {
        prevSummary = {
          ...prevSummary,
          NoShowCount: prevSummary.NoShowCount - 1,
          PendingCount: prevSummary.PendingCount + 1,
        };
      } */

      const noShowMessage =
        language === 'en'
          ? 'Appointment marked as No-show successfully'
          : 'تم تحديد المريض لم يحضر بنجاح';
      const undoNoShowMessage =
        language === 'en'
          ? 'Appointment No-show removed successfully'
          : 'تم إزالة تحديد المريض كلم يحضر بنجاح';
      return {
        ...state,
        noShowAppointmentStatus: status.SUCCESS,
        /* appointmentsSummary: prevSummary,
        appointments: renderer.success(appointmentsList),
        appointmentsList, */
        markAsNoShowAppointment: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        markAsNoShowModal: { isOpen: false, appointmentDetails: {} },
        snackBar: {
          show: true,
          message: payload.isNoShow ? undoNoShowMessage : noShowMessage,
        },
      };
    }

    case APPOINTMENTS.MARK_AS_NO_SHOW.FAIL:
      return {
        ...state,
        noShowAppointmentStatus: status.FAIL,
        markAsNoShowAppointment: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: '' },
        },
      };

    case APPOINTMENTS.MARK_AS_INSURED.LOAD:
      return {
        ...state,
        insureAppointmentStatus: status.SUBMITING,
        markAsInsuredAppointment: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };

    case APPOINTMENTS.MARK_AS_INSURED.SUCCESS: {
      const appointmentsList = state.appointmentsList.map(appointment =>
        appointment.ReservationKey === payload.appointmentKey
          ? {
              ...appointment,
              IsInsured: payload.isInsured === 1,
              UpdateEnabilities: {
                ...appointment.UpdateEnabilities,
              },
            }
          : appointment,
      );
      const insuredMessage =
        language === 'en'
          ? 'Appointment Marked as Insured Successfully'
          : 'تم تحديد المريض كمؤمن عليه بنجاح';
      const undoInsuredMessage =
        language === 'en'
          ? 'Appointment insurance removed successfully'
          : 'تم إزالة التأمين من على الحجز بنجاح';
      return {
        ...state,
        insureAppointmentStatus: status.SUCCESS,
        appointments: renderer.success(appointmentsList),
        appointmentsList,
        markAsInsuredAppointment: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        markAsInsuredModal: { isOpen: false, appointmentDetails: {} },
        snackBar: {
          show: true,
          message: payload.isInsured === 1 ? insuredMessage : undoInsuredMessage,
        },
      };
    }

    case APPOINTMENTS.MARK_AS_INSURED.FAIL:
      return {
        ...state,
        insureAppointmentStatus: status.FAIL,
        markAsInsuredAppointment: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: '' },
        },
      };

    case APPOINTMENTS.CHECK_IN.LOAD: {
      const appointmentsList = changeAppointmentState(
        state.appointmentsList,
        payload.appointmentKey,
        {
          isCheckingIn: true,
        },
      );
      return {
        ...state,
        checkInAppointmentStatus: status.SUBMITING,
        appointmentsList,
        appointments: renderer.success(appointmentsList),
      };
    }

    case APPOINTMENTS.CHECK_IN.SUCCESS: {
      /* const appointmentsList = [];
      let prevStatus;
      let prevSummary = state.appointmentsSummary;
      state.appointmentsList.forEach(appointment => {
        if (appointment.ReservationKey !== payload.appointmentKey) {
          appointmentsList.push(appointment);
        } else {
          prevStatus = appointment.ReservationStatus;
        }
      });
      if (!payload.isCheckedIn) {
        prevSummary = {
          ...prevSummary,
          InCheckInStatusCount: prevSummary.InCheckInStatusCount + 1,
          CancelledCount:
            prevStatus === appointmentsStatuses.Cancelled
              ? prevSummary.CancelledCount - 1
              : prevSummary.CancelledCount,
          CompletedCount:
            prevStatus === appointmentsStatuses.Completed
              ? prevSummary.CompletedCount - 1
              : prevSummary.CompletedCount,
          NoActionCount:
            prevStatus === appointmentsStatuses.NoAction
              ? prevSummary.NoActionCount - 1
              : prevSummary.NoActionCount,
          NoShowCount:
            prevStatus === appointmentsStatuses.NoShow
              ? prevSummary.NoShowCount - 1
              : prevSummary.NoShowCount,
          PendingCount:
            prevStatus === appointmentsStatuses.Pending
              ? prevSummary.PendingCount - 1
              : prevSummary.PendingCount,
        };
      } else {
        prevSummary = {
          ...prevSummary,
          InCheckInStatusCount: prevSummary.InCheckInStatusCount - 1,
          PendingCount: prevSummary.PendingCount + 1,
        };
      } */

      const checkInMessage =
        language === 'en' ? 'Appointment Checked-in Successfully' : 'تم تسجيل الحضور بنجاح';
      const checkOutMessage =
        language === 'en'
          ? 'Appointment check-in undone successfully'
          : 'تم إزالة تسجيل الحضور بنجاح';
      return {
        ...state,
        checkInAppointmentStatus: status.SUCCESS,
        /* appointmentsSummary: prevSummary,
        appointmentsList,
        appointments: renderer.success(appointmentsList), */
        snackBar: {
          show: true,
          message: !payload.isCheckedIn ? checkInMessage : checkOutMessage,
        },
      };
    }

    case APPOINTMENTS.CHECK_IN.FAIL: {
      const appointmentsList = changeAppointmentState(
        state.appointmentsList,
        payload.appointmentKey,
        {
          isCheckingIn: false,
          IsCheckedIn: false,
        },
      );
      return {
        ...state,
        appointmentsList,
        checkInAppointmentStatus: status.FAIL,
        appointments: renderer.success(appointmentsList),
      };
    }

    case APPOINTMENTS.COMPLETE.LOAD: {
      const appointmentsList = state.appointmentsList.map(appointment =>
        appointment.ReservationKey === payload.appointmentKey
          ? {
              ...appointment,
              isCompleting: true,
            }
          : appointment,
      );

      return {
        ...state,
        completeAppointmentStatus: status.SUBMITING,
        appointmentsList,
        appointments: renderer.success(appointmentsList),
      };
    }

    case APPOINTMENTS.COMPLETE.SUCCESS: {
      /* const appointmentsList = [];
      let prevStatus;
      let newState;
      let prevSummary = state.appointmentsSummary;

      const setNewStatus = (isCompelete, resDate) => {
        if (isCompelete) return 'Completed';
        if (
          new Date(resDate) < new Date() &&
          Math.abs(new Date(resDate) - new Date()) / 1000 / 60 / 60 > 24
        )
          return 'NoAction';
        return 'PatientCheckedIn';
      };

      state.appointmentsList.forEach(appointment => {
        if (appointment.ReservationKey === payload.appointmentKey) {
          newState = setNewStatus(payload.isCompelete, appointment.ReservationDate);
          prevStatus = appointment.ReservationStatus;
        } else {
          appointmentsList.push(appointment);
        }
      });

      if (prevStatus !== appointmentsStatuses.Completed) {
        prevSummary = {
          ...prevSummary,
          CompletedCount: prevSummary.CompletedCount + 1,
          CancelledCount:
            prevStatus === appointmentsStatuses.Cancelled
              ? prevSummary.CancelledCount - 1
              : prevSummary.CancelledCount,
          InCheckInStatusCount:
            prevStatus === appointmentsStatuses.PatientCheckedIn
              ? prevSummary.InCheckInStatusCount - 1
              : prevSummary.InCheckInStatusCount,
          NoActionCount:
            prevStatus === appointmentsStatuses.NoAction
              ? prevSummary.NoActionCount - 1
              : prevSummary.NoActionCount,
          NoShowCount:
            prevStatus === appointmentsStatuses.NoShow
              ? prevSummary.NoShowCount - 1
              : prevSummary.NoShowCount,
          PendingCount:
            prevStatus === appointmentsStatuses.Pending
              ? prevSummary.PendingCount - 1
              : prevSummary.PendingCount,
        };
      } else {
        prevSummary = {
          ...prevSummary,
          CompletedCount: prevSummary.CompletedCount - 1,
          InCheckInStatusCount:
            newState === appointmentsStatuses.PatientCheckedIn
              ? prevSummary.InCheckInStatusCount + 1
              : prevSummary.InCheckInStatusCount,
        };
      } */

      const message = language === 'en' ? 'Operation done Successfully' : 'تمت العملية بنجاح';

      return {
        ...state,
        completeAppointmentStatus: status.SUCCESS,
        /* appointmentsList,
        appointmentsSummary: prevSummary,
        appointments: renderer.success(appointmentsList), */
        snackBar: {
          show: true,
          message,
        },
      };
    }

    case APPOINTMENTS.COMPLETE.FAIL: {
      const appointmentsList = changeAppointmentState(
        state.appointmentsList,
        payload.appointmentKey,
        {
          isCompleting: false,
        },
      );
      return {
        ...state,
        completeAppointmentStatus: status.FAIL,
        appointmentsList,
        appointments: renderer.success(appointmentsList),
      };
    }

    case APPOINTMENTS.MODAL.CANCEL_TOGGLE:
      return {
        ...state,
        cancelAppointmentModal: {
          isOpen: payload.isOpened,
          appointmentDetails: state.cancelAppointmentModal.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.EDIT_APPOINTMENT_TOGGLE:
      return {
        ...state,
        editAppointmentModal: {
          isOpen: payload.isOpened,
          appointmentDetails: state.editAppointmentModal.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.PRINT_INVOICE_TOGGLE: {
      return {
        ...state,
        printInvoiceModal: {
          isOpen: payload.isOpened,
          printInvoiceDetails: state.printInvoiceModal.printInvoiceDetails,
        },
      };
    }

    case APPOINTMENTS.MODAL.SET_PRINT_INVOICE_DETAILS:
      return {
        ...state,
        printInvoiceModal: {
          isOpen: state.printInvoiceModal.isOpen,
          printInvoiceDetails: payload.payload,
        },
      };

    case APPOINTMENTS.MODAL.SET_CANCEL_APPOINTMENT_DETAILS:
      return {
        ...state,
        cancelAppointmentModal: {
          isOpen: state.cancelAppointmentModal.isOpen,
          appointmentDetails: payload.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.EDIT_APPOINTMENT:
      return {
        ...state,
        editAppointmentModal: {
          isOpen: state.editAppointmentModal.isOpen,
          appointmentDetails: payload.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.NO_SHOW_TOGGLE:
      return {
        ...state,
        markAsNoShowModal: {
          isOpen: payload.isOpened,
          appointmentDetails: state.markAsNoShowModal.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.CONFIRMATION:
      return {
        ...state,
        confirmationModal: {
          isOpen: payload.isOpened,
        },
      };

    case APPOINTMENTS.MODAL.SET_NO_SHOW_APPOINTMENT_DETAILS:
      return {
        ...state,
        markAsNoShowModal: {
          isOpen: state.markAsNoShowModal.isOpen,
          appointmentDetails: payload.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.INSURED_TOGGLE:
      return {
        ...state,
        markAsInsuredModal: {
          isOpen: payload.isOpened,
          appointmentDetails: state.markAsInsuredModal.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.SET_INSURED_APPOINTMENT_DETAILS:
      return {
        ...state,
        markAsInsuredModal: {
          isOpen: state.markAsInsuredModal.isOpen,
          appointmentDetails: payload.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.ADD_APPOINTMENT_TOGGLE:
      return {
        ...state,
        addAppointmentModal: {
          isOpen: payload.isOpened,
        },
      };

    case APPOINTMENTS.MODAL.ASSIGN_DOCTOR_TO_ROOM:
      return {
        ...state,
        assignDoctorModal: {
          isOpen: payload.isOpened,
          details: payload.payload,
        },
      };

    case APPOINTMENTS.MODAL.SET_PRESCRIPTION_APPOINTMENT_DETAILS:
      return {
        ...state,
        addPrescriptionModal: {
          isOpen: state.addPrescriptionModal.isOpen,
          appointmentDetails: payload.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.ADD_PRESCRIPTION_TOGGLE:
      return {
        ...state,
        addPrescriptionModal: {
          isOpen: payload.isOpened,
          appointmentDetails: state.addPrescriptionModal.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.SET_RESCHEDULE_APPOINTMENT_DETAILS:
      return {
        ...state,
        rescheduleModal: {
          isOpen: state.rescheduleModal.isOpen,
          appointmentDetails: payload.appointmentDetails,
        },
      };

    case APPOINTMENTS.MODAL.RESCHEDULE_TOGGLE:
      return {
        ...state,
        rescheduleModal: {
          isOpen: payload.isOpened,
          appointmentDetails: state.rescheduleModal.appointmentDetails,
        },
      };

    case APPOINTMENTS.SNACK_BAR.HIDE:
      return {
        ...state,
        snackBar: {
          show: false,
          message: '',
        },
      };

    case APPOINTMENTS.ADD_PRESCRIPTION.LOAD:
      return {
        ...state,
        addPrescriptionLoadStatus: status.SUBMITING,
        addPrescription: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
        lastUploadedPrescription: undefined,
      };

    case APPOINTMENTS.ADD_PRESCRIPTION.SUCCESS: {
      const appointmentsList = state.appointmentsList.map(appointment =>
        appointment.ReservationKey === payload.appointmentKey
          ? { ...appointment, IsPrescriptionSubmitted: payload.imagesCount !== 0 }
          : appointment,
      );
      const prescriptionMassage = payload.successMassage;

      return {
        ...state,
        appointmentsList,
        appointments: renderer.success(appointmentsList),
        addPrescriptionLoadStatus: status.SUCCESS,
        addPrescription: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        addPrescriptionModal: { isOpen: false, appointmentDetails: {} },
        snackBar: {
          show: true,
          message: prescriptionMassage,
        },
        lastUploadedPrescription: payload,
      };
    }

    case APPOINTMENTS.ADD_PRESCRIPTION.FAIL:
      return {
        ...state,
        addPrescriptionLoadStatus: status.FAIL,
        addPrescription: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: '' },
        },
        snackBar: {
          show: true,
          message: payload.errorMassage,
        },
        lastUploadedPrescription: undefined,
      };

    case APPOINTMENTS.NATIONAL_ID.LOAD:
      return {
        ...state,
        nationalId: {
          patientNationalId: {},
          status: status.FETCHING,
        },
      };

    case APPOINTMENTS.NATIONAL_ID.SUCCESS:
      return {
        ...state,
        nationalId: {
          patientNationalId: payload.data,
          status: status.SUCCESS,
        },
      };
    case APPOINTMENTS.NATIONAL_ID.FAIL:
      return {
        ...state,
        nationalId: {
          patientNationalId: {},
          status: status.FAIL,
        },
      };
    case APPOINTMENTS.NATIONAL_ID.RESET:
      return {
        ...state,
        nationalId: {
          patientNationalId: {},
          status: status.SHOULD_CALL_API,
        },
      };

    case APPOINTMENTS.INSURANCE_CARD.LOAD:
      return {
        ...state,
        insuranceCard: {
          patientInsuranceCard: {},
          status: status.FETCHING,
        },
      };

    case APPOINTMENTS.INSURANCE_CARD.SUCCESS:
      return {
        ...state,
        insuranceCard: {
          patientInsuranceCard: payload.data,
          status: status.SUCCESS,
        },
      };

    case APPOINTMENTS.INSURANCE_CARD.FAIL:
      return {
        ...state,
        insuranceCard: {
          patientInsuranceCard: {},
          status: status.FAIL,
        },
      };

    case APPOINTMENTS.UPLOAD_NATIONAL_ID.LOAD:
      return {
        ...state,
        uploadNationalIdStatus: status.FETCHING,
      };

    case APPOINTMENTS.UPLOAD_NATIONAL_ID.SUCCESS:
      return {
        ...state,
        uploadNationalIdStatus: status.SUCCESS,
      };

    case APPOINTMENTS.UPLOAD_NATIONAL_ID.FAIL:
      return {
        ...state,
        uploadNationalIdStatus: status.FAIL,
      };

    case APPOINTMENTS.APPROVAL_LETTER.LOAD:
      return {
        ...state,
        approvalLetter: {
          patientApprovalLetter: {},
          status: status.FETCHING,
        },
      };

    case APPOINTMENTS.APPROVAL_LETTER.SUCCESS:
      return {
        ...state,
        approvalLetter: {
          patientApprovalLetter: payload.data,
          status: status.SUCCESS,
        },
      };

    case APPOINTMENTS.APPROVAL_LETTER.FAIL:
      return {
        ...state,
        approvalLetter: {
          patientApprovalLetter: {},
          status: status.FAIL,
        },
      };

    case APPOINTMENTS.APPROVAL_LETTER.RESET:
      return {
        ...state,
        approvalLetter: {
          patientApprovalLetter: {},
          status: status.SHOULD_CALL_API,
        },
      };

    case APPOINTMENTS.UPLOAD_APPROVAL_LETTER.LOAD:
      return {
        ...state,
        uploadApprovalLetterStatus: status.FETCHING,
      };

    case APPOINTMENTS.UPLOAD_APPROVAL_LETTER.SUCCESS:
      return {
        ...state,
        uploadApprovalLetterStatus: status.SUCCESS,
      };

    case APPOINTMENTS.UPLOAD_APPROVAL_LETTER.FAIL:
      return {
        ...state,
        uploadApprovalLetterStatus: status.FAIL,
      };
    case APPOINTMENTS.MODAL.VIEW_SERVICES_TOGGLE: {
      const { isOpen, services, attachments, patientDetails } = payload.payload;
      return {
        ...state,
        viewServicesModal: {
          isOpen,
          services,
          attachments,
          patientDetails,
        },
      };
    }
    case APPOINTMENTS.MODAL.ADD_RESULTS_TOGGLE: {
      const { isOpen, reservationKey, results } = payload.payload;
      return {
        ...state,
        addResultsModal: {
          isOpen,
          reservationKey,
          results,
        },
      };
    }
    case APPOINTMENTS.MODAL.ATTACHMENTS_TOGGLE: {
      return {
        ...state,
        attachmentsModal: {
          ...payload.payload,
        },
      };
    }
    case APPOINTMENTS.MODAL.PHYSICAL_SERVICES_TOGGLE: {
      const { isOpen, servicesDetails, patientDetails } = payload.payload;
      return {
        ...state,
        physicalServicesModal: {
          isOpen,
          servicesDetails,
          patientDetails,
        },
      };
    }
    case APPOINTMENTS.SUBMIT_RESULTS.SUBMIT:
      return {
        ...state,
        submitResults: {
          status: status.SUBMITING,
        },
      };
    case APPOINTMENTS.SUBMIT_RESULTS.SUCCESS:
      return {
        ...state,
        submitResults: {
          status: status.SUCCESS,
        },
        snackBar: {
          show: true,
          message: payload.successMessage,
        },
      };
    case APPOINTMENTS.SUBMIT_RESULTS.FAIL:
      return {
        ...state,
        submitResults: {
          status: status.FAIL,
        },
        snackBar: {
          show: true,
          message: payload.errorMessage,
        },
      };
    case APPOINTMENTS.SUBMIT_RESULTS.RESET:
      return {
        ...state,
        submitResults: {
          status: status.SHOULD_CALL_API,
        },
      };
    case APPOINTMENTS.GET_RESULTS.LOAD:
      return {
        ...state,
        getResults: {
          status: status.FETCHING,
        },
      };
    case APPOINTMENTS.GET_RESULTS.SUCCESS:
      return {
        ...state,
        getResults: {
          uploadedResults: payload.payload.attachments,
          status: status.SUCCESS,
        },
      };
    case APPOINTMENTS.GET_RESULTS.FAIL:
      return {
        ...state,
        getResults: {
          status: status.FAIL,
        },
      };
    case APPOINTMENTS.GET_RESULTS.RESET:
      return {
        ...state,
        getResults: {
          status: status.SHOULD_CALL_API,
        },
      };
    default:
      return state;
  }
};
