import { takeEvery, put, all } from 'redux-saga/effects';
import { API, STATUS, decodeJwtToken, Cookie, decrypt, encrypt } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { generateArrayModelsFromMaps, convertURLtoBase64 } from 'modules/utils/ArrayModels';
import { AuthApi } from 'modules/sso';

import { LANGUAGE, PHOTO_TYPE, STATUS as STATUS_IDS } from '../types/entities';
import { ENTITIES } from '../actions/entities';
import { language } from '../types/language';

// get default insurance companies assigned on a branch
function* getBranchInsurances(payload) {
  const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);

  const branchInsurances = new API();
  const branchInsurancesResponse = yield branchInsurances.get(
    `${Urls.entity.GetBranchInsurances}?branchKey=${payload.branchKey}`,
    [
      {
        key: 'AccountKey',
        value: accountKey,
      },
      {
        key: 'x-vzt-authentication',
        value: accountToken,
      },
    ],
  );

  if (STATUS.isSuccess(branchInsurancesResponse.status)) {
    yield put({
      type: ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.SUCCESS,
      branchInsurances: branchInsurancesResponse.data,
    });
  } else {
    yield put({ type: ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.FAIL });
  }
}
// set updates and changes on insurance companies assigned on a branch
function* setBranchInsurances(payload) {
  // payload.branchKey payload.insurances
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);

  const branchInsurances = new API();
  const branchInsurancesBody = {
    BranchKey: payload.branchKey,
    InsuranceProviderKeys: payload.insurances,
  };
  const branchInsurancesHeaders = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ];
  const branchInsurancesResponse = yield branchInsurances.post(
    `${Urls.entity.UpsertBranchInsurances}`,
    branchInsurancesBody,
    branchInsurancesHeaders,
  );

  if (STATUS.isSuccess(branchInsurancesResponse.status)) {
    yield put({
      type: ENTITIES.BRANCHES.SET_BRANCH_INSURANCES.SUCCESS,
      payload: payload.branchKey,
    });
  } else {
    yield put({ type: ENTITIES.BRANCHES.SET_BRANCH_INSURANCES.FAIL });
  }
}

function* setBranchInsurancesSuccess({ payload }) {
  yield put({
    type: ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.LOAD,
    branchKey: payload,
  });
}

function* resetBranchInsurances() {
  yield put({
    type: ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.RESET_DONE,
  });
}

function* getEntitiesByEntityKeys(payload) {
  const entitiesResponse = yield new AuthApi().post(Urls.profile, {
    query: `query entityQuery ($entityKeys : [String])
        {
          entitiesByKeys(entityKeys:$entityKeys)
          {
            entityKey
            prefixTitleId
            entityPhotos
            {
              url
              photoTypeId
            }
            entityCountryDetails
            {
              name
              languageId
              about
            }
            branches
            {
              branchKey
              branchLanguageDetails
              {
                name
                languageId
              }
            }
          }
        }`,
    variables: {
      entityKeys: payload.entityKeys,
    },
  });

  if (STATUS.isSuccess(entitiesResponse.status)) {
    // Looping through all entities
    const incomingEntities = yield Promise.all(
      entitiesResponse.data.entitiesByKeys.map(async entity => {
        const entityPhotos = [];
        // Looping through all photos
        if (entity.entityPhotos) {
          await Promise.all(
            entity.entityPhotos.map(async photo => {
              // Convert url to base64
              const base64 = await convertURLtoBase64([photo.url]);
              entityPhotos.push({
                base64: base64.pop(),
                ...photo,
              });
            }),
          );
        }

        return {
          ...entity,
          entityPhotos,
        };
      }),
    );

    yield put({
      type: ENTITIES.ENTITIES.FETCH.SUCCEEDED,
      entities: incomingEntities,
    });
  } else {
    yield put({ type: ENTITIES.ENTITIES.FETCH.FAILED, error: entitiesResponse });
  }
}

function* getBranchesByBranchKeys(payload) {
  const { branchKeys, statusIdOnly } = payload;
  let branchQuery;

  if (statusIdOnly) {
    branchQuery = `
      branchKey
      statusId
      branchLanguageDetails
      {
        name    
      }
    `;
  } else {
    branchQuery = `
      branchKey
      latitude
      longitude
      clinicNumberIsoCode
      statusId
      branchAdminMobileNumber
      branchAdminMobileNumberIsoCode
      branchLanguageDetails
      {
        languageId
        name
        floor
        landMark
        streetName
        apartmentNumber
        buildingNumber
      }
      branchPhones
      {
        number
        phoneTypeId
        countryCode
      }
      rooms {
        roomKey
        userRooms {
          statusId 
          user {
            userKey
          }
        }
      }
    `;
  }

  const branchesResponse = yield new AuthApi().post(Urls.profile, {
    query: `query branchesQuery($branchKeys: [String]!)
      {
        branchesByKeys(branchKeys: $branchKeys)
        {
          ${branchQuery}
        }
      }`,
    variables: {
      branchKeys,
    },
  });

  if (STATUS.isSuccess(branchesResponse.status)) {
    yield put({
      type: ENTITIES.BRANCHES.FETCH.SUCCEEDED,
      branches: branchesResponse.data.branchesByKeys,
      isFullyLoaded: !statusIdOnly,
    });
  } else {
    yield put({ type: ENTITIES.BRANCHES.FETCH.FAILED, error: branchesResponse });
  }
}

function* getUsersByKeys(payload) {
  // If no users sent we don't call the APIs as they will fail
  if (payload.users.size === 0) {
    yield put({
      type: ENTITIES.USERS.FETCH.SUCCEEDED,
      profiles: [],
      accounts: [],
      userRooms: [],
    });

    return;
  }

  const accountsKeys = Array.from(payload.users.keys());
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const headers = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ];
  const { accountsResponse, profileResponse } = yield all({
    accountsResponse: new API().post(
      Urls.getAccountsByKeys,
      {
        AccountKeys: accountsKeys,
      },
      headers,
    ),
    profileResponse: new AuthApi().post(Urls.profile, {
      query: `query usersQuery($userKeys: [String]!)
        {
          usersByKeys(userKeys: $userKeys)
          {
            userKey
            userSpecialties {
              isMainSpecialty
              specialtyKey
            }
            nationalitiesIds
            userPhotos {
              photoTypeId
              url
            }
            prefixTitleId
            titleId
            genderId
            userCountryDetails {
              firstName
              lastName
              languageId
              about
              professionalTitle
            }
            statusId
          }
        }`,
      variables: {
        userKeys: accountsKeys,
      },
    }),
  });

  if (STATUS.isSuccess(accountsResponse.status) && STATUS.isSuccess(profileResponse.status)) {
    yield put({
      type: ENTITIES.USERS.FETCH.SUCCEEDED,
      profiles: profileResponse.data.usersByKeys,
      accounts: accountsResponse.data,
      userRooms: payload.users,
      branchKey: payload.branchKey,
    });
  } else {
    yield put({ type: ENTITIES.USERS.FETCH.FAILED });
  }
}

function* getUsersBranches(payload) {
  // If no users sent we don't call the APIs as they will fail
  if (payload.users.size === 0) {
    yield put({
      type: ENTITIES.USERS.FETCH.SUCCEEDED,
      users: [],
    });

    return;
  }

  const accountsKeys = payload.users;
  const profileResponse = yield new AuthApi().post(Urls.profile, {
    query: `query usersQuery($userKeys: [String]!)
        {
          usersByKeys(userKeys: $userKeys)
          {
            userKey
            entities {
              branches {
                userBranches {
                  userKey
                  branchKey
                  fees
                  userBranchesInsuranceProviders{
                    insuranceProviderKey
                  }
                }
              }
            }
          }
        }`,
    variables: {
      userKeys: accountsKeys,
    },
  });

  if (STATUS.isSuccess(profileResponse.status)) {
    yield put({
      type: ENTITIES.USERS.FETCH_BRANCHES.SUCCEEDED,
      users: profileResponse.data.usersByKeys,
    });
  } else {
    yield put({ type: ENTITIES.USERS.FETCH_BRANCHES.FAILED });
  }
}

function* upsertEntity(payload) {
  const { entityKey, prefixTitleId, countryId, name, about, photos } = payload.entity;
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);

  const entityCountryDetails = generateArrayModelsFromMaps(
    { name, about, countryId },
    'languageId',
  );
  const entityPhotos = generateArrayModelsFromMaps({ photoBase64: photos }, 'photoTypeId');
  const upsertEntityHeaders = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
    {
      key: 'EntityKey',
      value: entityKey,
    },
  ];

  const { accountsApi, profileApi } = yield all({
    accountsApi: new API().post(
      Urls.entity.createEntity,
      {
        NameEnglish: name.get(LANGUAGE.ENGLISH),
        NameArabic: name.get(LANGUAGE.ARABIC),
        EntityTypeId: 1,
        PrefixTitleId: prefixTitleId,
        EntityKey: entityKey,
        ImageModel: {
          ImageBase64String: photos.get(PHOTO_TYPE.LOGO),
        },
      },
      upsertEntityHeaders,
    ),
    profileApi: new AuthApi().post(Urls.profile, {
      query: `mutation ProfileMutation ($entityInput : EntityInput!)
        {
          upsertEntity(entityInput: $entityInput) {
            entityKey
          }
        }`,
      variables: {
        entityInput: {
          entityKey,
          prefixTitleId,
          entityCountryDetails,
          entityPhotos,
        },
      },
    }),
  });

  if (STATUS.isSuccess(accountsApi.status) && STATUS.isSuccess(profileApi.status)) {
    yield put({ type: ENTITIES.ENTITIES.EDIT.SUCCEEDED, entity: payload.entity, entityKey });
  } else {
    yield put({ type: ENTITIES.ENTITIES.EDIT.FAILED });
  }
}

function* upsertBranch(payload) {
  const {
    branchKey,
    lat,
    lng,
    isoCode,
    name,
    phones,
    adminPhone,
    buildingNumber,
    zipCode,
    floor,
    landMark,
    apartmentNumber,
    entityKey,
    streetName,
  } = payload.branch;
  const branchLanguageDetails = generateArrayModelsFromMaps(
    { buildingNumber, floor, landMark, apartmentNumber, streetName, name },
    'languageId',
  );

  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);

  const upsertEntityHeaders = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
    {
      key: 'EntityKey',
      value: entityKey,
    },
  ];

  const { accountsApi, profileApi } = yield all({
    accountsApi: new API().post(
      Urls.entity.createEntity,
      {
        NameEnglish: name.get(LANGUAGE.ENGLISH),
        NameArabic: name.get(LANGUAGE.ARABIC),
        EntityTypeId: 2,
        EntityKey: branchKey,
        ImageModel: {
          ImageBase64String: '',
        },
        EntityAddressModel: {
          StreetNameArabic: streetName.get(LANGUAGE.ARABIC),
          StreetNameEnglish: streetName.get(LANGUAGE.ENGLISH),
          BuildingNumberEnglish: buildingNumber.get(LANGUAGE.ENGLISH),
          BuildingNumberArabic: buildingNumber.get(LANGUAGE.ARABIC),
          FloorNumberEnglish: floor.get(LANGUAGE.ENGLISH),
          FloorNumberArabic: floor.get(LANGUAGE.ARABIC),
          Apartment: apartmentNumber.get(LANGUAGE.ENGLISH),
          LabelEnglish: landMark.get(LANGUAGE.ENGLISH),
          LabelArabic: landMark.get(LANGUAGE.ARABIC),
          Latitude: lat,
          Longitude: lng,
        },
        PhoneNumber: phones && phones[0] && phones[0].number,
        MessagesPhoneNumber: adminPhone && adminPhone[0] && adminPhone[0].adminNumber,
      },
      upsertEntityHeaders,
    ),
    profileApi: new AuthApi().post(Urls.profile, {
      query: `mutation ProfileMutation($branchInput : BranchInput!)
      {
        upsertBranch(branchInput: $branchInput){
          branchKey
          branchLanguageDetails
          {
            name
          }
        }
      }`,
      variables: {
        branchInput: {
          branchKey,
          entityKey,
          latitude: lat,
          longitude: lng,
          branchLanguageDetails,
          clinicNumberIsoCode: isoCode,
          branchPhones: phones,
          statusId: STATUS_IDS.ACTIVE,
          branchAdminMobileNumber: adminPhone && adminPhone[0] && adminPhone[0].adminNumber,
          branchAdminMobileNumberIsoCode: isoCode,
          zipCode,
        },
      },
    }),
  });

  if (STATUS.isSuccess(profileApi.status) && STATUS.isSuccess(accountsApi.status)) {
    yield put({
      type: ENTITIES.BRANCHES.EDIT.SUCCEEDED,
      branch: payload.branch,
      branchKey,
      entityKey,
    });
  } else {
    yield put({ type: ENTITIES.BRANCHES.EDIT.FAILED });
  }
}

function* upsertUser(payload) {
  const { userDetails, entitiesKeys } = payload;
  const {
    key,
    gender,
    prefixTitle,
    firstName,
    lastName,
    NationalitiesIds,
    professionalTitle,
    about,
    photos,
    countryId,
    title,
    subSpecialties,
    mainSpecialty,
    branches,
    number,
    countryCodeId,
    email,
    specialtyGroupId,
    statusId,
    symptoms,
  } = userDetails;

  // Check if there AccountKey saved in the cookies
  let userKey = Cookie.get(Cookie.ACCOUNT_KEY);
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  let accountsUrl;
  const accountsHeader = [];

  // We first check is the account was created in Accounts API or not
  // If it's already created we call EditAccount, and pass AccountKey in the headers
  // If not we call RegisterAccount
  let imgEdited = false;
  accountsHeader.push({
    key: 'x-vzt-authentication',
    value: accountToken,
  });
  let profilePhoto = photos.get(PHOTO_TYPE.PROFILE_PICTURE);
  const isDataUrlRegex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i; // eslint-disable-line
  let isUserEdit = false;
  if (userKey) {
    isUserEdit = true;
    imgEdited = profilePhoto.match(isDataUrlRegex);
    if (key !== undefined && key !== null && !imgEdited) {
      profilePhoto = {};
    } else {
      profilePhoto = {
        ImageBase64String: profilePhoto,
      };
    }
    accountsUrl = Urls.account.editAccount;
  } else {
    profilePhoto = {
      ImageBase64String: profilePhoto,
    };
    accountsUrl = Urls.account.registerAccount;
  }

  const userData = {
    FirstName: firstName.get(LANGUAGE.ENGLISH),
    LastName: lastName.get(LANGUAGE.ENGLISH),
    FirstNameArabic: firstName.get(LANGUAGE.ARABIC),
    LastNameArabic: lastName.get(LANGUAGE.ARABIC),
    PhoneNumber: number,
    EmailAddress: email,
    CountryId: countryId,
    CountryCodeId: countryCodeId,
    SpecialityKey: mainSpecialty,
    ImageModel: profilePhoto,
  };

  const getPayload = () => {
    if (isUserEdit) return { ...userData, AccountKey: decrypt(userKey) };
    return { ...userData, LoginStatus: 'Pending' };
  };

  const accountsResponse = yield new API().post(accountsUrl, getPayload(), accountsHeader);

  if (STATUS.isSuccess(accountsResponse.status)) {
    userKey = accountsResponse.data.AccountKey;

    // add user sypmtoms
    const userSypmtomsResponse = yield new API().put(Urls.updateDoctorSymptoms(userKey), {
      SymptomKeys: symptoms,
    });
    if (!STATUS.isSuccess(userSypmtomsResponse.status)) {
      yield put({
        type: ENTITIES.USERS.ADD.FAILED,
        error: 'unable to add symptoms',
        isConflict: false,
      });
      return;
    }
    // Save AccountKey in Cookies to know if the account has been generated before or not
    Cookie.set(Cookie.ACCOUNT_KEY, encrypt(userKey));

    // Create userCountryDetails array
    const userCountryDetails = generateArrayModelsFromMaps(
      {
        firstName,
        lastName,
        professionalTitle,
        about,
        countryId,
      },
      'languageId',
    );

    // Create userPhotos array
    const userPhotos = generateArrayModelsFromMaps(
      { photoBase64: photos, statusId },
      'photoTypeId',
    );

    // Create userSpecialties array
    let userSpecialties = subSpecialties.map(subSpecialty => ({
      specialtyKey: subSpecialty,
      statusId,
      isMainSpecialty: false,
    }));

    userSpecialties = [
      ...userSpecialties,
      {
        specialityGroupId: specialtyGroupId,
        specialtyKey: mainSpecialty,
        statusId,
        isMainSpecialty: true,
      },
    ];
    // Create userBranchInput
    const userBranchInput = branches.map(branch => {
      const { branchKey, branchFees, branchInsurances, bookingTypeId } = branch;
      const userBranchesInsuranceProviders = branchInsurances.map(branchInsurance => ({
        insuranceProviderKey: branchInsurance,
      }));

      return {
        userKey,
        branchKey,
        fees: branchFees,
        userBranchesInsuranceProviders,
        isOutsourced: false,
        isGhostProfile: false,
        acceptPromoCodes: false,
        supportWaitingList: false,
        bookingTypeId,
      };
    });

    const profileResponse = yield all({
      profileResponse: new AuthApi().post(Urls.profile, {
        query: `mutation ProfileMutation($userInput: UserInput!, $userBranchInput: [UserBranchInput])
          {
            upsertUser(userInput: $userInput, userBranchInput: $userBranchInput) {
              userKey
            }
          }
        `,
        variables: {
          userInput: {
            userKey,
            nationalitiesIds: NationalitiesIds,
            genderId: gender,
            titleId: title,
            prefixTitleId: prefixTitle,
            senderName: 'Vezeeta.com',
            statusId,
            entityKeys: entitiesKeys,
            userCountryDetails,
            userSpecialties,
            userPhotos,
          },
          userBranchInput,
        },
      }),
    });

    if (STATUS.isSuccess(profileResponse.profileResponse.status)) {
      // Remove AccountKey cookie since we don't need it anymore
      Cookie.remove(Cookie.ACCOUNT_KEY);

      yield put({
        type: ENTITIES.USERS.ADD.SUCCEEDED,
        user: userDetails,
        userKey,
        isUserEdit,
      });
    } else {
      yield put({
        type: ENTITIES.USERS.ADD.FAILED,
        error: accountsResponse.response.Message,
        isConflict: false,
      });
    }
  } else {
    yield put({
      type: ENTITIES.USERS.ADD.FAILED,
      error: accountsResponse.response.Message,
      isConflict: STATUS.isConflict(accountsResponse.status),
    });
  }
}

function* getHomeVisitsDrStats(payload) {
  const homeVisitsDrStats = new API();
  const homeVisitsDrStatsResponse = yield homeVisitsDrStats.get(
    `${Urls.homeVisits.getDoctorStats}?SupplierAccountKey=${payload.SupplierKey}&SupplierBranchKey=${payload.BranchKey}&CountryId=${payload.CountryId}&FilterStatsByDate=${payload.FilterStatsByDate}`,
  );

  if (STATUS.isSuccess(homeVisitsDrStatsResponse.status)) {
    yield put({
      type: ENTITIES.HOME_VISITS_DR_STATS.SUCCEEDED,
      data: homeVisitsDrStatsResponse.data,
    });
  } else if (homeVisitsDrStatsResponse.response.errorMessage === 'supplier is not found') {
    yield put({
      type: ENTITIES.HOME_VISITS_DR_STATS.FAILED,
      errorMessage:
        language === 'en'
          ? 'The doctor needs to switch on/off home visits at least once'
          : 'يجب على الدكتور تشغيل خدمة الزيارات المنزلية مرة على الأقل',
    });
  } else {
    yield put({
      type: ENTITIES.HOME_VISITS_DR_STATS.FAILED,
      errorMessage:
        language === 'en'
          ? 'An error has occurred, please retry again'
          : '!حدث خطأ، يُرجى إعادة المحاولة',
    });
  }
}

function* loadHomeVisitsDrLiveStatus(payload) {
  const homeVisitsDrStats = new API();
  const homeVisitsDrStatsResponse = yield homeVisitsDrStats.post(
    Urls.homeVisits.getDoctorsLiveStatus,
    payload.accKeys,
  );
  if (STATUS.isSuccess(homeVisitsDrStatsResponse.status)) {
    yield put({
      type: ENTITIES.HOME_VISITS_DR_LIVE_STATUS.SUCCEEDED,
      data: homeVisitsDrStatsResponse.data,
    });
  } else {
    yield put({ type: ENTITIES.HOME_VISITS_DR_LIVE_STATUS.FAILED });
  }
}

export default function* entities() {
  yield takeEvery(ENTITIES.ENTITIES.FETCH.LOADING, getEntitiesByEntityKeys);
  yield takeEvery(ENTITIES.BRANCHES.FETCH.LOADING, getBranchesByBranchKeys);
  yield takeEvery(ENTITIES.USERS.FETCH.LOADING, getUsersByKeys);
  yield takeEvery(ENTITIES.USERS.FETCH_BRANCHES.LOADING, getUsersBranches);
  yield takeEvery(ENTITIES.ENTITIES.EDIT.LOADING, upsertEntity);
  yield takeEvery(ENTITIES.BRANCHES.EDIT.LOADING, upsertBranch);
  yield takeEvery(ENTITIES.USERS.ADD.LOADING, upsertUser);
  yield takeEvery(ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.LOAD, getBranchInsurances);
  yield takeEvery(ENTITIES.BRANCHES.SET_BRANCH_INSURANCES.LOAD, setBranchInsurances);
  yield takeEvery(ENTITIES.BRANCHES.SET_BRANCH_INSURANCES.SUCCESS, setBranchInsurancesSuccess);
  yield takeEvery(ENTITIES.BRANCHES.FETCH_BRANCH_INSURANCES.RESET, resetBranchInsurances);
  yield takeEvery(ENTITIES.HOME_VISITS_DR_STATS.LOADING, getHomeVisitsDrStats);
  yield takeEvery(ENTITIES.HOME_VISITS_DR_LIVE_STATUS.LOADING, loadHomeVisitsDrLiveStatus);
}
