export const PAYMENT = {
  GET_PAYMENT_DETAILS: {
    LOAD: 'GET_PAYMENT_DETAILS_LOAD',
    SUCCEEDED: 'GET_PAYMENT_DETAILS_SUCCEEDED',
    FAILED: 'GET_PAYMENT_DETAILS_FAILED',
  },
  GET_CREDIT_CARDS: {
    LOAD: 'GET_CREDIT_CARDS_LOAD',
    SUCCEEDED: 'GET_CREDIT_CARDS_SUCCEEDED',
    FAILED: 'GET_CREDIT_CARDS_FAILED',
  },
  PAYMENT_METHODS: {
    LOADING: 'PAYMENT_METHODS_LOADING',
    SUCCEEDED: 'PAYMENT_METHODS_SUCCEEDED',
    FAILED: 'PAYMENT_METHODS_FAILED',
  },
  ADD_PAYMENT_TRANSACTION: {
    SUBMIT: 'ADD_PAYMENT_TRANSACTION_SUBMIT',
    SUCCEEDED: 'ADD_PAYMENT_TRANSACTION_SUCCEEDED',
    FAILED: 'ADD_PAYMENT_TRANSACTION_FAILED',
  },
  UPDATE_PAYMENT_METHOD: {
    SUBMIT: 'UPDATE_PAYMENT_METHOD_SUBMIT',
    SUCCEEDED: 'UPDATE_PAYMENT_METHOD_SUCCEEDED',
    FAILED: 'UPDATE_PAYMENT_METHOD_FAILED',
  },
  SET_PAYMENT_METHOD: {
    SUBMIT: 'SET_PAYMENT_METHOD_SUBMIT',
    SUCCEEDED: 'SET_PAYMENT_METHOD_SUCCEEDED',
    FAILED: 'SET_PAYMENT_METHOD_FAILED',
  },
};

export const STATUS = {
  PAID: 'statbe4b4c59fff9c5fa',
  PENDING: 'stat722f84ea2e350dc3',
  FAILED: 'stat25bc80da0991c35a',
  ACTIVE: 'stat2cf2df18deb30695',
  IN_ACTIVE: 'statb4bef99b309987d6',
  VOID: 'statec44eac715706d0b',
};

export const TRANSACTION_INFO = {
  SET: 'SET_TRANSACTION_INFO',
};

export const VALID_TRANSACTION = {
  LOADING: 'VALID_TRANSACTION_LOADING',
  SUCCEEDED: 'VALID_TRANSACTION_SUCCEEDED',
  FAILED: 'VALID_TRANSACTION_FAILED',
};

export const setPaymentMethod = payload => ({
  type: PAYMENT.SET_PAYMENT_METHOD.SUBMIT,
  payload,
});

export const setPaymentMethodSucceeded = payload => ({
  type: PAYMENT.SET_PAYMENT_METHOD.SUCCEEDED,
  payload,
});

export const setPaymentMethodFailed = payload => ({
  type: PAYMENT.SET_PAYMENT_METHOD.FAILED,
  payload,
});

export const updatePaymentMethod = payload => ({
  type: PAYMENT.UPDATE_PAYMENT_METHOD.SUBMIT,
  payload,
});

export const updatePaymentMethodSucceeded = payload => ({
  type: PAYMENT.UPDATE_PAYMENT_METHOD.SUCCEEDED,
  payload,
});

export const updatePaymentMethodFailed = payload => ({
  type: PAYMENT.UPDATE_PAYMENT_METHOD.FAILED,
  payload,
});

export const addPaymentTransaction = payload => ({
  type: PAYMENT.ADD_PAYMENT_TRANSACTION.SUBMIT,
  payload,
});

export const addPaymentTransactionSucceeded = payload => ({
  type: PAYMENT.ADD_PAYMENT_TRANSACTION.SUCCEEDED,
  payload,
});

export const addPaymentTransactionFailed = payload => ({
  type: PAYMENT.ADD_PAYMENT_TRANSACTION.FAILED,
  payload,
});

export const loadPaymentMethods = payload => ({
  type: PAYMENT.PAYMENT_METHODS.LOADING,
  payload,
});

export const setTransactionDetails = payload => ({
  type: TRANSACTION_INFO.SET,
  payload,
});

export const getValidTransaction = transactionKey => ({
  type: VALID_TRANSACTION.LOADING,
  transactionKey,
});

export const loadAccountPaymentDetails = accountKey => ({
  type: PAYMENT.GET_PAYMENT_DETAILS.LOAD,
  accountKey,
});

export const loadAccountPaymentDetailsSucceeded = paymentDetails => ({
  type: PAYMENT.GET_PAYMENT_DETAILS.SUCCEEDED,
  paymentDetails,
});

export const loadAccountPaymentDetailsFailed = error => ({
  type: PAYMENT.GET_PAYMENT_DETAILS.FAILED,
  error,
});

export const loadAccountCreditCards = (accountKey, isActive, status) => ({
  type: PAYMENT.GET_CREDIT_CARDS.LOAD,
  accountKey,
  isActive,
  status,
});

export const loadAccountCreditCardsSucceeded = cards => ({
  type: PAYMENT.GET_PAYMENT_DETAILS.SUCCEEDED,
  cards,
});

export const loadAccountCreditCardFailed = error => ({
  type: PAYMENT.GET_PAYMENT_DETAILS.FAILED,
  error,
});
