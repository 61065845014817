import { WIDGETS } from '../actions/widgets';

const initialState = {
  snackbarSettings: {
    message: '',
    show: false,
    color: '',
    backgroundColor: '',
    icon: '',
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case WIDGETS.SHOW_SNACK_BAR:
      return {
        ...state,
        snackbarSettings: {
          message: payload.message,
          show: true,
          color: payload.color,
          backgroundColor: payload.backgroundColor,
          icon: payload.icon,
        },
      };
    case WIDGETS.HIDE_SNACK_BAR:
      return {
        ...state,
        snackbarSettings: {
          message: '',
          show: false,
          color: '',
          backgroundColor: '',
          icon: '',
        },
      };
    default:
      return state;
  }
};
