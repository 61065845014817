import { takeEvery, put } from 'redux-saga/effects';
import { AuthApi } from 'modules/sso';
import { STATUS, API } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  SCHEDULES,
  getVezeetaSchedulesSucceeded,
  getVezeetaSchedulesFailed,
  VEZEETA_SCHEDULE,
} from '../actions/schedules';

function* loadSchedules() {
  const schedulesApi = new AuthApi();
  const schedulesApiResponse = yield schedulesApi.get(Urls.getRamadanSchedule);

  if (STATUS.isSuccess(schedulesApiResponse.status)) {
    yield put({
      type: SCHEDULES.SUCCEEDED,
      schedules: schedulesApiResponse.data,
    });
  } else {
    yield put({
      type: SCHEDULES.FAILED,
      schedules: schedulesApiResponse.data,
    });
  }
}

function* getVezeetaScheduleSaga(action) {
  const vezeetaSchedulesApi = new API();

  const header = [
    {
      key: 'countryid',
      value: `${action.payload.countryId}`,
    },
    {
      key: 'languageid',
      value: `${action.payload.languageId}`,
    },
    {
      key: 'RegionId',
      value: `${action.payload.regionId}`,
    },
  ];

  const response = yield vezeetaSchedulesApi.get(
    `${Urls.getVezeetaSchedule}?RoomKeys=${action.payload.roomKey}&AccountKeys=${action.payload.accountKey}&page=1&StartDate=${action.payload.date}&NumberOfDays=1`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getVezeetaSchedulesSucceeded({
        ...(response.data && response.data[0]),
      }),
    );
  } else {
    yield put(
      getVezeetaSchedulesFailed({
        ...response,
      }),
    );
  }
}

function* schedulesSaga() {
  yield takeEvery(SCHEDULES.LOADING, loadSchedules);
  yield takeEvery(VEZEETA_SCHEDULE.FETCH, getVezeetaScheduleSaga);
}

export default schedulesSaga;
