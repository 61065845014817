import { takeEvery, put } from 'redux-saga/effects';
import { STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { AuthApi } from 'modules/sso';

import { RELATIVE_TYPES } from '../actions/relativeTypes';

export const getUser = state => state.user;

function* getRelativeTypes(actions) {
  const apiObj = new AuthApi();
  const res = yield apiObj.get(
    `${Urls.getPatientRelativeTypes}?languageId=${actions.language === 'en' ? 1 : 2}`,
  );

  if (STATUS.isSuccess(res.status)) {
    yield put({ type: RELATIVE_TYPES.SUCCESS, relativeTypes: res.data });
  } else {
    yield put({ type: RELATIVE_TYPES.FAIL });
  }
}

export default function* relativeTypesSaga() {
  yield takeEvery(RELATIVE_TYPES.LOAD, getRelativeTypes);
}
