import { getItemName } from 'modules/utils/ComboBox';
import { INSURANCES, ENTITY_INSURANCES } from '../actions/insurances';
import { status } from '../enum';

const initialState = {
  insurances: [],
  insurancesNames: new Map(),
  isLoading: false,
  isSuccess: false,
  entityInsurances: undefined,
  entityInsurancesLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case INSURANCES.LOAD:
      return { ...state, isLoading: true, status: 'LOADING' };

    case INSURANCES.SUCCESS: {
      const insurancesNames = new Map();
      payload.insuranceProviders.forEach(insuranceProvider => {
        insurancesNames.set(
          insuranceProvider.InsuranceKey,
          getItemName(insuranceProvider, 'LanguageItemModels'),
        );
      });

      return {
        insurances: payload.insuranceProviders,
        isLoading: false,
        isSuccess: true,
        insurancesNames,
        status: 'SUCCESS',
      };
    }

    case INSURANCES.FAIL: {
      return {
        insurances: state.insurances,
        isLoading: false,
        isSuccess: false,
        status: 'FAILED',
      };
    }

    case ENTITY_INSURANCES.LOAD:
      return {
        ...state,
        entityInsurancesLoadState: status.FETCHING,
      };

    case ENTITY_INSURANCES.SUCCESS: {
      return {
        ...state,
        entityInsurances: payload.payload && payload.payload.Data,
        entityInsurancesLoadState: status.SUCCESS,
      };
    }

    case ENTITY_INSURANCES.FAIL: {
      return {
        ...state,
        entityInsurances: undefined,
        entityInsurancesLoadState: status.FAIL,
      };
    }

    default:
      return state;
  }
};
