import { RELATIVE_TYPES } from '../actions/relativeTypes';
import { status } from '../enum';

const initialState = {
  relativeTypes: [],
  relativeTypesLoadState: status.SHOULD_CALL_API,
  isLoading: false,
  isSuccess: false,
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case RELATIVE_TYPES.LOAD:
      return {
        ...state,
        relativeTypes: action.relativeTypes,
        relativeTypesLoadState: status.FETCHING,
        isLoading: false,
        isSuccess: true,
        relativeLoaded: false,
        relativeLoading: true,
      };

    case RELATIVE_TYPES.SUCCESS: {
      return {
        ...state,
        relativeTypes: action.relativeTypes,
        relativeTypesLoadState: status.SUCCESS,
        isLoading: false,
        isSuccess: true,
      };
    }

    case RELATIVE_TYPES.FAIL: {
      return {
        ...state,
        relativeTypesLoadState: status.FAIL,
        isLoading: false,
        isSuccess: false,
      };
    }

    default:
      return state;
  }
};
