import React from 'react';
import ReactDOM from 'react-dom';
import { signOut } from 'modules/sso';
import { HttpsRedirection, ErrorBoundary, theme } from '@vezeeta/web-components';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import store from 'modules/store';
import Pages from './pages';
import * as serviceWorker from './serviceWorker';

const logout = () => {
  signOut();
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary
      imageUrl={`${process.env.REACT_APP_CDN_URL}/assets/maintenance.svg`}
      buttonText="Logout"
      buttonIcon="logout"
      callbackAction={logout}
    >
      <HttpsRedirection disableHttps={process.env.REACT_APP_FORCE_HTTPS === 'false'}>
        <Provider store={store}>
          <Pages />
        </Provider>
      </HttpsRedirection>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
