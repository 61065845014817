// eslint-disable-next-line import/prefer-default-export
export const status = {
  SHOULD_CALL_API: 'SHOULD_CALL_API',
  FETCHING: 'FETCHING',
  SUBMITING: 'SUBMITING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const bookingTypesEnum = {
  physical: 'physical',
  telehealth: 'telehealth',
  homevisit: 'homevisit',
  primarycare: 'primarycare',
};

export const subBookingTypesEnum = {
  phone: 'phone',
  video: 'video',
};

export const branchBookingTypes = {
  PHYSCIAL: 1,
  TELEHEALTH: 2,
  HOME_VISIT: 3,
  PRIMARY_CARE: 4,
  PHYSCIAL_LAB: 5,
  PHYSCIAL_SCAN: 6,
};

export const DISCOUNT_TYPES = {
  BY_VALUE: 0,
  BY_PERCENTAGE: 1,
};

export const accountTypes = {
  1: 'Single Dr',
  2: 'Polyclinic',
};

export const APPOINTMENT_TYPES = {
  EXAMINATION: 0,
  CONSULTATIONS: 1,
  SERVICES: 3,
};

export const FIELDS_ERRORS = {
  VALID_FIELDS: 0,
  REQUIRED_FIELDS_ERROR: 1,
  NUMERICAL_FIELDS_ERROR: 2,
  ADD_SERVICE: 3,
};

export const APPROVAL_LETTER_STATUS = {
  NOT_REQUIRED: 0,
  REQUIRED: 1,
  SUBMITTED: 2,
};

export const dateFiltersRanges = {
  TODAY: 0,
  LAST_WEEK: 1,
  LAST_MONTH: 2,
  LAST_3_MONTH: 3,
  CUSTOM: 4,
};

export const CountriesIds = {
  1: 'Egypt',
  2: 'Jordan',
  3: 'Lebanon',
  4: 'Saudi Arabia',
  5: 'Nigeria',
  6: 'Kenya',
  241: 'Global',
};

export const Countries = [
  {
    fieldValue: 'Egypt',
    value: 1,
    searchable: undefined,
    fieldImage: null,
    key: 1,
    children: 'Egypt',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Jordan',
    value: 2,
    searchable: undefined,
    fieldImage: null,
    key: 2,
    children: 'Jordan',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Lebanon',
    value: 3,
    searchable: undefined,
    fieldImage: null,
    key: 3,
    children: 'Lebanon',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Saudi Arabia',
    value: 4,
    searchable: undefined,
    fieldImage: null,
    key: 4,
    children: 'Saudi Arabia',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Nigeria',
    value: 5,
    searchable: undefined,
    fieldImage: null,
    key: 5,
    children: 'Nigeria',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Kenya',
    value: 6,
    searchable: undefined,
    fieldImage: null,
    key: 6,
    children: 'Kenya',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Global',
    value: 241,
    searchable: undefined,
    fieldImage: null,
    key: 241,
    children: 'Global',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
];

export const SortBy = [
  {
    fieldValue: 'Area Bid, Ascending',
    value: 1,
    searchable: undefined,
    fieldImage: null,
    key: 1,
    children: 'Area Bid, Ascending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Area Bid, Descending',
    value: 2,
    searchable: undefined,
    fieldImage: null,
    key: 2,
    children: 'Area Bid, Descending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'City Bid, Ascending',
    value: 3,
    searchable: undefined,
    fieldImage: null,
    key: 3,
    children: 'City Bid, Ascending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'City Bid, Descending',
    value: 4,
    searchable: undefined,
    fieldImage: null,
    key: 4,
    children: 'City Bid, Descending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Created On, Ascending',
    value: 5,
    searchable: undefined,
    fieldImage: null,
    key: 5,
    children: 'Created On, Ascending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Created On, Descending',
    value: 6,
    searchable: undefined,
    fieldImage: null,
    key: 6,
    children: 'Created On, Descending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
];

export const SortByIds = {
  1: {
    sortByValue: 'AreaAmount',
    sortDirection: 'ASC',
  },
  2: {
    sortByValue: 'AreaAmount',
    sortDirection: 'DES',
  },
  3: {
    sortByValue: 'CityAmount',
    sortDirection: 'ASC',
  },
  4: {
    sortByValue: 'CityAmount',
    sortDirection: 'DES',
  },
  5: {
    sortByValue: 'CreateDate',
    sortDirection: 'ASC',
  },
  6: {
    sortByValue: 'CreateDate',
    sortDirection: 'DES',
  },
};

export const countriesISOCodes = {
  1: 'EG',
  2: 'JO',
  3: 'LB',
  4: 'SA',
  5: 'NG',
  6: 'KE',
  241: 'GO',
};

export const CityAreaStatus = [
  {
    fieldValue: 'No bid submitted',
    value: 1,
    searchable: undefined,
    fieldImage: null,
    key: 1,
    children: 'No bid submitted',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Bidding',
    value: 2,
    searchable: undefined,
    fieldImage: null,
    key: 2,
    children: 'Bidding',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Pending',
    value: 3,
    searchable: undefined,
    fieldImage: null,
    key: 3,
    children: 'Pending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Won',
    value: 4,
    searchable: undefined,
    fieldImage: null,
    key: 4,
    children: 'Won',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Lost',
    value: 5,
    searchable: undefined,
    fieldImage: null,
    key: 5,
    children: 'Lost',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Paid',
    value: 6,
    searchable: undefined,
    fieldImage: null,
    key: 6,
    children: 'Paid',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Unpaid',
    value: 7,
    searchable: undefined,
    fieldImage: null,
    key: 7,
    children: 'Unpaid',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Outbidded',
    value: 8,
    searchable: undefined,
    fieldImage: null,
    key: 8,
    children: 'Outbidded',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
];

export const monthNames = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sept.',
  'Oct.',
  'Nov.',
  'Dec.',
];

export const MockedData = [
  {
    campaignIndex: 1,
    date: new Date().toLocaleDateString(),
    entityListingKey: 'ent3232563634566',
    specialtyName: 'Dentistry',
    areaName: 'Nasr City',
    cityName: 'Cairo',
    roomKey: 'room45675432456',
    accountKey: 'acc4456765434',
    branchKey: 'br45656453423546',
    month: 'January',
  },
  {
    campaignIndex: 2,
    date: new Date().toLocaleDateString(),
    entityListingKey: 'ent3232563634566',
    specialtyName: 'Dentistry',
    areaName: 'Nasr City',
    cityName: 'Cairo',
    roomKey: 'room45675432456',
    accountKey: 'acc4456765434',
    branchKey: 'br45656453423546',
    month: 'January',
  },
];

export const TableHeader = [
  'CreatedOn',
  'Doctor Name',
  'Specialty',
  'City',
  'Area',
  'City bid',
  'Area bid',
  'City status',
  'Area status',
];

export const BID_STATUS_IDS = {
  NO_BID_SUBMITTED: 1,
  BIDDING: 2,
  PENDING: 3,
  WON: 4,
  LOST: 5,
  PAID: 6,
  UNPAID: 7,
  OUTBIDDED: 8,
};

export const BidStatus = {
  1: {
    en: 'No bid submitted',
    ar: 'لم يتم تقديم أي عطاء',
    statusId: 1,
  },
  2: {
    en: 'Bidding',
    ar: 'فترة المزايدة',
    statusId: 2,
  },
  3: {
    en: 'Pending',
    ar: 'في انتظار عرض النتائج',
    statusId: 3,
  },
  4: {
    en: 'Won',
    ar: 'فزت',
    statusId: 4,
  },
  5: {
    en: 'Lost',
    ar: 'لم تفز',
    statusId: 5,
  },
  6: {
    en: 'Paid',
    ar: 'تم الدفع',
    statusId: 6,
  },
  7: {
    en: 'Unpaid',
    ar: 'لم يتم الدفع',
    statusId: 7,
  },
  8: {
    en: 'Outbidded',
    ar: 'عرضك أقل',
    statusId: 8,
  },
};

export const prescriptionItemTypesEnum = {
  medicine: 1,
  labTest: 2,
  scan: 3,
};
