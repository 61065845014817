import { status } from 'modules/utils/enum';
import { COUNTRY, COUNTRIES } from '../actions/country';

const initialState = {
  Countries: [],
  AllCountries: [],
  CountriesList: [],
  nationalities: [],
  countriesCodes: new Map(),
  countriesMap: new Map(),
  countriesCodesMap: [],
  countriesDialCodes: new Map(),
  loaded: false,
  loading: false,
  countries: undefined,
  countriesLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case COUNTRIES.COUNTRIES.LOADING:
      return { ...state, countriesLoadState: status.FETCHING, countries: undefined };
    case COUNTRIES.COUNTRIES.SUCCEEDED: {
      return { ...state, countriesLoadState: status.SUCCESS, countries: action.payload };
    }
    case COUNTRIES.COUNTRIES.FAILED:
      return { ...state, countriesLoadState: status.FAIL };

    case COUNTRY.LOAD:
      return Object.assign({}, state, { loaded: false }, { loading: true });
    case COUNTRY.LOAD_DONE: {
      const countriesMap = new Map();
      const Countries = [];
      const nationalities = [];
      action.countries.forEach(country => {
        if (country.IsSupported) {
          Countries.push(country);
          countriesMap.set(country.CountryId, country);
        }
        let nationalityNameEnglish;
        let nationalityNameArabic;
        country.LanguageItemModels.map(model => {
          if (model.LanguageId === 1) {
            nationalityNameEnglish = model.Nationality;
          } else {
            nationalityNameArabic = model.Nationality;
          }
        });
        const countryNationality = {
          CountryId: country.CountryId,
          nationalityNameEnglish,
          nationalityNameArabic,
        };
        nationalities.push(countryNationality);
      });

      return {
        ...state,
        Countries,
        AllCountries: [...action.countries],
        nationalities,
        countriesMap,
        loaded: true,
        loading: false,
      };
    }
    case COUNTRY.LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    case COUNTRY.LOAD_LIST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case COUNTRY.LOAD_LIST_DONE: {
      const countriesCodes = new Map();
      const countriesCodesMap = new Map();
      const countriesDialCodes = new Map();

      action.countries.forEach(country => {
        countriesCodes.set(country.IsoCode, country.DialCode);
        countriesCodesMap.set(country.IsoCode, country);
        countriesDialCodes.set(country.DialCode, country.IsoCode);
      });

      return {
        ...state,
        CountriesList: [...action.countries],
        countriesCodes,
        countriesCodesMap,
        countriesDialCodes,
        loaded: true,
        loading: false,
      };
    }
    case COUNTRY.LOAD_LIST_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
};
