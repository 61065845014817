import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  CLINICS,
  loadClinicsDone,
  loadClinicsFail,
  BRANCH_DETAILS,
  getBranchDetailsSuccess,
  getBranchDetailsFail,
} from '../actions/clinics';

/**
 * Loads clinics from getAccountStructure API
 */
function* loadClinics(actions) {
  try {
    const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
    const apiObj = new API();
    const clinicsRes = yield apiObj.get(
      `${Urls.getAccountStructure}?languageId=${actions.language === 'en' ? 1 : 2}`,
      [
        {
          key: 'x-vzt-authentication',
          value: accountToken,
        },
      ],
    );
    if (STATUS.isSuccess(clinicsRes.status)) {
      yield put(loadClinicsDone(clinicsRes));
    } else {
      yield put(loadClinicsFail('Failed to load clinics'));
    }
  } catch (err) {
    yield put(loadClinicsFail('Failed to load clinics'));
  }
}

function* getBranchDetails(actions) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const apiObj = new API();
  const response = yield apiObj.get(`${Urls.getBranchDetails}/${actions.branchKey}`, [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ]);
  if (STATUS.isSuccess(response.status)) {
    yield put(getBranchDetailsSuccess(response));
  } else {
    yield put(getBranchDetailsFail(response));
  }
}

function* clinicsSaga() {
  yield takeEvery(CLINICS.LOAD, loadClinics);
  yield takeEvery(BRANCH_DETAILS.LOAD, getBranchDetails);
}

export default clinicsSaga;
