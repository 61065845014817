export const CITIES_AREAS = {
  CITIES: {
    LOAD: 'CITIES_LOAD',
    LOAD_DONE: 'CITIES_LOAD_DONE',
    LOAD_FAIL: 'CITIES_LOAD_FAIL',
  },
  AREAS: {
    LOAD: 'AREAS_LOAD',
    LOAD_DONE: 'AREAS_LOAD_DONE',
    LOAD_FAIL: 'AREAS_LOAD_FAIL',
  },
  BRANCHES_AREAS: {
    LOAD: 'BRANCHES_AREAS_LOAD',
    LOAD_DONE: 'BRANCHES_AREAS_LOAD_DONE',
    LOAD_FAIL: 'BRANCHES_AREAS_LOAD_FAIL',
  },
};

export const loadCities = () => ({
  type: CITIES_AREAS.CITIES.LOAD,
});

export const loadAreas = () => ({
  type: CITIES_AREAS.AREAS.LOAD,
});

export const loadBranchesAreas = branchesKeys => ({
  type: CITIES_AREAS.BRANCHES_AREAS.LOAD,
  branchesKeys,
});
