import { SERVICES } from '../actions/services';
import { language } from '../types/language';
import { status } from '../enum';

const initialState = {
  healthGroups: [],
  services: [],
  subServices: [],
  offers: [],
  units: [],
  redeem: {
    patient: {},
    state: undefined,
  },
  lastAddedOffer: null,
  selectedOffer: null,
  snackBar: {
    show: false,
    message: '',
  },
  /** Services Module */
  serviceDetailsModal: { serviceDetails: {}, isOpen: false },
  branchDoctorsModal: { branchDoctorsDetails: {}, isOpen: false },
  servicesListPage: undefined,
  submitServiceLoadState: status.SHOULD_CALL_API,
  servicesListPageLoadState: status.SHOULD_CALL_API,
  servicesListV2Page: undefined,
  servicesListV2PageLoadState: status.SHOULD_CALL_API,
  servicesCategoriesLoadState: status.SHOULD_CALL_API,
  servicesCategories: undefined,
  servicePriceLoadState: status.SHOULD_CALL_API,
  servicePrice: undefined,
  searchedServices: undefined,
  searchedServicesLoadState: status.SHOULD_CALL_API,
  addServiceLoadState: status.SHOULD_CALL_API,
  addServiceLoadMessage: undefined,
  editServiceLoadState: status.SHOULD_CALL_API,
  editServiceLoadMessage: undefined,
  serviceDetailsLoadState: status.SHOULD_CALL_API,
  serviceDetails: undefined,
  entitySpecialtyServicesLoadState: status.SHOULD_CALL_API,
  entitySpecialtyServices: undefined,
  deleteServiceLoadState: status.SHOULD_CALL_API,
  addBulkServices: undefined,
  addBulkServicesLoadState: status.SHOULD_CALL_API,
  serviceDiscount: undefined,
  serviceDiscountLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SERVICES.HEALTH_GROUPS.LOAD:
      return {
        ...state,
        isHealthGroupsLoaded: false,
        isHealthGroupsLoading: true,
      };

    case SERVICES.HEALTH_GROUPS.LOAD_DONE: {
      const { healthGroups } = payload;
      return {
        ...state,
        healthGroups,
        isHealthGroupsLoaded: true,
        isHealthGroupsLoading: false,
      };
    }

    case SERVICES.SERVICES.LOAD:
      return {
        ...state,
        isServicesLoaded: false,
        isServicesLoading: true,
      };

    case SERVICES.SERVICES.LOAD_DONE: {
      const { services } = payload;
      return {
        ...state,
        services,
        isServicesLoaded: true,
        isServicesLoading: false,
      };
    }

    case SERVICES.SUB_SERVICES.LOAD:
      return {
        ...state,
        isSubServicesLoaded: false,
        isSubServicesLoading: true,
      };

    case SERVICES.SUB_SERVICES.LOAD_DONE: {
      const { subServices } = payload;
      return {
        ...state,
        subServices,
        isSubServicesLoaded: true,
        isSubServicesLoading: false,
      };
    }

    case SERVICES.SUB_SERVICES.RESET: {
      return {
        ...state,
        subServices: [],
      };
    }

    case SERVICES.OFFER.LOAD_ALL:
      return {
        ...state,
        isOffersLoaded: false,
        isOffersLoading: true,
      };

    case SERVICES.OFFER.LOAD_ALL_DONE: {
      const { offers } = payload;
      return {
        ...state,
        offers,
        isOffersLoaded: true,
        isOffersLoading: false,
        snackBar: {
          show: true,
          message: language === 'en' ? 'Offer added successfully' : 'تم إضافة العرض بنجاح',
        },
      };
    }

    case SERVICES.OFFER.ADD:
      return {
        ...state,
        isOfferAdded: false,
        isOfferAdding: true,
      };

    case SERVICES.OFFER.ADD_DONE: {
      const { offer } = payload;
      return {
        ...state,
        lastAddedOffer: offer,
        isOfferAdded: true,
        isOfferAdding: false,
        snackBar: {
          show: true,
          message: language === 'en' ? 'Offer added successfully' : 'تم إضافة العرض بنجاح',
        },
      };
    }

    case SERVICES.OFFER.ADD_FAIL: {
      const { err } = payload;
      return {
        ...state,
        err,
        isOfferAdded: false,
        isOfferAdding: false,
        snackBar: {
          show: true,
          message: language === 'en' ? 'Invalid data' : 'البيانات غير صحيحة',
        },
      };
    }

    case SERVICES.OFFER.EDIT:
      return {
        ...state,
        isOfferEdited: false,
        isOfferEditing: true,
      };

    case SERVICES.OFFER.EDIT_DONE: {
      return {
        ...state,
        isOfferEdited: true,
        isOfferEditing: false,
        snackBar: {
          show: true,
          message: language === 'en' ? 'Offer edited successfully' : 'تم تعديل العرض بنجاح',
        },
      };
    }

    case SERVICES.OFFER.EDIT_FAIL: {
      const { err } = payload;
      return {
        ...state,
        err,
        isOfferEdited: false,
        isOfferEditing: false,
        snackBar: {
          show: true,
          message: language === 'en' ? 'Invalid data' : 'البيانات غير صحيحة',
        },
      };
    }

    case SERVICES.OFFER.SELECT: {
      const { offer } = payload;
      return {
        ...state,
        selectedOffer: offer,
      };
    }

    case SERVICES.OFFER.RESET: {
      return {
        ...state,
        selectedOffer: null,
        services: [],
        subServices: [],
        units: [],
      };
    }

    case SERVICES.CODE.REDEEM:
      return {
        ...state,
        redeem: {
          patient: {},
          state: SERVICES.CODE.REDEEM,
        },
        isCodeRedeeming: true,
        isCodeRedeemed: false,
      };

    case SERVICES.CODE.REDEEM_DONE: {
      return {
        ...state,
        redeem: {
          patient: payload.redeemed,
          state: SERVICES.CODE.REDEEM_DONE,
        },
        isCodeRedeeming: false,
        isCodeRedeemed: true,
      };
    }

    case SERVICES.CODE.REDEEM_FAIL: {
      return {
        ...state,
        redeem: {
          patient: {},
          state: SERVICES.CODE.REDEEM_FAIL,
        },
        isCodeRedeeming: false,
        isCodeRedeemed: false,
      };
    }

    case SERVICES.CODE.RESET_REDEEM: {
      return {
        ...state,
        redeem: {
          patient: {},
          state: SERVICES.CODE.RESET_REDEEM,
        },
      };
    }

    case SERVICES.UNITS.LOAD:
      return {
        ...state,
        isUnitsLoaded: false,
        isUnitsLoading: true,
      };

    case SERVICES.UNITS.LOAD_DONE: {
      const { units } = payload;
      return {
        ...state,
        units,
        isUnitsLoaded: true,
        isUnitsLoading: false,
      };
    }

    case SERVICES.SNACK_BAR.HIDE:
      return {
        ...state,
        snackBar: {
          show: false,
          message: '',
        },
      };
    /** Services Module */
    case SERVICES.MODAL.SERVICE_DETAILS_TOGGLE:
      return {
        ...state,
        serviceDetailsModal: { isOpen: payload.isOpened },
      };

    case SERVICES.MODAL.BRANCH_DOCTORS_TOGGLE:
      return {
        ...state,
        branchDoctorsModal: { isOpen: payload.isOpened },
      };

    case SERVICES.SET_SERVICE_DETAILS:
      return {
        ...state,
        serviceDetailsModal: {
          ...state.serviceDetailsModal,
          serviceDetails: payload.serviceDetails,
        },
      };

    case SERVICES.SERVICES_LIST.FETCH:
      return { ...state, servicesListPageLoadState: status.FETCHING, servicesListPage: undefined };
    case SERVICES.SERVICES_LIST.SUCCESS: {
      return {
        ...state,
        servicesListPageLoadState: status.SUCCESS,
        servicesListPage: payload.payload,
      };
    }
    case SERVICES.SERVICES_LIST.FAIL:
      return {
        ...state,
        servicesListPageLoadState: status.FAIL,
        servicesListPage: payload.payload,
      };

    case SERVICES.SERVICES_LIST_V2.FETCH:
      return {
        ...state,
        servicesListV2PageLoadState: status.FETCHING,
        servicesListV2Page: undefined,
      };
    case SERVICES.SERVICES_LIST_V2.SUCCESS: {
      return {
        ...state,
        servicesListV2PageLoadState: status.SUCCESS,
        servicesListV2Page: payload.payload,
      };
    }
    case SERVICES.SERVICES_LIST_V2.FAIL:
      return {
        ...state,
        servicesListV2PageLoadState: status.FAIL,
        servicesListV2Page: payload.payload,
      };

    case SERVICES.SERVICES_LIST_V2.RESET:
      return {
        ...state,
        servicesListV2PageLoadState: status.SHOULD_CALL_API,
        servicesListV2Page: undefined,
      };

    case SERVICES.SERVICES_CATEGORIES.FETCH:
      return {
        ...state,
        servicesCategoriesLoadState: status.FETCHING,
        servicesCategories: undefined,
      };
    case SERVICES.SERVICES_CATEGORIES.SUCCESS: {
      return {
        ...state,
        servicesCategoriesLoadState: status.SUCCESS,
        servicesCategories: payload.payload,
      };
    }
    case SERVICES.SERVICES_CATEGORIES.FAIL:
      return {
        ...state,
        servicesCategoriesLoadState: status.FAIL,
        servicesCategories: payload.payload,
      };

    case SERVICES.SERVICES_PRICE.FETCH:
      return {
        ...state,
        servicePriceLoadState: status.FETCHING,
        servicePrice: undefined,
      };
    case SERVICES.SERVICES_PRICE.SUCCESS: {
      return {
        ...state,
        servicePriceLoadState: status.SUCCESS,
        servicePrice: payload.payload,
      };
    }
    case SERVICES.SERVICES_PRICE.FAIL:
      return {
        ...state,
        servicePriceLoadState: status.FAIL,
        servicePrice: payload.payload,
      };

    case SERVICES.SUBMIT_SERVICE.SUBMIT:
      return {
        ...state,
        submitServiceLoadState: status.SUBMITING,
      };
    case SERVICES.SUBMIT_SERVICE.SUCCESS: {
      return {
        ...state,
        submitServiceLoadState: status.SUCCESS,
      };
    }
    case SERVICES.SUBMIT_SERVICE.FAIL:
      return {
        ...state,
        submitServiceLoadState: status.FAIL,
      };

    case SERVICES.GET_SERVICE_DETAILS.FETCH:
      return { ...state, serviceDetailsLoadState: status.FETCHING, serviceDetails: undefined };
    case SERVICES.GET_SERVICE_DETAILS.SUCCESS: {
      return {
        ...state,
        serviceDetailsLoadState: status.SUCCESS,
        serviceDetails: payload.payload,
      };
    }
    case SERVICES.GET_SERVICE_DETAILS.FAIL:
      return {
        ...state,
        serviceDetailsLoadState: status.FAIL,
        serviceDetails: payload.payload,
      };

    case SERVICES.DELETE_SERVICE.SUBMIT:
      return { ...state, deleteServiceLoadState: status.SUBMITING };
    case SERVICES.DELETE_SERVICE.SUCCESS: {
      return {
        ...state,
        deleteServiceLoadState: status.SUCCESS,
      };
    }
    case SERVICES.DELETE_SERVICE.FAIL:
      return {
        ...state,
        deleteServiceLoadState: status.FAIL,
      };

    case SERVICES.SEARCH.LOAD:
      return { ...state, searchedServicesLoadState: status.FETCHING, searchedServices: undefined };

    case SERVICES.SEARCH.SUCCESS: {
      return {
        ...state,
        searchedServicesLoadState: status.SUCCESS,
        searchedServices: payload.payload,
      };
    }
    case SERVICES.SEARCH.FAIL:
      return {
        ...state,
        searchedServicesLoadState: status.FAIL,
        searchedServices: payload.payload,
      };

    case SERVICES.ADD_SERVICE.LOAD:
      return { ...state, addServiceLoadState: status.SUBMITING };

    case SERVICES.ADD_SERVICE.SUCCESS:
      return {
        ...state,
        addServiceLoadState: status.SUCCESS,
        addServiceLoadMessage: payload.payload,
      };

    case SERVICES.ADD_SERVICE.FAIL:
      return { ...state, addServiceLoadState: status.FAIL, addServiceLoadMessage: payload.payload };

    case SERVICES.EDIT_SERVICE_DETAILS.SUBMIT:
      return { ...state, editServiceLoadState: status.SUBMITING };

    case SERVICES.EDIT_SERVICE_DETAILS.SUCCESS:
      return {
        ...state,
        editServiceLoadState: status.SUCCESS,
        editServiceLoadMessage: payload.payload,
      };

    case SERVICES.EDIT_SERVICE_DETAILS.FAIL:
      return {
        ...state,
        editServiceLoadState: status.FAIL,
        editServiceLoadMessage: payload.payload,
      };

    case SERVICES.GET_ENTITY_SPECIALTY_SERVICE.FETCH:
      return { ...state, entitySpecialtyServicesLoadState: status.FETCHING };

    case SERVICES.GET_ENTITY_SPECIALTY_SERVICE.SUCCESS:
      return {
        ...state,
        entitySpecialtyServicesLoadState: status.SUCCESS,
        entitySpecialtyServices: payload.payload,
      };

    case SERVICES.GET_ENTITY_SPECIALTY_SERVICE.FAIL:
      return {
        ...state,
        entitySpecialtyServicesLoadState: status.FAIL,
        entitySpecialtyServices: payload.payload,
      };
    case SERVICES.ADD_BULK_SERVICES.SUBMIT:
      return {
        ...state,
        addBulkServicesLoadState: status.SUBMITING,
      };
    case SERVICES.ADD_BULK_SERVICES.SUCCESS: {
      return {
        ...state,
        addBulkServicesLoadState: status.SUCCESS,
      };
    }
    case SERVICES.ADD_BULK_SERVICES.FAIL:
      return {
        ...state,
        addBulkServicesLoadState: status.FAIL,
      };

    case SERVICES.CALCULATE_DISCOUNTS.FETCH:
      return { ...state, serviceDiscountLoadState: status.FETCHING };

    case SERVICES.CALCULATE_DISCOUNTS.SUCCESS:
      return {
        ...state,
        serviceDiscountLoadState: status.SUCCESS,
        serviceDiscount: payload.payload,
      };

    case SERVICES.CALCULATE_DISCOUNTS.FAIL:
      return {
        ...state,
        serviceDiscountLoadState: status.FAIL,
        serviceDiscount: payload.payload,
      };

    default:
      return state;
  }
};
