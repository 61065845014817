export const INSURANCES = {
  LOAD: 'INSURANCE_PROVIDERS_LOAD',
  SUCCESS: 'INSURANCE_PROVIDERS_LOAD_SUCCESS',
  FAIL: 'INSURANCE_PROVIDERS_LOAD_FAIL',
};

export const ENTITY_INSURANCES = {
  LOAD: 'ENTITY_INSURANCE_PROVIDERS_LOAD',
  SUCCESS: 'ENTITY_INSURANCES_PROVIDERS_LOAD_SUCCESS',
  FAIL: 'ENTITY_INSURANCES_PROVIDERS_LOAD_FAIL',
};

export const loadInsuranceProviders = countryId => ({
  type: INSURANCES.LOAD,
  countryId,
});

export const loadEnityInsuranceProviders = entityKey => ({
  type: ENTITY_INSURANCES.LOAD,
  entityKey,
});

export const loadEnityInsuranceProvidersSuccess = payload => ({
  type: ENTITY_INSURANCES.SUCCESS,
  payload,
});

export const loadEnityInsuranceProvidersFail = () => ({
  type: ENTITY_INSURANCES.FAIL,
});
