import { SCHEDULES, VEZEETA_SCHEDULE } from '../actions/schedules';
import { status } from '../enum';

const initialState = {
  state: undefined,
  ramadanSchedule: {},
  vezeetaSchedule: undefined,
  vezeetaScheduleLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case VEZEETA_SCHEDULE.FETCH: {
      return {
        ...state,
        vezeetaScheduleLoadState: status.FETCHING,
        vezeetaSchedule: undefined,
      };
    }

    case VEZEETA_SCHEDULE.SUCCEEDED: {
      return {
        ...state,
        vezeetaScheduleLoadState: status.SUCCESS,
        vezeetaSchedule: payload.payload,
      };
    }

    case VEZEETA_SCHEDULE.FAILED: {
      return {
        ...state,
        vezeetaScheduleLoadState: status.FAIL,
        vezeetaSchedule: payload.payload,
      };
    }

    case SCHEDULES.LOADING: {
      return {
        ...state,
        state: SCHEDULES.LOADING,
      };
    }

    case SCHEDULES.SUCCEEDED: {
      return {
        ...state,
        state: SCHEDULES.SUCCEEDED,
        ramadanSchedule: payload.schedules,
      };
    }

    case SCHEDULES.FAILED: {
      return {
        ...state,
        state: SCHEDULES.FAILED,
      };
    }

    default: {
      return state;
    }
  }
};
