export const ASSISTANT = {
  FETCH_ASSISTANTS: {
    LOAD: 'ASSISTANTS_FETCH',
    SUCCESS: 'ASSISTANTS_FETCH_SUCCESS',
    FAIL: 'ASSISTANTS_FETCH_FAIL',
  },
  ASSISTANT_STATE: {
    LOAD: 'ASSISTANT_STATE_LOAD',
    SUCCESS: 'ASSISTANT_STATE_SUCCESS',
    FAIL: 'ASSISTANT_STATE_FAIL',
  },
  ADD_ASSISTANT: {
    LOAD: 'ASSISTANT_ADD_LOAD',
    SUCCESS: 'ASSISTANT_ADD_SUCCESS',
    FAIL: 'ASSISTANT_ADD_FAIL',
  },
  MODAL: {
    TOGGLE_ADD: 'ADD_MODAL',
  },
};

export const getAssistants = entityKey => ({
  type: ASSISTANT.FETCH_ASSISTANTS.LOAD,
  entityKey,
});

export const addAssistant = (entityKey, assistantDetails) => ({
  type: ASSISTANT.ADD_ASSISTANT.LOAD,
  assistantDetails,
  entityKey,
});

export const toggleAssistantState = state => ({
  type: ASSISTANT.ASSISTANT_STATE.LOAD,
  state,
});

export const toggleAddAssistanthModal = showModel => ({
  type: ASSISTANT.MODAL.TOGGLE_ADD,
  showModel,
});
