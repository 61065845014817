// @flow

import { renderer } from '@vezeeta/web-components';

import { AMAN } from '../actions/aman';

const initialState = {
  aman: renderer.loading(),
  amanBranches: [],
  selectedBranchKey: undefined,
  amanSubscriptionModal: {
    isOpen: false,
    isSaving: false,
    isEdit: false,
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case AMAN.FETCH.LOADING:
      return {
        ...state,
        aman: renderer.loading(),
        amanBranches: [],
      };

    case AMAN.FETCH.SUCCEEDED:
      return {
        ...state,
        amanBranches: payload.amanBranches,
        aman: renderer.success(),
      };

    case AMAN.FETCH.FAILED:
      return {
        ...state,
        aman: renderer.failure(),
        amanBranches: [],
      };

    case AMAN.SUBSCIBE.LOADING:
      return {
        ...state,
        aman: renderer.loading(),
        amanSubscriptionModal: {
          isOpen: true,
          isSaving: true,
        },
      };

    case AMAN.SUBSCIBE.SUCCEEDED:
      return {
        ...state,
        aman: renderer.success(),
        amanSubscriptionModal: {
          isOpen: false,
          isSaving: false,
        },
      };

    case AMAN.SUBSCIBE.FAILED:
      return {
        ...state,
        aman: renderer.failure(),
        amanSubscriptionModal: {
          isOpen: true,
          isSaving: false,
        },
      };

    case AMAN.EDIT.LOADING:
      return {
        ...state,
        aman: renderer.loading(),
        amanSubscriptionModal: {
          isOpen: true,
          isSaving: true,
          isEdit: true,
        },
      };

    case AMAN.EDIT.SUCCEEDED:
      return {
        ...state,
        aman: renderer.success(),
        amanSubscriptionModal: {
          isOpen: false,
          isSaving: false,
          isEdit: false,
        },
      };

    case AMAN.EDIT.FAILED:
      return {
        ...state,
        aman: renderer.failure(),
        amanSubscriptionModal: {
          isOpen: true,
          isSaving: false,
          isEdit: true,
        },
      };

    case AMAN.MODAL.SET_BRANCH_KEY:
      return {
        ...state,
        selectedBranchKey: payload.branchKey,
      };

    case AMAN.MODAL.AMAN_SUBSCRIPTION_TOGGLE:
      return {
        ...state,
        amanSubscriptionModal: {
          isOpen: payload.show,
          isEdit: payload.toggleAmanSubscriptionModalToEdit,
        },
      };

    default:
      return state;
  }
};
