// @flow

export const DATA_SOURCE = {
  ELASTIC_SEARCH: 1,
  DATABASE: 2,
};

export const STATUS = {
  PENDING: 1,
  ACTIVE: 2,
  INACTIVE: 3,
  DELETED: 4,
  PUBLISHED: 5,
};

export const PHOTO_TYPE = {
  PROFILE_PICTURE: 1,
  PROFESSIONAL_LICENSE: 2,
  DOCTOR_TITLE_LICENSE: 3,
  LOGO: 4,
  BRANCH_PHOTO: 5,
};

export const LANGUAGE = {
  ENGLISH: 1,
  ARABIC: 2,
};

export const IMAGE_OPERATION = {
  ORIGINAL: 1,
  COMPRESS: 2,
  THUMBNAIL: 3,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
};

export const ENTITY_TYPE = {
  CLINIC: 1,
  POLYCLINIC: 2,
  DOCTOR: 3,
};

export const PHONE_TYPE = {
  MOBILE: 1,
  HOT_LINE: 2,
  FIXED_LINE: 3,
  ALL: 4,
};
