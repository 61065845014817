import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { ROLES } from '../actions/roles';

function* setRoles() {
  const getRoles = new API();
  const roles = yield getRoles.get(Urls.role.getRoles);
  if (STATUS.isSuccess(roles.status)) {
    yield put({
      type: ROLES.SUCCEEDED,
      roles: roles.data,
    });
  } else {
    yield put({
      type: ROLES.FAILED,
      err: roles.data,
    });
  }
}

function* rolesSaga() {
  yield takeEvery(ROLES.LOADING, setRoles);
}

export default rolesSaga;
