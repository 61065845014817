export const CONFIRMATIONS = {
  FETCH: 'CONFIRMATIONS_FETCH',
  FETCH_SUCCESS: 'CONFIRMATIONS_FETCH_SUCCESS',
  SAVE: 'CONFIRMATIONS_SAVE',
  SAVE_SUCCESS: 'CONFIRMATIONS_SAVE_SUCCESS',
  FILTER: {
    SET_SELECTED_DOCTORS: 'CONFIRMATIONS_FILTER_SET_DOCTORS',
  },
  SNACK_BAR: {
    HIDE: 'CONFIRMATIONS_SNACK_BAR_HIDE',
  },
};

export const setSelectedDoctors = (keys, hasDoctors, selectedBranch) => ({
  type: CONFIRMATIONS.FILTER.SET_SELECTED_DOCTORS,
  keys,
  hasDoctors,
  selectedBranch,
});

/**
 * Load confirmation details
 * @param {array} keys, array of clinic keys
 * @param {string} clinicKey, current clinic key
 * @param {string} authorization, api secret
 * @param {string} language, culture
 */
export const fetchConfirmations = keys => ({
  type: CONFIRMATIONS.FETCH,
  keys,
});

/**
 * Save confirmation details
 * @param {array} confirmations, array of confirmations object
 * @param {string} clinicKey, current clinic key
 * @param {string} authorization, api secret
 * @param {string} language, culture
 */
export const saveConfirmations = confirmations => ({
  type: CONFIRMATIONS.SAVE,
  confirmations,
});

export const setSnackBarAsHidden = () => ({
  type: CONFIRMATIONS.SNACK_BAR.HIDE,
});
