export const ACCOUNTS = {
  BASICINFO: {
    LOADING: 'BASICINFO_LOADING',
    SUCCEEDED: 'BASICINFO_SUCCEEDED',
    FAILED: 'BASICINFO_FAILED',
  },
  Add_USER_ACCOUNT: {
    LOADING: 'Add_USER_ACCOUNT_LOADING',
    SUCCEEDED: 'Add_USER_ACCOUNT_SUCCEEDED',
    FAILED: 'Add_USER_ACCOUNT_FAILED',
  },
  EDIT_USER_ACCOUNTS: {
    LOADING: 'EDIT_USER_ACCOUNTS_LOADING',
    SUCCEEDED: 'EDIT_USER_ACCOUNTS_SUCCEEDED',
    FAILED: 'EDIT_USER_ACCOUNTS_FAILED',
  },
  UPDATE_LOGIN_STATUS: {
    LOADING: 'UPDATE_LOGIN_STATUS_LOADING',
    SUCCEEDED: 'UPDATE_LOGIN_STATUS_SUCCEEDED',
    FAILED: 'UPDATE_LOGIN_STATUS_FAILED',
  },
  SEARCH_ACCOUNTS: {
    LOADING: 'SEARCH_ACCOUNTS_LOADING',
    SUCCEEDED: 'SEARCH_ACCOUNTS_SUCCEEDED',
    FAILED: 'SEARCH_ACCOUNTS_FAILED',
  },
  SET_USER_ACCOUNT_INFO: 'SET_USER_ACCOUNT_INFO',
  TOGGLE_FILTER_DROPDOWN: 'TOGGLE_FILTER_DROPDOWN',
  TOGGLE_ADD_USER_MODAL: 'TOGGLE_ADD_USER_MODAL',
  TOGGLE_EDIT_USER_MODAL: 'TOGGLE_EDIT_USER_MODAL',
  TOGGLE_ABOUT_USER_MODAL: 'TOGGLE_ABOUT_USER_MODAL',
};

export const getBasicInfo = getAccountHeader => ({
  type: ACCOUNTS.BASICINFO.LOADING,
  getAccountHeader,
});

export const updateLoginStatus = payload => ({
  type: ACCOUNTS.UPDATE_LOGIN_STATUS.LOADING,
  payload,
});

export const updateLoginStatusSuccess = payload => ({
  type: ACCOUNTS.UPDATE_LOGIN_STATUS.SUCCEEDED,
  payload,
});

export const updateLoginStatusFail = payload => ({
  type: ACCOUNTS.UPDATE_LOGIN_STATUS.FAILED,
  payload,
});

export const addUserAccount = payload => ({
  type: ACCOUNTS.Add_USER_ACCOUNT.LOADING,
  payload,
});

export const addUserAccountSuccess = payload => ({
  type: ACCOUNTS.Add_USER_ACCOUNT.SUCCEEDED,
  payload,
});

export const addUserAccountFail = payload => ({
  type: ACCOUNTS.Add_USER_ACCOUNT.FAILED,
  payload,
});

export const editUserAccount = payload => ({
  type: ACCOUNTS.EDIT_USER_ACCOUNTS.LOADING,
  payload,
});

export const editUserAccountSuccess = payload => ({
  type: ACCOUNTS.EDIT_USER_ACCOUNTS.SUCCEEDED,
  payload,
});

export const editUserAccountFail = payload => ({
  type: ACCOUNTS.EDIT_USER_ACCOUNTS.FAILED,
  payload,
});

export const searchAccounts = payload => ({
  type: ACCOUNTS.SEARCH_ACCOUNTS.LOADING,
  payload,
});

export const searchAccountsSuccess = payload => ({
  type: ACCOUNTS.SEARCH_ACCOUNTS.SUCCEEDED,
  payload,
});

export const searchAccountsFail = payload => ({
  type: ACCOUNTS.SEARCH_ACCOUNTS.FAILED,
  payload,
});

export const setUserAccountInfo = payload => ({
  type: ACCOUNTS.SET_USER_ACCOUNT_INFO,
  payload,
});

export const toggleFilterDropDown = payload => ({
  type: ACCOUNTS.TOGGLE_FILTER_DROPDOWN,
  payload,
});

export const toggleAddUserModal = payload => ({
  type: ACCOUNTS.TOGGLE_ADD_USER_MODAL,
  payload,
});

export const toggleEditUserModal = payload => ({
  type: ACCOUNTS.TOGGLE_EDIT_USER_MODAL,
  payload,
});

export const toggleAboutUserModal = payload => ({
  type: ACCOUNTS.TOGGLE_ABOUT_USER_MODAL,
  payload,
});
