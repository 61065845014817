export const BIDS = {
  FETCH: 'FETCH_BIDS',
  SUCCESS: 'FETCH_SUCCESS_BIDS',
  FAIL: 'FETCH_FAIL_BIDS',
};

export const SNAKE_BAR = {
  HIDE_SNACK_BAR: 'HIDE_SNACK_BAR',
};

export const PLACE_BID = {
  SAVE: 'SAVE_PLACE_BID',
  SUCCESS: 'SAVE_PLACE_BID_SUCCESS',
  FAIL: 'SAVE_PLACE_BID_FAIL',
};

export const TOGGLE_TUTORIAL_MODAL = {
  TOGGLE: 'TOGGLE_TUTORIAL_MODAL',
};

export const BID_INFO = {
  SET: 'SET_BID_INFO',
};

export const fetchBids = payload => ({
  type: BIDS.FETCH,
  payload,
});

export const fetchBidsSuccess = payload => ({
  type: BIDS.SUCCESS,
  payload,
});

export const fetchBidsFail = () => ({
  type: BIDS.FAIL,
});

export const placeBid = payload => ({
  type: PLACE_BID.SAVE,
  payload,
});

export const placeBidSuccess = () => ({
  type: PLACE_BID.SUCCESS,
});

export const placeBidFail = () => ({
  type: PLACE_BID.FAIL,
});

export const setBidInfo = payload => ({
  type: BID_INFO.SET,
  payload,
});

export const toggleTutorialModal = payload => ({
  type: TOGGLE_TUTORIAL_MODAL.TOGGLE,
  payload,
});

export const hideSnakBar = () => ({
  type: SNAKE_BAR.HIDE_SNACK_BAR,
});
