import { COMMON } from '../actions/common';

const initialState = {
  context: undefined,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case COMMON.SET_CONTEXT: {
      return {
        ...state,
        context: payload.context,
      };
    }

    default:
      return state;
  }
};
