import { DEFAULT_ERROR_MESSAGE } from 'modules/utils/Constants';

export const APPOINTMENTS = {
  FETCH_COUNT_RESERVATION: {
    LOAD: 'COUNT_RESERVATION_FETCH',
    SUCCESS: 'COUNT_RESERVATION_SUCCESS',
    FAIL: 'COUNT_RESERVATION_FAIL',
  },
  FETCH: {
    LOAD: 'APPOINTMENTS_FETCH',
    SUCCESS: 'APPOINTMENTS_FETCH_SUCCESS',
    FAIL: 'APPOINTMENTS_FETCH_FAIL',
  },
  FETCH_UPDATES: {
    LOAD: 'APPOINTMENTS_FETCH_UPDATES',
    SUCCESS: 'APPOINTMENTS_FETCH_SUCCESS_UPDATES',
    FAIL: 'APPOINTMENTS_FETCH_FAIL_UPDATES',
  },
  LOAD_APPOINTMENTS_UPDATES: 'LOAD_APPOINTMENTS_UPDATES',
  CLOSE_APPOINTMENTS_NOTIFICATION_BAR: 'CLOSE_APPOINTMENTS_NOTIFICATION_BAR',
  FETCH_MORE: {
    LOAD: 'FETCH_MORE',
    SUCCESS: 'FETCH_MORE_SUCCESS',
    FAIL: 'FETCH_MORE_FAIL',
  },
  CREATE: {
    LOAD: 'APPOINTMENTS_CREATE',
    SUCCESS: 'APPOINTMENTS_CREATE_SUCCESS',
    FAIL: 'APPOINTMENTS_CREATE_FAIL',
  },
  MARK_AS_NO_SHOW: {
    LOAD: 'APPOINTMENTS_MARK_AS_NO_SHOW',
    SUCCESS: 'APPOINTMENTS_MARK_AS_NO_SHOW_SUCCESS',
    FAIL: 'APPOINTMENTS_MARK_AS_NO_SHOW_FAIL',
  },
  MARK_AS_INSURED: {
    LOAD: 'APPOINTMENTS_MARK_AS_INSURED',
    SUCCESS: 'APPOINTMENTS_MARK_AS_INSURED_SUCCESS',
    FAIL: 'APPOINTMENTS_MARK_AS_INSURED_FAIL',
  },
  CHECK_IN: {
    LOAD: 'APPOINTMENTS_CHECK_IN',
    SUCCESS: 'APPOINTMENTS_CHECK_IN_SUCCESS',
    FAIL: 'APPOINTMENTS_CHECK_IN_FAIL',
  },
  COMPLETE: {
    LOAD: 'APPOINTMENTS_COMPLETE',
    SUCCESS: 'APPOINTMENTS_COMPLETE_SUCCESS',
    FAIL: 'APPOINTMENTS_COMPLETE_FAIL',
  },
  CANCEL: {
    LOAD: 'APPOINTMENTS_CANCEL',
    SUCCESS: 'APPOINTMENTS_CANCEL_SUCCESS',
    FAIL: 'APPOINTMENTS_CANCEL_FAIL',
  },
  FILTER: {
    SET_SELECTED_DATES: 'APPOINTMENTS_FILTER_SET_SELECTED_DATES',
    SET_SELECTED_ROOMS_AND_DOCTORS: 'APPOINTMENTS_FILTER_SET_SELECTED_ROOMS_AND_DOCTORS',
    SET_PATIENT_NAME: 'SET_PATIENT_NAME',
  },
  MODAL: {
    CANCEL_TOGGLE: 'APPOINTMENTS_MODAL_CANCEL_TOGGLE',
    SET_CANCEL_APPOINTMENT_DETAILS: 'APPOINTMENTS_MODAL_SET_CANCEL_APPOINTMENT_DETAILS',
    NO_SHOW_TOGGLE: 'APPOINTMENTS_MODAL_NO_SHOW_TOGGLE',
    SET_NO_SHOW_APPOINTMENT_DETAILS: 'APPOINTMENTS_MODAL_SET_NO_SHOW_APPOINTMENT_DETAILS',
    INSURED_TOGGLE: 'APPOINTMENTS_MODAL_INSURED_TOGGLE',
    SET_INSURED_APPOINTMENT_DETAILS: 'APPOINTMENTS_MODAL_SET_INSURED_APPOINTMENT_DETAILS',
    ADD_APPOINTMENT_TOGGLE: 'APPOINTMENTS_MODAL_ADD_APPOINTMENT_TOGGLE',
    ASSIGN_DOCTOR_TO_ROOM: 'ASSIGN_DOCTOR_TO_ROOM',
    SET_PRESCRIPTION_APPOINTMENT_DETAILS: 'SET_PRESCRIPTION_APPOINTMENT_DETAILS',
    SET_RESCHEDULE_APPOINTMENT_DETAILS: 'SET_RESCHEDULE_APPOINTMENT_DETAILS',
    ADD_PRESCRIPTION_TOGGLE: 'ADD_PRESCRIPTION_MODAL_TOGGLE',
    CONFIRMATION: 'CONFIRMATION_MODAL_TOGGLE',
    RESCHEDULE_TOGGLE: 'RESCHEDULE_TOGGLE',
    EDIT_APPOINTMENT: 'EDIT_APPOINTMENT',
    EDIT_APPOINTMENT_TOGGLE: 'EDIT_APPOINTMENT_TOGGLE',
    PRINT_INVOICE_TOGGLE: 'PRINT_INVOICE_TOGGLE',
    SET_PRINT_INVOICE_DETAILS: 'SET_PRINT_INVOICE_DETAILS',
    APPOINTMENT_COMMENT: 'APPOINTMENT_COMMENT',
    VIEW_SERVICES_TOGGLE: 'VIEW_SERVICES_TOGGLE',
    ADD_RESULTS_TOGGLE: 'ADD_RESULTS_TOGGLE',
    ATTACHMENTS_TOGGLE: 'ATTACHMENTS_TOGGLE',
    PHYSICAL_SERVICES_TOGGLE: 'PHYSICAL_SERVICES_TOGGLE',
  },
  SNACK_BAR: {
    HIDE: 'APPOINTMENTS_SNACK_BAR_HIDE',
  },
  ADD_PRESCRIPTION: {
    LOAD: 'ADD_PRESCRIPTION',
    SUCCESS: 'ADD_PRESCRIPTION_SUCCESS',
    FAIL: 'ADD_PRESCRIPTION_FAIL',
  },
  ADD_APPOINTMENT: {
    SUBMIT: 'ADD_APPOINTMENT',
    SUCCESS: 'ADD_APPOINTMENT_SUCCESS',
    FAILED: 'ADD_APPOINTMENT_FAILED',
  },
  EDIT_APPOINTMENT: {
    SUBMIT: 'SUBMIT_EDIT_APPOINTMENT',
    SUCCESS: 'SUBMIT_EDIT_APPOINTMENT_SUCCESS',
    FAILED: 'SUBMIT_EDIT_APPOINTMENT_FAILED',
  },
  NATIONAL_ID: {
    LOAD: 'NATIONAL_ID_FETCH',
    SUCCESS: 'NATIONAL_ID_FETCH_SUCCESS',
    FAIL: 'NATIONAL_ID_FETCH_FAIL',
    RESET: 'NATIONAL_ID_RESET',
  },
  GET_APPOINTMENT_INVOICE: {
    LOAD: 'GET_APPOINTMENT_INVOICE_FETCH',
    SUCCESS: 'GET_APPOINTMENT_INVOICE_SUCCESS',
    FAIL: 'GET_APPOINTMENT_INVOICE_FAIL',
  },
  INSURANCE_CARD: {
    LOAD: 'INSURANCE_CARD_FETCH',
    SUCCESS: 'INSURANCE_CARD_FETCH_SUCCESS',
    FAIL: 'INSURANCE_CARD_FETCH_FAIL',
  },
  UPLOAD_NATIONAL_ID: {
    LOAD: 'UPLOAD_NATIONAL_ID',
    SUCCESS: 'UPLOAD_NATIONAL_ID_SUCCESS',
    FAIL: 'NUPLOAD_NATIONAL_ID_FAIL',
  },
  APPROVAL_LETTER: {
    LOAD: 'APPROVAL_LETTER_FETCH',
    SUCCESS: 'APPROVAL_LETTER_FETCH_SUCCESS',
    FAIL: 'APPROVAL_LETTER_FETCH_FAIL',
    RESET: 'APPROVAL_LETTER_RESET',
  },
  UPLOAD_APPROVAL_LETTER: {
    LOAD: 'UPLOAD_APPROVAL_LETTER',
    SUCCESS: 'UPLOAD_APPROVAL_LETTER_SUCCESS',
    FAIL: 'NUPLOAD_APPROVAL_LETTER_FAIL',
  },
  ADD_VITALS: {
    SUBMIT: 'ADD_VITALS',
    SUCCESS: 'ADD_VITALS_SUCCESS',
    FAIL: 'ADD_VITALS_FAIL',
  },
  UPDATE_VITALS: {
    SUBMIT: 'UPDATE_VITALS',
    SUCCESS: 'UPDATE_VITALS_SUCCESS',
    FAIL: 'UPDATE_VITALS_FAIL',
  },
  SET_APPOINTMENT_DETAILS: 'SET_APPOINTMENT_DETAILS',
  SUBMIT_RESULTS: {
    SUBMIT: 'SUBMIT_RESULTS',
    SUCCESS: 'SUBMIT_RESULTS_SUCCESS',
    FAIL: 'SUBMIT_RESULTS_FAIL',
    RESET: 'SUBMIT_RESULTS_RESET',
  },
  GET_RESULTS: {
    LOAD: 'GET_RESULTS',
    SUCCESS: 'GET_RESULTS_SUCCESS',
    FAIL: 'GET_RESULTS_FAIL',
    RESET: 'GET_RESULTS_RESET',
  },
};

export const setAppointmentsDetails = appointmentDetails => ({
  type: APPOINTMENTS.SET_APPOINTMENT_DETAILS,
  appointmentDetails,
});

export const toggleAppointmentCommentModal = isOpened => ({
  type: APPOINTMENTS.MODAL.APPOINTMENT_COMMENT,
  isOpened,
});

export const getReservationCount = payload => ({
  type: APPOINTMENTS.FETCH_COUNT_RESERVATION.LOAD,
  payload,
});

export const getReservationCountSuccess = payload => ({
  type: APPOINTMENTS.FETCH_COUNT_RESERVATION.SUCCESS,
  payload,
});

export const getReservationCountFailed = payload => ({
  type: APPOINTMENTS.FETCH_COUNT_RESERVATION.FAIL,
  payload,
});

export const getAppointmentInvoice = payload => ({
  type: APPOINTMENTS.GET_APPOINTMENT_INVOICE.LOAD,
  payload,
});

export const getAppointmentInvoiceSuccess = payload => ({
  type: APPOINTMENTS.GET_APPOINTMENT_INVOICE.SUCCESS,
  payload,
});

export const getAppointmentInvoiceFailed = payload => ({
  type: APPOINTMENTS.GET_APPOINTMENT_INVOICE.FAIL,
  payload,
});

export const addAppointment = payload => ({
  type: APPOINTMENTS.ADD_APPOINTMENT.SUBMIT,
  payload,
});

export const addAppointmentSuccess = payload => ({
  type: APPOINTMENTS.ADD_APPOINTMENT.SUCCESS,
  payload,
});

export const addAppointmentFailed = () => ({
  type: APPOINTMENTS.ADD_APPOINTMENT.FAILED,
});

export const submitEditAppointment = payload => ({
  type: APPOINTMENTS.EDIT_APPOINTMENT.SUBMIT,
  payload,
});

export const submitEditAppointmentSuccess = payload => ({
  type: APPOINTMENTS.EDIT_APPOINTMENT.SUCCESS,
  payload,
});

export const submitEditAppointmentFailed = () => ({
  type: APPOINTMENTS.EDIT_APPOINTMENT.FAILED,
});

export const fetchAppointments = (
  roomsNames,
  doctorsNames,
  doctors,
  startDate,
  endDate,
  patientKey,
  pushToCurrent = false,
  pageNumber = 0,
  newAppointment = false,
  pageSize,
  branchKeys,
  statuses,
) => ({
  type: APPOINTMENTS.FETCH.LOAD,
  roomsNames,
  doctorsNames,
  doctors,
  startDate,
  endDate,
  patientKey,
  pushToCurrent,
  pageNumber,
  newAppointment,
  pageSize,
  branchKeys,
  statuses,
});

export const fetchAppointmentsSuccess = (appointments, summary, pushToCurrent) => ({
  type: APPOINTMENTS.FETCH.SUCCESS,
  appointments,
  summary,
  pushToCurrent,
});

export const fetchAppointmentsFail = () => ({
  type: APPOINTMENTS.FETCH.FAIL,
});

export const fetchAppointmentsUpdates = (
  roomsNames,
  doctorsNames,
  doctors,
  startDate,
  endDate,
  patientKey,
  pushToCurrent = false,
  pageNumber = 0,
  newAppointment = false,
  pageSize,
) => ({
  type: APPOINTMENTS.FETCH_UPDATES.LOAD,
  roomsNames,
  doctorsNames,
  doctors,
  startDate,
  endDate,
  patientKey,
  pushToCurrent,
  pageNumber,
  newAppointment,
  pageSize,
});

export const fetchAppointmentsUpdatesSuccess = (appointments, pushToCurrent) => ({
  type: APPOINTMENTS.FETCH_UPDATES.SUCCESS,
  appointments,
  pushToCurrent,
});

export const fetchAppointmentsUpdatesFail = () => ({
  type: APPOINTMENTS.FETCH_UPDATES.FAIL,
});

export const loadAppointmentsUpdates = () => ({
  type: APPOINTMENTS.LOAD_APPOINTMENTS_UPDATES,
});

export const closeAppointmentsNotificationBar = () => ({
  type: APPOINTMENTS.CLOSE_APPOINTMENTS_NOTIFICATION_BAR,
});

export const createAppointment = (
  appointment,
  patient,
  roomsNames,
  doctorsNames,
  callback,
  ...callbackParams
) => ({
  type: APPOINTMENTS.CREATE.LOAD,
  appointment,
  patient,
  roomsNames,
  doctorsNames,
  callback,
  ...callbackParams,
});

export const createAppointmentSuccess = appointment => ({
  type: APPOINTMENTS.CREATE.SUCCESS,
  appointment,
});

export const createAppointmentFail = (error = DEFAULT_ERROR_MESSAGE) => ({
  type: APPOINTMENTS.CREATE.FAIL,
  error,
});

export const markAsNoShow = (appointmentKey, accountKey, roomKey, isNoShow, sendSMS) => ({
  type: APPOINTMENTS.MARK_AS_NO_SHOW.LOAD,
  appointmentKey,
  accountKey,
  roomKey,
  isNoShow,
  sendSMS,
});

export const markAsNoShowSuccess = (appointmentKey, isNoShow) => ({
  type: APPOINTMENTS.MARK_AS_NO_SHOW.SUCCESS,
  appointmentKey,
  isNoShow,
});

export const markAsNoShowFail = appointmentKey => ({
  type: APPOINTMENTS.MARK_AS_NO_SHOW.FAIL,
  appointmentKey,
});

export const markAsInsured = (appointmentKey, accountKey, roomKey, isInsured) => ({
  type: APPOINTMENTS.MARK_AS_INSURED.LOAD,
  appointmentKey,
  accountKey,
  roomKey,
  isInsured,
});

export const markAsInsuredSuccess = (appointmentKey, isInsured) => ({
  type: APPOINTMENTS.MARK_AS_INSURED.SUCCESS,
  appointmentKey,
  isInsured,
});

export const markAsInsuredFail = appointmentKey => ({
  type: APPOINTMENTS.MARK_AS_INSURED.FAIL,
  appointmentKey,
});

export const checkIn = (appointmentKey, accountKey, roomKey, isCheckedIn) => ({
  type: APPOINTMENTS.CHECK_IN.LOAD,
  appointmentKey,
  accountKey,
  roomKey,
  isCheckedIn,
});

export const checkInSuccess = (appointmentKey, isCheckedIn) => ({
  type: APPOINTMENTS.CHECK_IN.SUCCESS,
  appointmentKey,
  isCheckedIn,
});

export const checkInFail = appointmentKey => ({
  type: APPOINTMENTS.CHECK_IN.FAIL,
  appointmentKey,
});

export const complete = (appointmentKey, accountKey, roomKey, isCompelete, reservationDate) => ({
  type: APPOINTMENTS.COMPLETE.LOAD,
  appointmentKey,
  accountKey,
  roomKey,
  isCompelete,
  reservationDate,
});

export const completeSuccess = (appointmentKey, isCompelete) => ({
  type: APPOINTMENTS.COMPLETE.SUCCESS,
  appointmentKey,
  isCompelete,
});

export const completeFail = appointmentKey => ({
  type: APPOINTMENTS.COMPLETE.FAIL,
  appointmentKey,
});

export const cancel = appointmentKey => ({
  type: APPOINTMENTS.CANCEL.LOAD,
  appointmentKey,
});

export const cancelSuccess = appointmentKey => ({
  type: APPOINTMENTS.CANCEL.SUCCESS,
  appointmentKey,
});

export const cancelFail = appointmentKey => ({
  type: APPOINTMENTS.CANCEL.FAIL,
  appointmentKey,
});

export const setSelectedDates = dates => ({
  type: APPOINTMENTS.FILTER.SET_SELECTED_DATES,
  dates,
});

export const setSelectedRoomsAndDoctors = (roomsAndDoctors, hasDoctors) => ({
  type: APPOINTMENTS.FILTER.SET_SELECTED_ROOMS_AND_DOCTORS,
  roomsAndDoctors,
  hasDoctors,
});

export const setSelectedPatientKey = patientKey => ({
  type: APPOINTMENTS.FILTER.SET_PATIENT_NAME,
  patientKey,
});

export const toggleCancelAppointmentModal = isOpened => ({
  type: APPOINTMENTS.MODAL.CANCEL_TOGGLE,
  isOpened,
});

export const toggleEditAppointmentModal = isOpened => ({
  type: APPOINTMENTS.MODAL.EDIT_APPOINTMENT_TOGGLE,
  isOpened,
});

export const togglePrintInvoiceModal = isOpened => ({
  type: APPOINTMENTS.MODAL.PRINT_INVOICE_TOGGLE,
  isOpened,
});

export const setPrintInvoiceDetailsModal = payload => ({
  type: APPOINTMENTS.MODAL.SET_PRINT_INVOICE_DETAILS,
  payload,
});

export const setCancelAppointmentDetailsModal = appointmentDetails => ({
  type: APPOINTMENTS.MODAL.SET_CANCEL_APPOINTMENT_DETAILS,
  appointmentDetails,
});

export const setEditAppointmentDetailsModal = appointmentDetails => ({
  type: APPOINTMENTS.MODAL.EDIT_APPOINTMENT,
  appointmentDetails,
});

export const toggleMarkAsNoShowAppointmentModal = isOpened => ({
  type: APPOINTMENTS.MODAL.NO_SHOW_TOGGLE,
  isOpened,
});

export const setMarkAsNoShowAppointmentDetailsModal = appointmentDetails => ({
  type: APPOINTMENTS.MODAL.SET_NO_SHOW_APPOINTMENT_DETAILS,
  appointmentDetails,
});

export const toggleMarkAsInsuredAppointmentModal = isOpened => ({
  type: APPOINTMENTS.MODAL.INSURED_TOGGLE,
  isOpened,
});

export const setMarkAsInsuredAppointmentDetailsModal = appointmentDetails => ({
  type: APPOINTMENTS.MODAL.SET_INSURED_APPOINTMENT_DETAILS,
  appointmentDetails,
});

export const toggleAddAppointmentModal = isOpened => ({
  type: APPOINTMENTS.MODAL.ADD_APPOINTMENT_TOGGLE,
  isOpened,
});

export const toggleAssignDoctorModal = (isOpened, payload) => ({
  type: APPOINTMENTS.MODAL.ASSIGN_DOCTOR_TO_ROOM,
  isOpened,
  payload,
});

export const setAddPrescriptionDetailsModal = appointmentDetails => ({
  type: APPOINTMENTS.MODAL.SET_PRESCRIPTION_APPOINTMENT_DETAILS,
  appointmentDetails,
});

export const setRescheduleAppointmentDetailsModal = appointmentDetails => ({
  type: APPOINTMENTS.MODAL.SET_RESCHEDULE_APPOINTMENT_DETAILS,
  appointmentDetails,
});

export const toggleAddPrescriptionModal = isOpened => ({
  type: APPOINTMENTS.MODAL.ADD_PRESCRIPTION_TOGGLE,
  isOpened,
});

export const toggleRescheduleModal = isOpened => ({
  type: APPOINTMENTS.MODAL.RESCHEDULE_TOGGLE,
  isOpened,
});

export const setSnackBarAsHidden = () => ({
  type: APPOINTMENTS.SNACK_BAR.HIDE,
});

export const addPrescription = (
  reservationKey,
  prescriptionTypeId,
  prescriptionImages,
  successMassage,
  errorMassage,
  accountKey,
  insuranceKey,
  insuranceCardKey,
) => ({
  type: APPOINTMENTS.ADD_PRESCRIPTION.LOAD,
  reservationKey,
  prescriptionTypeId,
  prescriptionImages,
  successMassage,
  errorMassage,
  accountKey,
  insuranceKey,
  insuranceCardKey,
});

export const addPrescriptionSuccess = (appointmentKey, imagesCount, successMassage) => ({
  type: APPOINTMENTS.ADD_PRESCRIPTION.SUCCESS,
  appointmentKey,
  imagesCount,
  successMassage,
});

export const addPrescriptionFail = errorMassage => ({
  type: APPOINTMENTS.ADD_PRESCRIPTION.FAIL,
  errorMassage,
});

export const toggleConfirmationModal = isOpened => ({
  type: APPOINTMENTS.MODAL.CONFIRMATION,
  isOpened,
});

export const fetchNationalId = reservationKey => ({
  type: APPOINTMENTS.NATIONAL_ID.LOAD,
  reservationKey,
});

export const fetchNationalIdSuccess = data => ({
  type: APPOINTMENTS.NATIONAL_ID.SUCCESS,
  data,
});

export const fetchNationalIdFail = errorMassage => ({
  type: APPOINTMENTS.NATIONAL_ID.FAIL,
  errorMassage,
});

export const resetNationalId = () => ({
  type: APPOINTMENTS.NATIONAL_ID.RESET,
});

export const fetchPatientInsuranceCard = cardKey => ({
  type: APPOINTMENTS.INSURANCE_CARD.LOAD,
  cardKey,
});

export const fetchPatientInsuranceCardSuccess = data => ({
  type: APPOINTMENTS.INSURANCE_CARD.SUCCESS,
  data,
});

export const fetchPatientInsuranceCardFail = errorMassage => ({
  type: APPOINTMENTS.INSURANCE_CARD.FAIL,
  errorMassage,
});

export const uploadNationalId = data => ({
  type: APPOINTMENTS.UPLOAD_NATIONAL_ID.LOAD,
  data,
});

export const uploadNationalIdSuccess = data => ({
  type: APPOINTMENTS.UPLOAD_NATIONAL_ID.SUCCESS,
  data,
});

export const uploadNationalIdFail = errorMassage => ({
  type: APPOINTMENTS.UPLOAD_NATIONAL_ID.FAIL,
  errorMassage,
});

export const fetchApprovalLetter = reservationKey => ({
  type: APPOINTMENTS.APPROVAL_LETTER.LOAD,
  reservationKey,
});

export const fetchApprovalLetterSuccess = data => ({
  type: APPOINTMENTS.APPROVAL_LETTER.SUCCESS,
  data,
});

export const fetchApprovalLetterFail = errorMassage => ({
  type: APPOINTMENTS.APPROVAL_LETTER.FAIL,
  errorMassage,
});

export const resetApprovalLetter = () => ({
  type: APPOINTMENTS.APPROVAL_LETTER.RESET,
});

export const uploadApprovalLetter = data => ({
  type: APPOINTMENTS.UPLOAD_APPROVAL_LETTER.LOAD,
  data,
});

export const uploadApprovalLetterSuccess = data => ({
  type: APPOINTMENTS.UPLOAD_APPROVAL_LETTER.SUCCESS,
  data,
});

export const uploadApprovalLetterFail = errorMassage => ({
  type: APPOINTMENTS.UPLOAD_APPROVAL_LETTER.FAIL,
  errorMassage,
});

export const addVitals = payload => ({
  type: APPOINTMENTS.ADD_VITALS.SUBMIT,
  payload,
});

export const addVitalsSuccess = payload => ({
  type: APPOINTMENTS.ADD_VITALS.SUCCESS,
  payload,
});

export const addVitalsFail = payload => ({
  type: APPOINTMENTS.ADD_VITALS.FAIL,
  payload,
});

export const updateVitals = payload => ({
  type: APPOINTMENTS.UPDATE_VITALS.SUBMIT,
  payload,
});

export const updateVitalsSuccess = payload => ({
  type: APPOINTMENTS.UPDATE_VITALS.SUCCESS,
  payload,
});

export const updateVitalsFail = payload => ({
  type: APPOINTMENTS.UPDATE_VITALS.FAIL,
  payload,
});

export const toggleViewServicesModal = payload => ({
  type: APPOINTMENTS.MODAL.VIEW_SERVICES_TOGGLE,
  payload,
});

export const toggleAddResultsModal = payload => ({
  type: APPOINTMENTS.MODAL.ADD_RESULTS_TOGGLE,
  payload,
});

export const toggleAttachmentsModal = payload => ({
  type: APPOINTMENTS.MODAL.ATTACHMENTS_TOGGLE,
  payload,
});

export const togglePhysicalServicesModal = payload => ({
  type: APPOINTMENTS.MODAL.PHYSICAL_SERVICES_TOGGLE,
  payload,
});

export const submitResults = payload => ({
  type: APPOINTMENTS.SUBMIT_RESULTS.SUBMIT,
  payload,
});

export const submitResultsSuccess = successMessage => ({
  type: APPOINTMENTS.SUBMIT_RESULTS.SUCCESS,
  successMessage,
});

export const submitResultsFail = errorMessage => ({
  type: APPOINTMENTS.SUBMIT_RESULTS.FAIL,
  errorMessage,
});

export const submitResultsReset = () => ({
  type: APPOINTMENTS.SUBMIT_RESULTS.RESET,
});

export const getResults = payload => ({
  type: APPOINTMENTS.GET_RESULTS.LOAD,
  payload,
});

export const getResultsSuccess = payload => ({
  type: APPOINTMENTS.GET_RESULTS.SUCCESS,
  payload,
});

export const getResultsFail = () => ({
  type: APPOINTMENTS.GET_RESULTS.FAIL,
});

export const getResultsReset = () => ({
  type: APPOINTMENTS.GET_RESULTS.RESET,
});
