import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { fetchInvoicesSuccess, fetchInvoicesFail, INVOICES } from '../actions/invoices';

function* fetchInvoices(action) {
  const bidsAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'x-vzt-timezone-utc-offset',
      value: `${action.userTimeZone}`,
    },
  ];

  const response = yield bidsAPI.post(Urls.getBillingInvoices, action.payload, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      fetchInvoicesSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      fetchInvoicesFail({
        ...action.payload,
      }),
    );
  }
}

function* inVoicesSaga() {
  yield takeLatest(INVOICES.FETCH, fetchInvoices);
}

export default inVoicesSaga;
