import { put, takeEvery } from 'redux-saga/effects';
import { STATUS } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import { AuthApi } from 'modules/sso';

import { VACATIONS } from '../actions/vacations';

function* sagaGetVacation(payload) {
  const { startDate, endDate } = payload;
  let bodyRequest = {
    AccountRooms: payload.selectedDoctors.map(doctor => ({
      AccountKey: doctor.AccountKey,
      RoomKey: doctor.RoomKey,
    })),
  };

  bodyRequest = Object.assign({}, bodyRequest, {
    From: startDate,
    To: endDate,
  });

  const apiObj = new AuthApi();
  const res = yield apiObj.post(Urls.getVacations, bodyRequest);
  if (STATUS.isSuccess(res.status)) {
    let vacations = [];
    if (res.data) {
      vacations = res.data.map(doctor => {
        const DoctorName = payload.doctorsNames.get(doctor.AccountKey);
        const RoomName = payload.roomsNames.get(doctor.RoomKey);
        const Model = [];
        const modelFrom = new Date(doctor.From);
        const modelTo = new Date(doctor.To);

        Model.push({
          From: `${modelFrom.getMonth() + 1}/${modelFrom.getDate()}/${modelFrom.getFullYear()} ${
            doctor.From.split('T')[1]
          }`,
          To: `${modelTo.getMonth() + 1}/${modelTo.getDate()}/${modelTo.getFullYear()} ${
            doctor.To.split('T')[1]
          }`,
        });

        return {
          Model,
          AccountKey: doctor.AccountKey,
          RoomKey: doctor.RoomKey,
          DoctorName,
          RoomName,
        };
      });
    }
    yield put({ type: VACATIONS.LOAD_DONE, vacations });
  } else {
    yield put({ type: VACATIONS.LOAD_FAIL });
  }
}

function getBodySetVacation(vacations = [], IsDeleted = 0) {
  const apiVacationModel = {
    ActionMaker: 'Doctor',
    AccountRoomActions: [],
  };

  const vacationDetails = vacations;
  vacationDetails.doctors.forEach(doctor => {
    const accountRoomActionsObj = {
      AccountKey: doctor.AccountKey,
      RoomKey: doctor.RoomKey,
      Model: [],
    };

    accountRoomActionsObj.Model.push({
      From: vacationDetails.startDate,
      To: vacationDetails.endDate,
      IsDeleted,
    });

    apiVacationModel.AccountRoomActions.push(accountRoomActionsObj);
  });
  return apiVacationModel;
}

function* sagaAddVacation(payload) {
  const bodyReq = getBodySetVacation(payload.vacation, 0);
  const apiObj = new AuthApi();
  const res = yield apiObj.post(Urls.setVacations, bodyReq);

  if (payload.callback) {
    payload.callback(payload.callbackParams);
  }

  if (STATUS.isSuccess(res.status)) {
    yield put({
      type: VACATIONS.ADD_DONE,
      vacation: bodyReq.AccountRoomActions,
      rooms: payload.rooms,
      doctors: payload.doctors,
    });
  } else {
    yield put({ type: VACATIONS.ADD_FAIL, res });
  }
}

function* sagaDeleteVacation(payload) {
  const bodyReq = getBodySetVacation(payload.deleteVacation, 1);
  const apiObj = new AuthApi();
  const res = yield apiObj.post(Urls.setVacations, bodyReq);

  if (STATUS.isSuccess(res.status)) {
    yield put({
      type: VACATIONS.DELETE_DONE,
      vacation: payload.deleteVacation,
    });
  } else {
    yield put({ type: VACATIONS.DELETE_FAIL, res });
  }
}

function* sagaCountReservationInRange(payload) {
  const vacationDetails = payload.data;
  const vacationDetailsBody = [];
  vacationDetails.doctors.forEach(doctor => {
    vacationDetailsBody.push({
      AccountKey: doctor.AccountKey,
      RoomKey: doctor.RoomKey,
      From: vacationDetails.startDate,
      To: vacationDetails.endDate,
    });
  });

  const apiObj = new AuthApi();
  const countReservationsInRangeApi = yield apiObj.post(
    Urls.countReservationsInRange,
    vacationDetailsBody,
  );
  if (STATUS.isSuccess(countReservationsInRangeApi.status)) {
    let reservationsInRange = 0;
    countReservationsInRangeApi.data.forEach(doctor => {
      reservationsInRange += doctor.ReservationsCount;
    });

    yield put({
      type: VACATIONS.RESERVATIONS_IN_RANGE.LOAD_DONE,
      reservationsInRange,
    });
  } else {
    yield put({
      type: VACATIONS.RESERVATIONS_IN_RANGE.LOAD_FAIL,
      countReservationsInRangeApi,
    });
  }
}

function* vacationSaga() {
  yield takeEvery(VACATIONS.LOAD, sagaGetVacation);
  yield takeEvery(VACATIONS.ADD, sagaAddVacation);
  yield takeEvery(VACATIONS.DELETE, sagaDeleteVacation);
  yield takeEvery(VACATIONS.RESERVATIONS_IN_RANGE.LOAD, sagaCountReservationInRange);
}

export default vacationSaga;
