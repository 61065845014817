export const CLINICS = {
  LOAD: 'CLINICS_LOAD',
  LOAD_DONE: 'CLINICS_LOAD_DONE',
  LOAD_FAIL: 'CLINICS_LOAD_FAIL',
};

export const BRANCH_DETAILS = {
  LOAD: 'BRANCH_DETAILS_LOAD',
  SUCCESS: 'BRANCH_DETAILS_SUCCESS',
  FAIL: 'BRANCH_DETAILS_FAIL',
};

export const BRANCH_FILTER = {
  SET_BRANCH_KEY: 'SET_BRANCH_KEY',
};

export const setFilteredBranchKey = branchKey => ({
  type: BRANCH_FILTER.SET_BRANCH_KEY,
  branchKey,
});

export const loadClinics = language => ({
  type: CLINICS.LOAD,
  language,
});

export const loadClinicsDone = clinics => ({
  type: CLINICS.LOAD_DONE,
  clinics,
});

export const loadClinicsFail = err => ({
  type: CLINICS.LOAD_FAIL,
  err,
});

export const getBranchDetails = branchKey => ({
  type: BRANCH_DETAILS.LOAD,
  branchKey,
});

export const getBranchDetailsSuccess = payload => ({
  type: BRANCH_DETAILS.SUCCESS,
  payload,
});

export const getBranchDetailsFail = payload => ({
  type: BRANCH_DETAILS.FAIL,
  payload,
});
